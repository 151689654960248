<template>
  <div style="padding-left: 10px; padding-right: 10px;">
    <select
      v-model="run[association_1][attribute]"
      :disabled="isDisabled"
      @change="$emit('input-change')"
      @blur="validate()"
      class="form-control select optional"
      :name="inputName"
      :id="inputID">
      <option v-for="option in options" :value="option['value']">
        {{ option['label'] }}
      </option>
    </select>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'association_1',
      'attribute',
      'required',
      'index',
      'run',
      'varyBySite'
    ],

    data: function() {
      return {
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'runsClientSideValidations',
        'labels',
        'portfolio',
        'selectOptions',
        'tooltips',
        'runsPlaceholders'
      ]),
      label: function() {
        return this.labels[this.association_1][this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.attribute}`;
      },
      isDisabled: function() {
        if (!this.varyBySite && (this.attribute == "include_exported_renewable_electricity_in_total" || this.attribute == "include_exported_elec_emissions_in_total") && this.index != 0) {
          return true;
        } else {
          return false;
        }
      },
      inputID: function() {
        if (this.index >= 0) {
          return `run_${this.association_1}_attributes_${this.attribute}_${this.index + 1}`;
        } else {
          return `run_${this.association_1}_attributes_${this.attribute}`;
        }
        // return `run_${this.association_1}_attributes_attributes_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.attribute}]`;
      },
      options: function() {
        return this.selectOptions[this.association_1][this.attribute]["options"];
      },
      validateErrorMessage: function() {
        return this.portfolio.runsClientSideValidations.run[this.association_1][this.attribute];
      }
    },

    methods: {
      validate: function() {
        if(this.required && !this.run[this.association_1][this.attribute]) {
          this.validateError = true;
        } else if(this.required && this.run[this.association_1][this.attribute]) {
          this.validateError = false;
        }
      }
    },

    watch: {
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[0].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[0].classList.remove('has-error');
        }
      }
    }
  }
</script>
