<template>
    <div class="form-horizontal">
      <input
        v-model="run.site.prime_generator.prime_mover_type"
        name="run[site_attributes][prime_generator_attributes][prime_mover_type]"
        id="prime_generator_prime_mover_type_hidden"
        type="hidden" />
      <horizontal-select-input-nested-2
        association_1='site'
        association_2='prime_generator'
        attribute='prime_mover_type' />
      <input
        v-model="run.site.prime_generator.size_class"
        name="run[site_attributes][prime_generator_attributes][size_class]"
        id="prime_generator_size_class_hidden"
        type="hidden" />
      <horizontal-select-input-nested-2
        association_1='site'
        association_2='prime_generator'
        attribute='size_class' />
      <div class="col-sm-offset-6 col-sm-6 custom-rate-checkbox" style="margin-bottom:20px;">
        <input
          v-model="run.site.prime_generator.custom_prime_mover"
          class="checkbox-inline"
          type="checkbox"
          value="1"
          name="run[site_attributes][prime_generator_attributes][custom_prime_mover]"
          id="run_site_attributes_prime_generator_attributes_custom_prime_mover" />
        <label class="custom-electricity-rate-label" for="run_site_attributes_prime_generator_attributes_custom_prime_mover">
          Change default prime mover &amp; size class?
        </label>
        <tooltip
          :association_1="'site'"
          :association_2="'prime_generator'"
          :attribute="'change_default'" />
      </div>
      <div>
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='prime_generator'
          attribute='min_kw' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='prime_generator'
          attribute='min_allowable_kw' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='prime_generator'
          attribute='max_kw' />
  
        <div class="row prime-generator-single-cost">
          <div class="col-sm-6">&nbsp;</div>
          <div class="col-sm-4">
            <input
              name="run[site_attributes][prime_generator_attributes][single_cost_for_all_sizes]"
              type="hidden"
              value="0" />
            <input
              v-model="run.site.prime_generator.single_cost_for_all_sizes"
              class="checkbox-inline"
              type="checkbox"
              value="1"
              name="run[site_attributes][prime_generator_attributes][single_cost_for_all_sizes]"
              id="run_site_attributes_prime_generator_attributes_single_cost_for_all_sizes" />
            <label class="custom-electricity-rate-label" for="run_site_attributes_prime_generator_attributes_single_cost_for_all_sizes">Use single cost ($/kW) for all sizes? <tooltip :association_1="'site'" :association_2="'prime_generator'" :attribute="'single_cost_all_sizes'"></tooltip></label>
          </div>
        </div>
  
        <horizontal-string-input-nested-2
          v-if="run.site.prime_generator.single_cost_for_all_sizes"
          association_1='site'
          association_2='prime_generator'
          attribute='installed_cost_per_kw'
          required='true' />
        <div v-else class="cost-curve-grid">
          <div class="form-group size-cost-header">
            <div class="col-sm-offset-6 col-sm-6">
              <div>Size-Cost Pair 1</div>
              <div>Size-Cost Pair 2</div>
            </div>
          </div>
          <div class="form-group chp-cost-curve">
            <div class="col-sm-6 control-label">
              <strong>Electric power capacity (kW)&nbsp;<tooltip :association_1="'site'" :association_2="'prime_generator'" :attribute="'tech_sizes_for_cost_curve'"></tooltip></strong>
            </div>
            <div class="col-sm-6" style="display:flex;">
              <div class="cost-cell">
                <input
                  v-model="run.site.prime_generator.tech_sizes_for_cost_curve_1"
                  :placeholder="placeholders['site']['prime_generator']['tech_sizes_for_cost_curve_1']"
                  class="numeric decimal optional form-control"
                  label="false"
                  aria-label="Tech size for cost curve 1 of 2"
                  type="number"
                  step="any"
                  name="run[site_attributes][prime_generator_attributes][tech_sizes_for_cost_curve_1]" />
              </div>
              <div class="cost-cell">
                <input
                  v-model="run.site.prime_generator.tech_sizes_for_cost_curve_2"
                  :placeholder="placeholders['site']['prime_generator']['tech_sizes_for_cost_curve_2']"
                  class="numeric decimal optional form-control"
                  label="false"
                  aria-label="Tech size for cost curve 2 of 2"
                  type="number"
                  step="any"
                  name="run[site_attributes][prime_generator_attributes][tech_sizes_for_cost_curve_2]" />
              </div>
            </div>
          </div>
          <div class="form-group chp-cost-curve">
            <div class="col-sm-6 control-label">
              <strong>Total installed cost ($/kW)&nbsp;<tooltip :association_1="'site'" :association_2="'prime_generator'" :attribute="'installed_cost_per_kw'"></tooltip></strong>
            </div>
            <div class="col-sm-6" style="display:flex;">
              <div class="cost-cell">
                <input
                  v-model="run.site.prime_generator.installed_cost_per_kw_1"
                  :placeholder="placeholders['site']['prime_generator']['installed_cost_per_kw_1']"
                  class="numeric decimal optional form-control"
                  label="false"
                  aria-label="Installed cost us dollars per kw 1 of 2"
                  type="number"
                  step="any"
                  name="run[site_attributes][prime_generator_attributes][installed_cost_per_kw_1]" />
              </div>
              <div class="cost-cell">
                <input
                  v-model="run.site.prime_generator.installed_cost_per_kw_2"
                  :placeholder="placeholders['site']['prime_generator']['installed_cost_per_kw_2']"
                  class="numeric decimal optional form-control"
                  label="false"
                  aria-label="Installed cost us dollars per kw 2 of 2"
                  type="number"
                  step="any"
                  name="run[site_attributes][prime_generator_attributes][installed_cost_per_kw_2]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row section-actions">
        <div class="col-sm-6 col-sm-offset-6">
          <div class="toggle-more-inputs pull-left">
            <a @click="toggleAdvancedInputs();">
              <span v-if="displayAdvancedInputs">
                <font-awesome-icon icon='minus-square' />
                Show fewer inputs
              </span>
              <span v-else>
                <font-awesome-icon icon='plus-square' />
                Advanced inputs
              </span>
            </a>
          </div>
          <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
            <a @click="resetInputs()">
              <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
            </a>
          </div>
        </div>
      </div>
  
      <div v-show="displayAdvancedInputs">
        <div>
          <h4>Prime Generator Maintenance Schedule</h4>
          
          <input
            v-model="run.site.prime_generator.downtime_schedule_type"
            type="hidden"
            name="run[site_attributes][prime_generator_attributes][downtime_schedule_type]" />

          <ul class="nav nav-tabs">
            <li class="active"><a @click="updateDowntimeScheduleType('default')" data-toggle="tab" href="#prime_generator_maintenance_default_input"><i class="fas fa-calendar-alt" aria-hidden="true"></i> Default</a></li>
            <li><a @click="updateDowntimeScheduleType('uploaded')" data-toggle="tab" href="#prime_generator_maintenance_custom_input"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade in active" id="prime_generator_maintenance_default_input" data-default-schedule="">
              <p style="text-align:center;">A default maintenance schedule has been selected based on your prime mover type and the year of your electric load profile.</p>
              <p style="text-align:center;">Use the <strong>summary table</strong> and <strong>detailed table</strong> links below to view the details of the default schedule.</p>
            </div>
            
            <input
              v-model="run.site.prime_generator.downtime_schedule"
              type="hidden"
              name="run[site_attributes][prime_generator_attributes][downtime_schedule]" />

            <div class="tab-pane fade" id="prime_generator_maintenance_custom_input">
              <div class="form-group">
                <div class="col-sm-6 control-label">
                  <label class="file required" for="run_site_attributes_prime_generator_attributes_downtime_schedule_file"><abbr title="Required" class="required">*</abbr> Custom downtime schedule</label><a href="#" rel="tooltip" title="The default downtime maintenance schedule can be overwritten by uploading a custom downtime schedule. The format for a custom downtime schedule needs to conform to the .csv format provided by downloading the default schedule. All values must be integers. It is important to match up the custom schedule with the year's calendar. If using a reference building, the calendar year is equivalent to 2017. If uploading a custom load profile, the calendar year is the user-entered 'Year of load profile'. Monday is considered the first day of the week (start_day_of_week = 1). The valid start_hour range is 1 – 24. The first week of the month (start_week_of_month = 1) may not contain a start_day_of_week equal to Monday (1) – Saturday (6). For example, if the month starts on a Sunday, then the 1st week of the month consists of only that Sunday (start_day_of_week = 7), and the 2nd week of the month starts on the next day (Monday). For more information, please see the Prime Generator scheduled and unscheduled maintenance section in the <a href='/tool/reopt-user-manual.pdf' target='_blank' rel='noopener noreferrer'>help manual</a>."><i class="fas fa-question-circle" aria-hidden="true"></i></a>
                </div>
                <div class="col-sm-4">
                  <input class="form-control hidden" type="hidden" name="run[site_attributes][prime_generator_attributes][downtime_schedule_file]" id="run_site_attributes_prime_generator_attributes_downtime_schedule_file" />
                  <input @change="parseCSV($event)" class="form-control-file file required load-profile-file-upload" required="required" aria-required="true" type="file" name="run[site_attributes][prime_generator_attributes][downtime_schedule_file]" />
                  <!-- <p v-if="run.copied && this.run.site.prime_generator.downtime_schedule_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.prime_generator.downtime_schedule_file_data).metadata.filename }}</p> -->
                  <p v-if="run.copied && this.run.site.prime_generator.downtime_schedule_file_name" class="help-block">Uploaded file: {{ this.run.site.prime_generator.downtime_schedule_file_name }}</p>
                </div>
              </div>
            </div>
          </div>
          
          <div v-if="run.site.prime_generator.downtime_schedule_type == 'default'" class="row" style="display:flex;justify-content:space-between;">
            <div class="col-sm-7">
              <a v-if="!primegenMaintenanceScheduleLoading" @click="downloadPrimeGenSchedule()" class="chp-scheduler-download-link load-profile-action no-visit-color" style="margin-right:15px;"><i class="fas fa-download" aria-hidden="true"></i>Download schedule</a>
              <div v-else class="load-profile-spinner" data-load-type="typical">
                <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
              </div>
            </div>
  
            <div class="col-sm-5" style="display:flex;justify-content:flex-end;">
              <a v-if="!primegenMaintenanceScheduleLoading" @click="displayPrimeGenScheduleDefaultSummary()" class="primegen-scheduler-summary-view load-profile-action no-visit-color" style="margin-right:15px;"><i class="fas fa-list-ul" aria-hidden="true"></i>View summary table</a>
  
              <a v-if="!primegenMaintenanceScheduleLoading" @click="displayPrimeGenScheduleDefaultDetailed()" class="primegen-scheduler-detailed-view load-profile-action no-visit-color"><i class="fas fa-table" aria-hidden="true"></i>View detailed table</a>
              <div v-else class="load-profile-spinner" data-load-type="typical">
                <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div v-else-if="run.site.prime_generator.downtime_schedule_type == 'uploaded'" class="row" style="display:flex;justify-content:space-between;">
            <div class="col-sm-7">
            </div>
            <div class="col-sm-5" style="display:flex;justify-content:flex-end;">
              <a v-if="!primegenMaintenanceScheduleLoading" @click="displayPrimeGenScheduleUploadedSummary()" class="primegen-scheduler-summary-view load-profile-action no-visit-color" style="margin-right:15px;"><i class="fas fa-list-ul" aria-hidden="true"></i>View summary table</a>
  
              <a v-if="!primegenMaintenanceScheduleLoading" @click="displayPrimeGenScheduleUploadedDetailed()" class="primegen-scheduler-detailed-view load-profile-action no-visit-color"><i class="fas fa-table" aria-hidden="true"></i>View detailed table</a>
              <div v-else class="load-profile-spinner" data-load-type="typical">
                <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
              </div>
            </div>
          </div>
  
          <h4>Prime Generator Costs</h4>
          <div class="form-horizontal">
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='prime_generator'
              attribute='om_cost_per_kw' />
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='prime_generator'
              attribute='om_cost_per_kwh' />
          </div>
  
          <h4>Prime Generator System Characteristics</h4>
          <div class="form-horizontal">
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='prime_generator'
              attribute='electric_efficiency_full_load'
              v-on:input-change="displayHalfLoadWarning()" />
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='prime_generator'
              attribute='electric_efficiency_half_load'
              v-on:input-change="displayHalfLoadWarning()" />
            <horizontal-string-input-nested-2
              association_1='site'
              association_2='prime_generator'
              attribute='min_turn_down_fraction' />
          </div>
  
          <h4>Prime Generator Incentives and Tax Treatment</h4>
          <incentives-table
            association_1='site'
            association_2='prime_generator' />
  
          <h5>Tax Treatment</h5>
          <div class="form-horizontal">
            <horizontal-select-input-nested-2
              association_1='site'
              association_2='prime_generator'
              attribute='macrs_option_years' />
            <horizontal-select-input-nested-2
              association_1='site'
              association_2='prime_generator'
              attribute='macrs_bonus_fraction' />
          </div>
        </div>
      </div>
  
      <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
        <a @click="resetInputs()">
          <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
        </a>
      </div>
      
      <transition name="fade">
        <prime-gen-scheduler-summary
          v-if="showPrimeGenDefaultSummarySchedule"
          :scheduleType="'primegenMaintenanceDefaultSchedule'"/>
      </transition>
      <transition name="fade">
        <prime-gen-scheduler-detailed
          v-if="showPrimeGenDefaultDetailedSchedule"
          :scheduleType="'primegenMaintenanceDefaultSchedule'"/>
      </transition>
      <transition name="fade">
        <prime-gen-scheduler-summary
          v-if="showPrimeGenUploadedSummarySchedule"
          :scheduleType="'primegenMaintenanceUploadedSchedule'"/>
      </transition>
      <transition name="fade">
        <prime-gen-scheduler-detailed
          v-if="showPrimeGenUploadedDetailedSchedule"
          :scheduleType="'primegenMaintenanceUploadedSchedule'"/>
      </transition>
    </div>
  </template>
  
  <script>
    import { mapGetters } from 'vuex';
    import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
    import HorizontalStringInputNested3 from './inputs/HorizontalStringInputNested3';
    import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
    import HorizontalSelectInputNested3 from './inputs/HorizontalSelectInputNested3';
    import Tooltip from './Tooltip'
    import IncentivesTable from './inputs/IncentivesTable'
    import PrimeGenSchedulerSummary from './modals/PrimeGenSchedulerSummary'
    import PrimeGenSchedulerDetailed from './modals/PrimeGenSchedulerDetailed'
  
    export default {
      components: {
        // nested 2 b/c run.site.financial - measured from run
        PrimeGenSchedulerSummary,
        PrimeGenSchedulerDetailed,
        HorizontalStringInputNested2,
        HorizontalStringInputNested3,
        HorizontalSelectInputNested2,
        HorizontalSelectInputNested3,
        IncentivesTable,
        Tooltip,
      },
  
      data: function() {
        return {
          displayAdvancedInputs: false,
          roundTripEfficiency: 89.9
        }
      },
  
      computed: {
        ...mapGetters([
          'primegenMaintenanceDefaultSchedule',
          'primegenMaintenanceUploadedSchedule',
          'primegenMaintenanceScheduleLoading',
          'chpHeuristicSize',
          'run',
          'placeholders',
          'selectOptions',
          'showPrimeGenDefaultDetailedSchedule',
          'showPrimeGenDefaultSummarySchedule',
          'showCHPMaxSizeButton',
          'showPrimeGenUploadedDetailedSchedule',
          'showPrimeGenUploadedSummarySchedule',
          'urlRoot',
          'year'
        ])
      },
  
      methods: {
        displayHalfLoadWarning: function() {
          bootbox.alert('Including part-load efficiency differences is known to slow down the model and it could cause timeouts (no solution given). It is advised to fix the Prime Generator size if wanting to model part-load efficiency. To fix the size, enter the same value for Minimum and Maximum Electric Power Capacity (kW).');
        },
        toggleAdvancedInputs: function() {
          this.displayAdvancedInputs = !this.displayAdvancedInputs;
        },
        updateRoundTripEfficiency: function() {
          var rectifier = (this.run.site.storage.rectifier_efficiency_fraction / 100.0) || 0.96
          var internal = (this.run.site.storage.internal_efficiency_fraction / 100.0) || 0.975
          var inverter = (this.run.site.storage.inverter_efficiency_fraction / 100.0) || 0.96
          this.roundTripEfficiency = rectifier * internal * inverter * 100;
        },
        updateStore: function(payload) {
          // this is for 'standard' inputs
          var payload = {
            association_1: "site",
            association_2: "prime_generator",
            attribute: payload.attribute,
            value: payload.value,
          }
          this.$store.commit('updateValueNested2', payload);
        },
        updateSizeClasses: function() {
          var type = this.run.site.prime_generator.prime_mover_type;
          var payload = {
            association_2: 'prime_generator',
            input: 'size_class',
            value: { selected: 0 }
          };
          if(type == "recip_engine") {
            payload.value.options = [
              { label: "Size Class 0 (35 - 4,500 kW)", value: 0 },
              { label: "Size Class 1 (35 - 100 kW)", value: 1 },
              { label: "Size Class 2 (100 - 250 kW)", value: 2 },
              { label: "Size Class 3 (250 - 500 kW)", value: 3 },
              { label: "Size Class 4 (500 - 1,000 kW)", value: 4 },
              { label: "Size Class 5 (1,000 - 2,000 kW)", value: 5 },
              { label: "Size Class 6 (2,000 - 3,000)", value: 6 },
              { label: "Size Class 7 (3,000 - 4,500 kW)", value: 7 },
            ]
          } else if(type == "micro_turbine") {
            payload.value.options = [
              { label: "Size Class 0 (65 - 1,000 kW)", value: 0 },
              { label: "Size Class 1 (65 - 200 kW)", value: 1 },
              { label: "Size Class 2 (200 - 600 kW)", value: 2 },
              { label: "Size Class 3 (600 - 1,000 kW)", value: 3 },
            ]
          } else if(type == "combustion_turbine") {
            payload.value.options = [
              { label: "Size Class 0 (2,000 - 25,000 kW)", value: 0 },
              { label: "Size Class 1 (2,000 - 3,500 kW)", value: 1 },
              { label: "Size Class 2 (3,500 - 5,000 kW)", value: 2 },
              { label: "Size Class 3 (5,000 - 8,000 kW)", value: 3 },
              { label: "Size Class 4 (8,000 - 10,000 kW)", value: 4 },
              { label: "Size Class 5 (10,000 - 15,000 kW)", value: 5 },
              { label: "Size Class 6 (15,000 - 25,000 kW)", value: 6 },
            ]
          } else if(type == "steam_turbine") {
            payload.value.options = [
              { label: "Size Class 0 (0 - 25,000 kW)", value: 0 },
              { label: "Size Class 1 (0 - 1,000 kW)", value: 1 },
              { label: "Size Class 2 (1,000 - 5,000 kW)", value: 2 },
              { label: "Size Class 3 (5,000 - 25,000 kW)", value: 3 },
            ]
          } else if(type == "fuel_cell") {
            payload.value.options = [
              { label: "Size Class 0 (440 - 9,300 kW)", value: 0 },
              { label: "Size Class 1 (440 - 1,400 kW)", value: 1 },
              { label: "Size Class 2 (1,400 - 9,300 kW)", value: 2 },
            ]
          }
  
          this.$store.commit('updateSelectOptionsForInput', payload);
        },
        getPrimeGenSchedule: function() {
          this.$store.commit('updateObject', { object: 'primegenMaintenanceScheduleLoading', value: true });
  
          var self = this;
          $.ajax({
            url: self.urlRoot + '/primegen-schedule-stats-default',
            contentType: 'application/json',
            data: {
              prime_mover: self.run.site.prime_generator.prime_mover_type,
              year: 2017,
            },
            success: function handleResults(data) {
              self.$store.commit('updateObject', { object: 'primegenMaintenanceDefaultSchedule', value: data });
              setTimeout(function() {
                self.$store.commit('updateObject', { object: 'primegenMaintenanceScheduleLoading', value: false });
              }, 800)
            },
            error: function() {
              self.$store.commit('updateObject', { object: 'primegenMaintenanceDefaultSchedule', value: {} });
              setTimeout(function() {
                self.$store.commit('updateObject', { object: 'primegenMaintenanceScheduleLoading', value: false });
              }, 800)
  
              return false;
            },
          })
        },
        displayPrimeGenScheduleDefaultSummary: function() {
          this.$store.commit('updateObject', { object: 'showPrimeGenDefaultSummarySchedule', value: true });
        },
        displayPrimeGenScheduleUploadedSummary: function() {
          if(Object.keys(this.primegenMaintenanceUploadedSchedule).length > 0) {
            this.$store.commit('updateObject', { object: 'showPrimeGenUploadedSummarySchedule', value: true });
          } else {
            bootbox.alert('You must upload a valid custom downtime schedule.')
          }
        },
        displayPrimeGenScheduleDefaultDetailed: function() {
          this.$store.commit('updateObject', { object: 'showPrimeGenDefaultDetailedSchedule', value: true });
        },
        displayPrimeGenScheduleUploadedDetailed: function() {
          if(Object.keys(this.primegenMaintenanceUploadedSchedule).length > 0) {
            this.$store.commit('updateObject', { object: 'showPrimeGenUploadedDetailedSchedule', value: true });
          } else {
            bootbox.alert('You must upload a valid custom downtime schedule.')
          }
        },
        downloadPrimeGenSchedule: function() {
          var length = Object.keys(this.primegenMaintenanceDefaultSchedule.chp_unavailability_periods).length;
          var rows = [ ['month', 'start_week_of_month', 'start_day_of_week (1=Monday)', 'start_hour (1-24)', 'duration_hours'] ];
  
          var self = this;
          for(var i = 0; i < length; i ++) {
            var period = self.primegenMaintenanceDefaultSchedule.chp_unavailability_periods[i];
  
            rows.push([
              period.month,
              period.start_week_of_month,
              period.start_day_of_week,
              period.start_hour,
              period.duration_hours
            ])
          }
  
          var csv = Papa.unparse(rows);
          var blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, `${this.year}_${this.run.site.prime_generator.prime_mover_type}_default_schedule.csv`);
        },
        updateDowntimeScheduleType: function(type) {
          this.updateStore({ attribute: 'downtime_schedule_type', value: type });
        },
        updatePrimeGenMaxSize: function() {
          var maxSize = Number(this.chpHeuristicSize).toFixed(0);

          this.updateStore({ attribute: 'max_kw', value: maxSize });
          // TODO: below is a bit hacky but for w/e reason the above will update the
          // data in the vuex store but not the input :/
          document.getElementById("run_site_attributes_chp_attributes_max_kw").value = maxSize;
        },
        parseCSV: function(event) {
          this.$store.commit('updateObject', { object: 'primegenMaintenanceScheduleLoading', value: true });
          var file = event.target.files[0];
  
          if(file && file.name.split(".")[1] != "csv") {
            bootbox.alert("Uploaded file " + file.name + " must be a .csv");
            this.run.site.load_profile.loads_kw = [];
            event.target.value = "";
  
            return false;
          }
  
          var self = this;
          if(file) {
            Papa.parse(file, {
              delimiter: ",",
              complete: function(results) {
                try {
                  if(results.errors && results.errors.length > 0) {
                    bootbox.alert('The custom downtime schedule could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);
                    return false;
                  }
  
                  var rows = results.data;
                  var headerRow = rows[0];
                  if(headerRow.length !== 5 || !headerRow.includes("month") || !headerRow.includes("start_week_of_month") || (!headerRow.includes("start_day_of_week") && !headerRow.includes("start_day_of_week (1=Monday)")) || (!headerRow.includes("start_hour") && !headerRow.includes("start_hour (1-24)")) || !headerRow.includes("duration_hours")) {
                    bootbox.alert('The custom downtime schedule headers must only include month, start_week, start_day_of_week, start_hour, and duration_hours in that order');
                    return false;
                  }
  
                  if(!rows[rows.length - 1][0]) {
                    // if last row is empty line remove it
                    rows.pop();
                  }
  
                  for(var i = 1, len = rows.length; i < len; i++) {
                    var rowData = rows[i];
                    var allIntegers = rowData.every(function(el, i) {
                      return !isNaN(parseInt(el))
                    })
                    if(!allIntegers) {
                      bootbox.alert('All values in the csv must only be integers. Check row ' + i);
                      return false;
                    }
                  }
  
                  var payload = {
                    "year": self.year,
                    "chp_unavailability_periods": []
                  }
  
                  for(var i = 1, len = rows.length; i < len; i++) {
                    var rowData = rows[i];
                    var period = {
                      "month": parseInt(rowData[0]),
                      "start_week_of_month": parseInt(rowData[1]),
                      "start_day_of_week": parseInt(rowData[2]),
                      "start_hour": parseInt(rowData[3]),
                      "duration_hours": parseInt(rowData[4]),
                    }
                    payload["chp_unavailability_periods"].push(period);
                  }
  
                  $.ajax({
                    method: 'POST',
                    data: JSON.stringify(payload),
                    url: self.urlRoot + '/primegen-schedule-stats-upload',
                    success: function handleResults(data) {
                      self.$store.commit('updateObject', { object: 'primegenMaintenanceUploadedSchedule', value: data });
                      self.updateStore({ attribute: 'downtime_schedule', value: JSON.stringify(data) });
                      self.updateStore({ attribute: 'downtime_schedule_file_name', value: file.name });
                    },
                    error: function(data) {
                      bootbox.alert(data.responseJSON.Error);
                      self.$store.commit('updateObject', { object: 'primegenMaintenanceUploadedSchedule', value: {} });
                      self.updateStore({ attribute: 'downtime_schedule_file_name', value: '' });
  
                      return false;
                    }
                  });
                } finally {
                  setTimeout(function() {
                    self.$store.commit('updateObject', { object: 'primegenMaintenanceScheduleLoading', value: false });
                  }, 800)
                }
              }
            })
          }
        },
        resetInputs: function(event) {
          if(confirm("Are you sure you want to reset the inputs in the Prime Generator section to their default values?")) {
            if(this.run.site.prime_generator.prime_mover_type != 'recip_engine') this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'prime_mover_type', value: 'recip_engine'});
            if(this.run.site.prime_generator.size_class != 0) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'size_class', value: 0});
            if(this.run.site.prime_generator.custom_prime_mover) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'custom_prime_mover', value: false});
            if(this.run.site.prime_generator.min_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'min_kw', value: ''});
            if(this.run.site.prime_generator.min_allowable_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'min_allowable_kw', value: ''});
            if(this.run.site.prime_generator.max_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'max_kw', value: ''});
            if(this.run.site.prime_generator.single_cost_for_all_sizes) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'single_cost_for_all_sizes', value: false});
            if(this.run.site.prime_generator.installed_cost_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'installed_cost_per_kw', value: ''});
            if(this.run.site.prime_generator.tech_sizes_for_cost_curve_1) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'tech_sizes_for_cost_curve_1', value: ''});
            if(this.run.site.prime_generator.tech_sizes_for_cost_curve_2) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'tech_sizes_for_cost_curve_2', value: ''});
            if(this.run.site.prime_generator.installed_cost_per_kw_1) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'installed_cost_per_kw_1', value: ''});
            if(this.run.site.prime_generator.installed_cost_per_kw_2) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'installed_cost_per_kw_2', value: ''});
  
            if(this.run.site.prime_generator.downtime_schedule_type != 'default') this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'downtime_schedule_type', value: 'default'});
            if(this.run.site.prime_generator.downtime_schedule_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'downtime_schedule_file_name', value: ''});
            if(this.run.site.prime_generator.om_cost_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'om_cost_per_kw', value: ''});
            if(this.run.site.prime_generator.om_cost_per_kwh) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'om_cost_per_kwh', value: ''});
            if(this.run.site.prime_generator.electric_efficiency_full_load) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'electric_efficiency_full_load', value: ''});
            if(this.run.site.prime_generator.electric_efficiency_half_load) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'electric_efficiency_half_load', value: ''});
            if(this.run.site.prime_generator.min_turn_down_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'min_turn_down_fraction', value: ''});
  
            if(this.run.site.prime_generator.federal_itc_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'federal_itc_fraction', value: '' });
            if(this.run.site.prime_generator.federal_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'federal_rebate_per_kw', value: '' });
            if(this.run.site.prime_generator.state_ibi_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'state_ibi_fraction', value: '' });
            if(this.run.site.prime_generator.state_ibi_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'state_ibi_max', value: '' });
            if(this.run.site.prime_generator.state_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'state_rebate_per_kw', value: '' });
            if(this.run.site.prime_generator.state_rebate_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'state_rebate_max', value: '' });
            if(this.run.site.prime_generator.utility_ibi_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'utility_ibi_fraction', value: '' });
            if(this.run.site.prime_generator.utility_ibi_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'utility_ibi_max', value: '' });
            if(this.run.site.prime_generator.utility_rebate_per_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'utility_rebate_per_kw', value: '' });
            if(this.run.site.prime_generator.utility_rebate_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'utility_rebate_max', value: '' });
            if(this.run.site.prime_generator.production_incentive_per_kwh) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'production_incentive_per_kwh', value: '' });
            if(this.run.site.prime_generator.pbi_years) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'pbi_years', value: '' });
            if(this.run.site.prime_generator.production_incentive_max_benefit) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'production_incentive_max_benefit', value: '' });
            if(this.run.site.prime_generator.pbi_system_max_kw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'pbi_system_max_kw', value: '' });
            if(this.run.site.prime_generator.macrs_option_years != 5) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'macrs_option_years', value: 5 });
            if(this.run.site.prime_generator.macrs_bonus_fraction != 1) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'macrs_bonus_fraction', value: 1 });
  
            window.scrollTo(0, document.getElementById('prime_generator_heading').offsetTop);
          }
        }
      },
  
      mounted: function() {
        this.getPrimeGenSchedule();

        var elecLoadProfile = this.$store.getters.elecLoadProfile;
        if(this[elecLoadProfile]) {
          this.$emit('update-prime-generator-defaults');
        }

        // for copied runs
        if(this.run.site.prime_generator.downtime_schedule_file_data) {
          document.getElementById("run_site_attributes_prime_generator_attributes_downtime_schedule_file").value = this.run.site.prime_generator.downtime_schedule_file_data;
        }
      },
  
      watch: {
        'run.site.prime_generator.prime_mover_type': function() {
          this.updateSizeClasses();
          this.getPrimeGenSchedule();
  
          // if this conditional is not in place - the other attributes that update the
          // chp defaults (run.site.chp.boiler.existing_boiler_production_type, thermalSimBuildAnnualLoadProfile, etc.)
          // would set off a loop b/c they also update CHP.prime_mover_type and CHP.size_class
          if(this.run.site.prime_generator.custom_prime_mover) {
            this.$emit('update-prime-generator-defaults');
          }
        },
        'run.site.prime_generator.size_class': function() {
          if(this.run.site.prime_generator.custom_prime_mover) {
            this.$emit('update-prime-generator-defaults');
          }
        },
        'run.site.prime_generator.custom_prime_mover': function() {
          // we have hidden inputs for CHP.prime_mover_type and CHP.size_class b/c
          // they still need to be submitted with the form despite being disabled
          // to the user - hence disableing the hidden fields when the select fields
          // are enabled
          // https://stackoverflow.com/questions/368813/html-form-readonly-select-tag-input
          if(this.run.site.prime_generator.custom_prime_mover) {
            document.getElementById("prime_generator_prime_mover_type_hidden").disabled = true;
            document.getElementById("prime_generator_size_class_hidden").disabled = true;
          } else {
            document.getElementById("prime_generator_prime_mover_type_hidden").disabled = false;
            document.getElementById("prime_generator_size_class_hidden").disabled = false;
          }
        }
      }
    }
  </script>
  