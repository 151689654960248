<template>
  <table class="fixed-incentive" style="width: 190%;">
    <tr>
      <th class="col-sm-3"></th>
      <th class="col-sm-3" style="padding-bottom: 15px;">
        Production incentive ($/kWh)
        <a
          href="#"
          rel="tooltip"
          title="The dollar value of the incentive per kWh produced. <b>This value is not required.</b> For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank'>help manual</a>.">
          <i class="fas fa-question-circle" aria-hidden="true"></i>
        </a>
      </th>
      <th class="col-sm-3">
        Incentive duration (yrs)
        <a
          href="#"
          rel="tooltip"
          title="The number of years the production incentive is awarded. <b>This value is not required.</b> For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank'>help manual</a>.">
          <i class="fas fa-question-circle" aria-hidden="true"></i>
        </a>
      </th>
      <th class="col-sm-3">
        Maximum incentive ($)
        <a
          href="#"
          rel="tooltip"
          title="The maximum incentive amount awarded each year; if there is no limit, the value defaults to ‘Unlimited’. <b>This value is not required.</b> For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank'>help manual</a>.">
          <i class="fas fa-question-circle" aria-hidden="true"></i>
        </a>
      </th>
      <th class="col-sm-3">
        System size limit (kW)
        <a
          href="#"
          rel="tooltip"
          title="The maximum system size eligible for the production based incentive; if there is no limit, the value defaults to 'Unlimited'. <b>This value is not required.</b> For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank'>help manual</a>.">
          <i class="fas fa-question-circle" aria-hidden="true"></i>
        </a>
      </th>
    </tr>
    <tr>
      <th class="col-sm-3">
        <label class="control-label"> Total </label>
      </th>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="production_incentive_per_kwh"
          :run="portfolioRun"
          v-on:input-change="updateInputValueForRuns('production_incentive_per_kwh', portfolioRun[association_1][association_2]['production_incentive_per_kwh'])" />
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="pbi_years"
          :run="portfolioRun"
          v-on:input-change="updateInputValueForRuns('pbi_years', portfolioRun[association_1][association_2]['pbi_years'])" />
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="production_incentive_max_benefit"
          :run="portfolioRun"
          v-on:input-change="updateInputValueForRuns('production_incentive_max_benefit', portfolioRun[association_1][association_2]['production_incentive_max_benefit'])" />
      </td>
      <td class="col-sm-3">
        <portfolio-incentives-table-string-input-nested-2
          :association_1="association_1"
          :association_2="association_2"
          attribute="pbi_system_max_kw"
          :run="portfolioRun"
          v-on:input-change="updateInputValueForRuns('pbi_system_max_kw', portfolioRun[association_1][association_2]['pbi_system_max_kw'])" />
      </td>
    </tr>
  </table>    
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import Tooltip from "../Tooltip";
  import PortfolioIncentivesTableStringInputNested2 from "./PortfolioIncentivesTableStringInputNested2";
  
  export default {
    components: {
      Tooltip,
      PortfolioIncentivesTableStringInputNested2,
    },

    computed: {
      ...mapGetters([
        'portfolio'
      ]),
    },
  
    props: ["association_1", "association_2", "portfolioRun"],

    methods: {
      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: self.association_1,
            association_2: self.association_2,
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },
    },
  }
</script>