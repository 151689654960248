<template>
  <div id="new_multi_site_run" class="simple_form run" name="portfolio">
    <h2>Step 2: Enter Analysis Information</h2>
    <div class="panel-group">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="form-horizontal">
            <p class="required-note">
              <abbr class="required">*</abbr> Required field
            </p>
            <div class="form-horizontal">
              <div class="form-group string optional" :class="formGroupClass">
                <div class="col-sm-6 control-label">
                  <label class="string optional" for="portfolio_name"><abbr title="Required" class="required">*</abbr> Screening name</label>
                </div>
                <div class="col-sm-4">
                  <input
                    v-model="portfolio['name']"
                    class="form-control string optional"
                    type="text"
                    name="portfolio[name]"
                    id="portfolio_name"
                    @change="$emit('input-change');"
                    @blur="validatePortfolioName();" />
                  <span v-if="validateError" class="help-block">
                    {{ validateErrorMessage }}
                  </span>
                </div>
              </div>
            </div>
            <div class="form-horizontal">
              <horizontal-string-input
                attribute='number_of_runs'
                required=true />
            </div>
            <div v-if="portfolio.runs">
              <button id="create_runs_button" class="btn btn-primary" @click="addRunToRuns()">Add an additional Site</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="portfolio.runs">
      <br>
      <h2 id="step_form_start">Step 3: Choose Your Energy Goals</h2>
      <div class="choice-btns technology-btns focus-btns clean-energy-goals">
        <div id="tech-btns" class="choice-btns technology-btns">
          <label @click="displayCostSavingsMessage($event)" class="btn btn-lg btn-default" for="cost_savings">
            <span class="technology-checkbox">
              <input
                type="checkbox"
                value="1"
                checked=true
                id="cost_savings" />
              <span>Cost Savings <font-awesome-icon icon='dollar-sign' /></span>
            </span>
          </label>
          <label class="btn btn-lg btn-default" for="run_clean_energy">
            <span class="technology-checkbox">
              <input
                v-model="portfolioRun.clean_energy_targets"
                type="hidden"
                name="run[clean_energy_targets]" />
              <input
                v-model="portfolioRun.clean_energy_targets"
                type="checkbox"
                name="run[clean_energy_targets]"
                id="run_clean_energy" />
              <span>Clean Energy <font-awesome-icon icon='globe-americas' /></span>
            </span>
          </label>
        </div>
      </div>
      <h2>Step 4: Select Your Technologies (applies to all sites)</h2>
      <div class="choice-btns technology-btns" id="tech-btns">
        <label class="btn btn-lg btn-default" for="run_analyze_pv">
          <span class="technology-checkbox">
            <input
              name="run[analyze_pv]"
              type="hidden"
              value="0" />
            <input
              v-model="portfolioRun.analyze_pv"
              type="checkbox"
              value="1"
              checked="checked"
              name="run[analyze_pv]"
              id="run_analyze_pv" />
            <span>PV <font-awesome-icon icon='sun' /></span>
          </span>
        </label>
        <label class="btn btn-lg btn-default" for="run_analyze_battery">
          <span class="technology-checkbox">
            <input
              name="run[analyze_battery]"
              type="hidden"
              value="0" />
            <input
              v-model="portfolioRun.analyze_battery"
              type="checkbox"
              value="1"
              checked="checked"
              name="run[analyze_battery]"
              id="run_analyze_battery" />
            <span>Battery <font-awesome-icon icon='battery-full' /></span>
          </span>
        </label>
        <label class="btn btn-lg btn-default" for="run_analyze_wind">
          <span class="technology-checkbox">
            <input
              name="run[analyze_wind]"
              type="hidden"
              value="0" />
            <input
              v-model="portfolioRun.analyze_wind"
              type="checkbox"
              value="1"
              name="run[analyze_wind]"
              id="run_analyze_wind" />
            <span>Wind <slot name="wind_icon"></slot></span>
          </span>
        </label>
      </div>
      <br/>
      <h2>Step 4: Enter Your Site Data</h2>
      <div class="panel-group">
        <div class="panel panel-default">
          <div class="full-width">
            <div class="panel-body" style="padding-top: 0px; padding-left:0px; padding-right:0px;">
              <div class="form-horizontal">
                <table class="fixed" id="portfolio-step3-accordion">
                  <thead>
                    <tr>
                      <th class="col-sm-3" style="position: sticky; top: 0px; z-index: 3; background: #fdf4ed; padding: 15px;"></th>
                      <th class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index" style="position: sticky; top: 0px; z-index: 2; background: #fdf4ed; padding: 15px;">
                        <div v-if="run.site.description" class="control-label" style="text-align: center; padding-top: 0px;">
                          <label class="string optional" for="run_site_description">
                            {{ run.site.description }}
                          </label>
                        </div>
                        <div v-else class="control-label" style="text-align: center; padding-top: 0px;">
                          <label class="string optional" for="run_site_description">
                            {{ runsPlaceholders[index].site.description }}
                          </label>
                        </div>
                        <button @click="deleteRunFromRuns(index)" class="btn btn-primary btn-xs" style="height: 30px;">Delete Site</button>
                      </th>
                      <th class="col-sm-3" style="position: sticky; top: 0px; z-index: 2; background: #fdf4ed; padding: 15px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align: left;">
                        <div class="panel-group" style="width:1140px;">
                          <div class="panel panel-default">
                            <div class="panel-heading" id="site_heading">
                              <h3 class="section-title"><font-awesome-icon icon='map-marker-alt' />&nbsp;{{ sectionHeaders.site_utility }} <span class="section-required">(required)</span></h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <portfolio-site-form />
                  <tbody>
                    <tr>
                      <td style="text-align: left;">
                        <div class="panel-group" style="width:1140px;">
                          <div class="panel panel-default">
                            <div class="panel-heading" id="load_profile_heading">
                              <h3 class="section-title"><font-awesome-icon icon='signal' />&nbsp;{{ sectionHeaders.load_profile }} <span class="section-required">(required)</span></h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <portfolio-load-profile-form />
                  <tbody>
                    <tr>
                      <td style="text-align: left;">
                        <div class="panel-group" style="width:1140px;">
                          <div class="panel panel-default">
                            <div class="panel-heading" id="financial_heading">
                              <h3 class="section-title"><font-awesome-icon icon='dollar-sign' />&nbsp;{{ sectionHeaders.financial }}</h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <portfolio-financial-form />
                  <tbody>
                    <tr>
                      <td style="text-align: left;">
                        <div class="panel-group" style="width:1140px;">
                          <div class="panel panel-default">
                            <div class="panel-heading" id="emissions_heading">
                              <h3 class="section-title"><font-awesome-icon icon='dollar-sign' />&nbsp;{{ sectionHeaders.emissions }}</h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <portfolio-emissions-form />
                  <tbody v-if="portfolioRun.clean_energy_targets">
                    <tr>
                      <td style="text-align: left;">
                        <div class="panel-group" style="width:1140px;">
                          <div class="panel panel-default">
                            <div class="panel-heading" id="clean_energy_heading">
                              <h3 class="section-title"><font-awesome-icon icon='dollar-sign' />&nbsp;{{ sectionHeaders.clean_energy }}</h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <portfolio-clean-energy-form v-if="portfolioRun.clean_energy_targets" />
                  <tbody v-if="portfolioRun.analyze_pv">
                    <tr>
                      <td style="text-align: left;">
                        <div class="panel-group" style="width:1140px;">
                          <div class="panel panel-default">
                            <div class="panel-heading" id="pv_heading">
                              <h3 class="section-title"><font-awesome-icon icon='sun' />&nbsp;{{ sectionHeaders.pv }} <span class="section-required">(required)</span></h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <portfolio-pv-form v-if="portfolioRun.analyze_pv" />
                  <tbody v-if="portfolioRun.analyze_battery">
                    <tr>
                      <td style="text-align: left;">
                        <div class="panel-group" style="width:1140px;">
                          <div class="panel panel-default">
                            <div class="panel-heading" id="battery_heading">
                              <h3 class="section-title"><font-awesome-icon icon='battery-full' />&nbsp;{{ sectionHeaders.battery }}</h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <portfolio-storage-form v-if="portfolioRun.analyze_battery" />
                  <tbody v-if="portfolioRun.analyze_wind">
                    <tr>
                      <td style="text-align: left;">
                        <div class="panel-group" style="width:1140px;">
                          <div class="panel panel-default">
                            <div class="panel-heading" id="wind_heading">
                              <h3 class="section-title"><slot name="wind_icon"></slot>&nbsp;{{ sectionHeaders.wind }}</h3>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <portfolio-wind-form v-if="portfolioRun.analyze_wind" />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sticky-submit">
        <div class="text-right">
          <button @click="submitForm($event)" id="site-profile-submit" type="submit" class="btn btn-primary btn-lg" data-loading-text="Loading...">
            <div id="portfolio-results-spinner" style="display:none;"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
            Get Results <font-awesome-icon icon='arrow-circle-right' />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInput from './inputs/HorizontalStringInput';
  import Tooltip from './Tooltip';
  import PortfolioSiteForm from './PortfolioSiteForm'
  import PortfolioLoadProfileForm from './PortfolioLoadProfileForm';
  import PortfolioCleanEnergyForm from './PortfolioCleanEnergyForm';
  import PortfolioPvForm from './PortfolioPvForm';
  import PortfolioFinancialForm from './PortfolioFinancialForm';
  import PortfolioEmissionsForm from './PortfolioEmissionsForm';
  import PortfolioStorageForm from './PortfolioStorageForm';
  import PortfolioWindForm from './PortfolioWindForm';

  export default {
    components: {
      HorizontalStringInput,
      Tooltip,
      PortfolioSiteForm,
      PortfolioLoadProfileForm,
      PortfolioCleanEnergyForm,
      PortfolioPvForm,
      PortfolioFinancialForm,
      PortfolioEmissionsForm,
      PortfolioStorageForm,
      PortfolioWindForm,
    },

    data: function() {
      return {
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'csrfToken',
        'sectionHeaders',
        'urlRoot',
        'year',
        'run',
        'portfolioRun',
        'portfolio',
        'runsPlaceholders',
        'runsUtilityRates'
      ]),
      formGroupClass: function() {
        return 'portfolio_name';
      },
      validateErrorMessage: function() {
        return "Screening name cannot be blank";
      }
    },

    methods: {
      displayCostSavingsMessage: function(event) {
        bootbox.alert("Cost Savings cannot be unselected as an energy goal. If other energy goals are chosen, REopt will optimize your solution to meet those goals in the most cost effective manner over the analysis period.");
        event.preventDefault();
      },

      updateMultiRunParams: function() {
        var params = "run[number_of_runs]=" + this.run.number_of_runs
        this.$store.commit('updateObject', { object: 'multiRunParams', value: params });
      },

      addRunToRuns: function() {
        event.preventDefault();
        this.$store.commit('updateValue', { attribute: 'number_of_runs', value: parseInt(this.run.number_of_runs) + 1 });

        if (this.run.number_of_runs <= 10) {
          var newRun = JSON.parse(JSON.stringify(this.portfolioRun));
          this.$store.commit('updateRuns', newRun);
          let index = this.portfolio.runs.lastIndexOf(this.portfolio.runs[this.portfolio.runs.length-1]);
          this.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRates', value: [], index: index });
          // self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRatesLoading', value: false, index: self.index });
          this.runsPlaceholders.push({
            site: {
              description: 'Site ' + (index+1),
              load_profile: {
                annual_kwh: ''
              },
              financial: {
                analysis_years: '25'
              },
              electric_tariff: {
                cambium_start_year: '2024',
                emissions_factor_hourly_source: ''
              },
              pv: {
                azimuth: '180'
              },
              wind: {
                installed_cost_per_kw: '$4,760',
              }
            }
          });
        } else {
          this.$store.commit('updateValue', { attribute: 'number_of_runs', value: 10 });
          bootbox.alert("The number of sites cannot be greater than 10");
        }
      },

      validatePortfolioName: function() {
        if(!this.run.single_run && !this.portfolio['name']) {
          this.validateError = true;
        } else {
          this.validateError = false;
        }
      },

      deleteRunFromRuns(index) {
        event.preventDefault()
        this.portfolio.runs.splice(index,1)
        this.runsUtilityRates.splice(index,1)
        this.runsPlaceholders.splice(index,1)

        // check if there is a value in the PV existing_kw input field and if the existing_pv checkbox is checked.
        this.portfolio.runs.forEach(function(run, i) {
          if (run.site.pv.existing_pv) {
            $(`#run_site_attributes_pv_attributes_existing_kw_${i + 1}`).attr('disabled', false);
          } else if (!run.site.pv.existing_pv) {
            $(`#run_site_attributes_pv_attributes_existing_kw_${i + 1}`).attr('disabled', true);
          }
        })
        // reset the site description placeholder so they display consecutively
        var self = this;
        this.portfolio.runs.forEach(function(run, index) {
          self.$store.commit('updateRunsPlaceholder', {association_1: 'site', attribute: 'description', index: index, value: 'Site ' + (index+1)});
        })

        this.$store.commit('updateValue', { attribute: 'number_of_runs', value: parseInt(this.run.number_of_runs) - 1 })
      },

      updateTechValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },

      verifyNoErrors: function (event) {
        if (document.getElementsByClassName('has-error').length > 0) {
          event.preventDefault();
          document.querySelector('#portfolio-step3-accordion').scrollIntoView({
            behavior: 'smooth'
          });
          return false
        } else {
          return true
        }
      },

      submitForm: function (event) {
        if (this.verifyNoErrors(event) == true) {
          $("#portfolio-results-spinner").show();
          $.ajax({
            url: this.$store.state.urlRoot + '/results',
            type: 'POST',
            data: JSON.stringify(this.$store.state.portfolio),
            dataType: 'json',
            contentType: 'json',
            // processData: false,
            success: function (data) {
              window.location.href = (`${this.url}`)
            },
            error: data => { // arrow function required here to have $store access
              $("#portfolio-results-spinner").hide();
              this.$store.commit('updateSubmitError', data.responseJSON.errors)
              window.scrollTo({ top: 700, behavior: 'smooth' });
            }
          })
        } else {
          console.log("Validation Errors")
        }
      }
    },

    watch: {
      'run.number_of_runs': function() {
        this.updateMultiRunParams()
      },
      'portfolioRun.analyze_pv': function() {
        this.updateTechValueForRuns('analyze_pv', this.portfolioRun.analyze_pv)
      },
      'portfolioRun.analyze_battery': function() {
        this.updateTechValueForRuns('analyze_battery', this.portfolioRun.analyze_battery)
      },
      'portfolioRun.clean_energy_targets': function() {
        this.updateTechValueForRuns('clean_energy_targets', this.portfolioRun.clean_energy_targets)
      },
      'portfolioRun.analyze_wind': function() {
        this.updateTechValueForRuns('analyze_wind', this.portfolioRun.analyze_wind)
      },
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[0].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[0].classList.remove('has-error');
        }
      }
    },
  }
</script>