<template>
  <!-- <div v-if="!run.site.load_profile_boiler.split_heating_loads">
    <div class="custom-monthly-rate" style="margin-top: 5px;">
      <div class="container">
        <div class="row">
          <div class="col-xs-8 col-sm-8" style="text-align: right;">
          </div>
          <div class="col-xs-3 col-sm-3">
            <p>Monthly heating boiler fuel consumption (MMBtu)</p>
          </div>
        </div>
      </div>
      <div v-for="(month, i) in months" class="container monthly-entry-rows">
        <div class="row">
          <div class="col-xs-8 col-sm-8" style="text-align: right;">
            {{ month }}
          </div>
          <div class="col-xs-3 col-sm-3 monthly-entry-row">
            <input v-model="run.site.load_profile_boiler[`monthly_mmbtu_${i + 1}`]" @change="$emit('update-total-monthly-mmbtu')"  @paste="$emit('paste-total-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly boiler fuel consumption (MMBtu)`" type="number" step="any" :name="fuelName(i,'total')" />
          </div>
        </div>
      </div>
    </div>
    <br>
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='load_profile_boiler'
      attribute='addressable_load_fraction' />
  </div> -->
  <div v-if="!run.site.load_profile_boiler.split_heating_loads">
    <div style="margin-top: 5px;">
      <div class="form-group select optional">
        <div class="col-sm-offset-6 col-sm-4 heating-load-types">
          <div style="width: 50%;">Monthly heating boiler fuel consumption (MMBtu)</div>
          <div style="width: 50%;">Addressable Load Percent (%)</div>
        </div>
      </div>
      <div v-for="(month, i) in months">
        <div class="form-group select optional" style="margin: 8px 0px">
          <div class="col-xs-6 col-sm-6" style="text-align: right;">
            {{ month }}
          </div>
          <div class="col-sm-4 heating-load-types">
            <input v-model="run.site.load_profile_boiler[`monthly_mmbtu_${i + 1}`]" @change="$emit('update-total-monthly-mmbtu')"  @paste="$emit('paste-total-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly boiler fuel consumption (MMBtu)`" type="number" step="any" :name="fuelName(i,'total')" />
            <input v-model="run.site.load_profile_boiler[`addressable_load_fraction_monthly_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-percent-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} addressable load fraction`" placeholder="100%" type="number" step="any" :name="percentName(i)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="run.site.load_profile_boiler.split_heating_loads">
    <div style="margin-top: 5px;">
      <div class="form-group select optional">
        <div class="col-sm-offset-6 col-sm-6 heating-load-types">
          <div style="width: 50%;">Space Heating (MMBtu)</div>
          <div style="width: 50%;">Water Heating (MMBtu)</div>
        </div>
      </div>
      <div v-for="(month, i) in months">
        <div class="form-group select optional" style="margin: 8px 0px">
          <div class="col-xs-6 col-sm-6" style="text-align: right;">
            {{ month }}
          </div>
          <div class="col-sm-6 heating-load-types">
            <input v-model="run.site.load_profile_boiler.space_heating_load[`monthly_mmbtu_${i + 1}`]" @change="$emit('update-space-monthly-mmbtu');" @paste="$emit('paste-space-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly boiler fuel consumption (MMBtu)`" type="number" step="any" :name="fuelName(i, 'space')" style="width: 50%;"/>
            <input v-model="run.site.load_profile_boiler.water_heating_load[`monthly_mmbtu_${i + 1}`]" @change="$emit('update-water-monthly-mmbtu');" @paste="$emit('paste-water-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly boiler fuel consumption (MMBtu)`" type="number" step="any" :name="fuelName(i, 'water')" style="width: 50%;"/>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="form-group select optional" style="margin-right: 0px; margin-left: 0px;">
      <div class="col-sm-6 control-label">
        <label class="grouped_select required" for="run_site_attributes_load_profile_boiler_attributes_annual_mmbtu">
          Addressable load percent (%)
        </label>
        <tooltip
          association_1='site'
          association_2='load_profile_boiler'
          attribute='addressable_load_fraction' />
      </div>
      <div class="col-sm-6">
        <div class="heating-load-types">
          <input
            v-model="run.site.load_profile_boiler.space_heating_load.addressable_load_fraction"
            :placeholder="placeholders['site']['load_profile_boiler']['space_heating_load']['addressable_load_fraction']"
            class="form-control string optional"
            type="text"
            style="width: 50%;"/>
          <input
            v-model="run.site.load_profile_boiler.water_heating_load.addressable_load_fraction"
            :placeholder="placeholders['site']['load_profile_boiler']['water_heating_load']['addressable_load_fraction']"
            class="form-control string optional"
            type="text"
            style="width: 50%;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  import HorizontalStringInputNested2 from './HorizontalStringInputNested2';
  export default {
    components: {
      HorizontalStringInputNested2,
      Tooltip
    },
    props: ['type'],
    data: function() {
      return {
        months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
      }
    },
    computed: {
      ...mapGetters([
        'run',
        'placeholders',
      ]),
    },
    methods: {
      fuelName: function(i, type) {
        if (type == 'total') {
          return `run[site_attributes][load_profile_boiler_attributes][monthly_mmbtu_${i + 1}]`;
        } else if (type == 'space') {
          return `run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][monthly_mmbtu_${i + 1}]`;
        } else if (type == 'water') {
          return `run[site_attributes][load_profile_boiler_attributes][water_heating_load_attributes][monthly_mmbtu_${i + 1}]`;
        }
      },
      percentName: function(i) {
        return `run[site_attributes][load_profile_boiler_attributes][addressable_load_fraction_monthly_${i + 1}]`;
      }
    }
  }
</script>