<template>
  <tbody>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4>Electric Grid Climate Emissions Factors <tooltip :association_1="'site'" :association_2="'emissions'" :attribute="'climate_section_header'"></tooltip></h4>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div style="margin-bottom: 20px;">Inputs to generate levelized climate emissions factors from <a href="https://scenarioviewer.nrel.gov/" target="_blank">Cambium</a> data</div>
      </td>
    </tr>
    <tr v-if="displayPortfolioGridClimateEmissionsWarning">
      <td colspan="4" class="col-sm-3" style="text-align: left;">
        <div class="alert alert-danger alert-form-errors" style="margin-bottom: 20px;">
          <p>Default grid climate emissions factors cannot be determined for international locations and will be assumed to be zero unless modified.</p>
        </div>
      </td>
    </tr>
    <tr v-if="displayPortfolioClimateEmissionsWarning">
      <td colspan="4" class="col-sm-3" style="text-align: left;">
        <div class="alert alert-danger alert-form-errors" style="margin-bottom: 20px;">
          <p>If you are evaluating sites within the contiguous U.S. (CONUS) in combination with non-CONUS sites, default hourly emissions rates from Cambium will be used for CONUS sites if the annual value is left blank.</p>
        </div>
      </td>
    </tr>
    <tr>
      <th class="col-sm-3" style="padding-top: 10px;">
        <div class="control-label">
          <label class="grouped_select required" for="run_site_attributes_electric_tariff_attributes_cambium_emissions_type">
            Choose Climate Emissions Factor
          </label>
        </div>
      </th>
      <td colspan="2" class="col-sm-3" style="position: sticky; left: 440px; z-index: 1; background: #fdf4ed; text-align: left;">
        <div class="custom-elec-rate-options">
          <input
            v-model="portfolioRun.site.electric_tariff.cambium_emissions_type"
            type="radio"
            value="hourly"
            name="run[site_attributes][electric_tariff_attributes][cambium_emissions_type]"
            id="run_site_attributes_electric_tariff_attributes_cambium_emissions_type_hourly" />
          <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_cambium_emissions_type_hourly">Hourly</label>
          <input
            v-model="portfolioRun.site.electric_tariff.cambium_emissions_type"
            type="radio"
            value="annual"
            name="run[site_attributes][electric_tariff_attributes][cambium-emissions-type]"
            id="run_site_attributes_electric_tariff_attributes_cambium_emissions_type_annual" />
          <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_cambium_emissions_type_annual">Annual</label>
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.cambium_emissions_type == 'hourly'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_electric_tariff_cambium_location_type">
            {{ labels.site.electric_tariff.cambium_location_type }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="cambium_location_type" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCambiumLocationTypeBySite"
            id="toggle_cambium_location_type"
            type="checkbox"
            @change="varySelectAttributeBySite(varyCambiumLocationTypeBySite, 'cambium_location_type', 'GEA Regions')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_cambium_location_type">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyCambiumLocationTypeBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_location_type">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_location_type'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('cambium_location_type', portfolioRun.site.electric_tariff.cambium_location_type)" />
        </div>
      </td>
      <td v-if="varyCambiumLocationTypeBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_location_type">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_location_type'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.cambium_emissions_type == 'hourly'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_electric_tariff_cambium_metric_col">
            {{ labels.site.electric_tariff.cambium_metric_col }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="cambium_metric_col" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCambiumMetricColBySite"
            id="toggle_cambium_metric_col"
            type="checkbox"
            @change="varySelectAttributeBySite(varyCambiumMetricColBySite, 'cambium_metric_col', 'lrmer_co2e')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_cambium_metric_col">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyCambiumMetricColBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_metric_col">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_metric_col'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('cambium_metric_col', portfolioRun.site.electric_tariff.cambium_metric_col)" />
        </div>
      </td>
      <td v-if="varyCambiumMetricColBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_metric_col">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_metric_col'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.cambium_emissions_type == 'hourly'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_electric_tariff_cambium_scenario">
            {{ labels.site.electric_tariff.cambium_scenario }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="cambium_scenario" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCambiumScenarioBySite"
            id="toggle_cambium_scenario"
            type="checkbox"
            @change="varySelectAttributeBySite(varyCambiumScenarioBySite, 'cambium_scenario', 'Mid-case')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_cambium_scenario">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyCambiumScenarioBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_scenario">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_scenario'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('cambium_scenario', portfolioRun.site.electric_tariff.cambium_scenario)" />
        </div>
      </td>
      <td v-if="varyCambiumScenarioBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_scenario">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_scenario'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.cambium_emissions_type == 'hourly'">
      <th class="col-sm-3">
        <!-- <div class="control-label" style="margin-bottom: 0;"> -->
        <div class="control-label">
          <label class="string optional" for="run_site_electric_tariff_cambium_greening_of_grid">
            {{ labels.site.electric_tariff.cambium_greening_of_grid }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="cambium_greening_of_grid" />
        </div>
        <!-- this might not get to have the option to apply to all sites due to cambium_levelization_years
        needing to be updated when either analysis_years or cambium_greening_of_grid is changed -->
        <!-- <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCambiumgreeningOfGridBySite"
            id="toggle_cambium_greening_of_grid"
            type="checkbox"
            @change="varySelectAttributeBySite(varyCambiumgreeningOfGridBySite, 'cambium_greening_of_grid', true)" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_cambium_greening_of_grid">
            Vary this input by site
          </label>
        </div> -->
      </th>
      <!-- <td v-if="!varyCambiumgreeningOfGridBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_greening_of_grid">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_greening_of_grid'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('cambium_greening_of_grid', portfolioRun.site.electric_tariff.cambium_greening_of_grid);toggleEmissionsYears(index, 'portfolioRun');" />
          <input
            v-model="portfolioRun.site.electric_tariff.cambium_levelization_years"
            class="form-control hidden"
            type="hidden"
            name="run[site_attributes][electric_tariff_attributes][cambium_levelization_years]"
            id="run_site_attributes_electric_tariff_attributes_cambium_levelization_years" />
        </div>
      </td> -->
      <td v-if="varyCambiumgreeningOfGridBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_greening_of_grid">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_greening_of_grid'
            :run="run"
            :index="index"
            :varyBySite="true"
            v-on:input-change="toggleEmissionsYears(index, 'run');" />
            <!-- would not need to specify runType for toggleEmissionsYears if there ends up being no varyBySite checkbox -->
          <input
            v-model="run.site.electric_tariff.cambium_levelization_years"
            class="form-control hidden"
            type="hidden"
            name="run[site_attributes][electric_tariff_attributes][cambium_levelization_years]"
            id="run_site_attributes_electric_tariff_attributes_cambium_levelization_years" />
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.cambium_emissions_type == 'hourly'">
      <th class="col-sm-3">
        <!-- <div class="control-label" style="margin-bottom: 0;"> -->
        <div class="control-label">
          <label class="string optional" for="run_site_electric_tariff_cambium_start_year">
            {{ labels.site.electric_tariff.cambium_start_year }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="cambium_start_year" />
        </div>
        <!-- <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCambiumStartYearBySite"
            id="toggle_cambium_start_year"
            type="checkbox"
            @change="varyAttributeBySite(varyCambiumStartYearBySite, 'cambium_start_year')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_cambium_start_year">
            Vary this input by site
          </label>
        </div> -->
      </th>
      <!-- <td v-if="!varyCambiumStartYearBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="electric_tariff"
          attribute='cambium_start_year'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('cambium_start_year', portfolioRun.site.electric_tariff.cambium_start_year)" />
      </td> -->
      <td v-if="varyCambiumStartYearBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="electric_tariff"
          attribute='cambium_start_year'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.cambium_emissions_type == 'hourly'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_electric_tariff_cambium_grid_level">
            {{ labels.site.electric_tariff.cambium_grid_level }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="cambium_grid_level" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCambiumGridLevelBySite"
            id="toggle_cambium_grid_level"
            type="checkbox"
            @change="varySelectAttributeBySite(varyCambiumGridLevelBySite, 'cambium_grid_level', 'enduse')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_cambium_grid_level">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyCambiumGridLevelBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_grid_level">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_grid_level'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('cambium_grid_level', portfolioRun.site.electric_tariff.cambium_grid_level)" />
        </div>
      </td>
      <td v-if="varyCambiumGridLevelBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_cambium_grid_level">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='cambium_grid_level'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.cambium_emissions_type == 'annual'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
        <!-- <div class="control-label"> -->
          <label class="string optional" for="run_site_electric_tariff_co2_annual_emissions_factor">
            {{ labels.site.electric_tariff.co2_annual_emissions_factor }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="co2_annual_emissions_factor" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCO2AnnualEmissionsFactorBySite"
            id="toggle_co2_annual_emissions_factor"
            type="checkbox"
            @change="varyAttributeBySite(varyCO2AnnualEmissionsFactorBySite, 'co2_annual_emissions_factor')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_co2_annual_emissions_factor">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyCO2AnnualEmissionsFactorBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="displayPortfolioAnnualEmissionsFactors"
          association_1='site'
          association_2="electric_tariff"
          attribute='co2_annual_emissions_factor'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('co2_annual_emissions_factor', portfolioRun.site.electric_tariff.co2_annual_emissions_factor)" />
      </td>
      <td v-if="varyCO2AnnualEmissionsFactorBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="displayPortfolioAnnualEmissionsFactors"
          association_1='site'
          association_2="electric_tariff"
          attribute='co2_annual_emissions_factor'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.cambium_emissions_type == 'annual'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
        <!-- <div class="control-label"> -->
          <label class="string optional" for="run_site_electric_tariff_emissions_factor_CO2_decrease_fraction">
            {{ labels.site.electric_tariff.emissions_factor_CO2_decrease_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="emissions_factor_CO2_decrease_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyEmissionsFactorCO2DecreaseFractionBySite"
            id="toggle_emissions_factor_CO2_decrease_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyEmissionsFactorCO2DecreaseFractionBySite, 'emissions_factor_CO2_decrease_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_emissions_factor_CO2_decrease_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyEmissionsFactorCO2DecreaseFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="electric_tariff"
          attribute='emissions_factor_CO2_decrease_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('emissions_factor_CO2_decrease_fraction', portfolioRun.site.electric_tariff.emissions_factor_CO2_decrease_fraction)" />
      </td>
      <td v-if="varyEmissionsFactorCO2DecreaseFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="electric_tariff"
          attribute='emissions_factor_CO2_decrease_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4>Electric Grid Health Emissions Factors <tooltip :association_1="'site'" :association_2="'emissions'" :attribute="'health_section_header'"></tooltip></h4>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div style="margin-bottom: 20px;">Inputs to generate levelized health emissions factors from <a href="https://www.epa.gov/avert" target="_blank">AVERT</a> data</div>
      </td>
    </tr>
    <tr v-if="displayPortfolioGridHealthEmissionsWarning">
      <td colspan="4" class="col-sm-3" style="text-align: left;">
        <div class="alert alert-danger alert-form-errors" style="margin-bottom: 20px;">
          <p>Default grid health emissions factors cannot be determined for international locations and will be assumed to be zero unless modified.</p>
        </div>
      </td>
    </tr>
    <tr v-if="displayPortfolioHealthEmissionsWarning">
      <td colspan="4" class="col-sm-3" style="text-align: left;">
        <div class="alert alert-danger alert-form-errors" style="margin-bottom: 20px;">
          <p>If you are evaluating sites within the contiguous U.S. (CONUS) in combination with non-CONUS sites, default hourly emissions rates from AVERT will be used for CONUS sites if the annual values are left blank.</p>
        </div>
      </td>
    </tr>
    <tr>
      <th class="col-sm-3" style="padding-top: 20px;">
        <div class="control-label">
          <label class="grouped_select required" for="run_site_attributes_electric_tariff_attributes_emissions_type">
            Choose Health Emissions Factor
          </label>
        </div>
      </th>
      <td colspan="2" class="col-sm-3" style="position: sticky; left: 440px; z-index: 1; background: #fdf4ed; text-align: left; padding-top: 10px;">
        <div class="custom-elec-rate-options">
          <input
            v-model="portfolioRun.site.electric_tariff.emissions_type"
            type="radio"
            value="hourly"
            name="run[site_attributes][electric_tariff_attributes][emissions_type]"
            id="run_site_attributes_electric_tariff_attributes_emissions_type_hourly" />
          <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_emissions_type_hourly">Hourly</label>
          <input
            v-model="portfolioRun.site.electric_tariff.emissions_type"
            type="radio"
            value="annual"
            name="run[site_attributes][electric_tariff_attributes][emissions_type]"
            id="run_site_attributes_electric_tariff_attributes_emissions_type_annual" />
          <label class="custom-rate-time-period" for="run_site_attributes_electric_tariff_attributes_emissions_type_annual">Annual</label>
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.emissions_type == 'hourly'">
      <th class="col-sm-3">
        <!-- <div class="control-label" style="margin-bottom: 0;"> -->
        <div class="control-label">
          <label class="string optional" for="run_site_electric_tariff_emissions_factor_hourly_source">
            {{ labels.site.electric_tariff.emissions_factor_hourly_source }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="emissions_factor_hourly_source" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_electric_tariff_emissions_factor_hourly_source">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="electric_tariff"
            attribute='emissions_factor_hourly_source'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.emissions_type == 'annual'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
        <!-- <div class="control-label"> -->
          <label class="string optional" for="run_site_electric_tariff_nox_annual_emissions_factor">
            {{ labels.site.electric_tariff.nox_annual_emissions_factor }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="nox_annual_emissions_factor" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyNOxAnnualEmissionsFactorBySite"
            id="toggle_nox_annual_emissions_factor"
            type="checkbox"
            @change="varyAttributeBySite(varyNOxAnnualEmissionsFactorBySite, 'nox_annual_emissions_factor')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_nox_annual_emissions_factor">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyNOxAnnualEmissionsFactorBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="displayPortfolioAnnualEmissionsFactors"
          association_1='site'
          association_2="electric_tariff"
          attribute='nox_annual_emissions_factor'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('nox_annual_emissions_factor', portfolioRun.site.electric_tariff.nox_annual_emissions_factor)" />
      </td>
      <td v-if="varyNOxAnnualEmissionsFactorBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="displayPortfolioAnnualEmissionsFactors"
          association_1='site'
          association_2="electric_tariff"
          attribute='nox_annual_emissions_factor'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.emissions_type == 'annual'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
        <!-- <div class="control-label"> -->
          <label class="string optional" for="run_site_electric_tariff_so2_annual_emissions_factor">
            {{ labels.site.electric_tariff.so2_annual_emissions_factor }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="so2_annual_emissions_factor" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varySO2AnnualEmissionsFactorBySite"
            id="toggle_so2_annual_emissions_factor"
            type="checkbox"
            @change="varyAttributeBySite(varySO2AnnualEmissionsFactorBySite, 'so2_annual_emissions_factor')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_so2_annual_emissions_factor">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varySO2AnnualEmissionsFactorBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="displayPortfolioAnnualEmissionsFactors"
          association_1='site'
          association_2="electric_tariff"
          attribute='so2_annual_emissions_factor'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('so2_annual_emissions_factor', portfolioRun.site.electric_tariff.so2_annual_emissions_factor)" />
      </td>
      <td v-if="varySO2AnnualEmissionsFactorBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="displayPortfolioAnnualEmissionsFactors"
          association_1='site'
          association_2="electric_tariff"
          attribute='so2_annual_emissions_factor'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-if="portfolioRun.site.electric_tariff.emissions_type == 'annual'">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
        <!-- <div class="control-label"> -->
          <label class="string optional" for="run_site_electric_tariff_pm25_annual_emissions_factor">
            {{ labels.site.electric_tariff.pm25_annual_emissions_factor }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="pm25_annual_emissions_factor" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyPM25AnnualEmissionsFactorBySite"
            id="toggle_pm25_annual_emissions_factor"
            type="checkbox"
            @change="varyAttributeBySite(varyPM25AnnualEmissionsFactorBySite, 'pm25_annual_emissions_factor')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_pm25_annual_emissions_factor">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyPM25AnnualEmissionsFactorBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="displayPortfolioAnnualEmissionsFactors"
          association_1='site'
          association_2="electric_tariff"
          attribute='pm25_annual_emissions_factor'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('pm25_annual_emissions_factor', portfolioRun.site.electric_tariff.pm25_annual_emissions_factor)" />
      </td>
      <td v-if="varyPM25AnnualEmissionsFactorBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="displayPortfolioAnnualEmissionsFactors"
          association_1='site'
          association_2="electric_tariff"
          attribute='pm25_annual_emissions_factor'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
        <!-- <div class="control-label"> -->
          <label class="string optional" for="run_site_electric_tariff_emissions_factor_nox_so2_pm25_decrease_fraction">
            {{ labels.site.electric_tariff.emissions_factor_nox_so2_pm25_decrease_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="electric_tariff"
            attribute="emissions_factor_nox_so2_pm25_decrease_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyEmissionsFactorNoxSo2Pm25DecreaseFractionBySite"
            id="toggle_emissions_factor_nox_so2_pm25_decrease_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyEmissionsFactorNoxSo2Pm25DecreaseFractionBySite, 'emissions_factor_nox_so2_pm25_decrease_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_emissions_factor_nox_so2_pm25_decrease_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyEmissionsFactorNoxSo2Pm25DecreaseFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="electric_tariff"
          attribute='emissions_factor_nox_so2_pm25_decrease_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('emissions_factor_nox_so2_pm25_decrease_fraction', portfolioRun.site.electric_tariff.emissions_factor_nox_so2_pm25_decrease_fraction)" />
      </td>
      <td v-if="varyEmissionsFactorNoxSo2Pm25DecreaseFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="electric_tariff"
          attribute='emissions_factor_nox_so2_pm25_decrease_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div class="row section-actions">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="toggle-more-inputs pull-left">
              <a @click="toggleAdvancedInputs();">
                <span v-if="displayAdvancedInputs">
                  <font-awesome-icon icon='minus-square' />
                  Show fewer inputs
                </span>
                <span v-else>
                  <font-awesome-icon icon='plus-square' />
                  Optional inputs
                </span>
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4>Treatment of exported electricity</h4>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_include_exported_renewable_electricity_in_total">
            {{ labels.site.include_exported_renewable_electricity_in_total }}
          </label>
          <tooltip
            association_1="site"
            attribute="include_exported_renewable_electricity_in_total" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyIncludeExportedRenewableElectricityInTotalBySite"
            id="toggle_include_exported_renewable_electricity_in_total"
            type="checkbox"
            @change="varyNested1SelectAttributeBySite(varyIncludeExportedRenewableElectricityInTotalBySite, 'include_exported_renewable_electricity_in_total', true)" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_include_exported_renewable_electricity_in_total">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyIncludeExportedRenewableElectricityInTotalBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional portfoliorun_site_include_exported_renewable_electricity_in_total">
          <portfolio-horizontal-select-input-nested-1
            association_1='site'
            attribute='include_exported_renewable_electricity_in_total'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputNested1ValueForRuns('include_exported_renewable_electricity_in_total', portfolioRun.site.include_exported_renewable_electricity_in_total)" />
        </div>
      </td>
      <td v-if="varyIncludeExportedRenewableElectricityInTotalBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional portfoliorun_site_include_exported_renewable_electricity_in_total">
          <portfolio-horizontal-select-input-nested-1
            association_1='site'
            attribute='include_exported_renewable_electricity_in_total'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_include_exported_elec_emissions_in_total">
            {{ labels.site.include_exported_elec_emissions_in_total }}
          </label>
          <tooltip
            association_1="site"
            attribute="include_exported_elec_emissions_in_total" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyIncludeExportedElecEmissionsInTotalBySite"
            id="toggle_include_exported_elec_emissions_in_total"
            type="checkbox"
            @change="varyNested1SelectAttributeBySite(varyIncludeExportedElecEmissionsInTotalBySite, 'include_exported_elec_emissions_in_total', true)" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_include_exported_elec_emissions_in_total">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyIncludeExportedElecEmissionsInTotalBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional portfoliorun_site_include_exported_elec_emissions_in_total">
          <portfolio-horizontal-select-input-nested-1
            association_1='site'
            attribute='include_exported_elec_emissions_in_total'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputNested1ValueForRuns('include_exported_elec_emissions_in_total', portfolioRun.site.include_exported_elec_emissions_in_total)" />
        </div>
      </td>
      <td v-if="varyIncludeExportedElecEmissionsInTotalBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional portfoliorun_site_include_exported_elec_emissions_in_total">
          <portfolio-horizontal-select-input-nested-1
            association_1='site'
            attribute='include_exported_elec_emissions_in_total'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2';
  // import HorizontalStringInputNested3 from './inputs/HorizontalStringInputNested3';
  // import HorizontalSelectInput from './inputs/HorizontalSelectInput';
  import PortfolioHorizontalSelectInputNested1 from './inputs/PortfolioHorizontalSelectInputNested1';
  import PortfolioHorizontalSelectInputNested2 from './inputs/PortfolioHorizontalSelectInputNested2';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested2,
      PortfolioHorizontalSelectInputNested1,
      PortfolioHorizontalSelectInputNested2,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
        varyCambiumLocationTypeBySite: false,
        varyCambiumMetricColBySite: false,
        varyCambiumScenarioBySite: false,
        varyCambiumgreeningOfGridBySite: true,
        varyCambiumStartYearBySite: true,
        varyCambiumGridLevelBySite: false,
        varyCO2AnnualEmissionsFactorBySite: true,
        varyEmissionsFactorCO2DecreaseFractionBySite: false,
        varyNOxAnnualEmissionsFactorBySite: true,
        varySO2AnnualEmissionsFactorBySite: true,
        varyPM25AnnualEmissionsFactorBySite: true,
        varyEmissionsFactorNoxSo2Pm25DecreaseFractionBySite: false,
        varyIncludeExportedRenewableElectricityInTotalBySite: false,
        varyIncludeExportedElecEmissionsInTotalBySite: false,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'portfolioRun',
        'portfolio',
        'runsPlaceholders',
        'displayPortfolioAnnualEmissionsFactors',
        'displayPortfolioGridClimateEmissionsWarning',
        'displayPortfolioGridHealthEmissionsWarning',
        'displayPortfolioClimateEmissionsWarning',
        'displayPortfolioHealthEmissionsWarning',
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      updateInputNested1ValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },
      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: 'electric_tariff',
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },
      varyAttributeBySite: function(action, attr) {
        if (action && this.portfolioRun.site.electric_tariff[attr]) {
          this.updateInputValueForRuns(attr, null);
          this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: attr, value: null });
        } else if (!action) {
          this.updateInputValueForRuns(attr, null);
        }
      },
      varySelectAttributeBySite: function(action, attr, defaultVal) {
        if (action && this.portfolioRun.site.electric_tariff[attr] !== defaultVal) {
          this.updateInputValueForRuns(attr, defaultVal);
          this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: attr, value: defaultVal });
        } else if (!action) {
          this.updateInputValueForRuns(attr, defaultVal);
        }
      },
      varyNested1SelectAttributeBySite: function(action, attr, defaultVal) {
        if (action && this.portfolioRun.site.electric_tariff[attr] !== defaultVal) {
          this.updateInputNested1ValueForRuns(attr, defaultVal);
          this.$store.commit('updatePortfolioValueNested1', { association_1: 'site', attribute: attr, value: defaultVal });
        } else if (!action) {
          this.updateInputNested1ValueForRuns(attr, defaultVal);
        }
      },
      toggleEmissionsYears: function(index, runType) {
        // this might not make sense as it might need to always vary by site in case a user changes the financial.analysis_years
        // or write code that checks the checkbox for analysis_years if the emissions years is checked and vice versa.
        // if (runType == 'portfolioRun') {
        //   if (this.portfolioRun.site.electric_tariff.cambium_greening_of_grid && !this.portfolioRun.site.financial.analysis_years) {
        //     this.updateInputValueForRuns('cambium_levelization_years', null);
        //     this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.runsPlaceholders.site.financial.analysis_years) - 1), index: index });
        //     if (this.portfolioRun.site.electric_tariff.cambium_start_year) {
        //       this.updateInputValueForRuns('cambium_levelization_years', null);
        //     }
        //   } else if (this.portfolioRun.site.electric_tariff.cambium_greening_of_grid && this.portfolioRun.site.financial.analysis_years) {
        //     this.updateInputValueForRuns('cambium_levelization_years', this.portfolioRun.site.financial.analysis_years);
        //     this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.portfolioRun.site.financial.analysis_years) - 1), index: index });
        //     if (this.portfolioRun.site.electric_tariff.cambium_start_year) {
        //       this.updateInputValueForRuns('cambium_levelization_years', null);
        //     }
        //   } else if (!this.portfolioRun.site.electric_tariff.cambium_greening_of_grid) {
        //     this.updateInputValueForRuns('cambium_levelization_years', 1);
        //     this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: 2024, index: index });
        //   }
        // }

        if (runType == 'run') {
          if (this.portfolio.runs[index].site.electric_tariff.cambium_greening_of_grid && !this.portfolio.runs[index].site.financial.analysis_years) {
            this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: null, index: index });
            this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.runsPlaceholders[index].site.financial.analysis_years) - 1), index: index });
            if (this.portfolio.runs[index].site.electric_tariff.cambium_start_year) {
              this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '', index: index });
            }
          } else if (this.portfolio.runs[index].site.electric_tariff.cambium_greening_of_grid && this.portfolio.runs[index].site.financial.analysis_years) {
            this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: this.portfolio.runs[index].site.financial.analysis_years, index: index });
            this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.portfolio.runs[index].site.financial.analysis_years) - 1), index: index });
            if (this.portfolio.runs[index].site.electric_tariff.cambium_start_year) {
              this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '', index: index });
            }
          } else if (!this.portfolio.runs[index].site.electric_tariff.cambium_greening_of_grid) {
            this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: 1, index: index });
            this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024', index: index });
          }
        }
      },
    },

    watch: {
      'portfolioRun.site.electric_tariff.cambium_emissions_type': function() {
        var self = this;
        this.updateInputValueForRuns('cambium_emissions_type', this.portfolioRun.site.electric_tariff.cambium_emissions_type);
      },
      'portfolioRun.site.electric_tariff.emissions_type': function() {
        var self = this;
        this.updateInputValueForRuns('emissions_type', this.portfolioRun.site.electric_tariff.emissions_type);
      },
    },

    mounted: function() {
      var self = this;
      this.portfolio.runs.forEach(function(run, i) {
        self.toggleEmissionsYears(i, 'run');
      })
    }
  }
</script>