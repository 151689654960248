<template>
  <a
    v-if="tooltip_text"
    :id="id"
    href="#"
    rel="tooltip"
    role="tooltip"
    :title="tooltip_text">
    <i class="fas fa-question-circle" aria-hidden="true"> </i>
  </a>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["association_1", "association_2", "association_3", "attribute"],

  computed: {
    ...mapGetters(["tooltips", "run"]),
    id: function () {
      if (this.attribute == "address") {
        return "site_address_tooltip";
      } else if (this.run.grid && this.association_2 == "generator" && this.attribute == "existing_kw") {
        return "existing_kw_emergency_gen_tooltip"
      } else if (!this.run.grid) {
        if (this.association_2 == "generator" && this.attribute == "installed_cost_per_kw") {
          return "generator_installed_cost_per_kw_tooltip";
        } else if (this.association_2 == "generator" && this.attribute == "min_turn_down_fraction") {
          return "min_turn_down_fraction_tooltip"
        }
      } else if (this.association_2 == "ghp" && this.attribute == "ghp_fluid_flow_rate_gpm_per_ton") {
        return "wwhp_nominal_flow_rate_tooltip";
      }
    },
    tooltip_text: function () {
      if (this.association_3) {
        return this.tooltips[this.association_1][this.association_2][this.association_3][this.attribute];
      } else if (this.association_2) {
        return this.tooltips[this.association_1][this.association_2][this.attribute];
      } else if (this.association_1) {
        return this.tooltips[this.association_1][this.attribute];
      } else if (this.attribute) {
        return this.tooltips[this.attribute];
      }
    },
  },

  methods: {
    updateTooltipText: function () {
      if (!this.run.grid) {
        if (this.association_2 == "generator" && this.attribute == "installed_cost_per_kw") {
          var text = "Fully burdened cost of the new installed diesel generator in dollars per kilowatt. If the generator replacement year is less than the analysis period, the generator replacement cost is assumed to be equal to the installation cost. This value is not required. For more information and for guidance on how to model multiple generator replacements, please see the <a href='/tool/reopt-user-manual.pdf' target='_blank' rel='noopener noreferrer'>help manual</a>.";
        // TODO: I believe this can change once vue bootstrap integrated
        $("#generator_installed_cost_per_kw_tooltip").attr("title", text);
        } else if (this.association_2 == "generator" && this.attribute == "min_turn_down_fraction") {
          var text = "For an off-grid generator, a higher minimum turndown can help avoid issues from underloading, but can also result in infeasible solutions in some cases (particularly if a battery is not included in the solution).";
          // TODO: I believe this can change once vue bootstrap integrated
          $("#min_turn_down_fraction_tooltip").attr("title", text);
        }
      }

      if (this.run.grid && this.association_2 == "generator" && this.attribute == "existing_kw") {
        var text = "If the existing generator option is selected, then the size of the existing generator is required to complete the optimization for energy resilience. REopt dispatch assumes that this generator can run in parallel with other distributed energy resources."
        $("#existing_kw_emergency_gen_tooltip").attr("title", text);
      }

      if (this.association_2 == "ghp" && this.attribute == "ghp_fluid_flow_rate_gpm_per_ton") {
        if (this.run.site.ghp.heat_pump_configuration == 'WWHP') {
          var text = "Volumetric flow rate of the fluid in the hydronic space heating loop and hydronic chilled water-cooling loop per peak ton heating/cooling in units of GPM/ton.";
        } else {
          var text = "Volumetric flow rate of the fluid in the GHX per peak ton heating/cooling in units of GPM/ton.";
        }
        $("#wwhp_nominal_flow_rate_tooltip").attr("title", text);
      }
    },
  },

  watch: {
    "run.grid": function () {
      if (this.attribute == "address") {
        if (this.run.grid) {
          var text = "The Site Location may be entered as a street address, city, state or zip code. The dropdown menu will populate as you type and a location must then be selected from this menu. <b>This value is required.</b> The location is used to determine solar and wind resource data and applicable utility rates. Solar and wind resource and utility rate data is available for locations in the US. Once the location is typed into the form, the matching location selection must be chosen from the dropdown menu in order to load the Electricity Rate options menu in the data entry field below. Currently, the location must be within a state in the United States—the required menu of electricity rates will be inaccessible for foreign and U.S. territory locations. However, options are given for using the tool for international use. For more information, please see the <a href='/tool/reopt-user-manual.pdf' target='_blank' rel='noopener noreferrer'>help manual</a>.";
        } else {
          var text = "The Site Location may be entered as a street address, city, state, or zip code. The dropdown menu will populate as you type and a location must then be selected from this menu. This value is required. The location is used to determine solar resource data. For more information, please see the <a href='/tool/reopt-user-manual.pdf' target='_blank' rel='noopener noreferrer'>help manual</a>.";
        }

        // TODO: I believe this can change once vue bootstrap integrated
        $("#site_address_tooltip").attr("title", text);
      }

      this.updateTooltipText();
    },

    "run.site.ghp.heat_pump_configuration": function() {
      this.updateTooltipText();
    }
  },

  mounted: function () {
    // needed for accordions like generator that aren't always displayed (unlike the Site accordion)
    this.updateTooltipText();
  },
};
</script>
