<template>
  <div class="form-horizontal">
    <p class="required-note">
      <abbr class="required">*</abbr> Required field
    </p>
    <horizontal-string-input-nested-1
      association_1='site'
      attribute='description'
      @input-change="updateDocumentTitle()" />
    <horizontal-string-input-nested-1
      association_1='site'
      attribute='address'
      required=true
      @input-change="updateLatLong()"
      v-on:update-emissions-defaults="updateEmissionsDefaults(); updateHealthEmissionsDefaults();" />
    <input
      v-model="run.site.latitude"
      type="text"
      name="run[site_attributes][latitude]"
      id="run_site_attributes_latitude" />
    <input
      v-model="run.site.longitude"
      type="text"
      name="run[site_attributes][longitude]"
      id="run_site_attributes_longitude" />
    <div class="form-horizontal">
      <div class="form-group pv-wind-space-avail">
        <div class="col-sm-6 control-label">PV & wind space available</div>
        <div class="col-sm-6 space-inputs">
          <input v-model="run.site.pv_wind_space_available" type="radio" value="land" checked="checked" name="run[site_attributes][pv_wind_space_available]" id="run_site_attributes_pv_wind_space_available_land" />
          <label class="custom-rate-time-period" for="run_site_attributes_pv_wind_space_available_land">Land only</label>
          <input v-model="run.site.pv_wind_space_available" type="radio" value="roofspace" name="run[site_attributes][pv_wind_space_available]" id="run_site_attributes_pv_wind_space_available_roofspace" />
          <label class="custom-rate-time-period" for="run_site_attributes_pv_wind_space_available_roofspace">Roofspace only</label>
          <input v-model="run.site.pv_wind_space_available" type="radio" value="both" name="run[site_attributes][pv_wind_space_available]" id="run_site_attributes_pv_wind_space_available_both" />
          <label class="custom-rate-time-period" for="run_site_attributes_pv_wind_space_available_both">Land &amp; roofspace</label>
        </div>
      </div>
      <horizontal-string-input-nested-1
        v-if="run.site.pv_wind_space_available == 'land' || run.site.pv_wind_space_available == 'both'"
        association_1='site'
        attribute='land_acres' />
      <horizontal-string-input-nested-1
        v-if="run.site.pv_wind_space_available == 'roofspace' || run.site.pv_wind_space_available == 'both'"
        association_1='site'
        attribute='roof_squarefeet' />
    </div>

    <horizontal-string-input-nested-2
      v-if="run.analyze_ghp"
      association_1='site'
      association_2='ghp'
      attribute='building_sqft'
      required=true />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
               Advanced inputs
            </span>
          </a>
        </div>

        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <h4>Solver settings</h4>
      <div class="form-horizontal">
        <horizontal-string-input
          attribute='optimality_tolerance_techs' />
        <horizontal-select-input
          attribute='solver_name' />
        <horizontal-string-input
          attribute='timeout_seconds' />
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInput from './inputs/HorizontalStringInput';
  import HorizontalSelectInput from './inputs/HorizontalSelectInput';
  import HorizontalStringInputNested1 from './inputs/HorizontalStringInputNested1';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalStringInputNested3 from './inputs/HorizontalStringInputNested3';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import SelectElecRate from './inputs/SelectElecRate';
  import MonthlyInputs from './inputs/MonthlyInputs';
  import Tooltip from './Tooltip';

  export default {
    components: {
      // nested 1 b/c run.site - measured from run
      HorizontalStringInput,
      HorizontalSelectInput,
      HorizontalStringInputNested1,
      HorizontalStringInputNested2,
      HorizontalStringInputNested3,
      HorizontalSelectInputNested2,
      SelectElecRate,
      Tooltip,
      MonthlyInputs
    },

    data: function() {
      return {
        displayAdvancedInputs: false
      }
    },

    computed: {
      ...mapGetters([
        'currentUser',
        'customRates',
        'months',
        'run',
        'urlRoot',
        'placeholders',
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      updateStoreNested1: function(association_1, attribute) {
        var payload = {
          association_1: association_1,
          attribute: attribute,
          value: this.run[association_1][attribute],
        }
        this.$store.commit('updateValueNested1', payload);
      },
      updateLatLong: function() {
        if(this.run.site.address === "") {
          this.run.site.latitude = "";
          this.run.site.longitude = "";
        }
      },
      updateGHPGroundConductivity: function() {
        var self = this;
        $.ajax({
          url: `${this.urlRoot}/ghp-ground-conductivity`,
          data: {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
          },
          success: function handleResults(data) {
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp', attribute: 'ground_thermal_conductivity_btu_per_hr_ft_f', value: data.thermal_conductivity });
          }
        })
      },
      resetInputs: function() {
        if(confirm("Are you sure you want to reset the inputs in the Site and Utility section to their default values?")) {
          var self = this;
          // standard (non chp, prime generator, or ghp) site inputs
          if(this.run.site.address) this.$store.commit({ type: 'updateValueNested1', association_1: 'site', attribute: 'address', value: '' });
          if(this.run.site.latitude) this.$store.commit({ type: 'updateValueNested1', association_1: 'site', attribute: 'latitude', value: '' });
          if(this.run.site.longitude) this.$store.commit({ type: 'updateValueNested1', association_1: 'site', attribute: 'longitude', value: '' });

          if(this.run.site.description) this.$store.commit({ type: 'updateValueNested1', association_1: 'site', attribute: 'description', value: '' });
          if(this.run.site.pv_wind_space_available != 'land') this.$store.commit({ type: 'updateValueNested1', association_1: 'site', attribute: 'pv_wind_space_available', value: 'land' });
          if(this.run.site.land_acres) this.$store.commit({ type: 'updateValueNested1', association_1: 'site', attribute: 'land_acres', value: '' });
          if(this.run.site.roof_squarefeet) this.$store.commit({ type: 'updateValueNested1', association_1: 'site', attribute: 'roof_squarefeet', value: '' });
          if(this.run.optimality_tolerance_techs) this.$store.commit({ type: 'updateValue', attribute: 'optimality_tolerance_techs', value: '' });
          if(this.run.solver_name) this.$store.commit({ type: 'updateValue', attribute: 'solver_name', value: 'HiGHS' });
          if(this.run.timeout_seconds) this.$store.commit({ type: 'updateValue', attribute: 'timeout_seconds', value: '' });

          // prime generator inputs
          if(this.run.site.prime_generator.fuel_type != 'natural_gas') this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'prime_generator', attribute: 'fuel_type', value: 'natural_gas' });

          // ghp inputs
          if(this.run.site.ghp.building_sqft) this.$store.commit({ type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'building_sqft', value: '' });

          window.scrollTo(0, document.getElementById('site_heading').offsetTop);
        }
      },
      // need to update this to reflect the two different emissions types, health and cambium.
      emissionsFactorEntered: function() {
        if(this.run.site.electric_tariff.emissions_type == 'hourly' && this.run.site.electric_tariff.emissions_factor_hourly_source) {
          return true;
        } else if(this.run.site.electric_tariff.emissions_type == 'uploaded' && this.run.site.electric_tariff.emissions_file) {
          return true;
        } else if(this.run.site.electric_tariff.cambium_emissions_type == 'uploaded' && this.run.site.electric_tariff.cambium_emissions_file) {
          return true;
        }
      },
      updateEmissionsDefaults: function() {
        var self = this;
        $.ajax({
          url: `${this.urlRoot}/emissions-profile`,
          data: {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            // when viewing the emissisons charts are enabled again, this year value will either be 
            // 2017 if a user doesn't upload a custom typical load profile, or whatever value the 
            // user enters when they upload a custom load profile.
            year: 2017
          },
          success: function handleResults(data) {
            self.$store.commit('updateObject', { object: 'displayAnnualEmissionsFactors', value: false });

            var payload = {
              maxFactor: Math.max(...data.emissions_factor_series_lb_CO2_per_kwh),
              minFactor: Math.min(...data.emissions_factor_series_lb_CO2_per_kwh),
              meanFactor: (data.emissions_factor_series_lb_CO2_per_kwh.reduce((a, b) => a + b)) / data.emissions_factor_series_lb_CO2_per_kwh.length,
              series: data.emissions_factor_series_lb_CO2_per_kwh
            }

            if(data.avert_region.includes("Alaska") || data.avert_region.includes("Hawaii")) {
              if(!this.emissionsFactorEntered) {
                //TODO: the .click() should not ne necessary once bootstrap-vue integrated
                document.querySelectorAll("[href='#emissions_factor_annual']")[0].click();
                document.querySelectorAll("[href='#cambium_emissions_factor_annual']")[0].click();
                document.querySelectorAll("[href='#cambium_emissions_factor_hourly']")[0].style.pointerEvents = "none";
                document.querySelectorAll("[href='#emissions_factor_hourly']")[0].style.pointerEvents = "none";

                self.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'emissions_factor_hourly_source', value: '' });

                // .toString() will still show 0 values, otherwise the default will be left blank
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'co2_annual_emissions_factor', value: data.emissions_factor_series_lb_CO2_per_kwh[0].toString() });
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'nox_annual_emissions_factor', value: data.emissions_factor_series_lb_NOx_per_kwh[0].toString() });
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'so2_annual_emissions_factor', value: data.emissions_factor_series_lb_SO2_per_kwh[0].toString() });
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'pm25_annual_emissions_factor', value: data.emissions_factor_series_lb_SO2_per_kwh[0].toString() });

                self.$store.commit('updateObject', { object: 'displayGridClimateEmissionsWarning', value: false });
                self.$store.commit('updateObject', { object: 'displayGridHealthEmissionsWarning', value: false });
              }
            } else if(data.avert_region) {
              // once the chart is enabled again, this needs to be commented back in and logic updated
              // as emissions_factor_hourly_source gets autoselected when defaults are fetched.
              // if(!self.run.site.electric_tariff.emissions_factor_hourly_source) {
              //   self.$store.commit('updateObject', { object: 'emissionsHourlyLoading', value: true });
              //   self.$store.commit('updateObject', { object: 'emissionsHourlyProfile', value: payload });
              // }

              // setTimeout(function() {
              //   self.$store.commit('updateObject', { object: 'emissionsHourlyLoading', value: false });
              // }, 800);

              document.querySelectorAll("[href='#cambium_emissions_factor_hourly']")[0].style.pointerEvents = "auto";
              document.querySelectorAll("[href='#emissions_factor_hourly']")[0].style.pointerEvents = "auto";

              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'emissions_factor_hourly_source', value: data.avert_region });

              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'co2_annual_emissions_factor', value: '' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'nox_annual_emissions_factor', value: '' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'so2_annual_emissions_factor', value: '' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'pm25_annual_emissions_factor', value: '' });

              self.$store.commit('updateObject', { object: 'displayGridClimateEmissionsWarning', value: false });
              self.$store.commit('updateObject', { object: 'displayGridHealthEmissionsWarning', value: false });
            }

            setTimeout(function() {
              // a little hacky but this is to force re-rendering of the emissions
              // annual tab so placeholders also update - otherwise the data
              // (e.g. placeholders.site.financial.co2_annual_emissions_factor)
              // will update but the displayed placeholder won't :/
              // Again something bootstrap-vue should solve
              self.$store.commit('updateObject', { object: 'displayAnnualEmissionsFactors', value: true });
            }, 1)
          },
          error: function(data) {
            self.$store.commit('updateObject', { object: 'displayAnnualEmissionsFactors', value: false });
            document.querySelectorAll("[href='#emissions_factor_annual']")[0].click();
            document.querySelectorAll("[href='#cambium_emissions_factor_annual']")[0].click();
            document.querySelectorAll("[href='#cambium_emissions_factor_hourly']")[0].style.pointerEvents = "none";
            document.querySelectorAll("[href='#emissions_factor_hourly']")[0].style.pointerEvents = "none";

            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'co2_annual_emissions_factor', value: '0' });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'nox_annual_emissions_factor', value: '0' });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'so2_annual_emissions_factor', value: '0' });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'pm25_annual_emissions_factor', value: '0' });

            // update the actual values here since this input is required for non HI/AK locations
            self.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'co2_annual_emissions_factor', value: '0' });
            self.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'nox_annual_emissions_factor', value: '0' });
            self.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'so2_annual_emissions_factor', value: '0' });
            self.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'electric_tariff', attribute: 'pm25_annual_emissions_factor', value: '0' });

            self.$store.commit('updateObject', { object: 'displayGridClimateEmissionsWarning', value: true });
            self.$store.commit('updateObject', { object: 'displayGridHealthEmissionsWarning', value: true });

            setTimeout(function() {
              // a little hacky but this is to force re-rendering of the emissions
              // annual tab so placeholders also update - otherwise the data
              // (e.g. placeholders.site.financial.co2_annual_emissions_factor)
              // will update but the displayed placeholder won't :/
              // Again something bootstrap-vue should solve
              self.$store.commit('updateObject', { object: 'displayAnnualEmissionsFactors', value: true });
            }, 1)
          }
        })
      },
      updateHealthEmissionsDefaults: function() {
        if(this.run.site.financial.om_cost_escalation_rate_fraction) {
          var inflation = this.run.site.financial.om_cost_escalation_rate_fraction / 100.0;
        } else {
          var inflation = 0.025;
        }

        if(this.run.site.latitude && this.run.site.longitude) {
          var self = this;
          $.ajax({
            url: `${this.urlRoot}/emissions-health-defaults`,
            data: {
              latitude: this.run.site.latitude,
              longitude: this.run.site.longitude,
              inflation: inflation,
            },
            success: function handleResults(data) {
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'NOx_onsite_fuelburn_cost_per_tonne', value: data.NOx_onsite_fuelburn_cost_per_tonne });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'NOx_grid_cost_per_tonne', value: data.NOx_grid_cost_per_tonne });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'SO2_onsite_fuelburn_cost_per_tonne', value: data.SO2_onsite_fuelburn_cost_per_tonne });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'SO2_grid_cost_per_tonne', value: data.SO2_grid_cost_per_tonne });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'PM25_onsite_fuelburn_cost_per_tonne', value: data.PM25_onsite_fuelburn_cost_per_tonne });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'PM25_grid_cost_per_tonne', value: data.PM25_grid_cost_per_tonne });

              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'NOx_cost_escalation_rate_fraction', value: Number(data.NOx_cost_escalation_rate_fraction * 100.0).toFixed(2) + "%" });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'SO2_cost_escalation_rate_fraction', value: Number(data.SO2_cost_escalation_rate_fraction * 100.0).toFixed(2) + "%" });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'PM25_cost_escalation_rate_fraction', value: Number(data.PM25_cost_escalation_rate_fraction * 100.0).toFixed(2) + "%" });

              self.$store.commit('updateObject', { object: 'displayEmissionsHealthCostsWarning', value: false });
            },
            error: function(data) {
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'NOx_onsite_fuelburn_cost_per_tonne', value: '0' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'NOx_grid_cost_per_tonne', value: '0' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'SO2_onsite_fuelburn_cost_per_tonne', value: '0' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'SO2_grid_cost_per_tonne', value: '0' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'PM25_onsite_fuelburn_cost_per_tonne', value: '0' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'PM25_grid_cost_per_tonne', value: '0' });

              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'NOx_cost_escalation_rate_fraction', value: '0%' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'SO2_cost_escalation_rate_fraction', value: '0%' });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'financial', attribute: 'PM25_cost_escalation_rate_fraction', value: '0%' });

              self.$store.commit('updateObject', { object: 'displayEmissionsHealthCostsWarning', value: true });
            }
          })
        }
      },
      updateDocumentTitle: function() {
        if (this.run.site.description) {
          document.title = `${this.run.site.description} | REopt Web Tool | REopt Energy Integration & Optimization | NREL`
        } else {
          document.title = 'REopt Web Tool | REopt Energy Integration & Optimization | NREL'
        }
      }
    },

    watch: {
      'run.site.pv_wind_space_available': function() {
        if(this.run.site.pv_wind_space_available == "roofspace") {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'pv', attribute: 'array_type', value: 'Rooftop, Fixed' });
        } else {
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'pv', attribute: 'array_type', value: 'Ground Mount, Fixed' });
        }
      },
      'run.site.financial.om_cost_escalation_rate_fraction': function() {
        this.updateHealthEmissionsDefaults();
      },
    },

    mounted: function() {
      // for copied runs
      if(this.run.site.latitude) {
        this.updateEmissionsDefaults();
      }

      if (this.run.site.address) {
        this.updateHealthEmissionsDefaults();
      }
    }
  }
</script>
