<template>
  <div>
    <br />
    <h4><abbr class="required">*</abbr> Typical cooling plant load <tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'section_header'"></tooltip></h4>
    <p>How would you like to enter the typical chiller thermal load profile?</p>

    <input
      v-model="run.site.load_profile_chiller.cold_load_type"
      type="hidden"
      name="run[site_attributes][load_profile_chiller_attributes][cold_load_type]" />

    <ul class="nav nav-tabs">
      <li class="active"><a @click="updateColdLoadType('simulated');" data-toggle="tab" href="#cold_load_profile_simulated_inputs"><i class="fas fa-home" aria-hidden="true"></i> Simulate</a></li>
      <li><a @click="updateColdLoadType('simulated_campus');" data-toggle="tab" href="#cold_simulated_campus"><i class="fas fa-university" aria-hidden="true"></i> Simulate Campus</a></li>
      <li><a @click="updateColdLoadType('uploaded');" data-toggle="tab" href="#cold_load_profile_uploaded" class="technology-btns"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
      <li><a @click="updateColdLoadType('custom');" data-toggle="tab" href="#cold_load_profile_custom" class="technology-btns"><i class="fas fa-wrench" aria-hidden="true"></i> Custom</a></li>
    </ul>
    <div class="tab-content">
      <div v-if="run.site.load_profile_chiller.cold_load_type == 'simulated'" class="tab-pane fade in active">
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='load_profile_chiller'
          attribute='doe_reference_name'
          required=true
          v-on:input-change="getColdSimulatedLoadProfile();" />
        <div class="row">
          <div class="col-sm-offset-6" style="padding-left:15px;">
            <input v-model="run.site.load_profile_chiller.custom_energy_consump" type="checkbox" value="1" id="custom_energy_consumption" name="run[site_attributes][load_profile_chiller_attributes][custom_energy_consump]" />
            <label style="font-weight:normal" for="custom_energy_consumption">
              &nbsp;Enter custom annual or monthly thermal energy amounts <tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'custom_energy_consump'"></tooltip>
            </label>
          </div>
        </div>
        <div v-if="run.site.load_profile_chiller.custom_energy_consump" >
          <div class="col-sm-offset-6 col-sm-6 simulated-load-options">
            <input
              v-model="run.site.load_profile_chiller.simulated_load_type"
              style="width:15px;"
              type="radio"
              value="annual"
              checked="checked"
              name="run[site_attributes][load_profile_chiller_attributes][simulated_load_type]"
              id="run_site_attributes_load_profile_chiller_attributes_simulated_load_type_annual" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_chiller_attributes_simulated_load_type_annual">Annual</label>
            <input
              v-model="run.site.load_profile_chiller.simulated_load_type"
              class="simulated-monthly"
              style="width:15px;"
              type="radio"
              value="monthly"
              name="run[site_attributes][load_profile_chiller_attributes][simulated_load_type]"
              id="run_site_attributes_load_profile_chiller_attributes_simulated_load_type_monthly" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_chiller_attributes_simulated_load_type_monthly">Monthly</label>
          </div>
          <horizontal-string-input-nested-2
            v-if="run.site.load_profile_chiller.simulated_load_type == 'annual'" association_1='site' association_2='load_profile_chiller'
            ref='annualTonHour'
            attribute='annual_tonhour'
            v-on:input-change="getColdSimBuildingAnnualLoad()" />
          <monthly-inputs
            v-else="run.site.load_profile_chiller.simulated_load_type == 'monthly'"
            type='cold'
            v-on:input-change="getColdSimBuildingMonthlyLoad()"
            v-on:paste-inputs="pasteInputs('cold')" />
        </div>
      </div>

      <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'simulated_campus'" class="tab-pane" id="cold_simulated_campus">
        <cold-simulated-campus-table
          v-on:update-cold-load="getColdSimulatedLoadProfile()" />
      </div>

      <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'uploaded'" class="tab-pane" id="cold_load_profile_uploaded">
        <input v-model="run.site.load_profile_chiller.cold_uploaded_loads_ton" type="hidden" name="run[site_attributes][load_profile_chiller_attributes][cold_uploaded_loads_ton]"/>

        <div class="form-group file required">
          <div class="col-sm-6 control-label">
            <label class="file required" for="run_site_attributes_load_profile_chiller_attributes_cold_load_file"><abbr title="Required" class="required">*</abbr> Thermal energy consumption load profile</label><tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'cold_load_file'"></tooltip>
          </div>
          <div class="col-sm-4">
            <input
              type="hidden"
              name="run[site_attributes][load_profile_chiller_attributes][cold_load_file]" />
            <input
              @change="parseCSV($event, 'cold')"
              class="form-control-file file required"
              required="required"
              aria-required="true"
              type="file"
              name="run[site_attributes][load_profile_chiller_attributes][cold_load_file]" />
            <!-- <p v-if="run.copied && this.run.site.load_profile_chiller.cold_load_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.load_profile_chiller.cold_load_file_data).metadata.filename }}</p> -->
            <p v-if="run.copied && this.run.site.load_profile_chiller.cold_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile_chiller.cold_load_file_name }}</p>
          </div>
        </div>
      </div>

      <div
        v-else-if="run.site.load_profile_chiller.cold_load_type == 'custom'"
        class="tab-pane"
        id="cold_load_profile_custom">
        <div class="col-sm-offset-6 col-sm-6 simulated-load-options" style="margin-bottom:10px;">
          <input
            v-model="run.site.load_profile_chiller.custom_load_type"
            style="width:15px;"
            type="radio"
            value="annual"
            checked="checked"
            name="run[site_attributes][load_profile_chiller_attributes][custom_load_type]"
            id="run_site_attributes_load_profile_chiller_attributes_custom_load_type_annual" />
          <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_chiller_attributes_custom_load_type_annual">Annual</label>
          <input
            v-model="run.site.load_profile_chiller.custom_load_type"
            class="simulated-monthly"
            style="width:15px;"
            type="radio"
            value="monthly"
            name="run[site_attributes][load_profile_chiller_attributes][custom_load_type]"
            id="run_site_attributes_load_profile_chiller_attributes_custom_load_type_monthly" />
          <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_chiller_attributes_custom_load_type_monthly">Monthly</label>
        </div>
        <horizontal-string-input-nested-2
          v-if="run.site.load_profile_chiller.custom_load_type == 'annual'"
          association_1='site'
          association_2='load_profile_chiller'
          attribute='annual_fraction_of_electric_load'
          required='true'
          v-on:input-change="getColdCustomAnnualPctLoad()" />
        <monthly-inputs
          v-else="run.site.load_profile_chiller.custom_load_type == 'monthly'"
          type='cold-percent'
          v-on:input-change="getColdCustomMonthlyPctLoad()"
          v-on:paste-inputs="pasteInputs('cold-percent')" />
      </div>
    </div>
    <div v-if="run.site.load_profile_chiller.cold_load_type == 'simulated' && (!run.site.load_profile_chiller.custom_energy_consump || (run.site.load_profile_chiller.custom_energy_consump && run.site.load_profile_chiller.simulated_load_type == 'annual' && !run.site.load_profile_chiller.annual_tonhour))" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimBuildLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!coldLoadProfileLoading" @click="displayColdSimBuildingLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'simulated' && run.site.load_profile_chiller.custom_energy_consump && run.site.load_profile_chiller.simulated_load_type == 'annual' && run.site.load_profile_chiller.annual_tonhour" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimBuildAnnualLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!coldLoadProfileLoading" @click="displayColdSimBuildingAnnualLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'simulated' && run.site.load_profile_chiller.custom_energy_consump && run.site.load_profile_chiller.simulated_load_type == 'monthly'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimBuildMonthlyLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!coldLoadProfileLoading" @click="displayColdSimBuildingMonthlyLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'simulated_campus' && !run.site.load_profile_chiller.custom_annual_simulated_campus" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimCampusFromElecLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!coldLoadProfileLoading" @click="displayColdSimCampusFromElecLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'simulated_campus' && run.site.load_profile_chiller.custom_annual_simulated_campus" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!coldLoadProfileLoading" @click="downloadLoadProfile('coldSimCampusLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download cooling plant load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!coldLoadProfileLoading" @click="displayColdSimCampusLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'uploaded'" class="row">
      <div class="col-sm-6 col-sm-offset-6 text-right">
        <a v-if="!coldLoadProfileLoading" @click="displayColdUploadedLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'custom' && run.site.load_profile_chiller.custom_load_type == 'annual'" class="row">
      <div class="col-sm-6 col-sm-offset-6 text-right">
        <a v-if="!coldLoadProfileLoading" @click="displayColdCustomAnnualPctLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_chiller.cold_load_type == 'custom' && run.site.load_profile_chiller.custom_load_type == 'monthly'" class="row">
      <div class="col-sm-6 col-sm-offset-6 text-right">
        <a v-if="!coldLoadProfileLoading" @click="displayColdCustomMonthlyPctLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart cooling plant load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>

    <transition name="fade">
      <cold-load-profile-modal
        v-if="showColdSimBuildLoadProfile"
        :loadType="'coldSimBuildLoadProfile'">
      </cold-load-profile-modal>
    </transition>
    <transition name="fade">
      <cold-load-profile-modal
        v-if="showColdSimBuildAnnualLoadProfile"
        :loadType="'coldSimBuildAnnualLoadProfile'">
      </cold-load-profile-modal>
    </transition>
    <transition name="fade">
      <cold-load-profile-modal
        v-if="showColdSimBuildMonthlyLoadProfile"
        :loadType="'coldSimBuildMonthlyLoadProfile'">
      </cold-load-profile-modal>
    </transition>
    <transition name="fade">
      <cold-load-profile-modal
        v-if="showColdSimCampusFromElecLoadProfile"
        :loadType="'coldSimCampusFromElecLoadProfile'">
      </cold-load-profile-modal>
    </transition>
    <transition name="fade">
      <cold-load-profile-modal
        v-if="showColdSimCampusLoadProfile"
        :loadType="'coldSimCampusLoadProfile'">
      </cold-load-profile-modal>
    </transition>
    <transition name="fade">
      <cold-load-profile-modal
        v-if="showColdUploadedLoadProfile"
        :loadType="'coldUploadedLoadProfile'">
      </cold-load-profile-modal>
    </transition>
    <transition name="fade">
      <cold-load-profile-modal
        v-if="showColdCustomAnnualPctLoadProfile"
        :loadType="'coldCustomAnnualPctLoadProfile'">
      </cold-load-profile-modal>
    </transition>
    <transition name="fade">
      <cold-load-profile-modal
        v-if="showColdCustomMonthlyPctLoadProfile"
        :loadType="'coldCustomMonthlyPctLoadProfile'">
      </cold-load-profile-modal>
    </transition>

    <horizontal-string-input-nested-2
      association_1='site'
      association_2='electric_chiller'
      attribute='cop'
      v-on:input-change="getColdCustomAnnualPctLoad()" />
    <horizontal-string-input-nested-2
      v-if="!run.analyze_ghp"
      association_1='site'
      association_2='electric_chiller'
      attribute='max_thermal_factor_on_peak_load' />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './HorizontalSelectInputNested2';
  import ColdLoadProfileModal from './../modals/ColdLoadProfileModal';
  import ColdSimulatedCampusTable from './ColdSimulatedCampusTable';
  import Tooltip from './../Tooltip';
  import MonthlyInputs from './MonthlyInputs';

  export default {
    components: {
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      ColdLoadProfileModal,
      ColdSimulatedCampusTable,
      Tooltip,
      MonthlyInputs
    },


    data: function() {
      return {
        tonhourToKwht: 3.51685,
      }
    },

    computed: {
      ...mapGetters([
        'placeholders',
        'run',
        'selectOptions',
        'tooltips',
        'urlRoot',

        'elecLoadProfile',
        'elecSimBuildAnnualLoadProfile',
        'elecSimBuildMonthlyLoadProfile',
        'elecSimCampusLoadProfile',
        'elecUploadedLoadProfile',

        'coldLoadProfile',
        'coldSimBuildLoadProfile',
        'coldSimBuildAnnualLoadProfile',
        'coldSimBuildMonthlyLoadProfile',
        'coldSimCampusFromElecLoadProfile',
        'coldLoadProfileLoading',
        'coldCustomAnnualPctLoadProfile',
        'coldCustomMonthlyPctLoadProfile',
        'coldUploadedLoadProfile',
        'coldSimCampusLoadProfile',

        'showColdSimCampusFromElecLoadProfile',
        'showColdSimBuildAnnualLoadProfile',
        'showColdSimCampusLoadProfile',
        'showColdSimBuildLoadProfile',
        'showColdSimBuildMonthlyLoadProfile',
        'showColdCustomAnnualPctLoadProfile',
        'showColdCustomMonthlyPctLoadProfile',
        'showColdUploadedLoadProfile',

        'annualTonHourForRatio',
        'ratioHeatingToCooling',

      ]),
      watchDependencies:  function() {
        return this.run.analyze_ghp,
        this.run.analyze_cws,
        this.run.analyze_ashp_space,
        this.run.analyze_ashp_water,
        this.run.site.latitude, 
        this.run.site.longitude,
        this.run.site.load_profile.simulated_load_type, 
        this.run.site.load_profile_chiller.simulated_load_type, 
        this.run.site.load_profile.typical_load_type,
        this.run.site.load_profile_chiller.cold_load_type, 
        this.run.site.load_profile_chiller.custom_load_type,
        new Date();
      },
    },

    methods: {
      displayColdSimCampusFromElecLP: function() {
        // display cold simulated campus load profile
        if(Object.keys(this.coldSimCampusFromElecLoadProfile).length == 0) {
          bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdSimCampusFromElecLoadProfile', value: true });
      },
      displayColdSimCampusLP: function() {
        // display cold simulated campus load profile
        if(Object.keys(this.coldSimCampusLoadProfile).length == 0) {
          bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdSimCampusLoadProfile', value: true });
      },
      displayColdSimBuildingAnnualLP: function() {
        // display cold simulated building annual load profile
        if(!this.run.site.load_profile_chiller.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdSimBuildAnnualLoadProfile', value: true });
      },
      displayColdSimBuildingLP: function() {
        // display cold building annual load profile (no custom energy consumption)
        var elecLoadProfile = this.$store.getters.elecLoadProfile;
        if(!this.run.site.load_profile_chiller.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude || Object.keys(this[elecLoadProfile]).length == 0) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first. If no custom thermal energy amounts are entered, please verify a typical electrical load is present.');
          return false;
        }
        if (this.run.site.load_profile_chiller.custom_energy_consump && this.run.site.load_profile_chiller.custom_load_type == 'annual' && !this.run.site.load_profile_chiller.annual_tonhour) {
          this.$store.commit('updateObject', { object: 'showColdSimBuildAnnualLoadProfile', value: true });
        } else {
          this.$store.commit('updateObject', { object: 'showColdSimBuildLoadProfile', value: true });
        }
      },
      displayColdSimBuildingMonthlyLP: function() {
        // display cold simulated building monthly load profile
        if(!this.allMonthlyInputsPresent('cold') || !this.run.site.load_profile_chiller.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
          bootbox.alert('Please verify that all monthly values for cooling plant thermal energy consumption have been entered, a Type of Building has been selected, and a Site Location entered.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdSimBuildMonthlyLoadProfile', value: true });
      },
      displayColdCustomAnnualPctLP: function() {
        // display cold custom annual percent load profile
        if(!this.coldCustomAnnualPctLoadProfile.loadsTonHour) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdCustomAnnualPctLoadProfile', value: true });
      },
      displayColdCustomMonthlyPctLP: function() {
        // display cold custom annual percent load profile
        if(!this.coldCustomMonthlyPctLoadProfile.loadsTonHour) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showColdCustomMonthlyPctLoadProfile', value: true });
      },
      displayColdUploadedLP: function() {
        // display electric uploaded load profile
        if(!this.coldUploadedLoadProfile.loadsTonHour) {
          bootbox.alert('A cooling plant thermal load profile must be selected or uploaded first.');
          return false;
        }
        this.$store.commit('updateObject', { object: 'showColdUploadedLoadProfile', value: true });
      },
      getColdSimBuildingAnnualLoad: function() {
        if (this.run.site.load_profile_chiller.annual_tonhour) {
          this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });
          var self = this;
          // Adding these conditionals to send additional parameters to the simulated_annual_cold_load method in the runs_controller.
          // This allows the default annual_tonhour value to update if there is a user entered annual_tonhour value.
          if (this.run.site.load_profile.typical_load_type == "simulated_building" && this.run.site.load_profile.annual_kwh) {
            var annualKwh = this.run.site.load_profile.annual_kwh;
            var doeReferenceName = this.run.site.load_profile.doe_reference_name;
          } else if (this.run.site.load_profile.typical_load_type == "simulated_building" && !this.run.site.load_profile.annual_kwh) {
            var annualKwh = parseInt(this.$store.state.placeholders.site.load_profile.annual_kwh.split(',').join(''));
            var doeReferenceName = this.run.site.load_profile.doe_reference_name;
          } else if (this.run.site.load_profile.typical_load_type  == "simulated_campus") {
            var annualKwh = this.run.site.load_profile.campus_annual_kwh;
            var doeReferenceNames = this.run.site.load_profile.campus_buildings.map(function(el, i) {
              return el.doe_reference_name
            })
            var percentShares = this.run.site.load_profile.campus_buildings.map(function(el, i) {
              return el.percent_of_total/100.0
            })
          }

          $.ajax({
            url: `${self.urlRoot}/simulated-annual-cold-load`,
            data: {
              latitude: this.run.site.latitude,
              longitude: this.run.site.longitude,
              doe_reference_name: doeReferenceName,
              doe_reference_names: doeReferenceNames,
              percent_shares: percentShares,
              cooling_doe_ref_name: this.run.site.load_profile_chiller.doe_reference_name,
              annual_tonhour: this.run.site.load_profile_chiller.annual_tonhour,
              annual_kwh: annualKwh,
            },
            success: function(data) {
              var payload = {
                loadsTonHour: data.cold_load_data.loads_ton,
                minTonHour: data.cold_load_data.min_ton,
                meanTonHour: data.cold_load_data.mean_ton,
                maxTonHour: data.cold_load_data.max_ton,
              }
              self.$store.commit('updateObject', { object: 'coldSimBuildAnnualLoadProfile', value: payload });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_chiller', attribute: 'cop', value: data.cold_load_data.chiller_cop });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'load_profile_chiller', attribute: 'annual_tonhour', value: Math.round(data.elec_load_data_for_defaults.cooling_defaults.annual_tonhour) });

              self.updateAbsChillerDefaults();
            },
            error: function() {
              self.$store.commit('updateObject', { object: 'coldSimBuildAnnualLoadProfile', value: {} });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
            }
          })
        } else {
          this.getColdSimulatedLoadProfile();
        }
      },
      getColdSimulatedLoadProfile: function() {
        // see comment in getUrlForSimColdLoadBasedOnElec method above
        var coldType = this.run.site.load_profile_chiller.cold_load_type;
        var coldSimType = this.run.site.load_profile_chiller.simulated_load_type;
        var coldBuilding = this.run.site.load_profile_chiller.doe_reference_name;
        var customEnergyConsumption = this.run.site.load_profile_chiller.custom_energy_consump;
        var elecLoadProfile = this.$store.getters.elecLoadProfile;
        var url = this.getUrlForSimColdLoadBasedOnElec(elecLoadProfile);

        if(coldType == 'simulated') {
          if(!coldBuilding) {
            this.$store.commit('updateObject', { object: 'coldSimBuildLoadProfile', value: {} });
            this.$store.commit('updateObject', { object: 'coldSimBuildAnnualLoadProfile', value: {} });
            this.$store.commit('updateObject', { object: 'coldSimBuildMonthlyLoadProfile', value: {} });

            return false;
          } else {

            if(!customEnergyConsumption || (customEnergyConsumption && coldSimType == "annual" && !this.run.site.load_profile_chiller.annual_tonhour)) {
              if(Object.keys(this[elecLoadProfile]).length == 0) {
                // if no elec load then set cold load to null
                this.$store.commit('updateObject', { object: 'coldSimBuildLoadProfile', value: {} });

                return false;
              }
              // get cold load based on elec load inputs and cold building
              // when the annual_tonhour has not been specified by the user we still base the
              // cold load on the elec load
              this.getColdSimLoadProfileBasedOnElec(url, this.$store.getters.coldLoadProfile);
            } else if(customEnergyConsumption && coldSimType == "annual" && this.run.site.load_profile_chiller.annual_tonhour) {
              this.getColdSimBuildingAnnualLoad();
            } else if(customEnergyConsumption && coldSimType == "monthly") {
              this.getColdSimBuildingMonthlyLoad();
            }
          }
        } else if(coldType == "simulated_campus") {
          var customAnnual = this.run.site.load_profile_chiller.custom_annual_simulated_campus;
          if(!customAnnual) {
            var verifyAllBuildings = !this.run.site.load_profile_chiller.campus_buildings.map((el) => el.doe_reference_name).includes(undefined);
            var buildings = this.run.site.load_profile_chiller.campus_buildings;
            if(buildings.length == 1) {
              var percentSum = Number(buildings[0].percent_of_total);
            } else {
              var percentSum = buildings.map(function(el, i) {
                return Number(el.percent_of_total);
              }).reduce(function(total, num) {
                return total + num;
              })
            }

            if(Object.keys(this[elecLoadProfile]).length == 0 || !verifyAllBuildings || percentSum != 100) {
              // if no elec load then set cold load to null
              this.$store.commit('updateObject', { object: 'coldSimCampusFromElecLoadProfile', value: {} });

              return false;
            } else {
              this.getColdSimLoadProfileBasedOnElec(url, this.$store.getters.coldLoadProfile);
            }
          }
        }
      },
      getColdSimBuildingMonthlyLoad: function() {
        if(this.allMonthlyInputsPresent('cold') && this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile_chiller.doe_reference_name) {
          this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

          var monthly_tonhour = [];
          for(var i = 1; i <= 12; i++) {
            monthly_tonhour.push(this.run.site.load_profile_chiller[`monthly_tonhour_${i}`]);
          }

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            doe_reference_name: this.run.site.load_profile_chiller.doe_reference_name,
            monthly_tonhour: monthly_tonhour,
          }

          var self = this;
          $.ajax({
            url: self.urlRoot + "/simulated-monthly-cold-load",
            data: params,
            success: function(data) {
              var payload = {
                loadsTonHour: data.loads_ton,
                minTonHour: data.min_ton,
                meanTonHour: data.mean_ton,
                maxTonHour: data.max_ton,
              }
              self.$store.commit('updateObject', { object: 'coldSimBuildMonthlyLoadProfile', value: payload });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_chiller', attribute: 'cop', value: data.chiller_cop });

              self.updateAbsChillerDefaults();
            },
            error: function() {
              self.$store.commit('updateObject', { object: 'coldSimBuildMonthlyLoadProfile', value: {} });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
            }
          })
        }
      },
      getColdCustomAnnualPctLoad: function() {
        var self = this;
        var fraction = this.run.site.load_profile_chiller.annual_fraction_of_electric_load;
        var typicalLoadType = this.getTypicalLoadType();
        this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

        if(!fraction || !Object.keys(this[typicalLoadType]).length) {
          this.$store.commit('updateObject', { object: 'coldCustomAnnualPctLoadProfile', value: {} });
        } else {
          var factor = (fraction / 100.0);
          var maxKw = this[typicalLoadType].maxKw * factor;
          var chillerCOP = this.run.site.electric_chiller.cop;

          if(chillerCOP) {
            this.updateColdLoad(this[typicalLoadType].loadsKw, chillerCOP, 'annual');
          } else {
            var fraction = this.run.site.load_profile_chiller.annual_fraction_of_electric_load / 100.0;
            var load = this[typicalLoadType].loadsKw.map(function(el, i) {
              return el * fraction;
            })

            // send fractional load to API to get chiller COP and THEN adjust load yet again
            this.getChillerCOPAndUpdateLoad(load, 'annual');
          }
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
        }, 800);
      },
      updateColdLoadType: function(type) {
        this.updateStoreNested2({
          association_2: 'load_profile_chiller',
          attribute: 'cold_load_type',
          value: type
        });
      },
      downloadLoadProfile: function(loadProfile) {
        if(loadProfile.includes("cold")) {
          var values = this[loadProfile].loadsTonHour;
          var rows = [ ['Hour', 'Cooling Thermal Load (tons)'] ];
        }
        if(!values || values.length == 0) {
          bootbox.alert("A load profile must be selected or uploaded first.");
          return false;
        }

        for(var i = 0, len = values.length; i < len; i++) {
          rows.push([i + 1, values[i]]);
        }

        var csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'REopt Load Profile.csv');
      },
      pasteInputs: function(type) {
        event.preventDefault();
        var self = this;
        var clip = event.clipboardData.getData('text');

        if (clip.includes('\r\n')) {
          var clipArray = clip.split('\r\n');
          if (clipArray.length > 12) {
            // remove any values at the end of the array if they exceed the 12 month count so the first 12 paste to the correct months
            clipArray.splice(12, clipArray.length - 12);
          }
          // remove any commas within the numbers if they exist
          clipArray.forEach(function(item, i) {
            clipArray[i] = item.split(',').join('');
          })

          // set the month number to be assigned dynamically below
          if (type === 'cold') {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[3].split('_')[2]);
          } else if (type === 'cold-percent') {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[3].split('_')[3]);
          }

          if (type === 'cold' || type === 'cold-percent') {
            var attributeNameArray = event.target.name.replaceAll(']', '').split('[')[3].split('_');
            attributeNameArray.splice(-1);
            var attrBaseName = attributeNameArray.join('_');
          }
        } else {
          // remove any commas if they exist for single value paste
          var clipValue = clip.split(',').join('');
          var attributeName = event.target.name.replaceAll(']', '').split('[')[3];
        }

        if (clipArray) {
          clipArray.forEach(function(item, i) {
            // Ensure the values are only numbers.
            if (isNaN(parseFloat(item))) {
              bootbox.alert('The monthly input must contain only numeric values. Could not parse value "' + item + '"');
              return false;
            } else {
              self.$store.commit('updateValueNested2', {
                association_1: 'site',
                association_2: 'load_profile_chiller',
                attribute: attrBaseName + `_${i + month}`,
                value: item
              });
            }
          });
        } else {
          // for single input paste
          self.$store.commit('updateValueNested2', {
            association_1: 'site',
            association_2: 'load_profile_chiller',
            attribute: attributeName,
            value: clipValue
          });
        }

        if (type === 'cold') {
          this.getColdSimBuildingMonthlyLoad();
        } else if (type === 'cold-percent') {
          this.getColdCustomMonthlyPctLoad();
        }
      },
      getColdCustomMonthlyPctLoad: function() {
        var self = this;
        var typicalLoadType = this.getTypicalLoadType();
        this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

        if(!this.allMonthlyInputsPresent('cold-percent') || !Object.keys(this[typicalLoadType]).length || !this.run.site.latitude || !this.run.site.longitude) {
          this.$store.commit('updateObject', { object: 'coldCustomMonthlyPctLoadProfile', value: {} });
        } else {
          var monthly_fraction = [];
          for(var i = 1; i <= 12; i++) {
            var load = self.run.site.load_profile_chiller[`lp_fraction_monthly_${i}`];
            monthly_fraction.push(load / 100);
          }

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            monthly_fraction: monthly_fraction,
          }

          $.ajax({
            url: this.urlRoot + "/simulated-monthly-cold-load",
            data: params,
            success: function handleResults(data) {
              var typicalLoadType = self.getTypicalLoadType();
              var initialLoad = self[typicalLoadType].loadsKw.map(function(el, i) {
                return el * data.loads_fraction[i];
              })

              // send fractional load to API to get chiller COP and THEN adjust load yet again
              self.getChillerCOPAndUpdateLoad(initialLoad, 'monthly');
            },
          })
        }

        setTimeout(function() {
          self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
        }, 800);
      },
      getTypicalLoadType: function() {
        var type = this.run.site.load_profile.typical_load_type;

        if(type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "annual") {
          return 'elecSimBuildAnnualLoadProfile';
        } else if(type == "simulated_building" && this.run.site.load_profile.simulated_load_type == "monthly") {
          return 'elecSimBuildMonthlyLoadProfile';
        } else if(type == "simulated_campus") {
          return 'elecSimCampusLoadProfile';
        } else if(type == "uploaded") {
          return 'elecUploadedLoadProfile';
        }
      },
      allMonthlyInputsPresent: function(type) {
        var values = [];
        for(var i = 1; i <= 12; i++) {
          if(type == "cold") {
            values.push(this.run.site.load_profile_chiller[`monthly_tonhour_${i}`]);
          } else if(type == "cold-percent") {
            values.push(this.run.site.load_profile_chiller[`lp_fraction_monthly_${i}`]);
          }
        }

        if(values.includes("") || values.includes(null) || values.includes(undefined)) {
          return false;
        } else {
          return true;
        }
      },
      getChillerCOPAndUpdateLoad: function(initialLoad, type) {
        var self = this;

        $.ajax({
          url: self.urlRoot + "/electric-chiller-cop",
          data: { max_kw: Math.max.apply(Math, initialLoad) },
          success: function handleResults(data) {
            self.$store.commit('updatePlaceholder', {
              association_1: 'site',
              association_2: 'electric_chiller',
              attribute: 'cop',
              value: data.existing_chiller_cop
            });

            self.updateColdLoad(initialLoad, data.existing_chiller_cop, type);
          },
        })
      },
      updateColdLoad: function(initialLoad, chillerCOP, type) {
        var coldLoad = {};
        var factor = chillerCOP / this.tonhourToKwht;

        coldLoad.loadsTonHour = initialLoad.map(function(el, i) {
          return el * factor;
        })
        coldLoad.maxTonHour = Math.max.apply(Math, initialLoad) * factor;
        coldLoad.minTonHour = Math.min.apply(Math, initialLoad) * factor;
        coldLoad.meanTonHour = this.$store.getters.average(initialLoad) * factor;

        if(type == "annual") {
          this.$store.commit('updateObject', { object: 'coldCustomAnnualPctLoadProfile', value: coldLoad });
        } else if(type == "monthly") {
          this.$store.commit('updateObject', { object: 'coldCustomMonthlyPctLoadProfile', value: coldLoad });
        }
      },
      updateStore: function(attr, value) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          attribute: attr,
          value: value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateStoreNested2: function(payload) {
        var payload = {
          association_1: 'site',
          association_2: payload.association_2,
          attribute: payload.attribute,
          value: payload.value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateAbsChillerDefaults: function() {
        if(!this.run.site.chp.analyze_abs_chiller) {
          return false;
        }
        var self = this;
        $.ajax({
          url: self.urlRoot + '/abs-chiller-defaults',
          data: {
            hot_water_or_steam: this.run.site.chp.boiler.existing_boiler_production_type,
            max_cooling_load_tons: this[this.$store.getters.coldLoadProfile].maxTonHour,
          },
          success: function handleResults(data) {
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'cop_thermal', value: data.default_inputs.cop_thermal });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'installed_cost_per_ton', value: '$' + Math.round(data.default_inputs.installed_cost_per_ton) });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', association_3: 'absorption_chiller', attribute: 'om_cost_per_ton', value: '$' + Math.round(data.default_inputs.om_cost_per_ton) });
          }
        })
      },
      getUrlForSimColdLoadBasedOnElec: function(elecLoadProfile) {
        // this is used to create the url and params for simulating a cold load
        // based on the elec load (hence all the conditionals and permutations)
        // this happens when custom annual (or monthly) tonhours are not entered
        // by the user (we still simulate the cold load, this simulation is based
        // on the electric load and cold load building type(s)). This happens when:
        // !run.site.load_profile_chiller.custom_energy_consump
        // OR
        // !run.site.load_profile_chiller.custom_annual_simulated_campus
        // this is so users don't specify cold loads that are greater than elec loads
        // which gives the API trouble during optimization
        var coldType = this.run.site.load_profile_chiller.cold_load_type;
        var coldBuilding = this.run.site.load_profile_chiller.doe_reference_name;
        var elecBuilding = this.run.site.load_profile.doe_reference_name;
        if(this.run.site.load_profile.annual_kwh) {
          var annualKwh = this.run.site.load_profile.annual_kwh;
        } else if(this[`${elecLoadProfile}`].loadsKw) {
          var annualKwh = this[`${elecLoadProfile}`].loadsKw.reduce((a, b) => a + b);
        }
        var campusAnnualKwh = this.run.site.load_profile.campus_annual_kwh;

        if(coldType == "simulated" && elecLoadProfile == 'elecSimBuildAnnualLoadProfile') {
          return `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&doe_reference_name=${elecBuilding}&annual_kwh=${annualKwh}&cooling_doe_ref_name=${coldBuilding}`;
        } else if(coldType == "simulated" && elecLoadProfile == 'elecSimBuildMonthlyLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&doe_reference_name=${elecBuilding}&cooling_doe_ref_name=${coldBuilding}`;
          for(var i = 1; i <= 12; i++) {
            url += "&monthly_totals_kwh[]=" + this.run.site.load_profile[`energy_use_monthly_${i}`];
          }

          return url;
        } else if(coldType == "simulated" && elecLoadProfile == 'elecSimCampusLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&cooling_doe_ref_name=${coldBuilding}&annual_kwh=${campusAnnualKwh}`;
          this.run.site.load_profile.campus_buildings.forEach(function(el) {
            url += `&doe_reference_names[]=${el.doe_reference_name}`;
            url += `&percent_shares[]=${el.percent_of_total / 100.0}`;
          })

          return url;
        } else if(coldType == "simulated" && elecLoadProfile == 'elecUploadedLoadProfile') {
          return `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&cooling_doe_ref_name=${coldBuilding}`;
        } else if(coldType == "simulated_campus" && elecLoadProfile == 'elecSimBuildAnnualLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&doe_reference_name=${elecBuilding}&annual_kwh=${annualKwh}`;
          this.run.site.load_profile_chiller.campus_buildings.forEach(function(el) {
            url += `&cooling_doe_ref_names[]=${el.doe_reference_name}`;
            url += `&cooling_pct_shares[]=${el.percent_of_total / 100.0}`;
          })

          return url;
        } else if(coldType == "simulated_campus" && elecLoadProfile == 'elecSimBuildMonthlyLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&doe_reference_name=${elecBuilding}`;
          this.run.site.load_profile_chiller.campus_buildings.forEach(function(el) {
            url += `&cooling_doe_ref_names[]=${el.doe_reference_name}`;
            url += `&cooling_pct_shares[]=${el.percent_of_total / 100.0}`;
          })
          for(var i = 1; i <= 12; i++) {
            url += "&monthly_totals_kwh[]=" + this.run.site.load_profile[`energy_use_monthly_${i}`];
          }

          return url;
        } else if(coldType == "simulated_campus" && elecLoadProfile == 'elecSimCampusLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}&annual_kwh=${campusAnnualKwh}`
          this.run.site.load_profile.campus_buildings.forEach(function(el) {
            url += `&doe_reference_names[]=${el.doe_reference_name}`;
            url += `&percent_shares[]=${el.percent_of_total / 100.0}`;
          })
          this.run.site.load_profile_chiller.campus_buildings.forEach(function(el) {
            url += `&cooling_doe_ref_names[]=${el.doe_reference_name}`;
            url += `&cooling_pct_shares[]=${el.percent_of_total / 100.0}`;
          })

          return url;
        } else if(coldType == "simulated_campus" && elecLoadProfile == 'elecUploadedLoadProfile') {
          var url = `${this.urlRoot}/simulated-cold-elec-load?latitude=${this.run.site.latitude}&longitude=${this.run.site.longitude}`;
          this.run.site.load_profile_chiller.campus_buildings.forEach(function(el) {
            url += `&cooling_doe_ref_names[]=${el.doe_reference_name}`;
            url += `&cooling_pct_shares[]=${el.percent_of_total / 100.0}`;
          })

          return url;
        }
      },
      getColdSimLoadProfileBasedOnElec: function(url, coldLoadProfile) {
        // see comment in getUrlForSimColdLoadBasedOnElec method above
        this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

        var self = this;
        $.ajax({
          url: url,
          success: function(data) {
            var payload = {
              loadsTonHour: data.cooling_defaults.loads_ton,
              minTonHour: data.cooling_defaults.min_ton,
              meanTonHour: data.cooling_defaults.mean_ton,
              maxTonHour: data.cooling_defaults.max_ton,
            }

            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_chiller', attribute: 'cop', value: data.cooling_defaults.chiller_cop });
            self.$store.commit('updateObject', { object: coldLoadProfile, value: payload });

            if (self.run.site.electric_chiller.cop) {
              var cop = self.run.site.electric_chiller.cop
            } else {
              var cop = data.cooling_defaults.chiller_cop
            }

            if(self.run.site.load_profile.typical_load_type == "uploaded") {
              // when the electric typical load type == uploaded, the cold load
              // profile is based on the `fraction_of_total_electric_profile` returned
              // by the /simulated_load endpoint (if no tonhour specified)
              cop ? '' : cop = data.cooling_defaults.chiller_cop;

              var coldLoad = self[self.$store.getters.elecLoadProfile].loadsKw.map(function(el, i) {
                return el * data.cooling_defaults.fraction_of_total_electric_profile[i] * cop / tonhourToKwht;
              })
              var payload = {
                loadsTonHour: coldLoad,
                minTonHour: Math.min.apply(Math, coldLoad),
                meanTonHour: self.$store.getters.average(coldLoad),
                maxTonHour: Math.max.apply(Math, coldLoad),
              }
              self.$store.commit('updateObject', { object: coldLoadProfile, value: payload });
            }

            payload.annualTonHour = data.cooling_defaults.annual_tonhour;
            self.populateAnnualTonHourValueOrDefault(payload);
            self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
            self.updateAbsChillerDefaults();

            if (self.run.analyze_ghp) {
              var annualTonHourForRatio = payload.annualTonHour;
              self.$store.commit('updateObject', { object: 'annualTonHourForRatio', value: annualTonHourForRatio });
            }

            if (self.run.analyze_ghp && self.annualMmbtuForRatio) {
              self.updateRatioHeatingToCooling();
            }
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'coldSimBuildLoadProfile', value: {} });
            self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
          }
        })
      },
      populateAnnualTonHourValueOrDefault: function(payload) {
        // if annual tonhour already present then we just update the default
        if(!this.run.site.load_profile_chiller.annual_tonhour) {

          this.$store.commit('updateObject', { object: 'coldSimBuildAnnualLoadProfile', value: payload });
        }

        this.$store.commit('updatePlaceholder', {
          association_1: 'site',
          association_2: 'load_profile_chiller',
          attribute: 'annual_tonhour',
          value: Math.round(payload.annualTonHour)
        });
      },
      updateRatioHeatingToCooling: function() {
        var annualMmbtu = this.annualMmbtuForRatio;
        var annualTonHour = this.annualTonHourForRatio;

        if (this.run.site.ghp.boiler.boiler_efficiency) {
          var boilerEfficiency = this.run.site.ghp.boiler.boiler_efficiency / 100;
        } else {
          var boilerEfficiency = this.placeholders.site.ghp.boiler.boiler_efficiency.split('%')[0] / 100;
        }

        if (this.run.site.ghp.space_heating_efficiency_thermal_factor) {
          var heatingThermalFactor = this.run.site.ghp.space_heating_efficiency_thermal_factor / 100;
        } else {
          var heatingThermalFactor = this.placeholders.site.ghp.space_heating_efficiency_thermal_factor.split('%')[0] / 100;
        }

        if (this.run.site.ghp.cooling_efficiency_thermal_factor) {
          var coolingThermalFactor = this.run.site.ghp.cooling_efficiency_thermal_factor / 100;
        } else {
          var coolingThermalFactor = this.placeholders.site.ghp.cooling_efficiency_thermal_factor.split('%')[0] / 100;
        }
        if (!this.run.site.ghp.can_serve_dhw) {
          var calculateRatio = ((annualMmbtu - this.dhwAnnualMMbtuForRatio) * boilerEfficiency * heatingThermalFactor) / (annualTonHour * 0.012 * coolingThermalFactor);
        } else {
          var calculateRatio = (annualMmbtu * boilerEfficiency * heatingThermalFactor) / (annualTonHour * 0.012 * coolingThermalFactor);
        }
        this.$store.commit('updateObject', { object: 'ratioHeatingToCooling', value: calculateRatio });
      },
      parseCSV: function(event, type) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = [];
                var hasColumns = false;
                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i < 3) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Detect when the CSV has multiple columns. Otherwise assume
                      // the CSV is just straight list of values.
                      if(value.indexOf('hour') !== -1) {
                        hasColumns = true;
                      }

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  var valueIndex = (hasColumns) ? 1 : 0;
                  var value = parseFloat(row[valueIndex]);
                  if(isNaN(value)) {
                    self.run.site.load_profile.loads_kw = [];
                    event.target.value = "";
                    bootbox.alert('The load profile must contain only numeric values. Could not parse value at row ' + (i + 1) + ': "' + row[valueIndex] + '"');

                    return false;
                  }
                  values.push(value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = values.length;
                if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                  self.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.')

                  return false;
                }

                if(type == 'cold') {
                  self.updateUploadedLoadProfile(values, 'cold');
                  self.updateStoreNested2({
                    association_2: 'load_profile_chiller',
                    attribute: 'cold_uploaded_loads_ton',
                    value: JSON.stringify(values)
                  });
                  self.updateStoreNested2({association_2: 'load_profile_chiller', attribute: 'cold_load_file_name', value: file.name});
                  self.displayColdLPLoading();
                }
              } finally {
              }
            }
          })
        }
      },
      updateUploadedLoadProfile: function(values, type) {
        var avgValue = 0;
        var minValue;
        var maxValue;
        for(var i = 0; i < values.length; i++) {
          var value = values[i];
          avgValue += value;

          if(minValue === undefined || value < minValue) {
            minValue = value;
          }

          if(maxValue === undefined || value > maxValue) {
            maxValue = value;
          }
        }
        avgValue = avgValue / values.length;

        if(type == 'cold') {
          var payload = {
            loadsTonHour: values,
            minTonHour: minValue,
            meanTonHour: avgValue,
            maxTonHour: maxValue,
          }

          this.$store.commit('updateObject', { object: 'coldUploadedLoadProfile', value: payload });
          this.updateAbsChillerDefaults();
          // call new endpoint to populate electric cop
        }
      },
      displayColdLPLoading: function() {
        var type = this.run.site.load_profile_chiller.cold_load_type;
        var simBuildType = this.run.site.load_profile_chiller.simulated_load_type;
        var coldSimBuildingAnnualLP = type == "simulated" && simBuildType == "annual" && this.coldSimBuildAnnualLoadProfile.loadsTonHour;
        var coldSimBuildingMonthlyLP = type == "simulated" && simBuildType == "monthly" && this.coldSimBuildMonthlyLoadProfile.loadsTonHour;
        var coldUploadedLP = type == "uploaded" && this.coldUploadedLoadProfile.loadsTonHour;
        if(coldSimBuildingAnnualLP || coldSimBuildingMonthlyLP || coldUploadedLP) {
          this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });
          var self = this;
          setTimeout(function() {
            self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
          }, 800);
        }
      },
    },

    watch: {
      watchDependencies: function() {
        var type = this.run.site.load_profile.typical_load_type;
        if (
          this.run.analyze_ghp || 
          (this.run.site.chp && this.run.site.chp.analyze_abs_chiller) || 
          this.run.analyze_cws ||
          (this.run.analyze_ashp_space && (this.run.ashp_space_heater.ashp_configuration == 3 || this.run.ashp_space_heater.ashp_configuration == 4))
        ) {
          this.getColdSimulatedLoadProfile();
        }
      },
      'run.site.load_profile_chiller.custom_energy_consump': function() {
        if (!$('#custom_energy_consumption')[0].checked) {
          this.getColdSimulatedLoadProfile();
          if (this.run.site.load_profile_chiller.annual_tonhour) {
            this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'load_profile_chiller', attribute: 'annual_tonhour', value: '' })
          }
        } else if ($('#custom_energy_consumption')[0].checked && this.run.site.load_profile_chiller.simulated_load_type == 'monthly') {
          this.getColdSimBuildingMonthlyLoad();
        }
      },
    },

    mounted: function() {
      var self = this;

      //TODO: the .click() should not ne necessary once bootstrap-vue integrated
      if (
        this.run.analyze_ghp || 
        (this.run.site.chp && this.run.site.chp.analyze_abs_chiller) || 
        this.run.analyze_cws ||
        (this.run.analyze_ashp_space && (this.run.ashp_space_heater.ashp_configuration == 3 || this.run.ashp_space_heater.ashp_configuration == 4))
      ) {
        if(this.run.site.load_profile_chiller.cold_load_type == "simulated" && this.run.site.load_profile_chiller.simulated_load_type == "annual") {
          document.querySelectorAll("[href='#cold_load_profile_simulated_inputs']")[0].click();
          this.getColdSimBuildingAnnualLoad();
        } else if(this.run.site.load_profile_chiller.cold_load_type == "simulated" && this.run.site.load_profile_chiller.simulated_load_type == "monthly") {
          document.querySelectorAll("[href='#cold_load_profile_simulated_inputs']")[0].click();
          this.getColdSimBuildingMonthlyLoad();
        } else if(this.run.site.load_profile_chiller.cold_load_type == "simulated_campus") {
          document.querySelectorAll("[href='#cold_simulated_campus']")[0].click();
        } else if(this.run.site.load_profile_chiller.cold_load_type == "uploaded") {
          this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile_chiller.thermal_loads_ton), 'cold');
          document.querySelectorAll("[href='#cold_load_profile_uploaded']")[0].click();
        } else if(this.run.site.load_profile_chiller.cold_load_type == "custom") {
          this.getColdCustomAnnualPctLoad();
          document.querySelectorAll("[href='#cold_load_profile_custom']")[0].click();
        }
      }

      if(this.run.site.load_profile_chiller.cold_load_file_data) {
        document.getElementById("run_site_attributes_load_profile_chiller_attributes_cold_load_file").value = this.run.site.load_profile_chiller.cold_load_file_data;
        // this loadTonHour variable is pointing to the wrong values. I think it should be: this.run.site.load_profile_chiller.cold_uploaded_loads_ton
        // but check when GHP is hooked up
        var loadTonHour = JSON.parse(this.run.site.load_profile.loads_kw)
        // var loadTonHour = JSON.parse(this.run.site.load_profile_chiller.cold_uploaded_loads_ton)
        var payload = {
          loadsTonHour: loadTonHour,
          minTonHour: Math.min.apply(Math, loadTonHour),
          meanTonHour: this.$store.getters.average(loadTonHour),
          maxTonHour: Math.max.apply(Math, loadTonHour),
        }

        this.$store.commit("updateObject", { object: "coldUploadedLoadProfile", value: payload });
        this.updateAbsChillerDefaults();
      }
    }
  }
</script>

<style>
  .noUi-handle-lower {
    background-color: rgb(61,111,189);
    box-shadow: none;
  }
</style>
