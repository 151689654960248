<template>
  <div class="form-group select optional" :class="formGroupClass">
    <div style="padding-left: 10px; padding-right: 10px;">
      <select
        v-model="portfolioRun[attribute]"
        :disabled="isDisabled"
        @change="$emit('input-change')"
        class="form-control select optional"
        :name="inputName"
        :id="inputID">
        <option v-for="option in options" :value="option['value']">
          {{ option['label'] }}
        </option>
      </select>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'attribute',
      'index',
      'run',
      'required'
    ],

    data: function() {
      return {
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'clientSideValidations',
        'labels',
        'portfolioRun',
        'selectOptions',
        'tooltips',
      ]),
      label: function() {
        return this.labels[this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.attribute}`;
      },
      isDisabled: function() {
        if (this.attribute == 'clean_energy_target' && this.index != 0) {
          return true
        } else {
          return false
        }
      },
      inputID: function() {
        return `run_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.attribute}]`;
      },
      options: function() {
        return this.selectOptions[this.attribute]["options"];
      }
    }
  }
</script>
