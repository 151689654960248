<template>
  <div>
    <div class="row">
      <div class="col-sm-offset-6" style="padding-left:15px;">
        <input
          v-model="run.site.load_profile_chiller.custom_annual_simulated_campus"
          type="checkbox"
          value="1"
          id="custom_annual_simulated_campus"
          @click="getColdSimCampusLoad()" />
        <label style="font-weight:normal" for="custom_annual_simulated_campus">
          &nbsp;Enter custom annual thermal energy amount <tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'custom_energy_consump'"></tooltip>
        </label>
      </div>
    </div>
    <horizontal-string-input-nested-2
      v-if="run.site.load_profile_chiller.custom_annual_simulated_campus"
      association_1='site'
      association_2='load_profile_chiller'
      attribute='campus_annual_tonhour'
      v-on:input-change="getColdSimCampusLoad()"
      required='true' />
    <div class="row">
      <div class="col-sm-offset-3 col-sm-7">
        <table class="simulated-campus" id="cold-campus">
          <thead>
            <tr>
              <th>Select Building Type <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'campus_select_building'"></tooltip></th>
              <th>% of Total Thermal Energy Consumption (tonhour) <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'percent_of_energy_consump'"></tooltip></th>
            </tr>
          </thead>
          <tbody class="fields">
            <tr v-for="(building, i) in run.site.load_profile_chiller.campus_buildings" >
              <td class="campus-building-type">
                <select v-model="building.doe_reference_name" @change="getColdSimCampusLoad()" label="false" class="select optional" :name="`run[site_attributes][load_profile_chiller_attributes][campus_buildings_attributes][${i}][doe_reference_name]`" >
                  <option v-for="option in options" :value="option['value']">
                    {{ option['label'] }}
                  </option>
                </select>
              </td>
              <td>
                <input v-model="building.percent_of_total" @change="getColdSimCampusLoad()" label="false" class="string optional building-percent" type="text" :name="`run[site_attributes][load_profile_chiller_attributes][campus_buildings_attributes][${i}][percent_of_total]`" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-offset-3">
        <div class="campus-table-actions">
          <button type="button" @click="addBuilding()" class="btn btn-primary">Add Building</button>
          <button type="button" @click="removeBuilding()" class="remove-campus-building btn btn-primary">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  import HorizontalStringInputNested2 from './HorizontalStringInputNested2';

  export default {
    components: {
      HorizontalStringInputNested2,
      Tooltip
    },

    computed: {
      ...mapGetters([
        'coldSimCampusLoadProfile',
        'run',
        'selectOptions',
        'urlRoot',
        'annualMmbtuForRatio',
        'annualTonHourForRatio',
        'placeholders',
        'dhwAnnualMMbtuForRatio'
      ]),
      options: function() {
        return this.selectOptions.site.load_profile.doe_reference_name.options;
      },
      watchDependencies:  function() {
        return this.run.site.latitude, 
        this.run.site.longitude,
        new Date();
      },
    },

    methods: {
      addBuilding: function() {
        if(this.run.site.load_profile_chiller.campus_buildings.length == 5) {
          bootbox.alert("Can only have a maximum of 5 building types.");
          return false;
        }

        var campusBuildings = [
          ...this.run.site.load_profile_chiller.campus_buildings,
          {
            doe_reference_name: '',
            percent_of_total: '',
          }
        ]

        var payload = {
          association_1: 'site',
          association_2: 'load_profile_chiller',
          attribute: 'campus_buildings',
          value: campusBuildings,
        }
        this.$store.commit('updateValueNested2', payload);
        this.getColdSimCampusLoad();
        this.$emit('update-cold-load', event);
      },
      removeBuilding: function() {
        if(this.run.site.load_profile_chiller.campus_buildings.length == 1) {
          bootbox.alert("Must have at least 1 building type.");
          return false;
        }

        this.$store.commit('removeColdCampusBuilding');
        this.getColdSimCampusLoad();
        this.$emit('update-cold-load', event);
      },
      verifyAllBuildingsSelected: function() {
        var selected = this.run.site.load_profile_chiller.campus_buildings.map(function(el, i) {
          if(el.doe_reference_name) {
            return true;
          } else {
            return false;
          }
        })

        if(selected.includes(false)) {
          return false;
        } else {
          return true;
        }
      },
      verifyPercentsSumTo100: function() {
        var sum = this.run.site.load_profile_chiller.campus_buildings.map(function(el, i) {
          return Number(el.percent_of_total);
        }).reduce(function(total, num) {
          return total + num;
        })

        if(sum == 100) {
          return true;
        } else {
          return false;
        }
      },
      getColdSimCampusLoad: function(event) {
        var customAnnual = $('#custom_annual_simulated_campus')[0].checked;

        if(!customAnnual && this.run.site.latitude && this.run.site.longitude && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
          // this conditional is for when user does not select custom annual tonhour
          this.$emit('update-cold-load', event);
        } else if(customAnnual && this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile_chiller.campus_annual_tonhour && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
          this.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: true });

          var doeReferenceNames = this.run.site.load_profile_chiller.campus_buildings.map(function(el, i) {
            return el.doe_reference_name
          })
          var percentShares = this.run.site.load_profile_chiller.campus_buildings.map(function(el, i) {
            return el.percent_of_total/100.0
          })

          var params = {
            latitude: this.run.site.latitude,
            longitude: this.run.site.longitude,
            campus_annual_tonhour: this.run.site.load_profile_chiller.campus_annual_tonhour,
            doe_reference_names: doeReferenceNames,
            percent_shares: percentShares,
          }

          var self = this;
          $.ajax({
            url: self.urlRoot + '/simulated-campus-cold-load',
            contentType: 'application/json',
            data: params,
            success: function handleResults(data) {
              var payload = {
                loadsTonHour: data.loads_ton,
                minTonHour: data.min_ton,
                meanTonHour: data.mean_ton,
                maxTonHour: data.max_ton,
              }
              self.$store.commit('updateObject', { object: 'coldSimCampusLoadProfile', value: payload });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
              self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'electric_chiller', attribute: 'cop', value: data.chiller_cop });

              if (self.run.analyze_ghp) {
                var annualTonHourForRatio = data.annual_tonhour;
                self.$store.commit('updateObject', { object: 'annualTonHourForRatio', value: annualTonHourForRatio });
              }

              if (self.run.analyze_ghp && self.annualMmbtuForRatio) {
                self.updateRatioHeatingToCooling();
              }
            },
            error: function() {
              bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
              self.$store.commit('updateObject', { object: 'coldSimCampusLoadProfile', value: {} });
              self.$store.commit('updateObject', { object: 'coldLoadProfileLoading', value: false });
            },
          })
        } else if(!customAnnual) {
          this.$store.commit('updateObject', { object: 'coldSimCampusFromElecLoadProfile', value: {} });
        } else if(customAnnual) {
          this.$store.commit('updateObject', { object: 'coldSimCampusLoadProfile', value: {} });
        }
      },
      updateRatioHeatingToCooling: function() {
        this.$emit('update-ghp-heat-to-cool');
      },
    },

    watch: {
      watchDependencies: function() {
        this.getColdSimCampusLoad();
      }
    },

    mounted: function() {
      var customAnnual = $('#custom_annual_simulated_campus')[0].checked;

      if(!customAnnual && this.run.site.latitude && this.run.site.longitude && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
        // this conditional is for when user does not select custom annual tonhour
        this.$emit('update-cold-load', event);
      } else if(customAnnual && this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile_chiller.campus_annual_tonhour && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
        this.getColdSimCampusLoad();
      }
    }
  }
</script>
