<template>
  <tbody>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_clean_energy_target">
            {{ labels.clean_energy_target }}
          </label>
          <tooltip attribute="clean_energy_target" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-select-input
          attribute='clean_energy_target'
          :run="portfolioRun"
          :index="index"
          v-on:input-change="toggleInputs()" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3" id="run_site_renewable_electricity_min_fraction">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_renewable_electricity_min_fraction">
            {{ labels.site.renewable_electricity_min_fraction }}
          </label>
          <tooltip
            association_1="site"
            attribute="renewable_electricity_min_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyRenewableElectricityMinFractionBySite"
            id="toggle_renewable_electricity_min_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyRenewableElectricityMinFractionBySite, 'renewable_electricity_min_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_renewable_electricity_min_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyRenewableElectricityMinFractionBySite" class="col-sm-3 run_site_renewable_electricity_min_fraction" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='renewable_electricity_min_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('renewable_electricity_min_fraction', portfolioRun.site.renewable_electricity_min_fraction)" />
      </td>
      <td v-if="varyRenewableElectricityMinFractionBySite" class="col-sm-3 run_site_renewable_electricity_min_fraction" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='renewable_electricity_min_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3" id="run_site_renewable_electricity_max_fraction">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_renewable_electricity_max_fraction">
            {{ labels.site.renewable_electricity_max_fraction }}
          </label>
          <tooltip
            association_1="site"
            attribute="renewable_electricity_max_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyRenewableElectricityMaxFractionBySite"
            id="toggle_renewable_electricity_max_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyRenewableElectricityMaxFractionBySite, 'renewable_electricity_max_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_renewable_electricity_max_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyRenewableElectricityMaxFractionBySite" class="col-sm-3 run_site_renewable_electricity_max_fraction" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='renewable_electricity_max_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('renewable_electricity_max_fraction', portfolioRun.site.renewable_electricity_max_fraction)" />
      </td>
      <td v-if="varyRenewableElectricityMaxFractionBySite" class="col-sm-3 run_site_renewable_electricity_max_fraction" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='renewable_electricity_max_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3" id="run_site_CO2_emissions_reduction_min_fraction">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_CO2_emissions_reduction_min_fraction">
            {{ labels.site.CO2_emissions_reduction_min_fraction }}
          </label>
          <tooltip
            association_1="site"
            attribute="CO2_emissions_reduction_min_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCO2EmissionsReductionMinFractionBySite"
            id="toggle_CO2_emissions_reduction_min_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyCO2EmissionsReductionMinFractionBySite, 'CO2_emissions_reduction_min_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_CO2_emissions_reduction_min_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyCO2EmissionsReductionMinFractionBySite" class="col-sm-3 run_site_CO2_emissions_reduction_min_fraction" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='CO2_emissions_reduction_min_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('CO2_emissions_reduction_min_fraction', portfolioRun.site.CO2_emissions_reduction_min_fraction)" />
      </td>
      <td v-if="varyCO2EmissionsReductionMinFractionBySite" class="col-sm-3 run_site_CO2_emissions_reduction_min_fraction" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='CO2_emissions_reduction_min_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3" id="run_site_CO2_emissions_reduction_max_fraction">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_CO2_emissions_reduction_max_fraction">
            {{ labels.site.CO2_emissions_reduction_max_fraction }}
          </label>
          <tooltip
            association_1="site"
            attribute="CO2_emissions_reduction_max_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCO2EmissionsReductionMaxFractionBySite"
            id="toggle_CO2_emissions_reduction_max_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyCO2EmissionsReductionMaxFractionBySite, 'CO2_emissions_reduction_max_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_CO2_emissions_reduction_max_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyCO2EmissionsReductionMaxFractionBySite" class="col-sm-3 run_site_CO2_emissions_reduction_max_fraction" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='CO2_emissions_reduction_max_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('CO2_emissions_reduction_max_fraction', portfolioRun.site.CO2_emissions_reduction_max_fraction)" />
      </td>
      <td v-if="varyCO2EmissionsReductionMaxFractionBySite" class="col-sm-3 run_site_CO2_emissions_reduction_max_fraction" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-1
          association_1='site'
          attribute='CO2_emissions_reduction_max_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
  </tbody>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested1 from './inputs/PortfolioHorizontalStringInputNested1';
  import PortfolioHorizontalSelectInput from './inputs/PortfolioHorizontalSelectInput';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalSelectInput,
      PortfolioHorizontalStringInputNested1,
    },

    data: function() {
      return {
        varyRenewableElectricityMinFractionBySite: false,
        varyRenewableElectricityMaxFractionBySite: false,
        varyCO2EmissionsReductionMinFractionBySite: false,
        varyCO2EmissionsReductionMaxFractionBySite: false,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'portfolioRun',
        'portfolio'
      ])
    },

    methods: {
      updateSelectValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            attribute: attr,
            value: value,
            index: i,
          }

          self.$store.commit('updateRunsValues', payload);
        })
      },
      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            attribute: attr,
            value: value,
            index: i,
          }

          self.$store.commit('updateRunsValues', payload);
        })
      },
      varyAttributeBySite: function(action, attr) {
        if (action && this.portfolioRun.site[attr]) {
          this.updateInputValueForRuns(attr, null);
          this.$store.commit('updatePortfolioValueNested1', { association_1: 'site', attribute: attr, value: null });
        } else if (!action) {
          this.updateInputValueForRuns(attr, null);
        }
      },
      toggleInputs: function() {
        // its odd this is needed - another weird Vue reactivity issue, but if
        // v-if is used then the tooltips don't toggle properly (another thing that will prob go away w/ Vue-Bootstrap)
        if(this.portfolioRun.clean_energy_target == 'emissions') {
          document.getElementById('run_site_renewable_electricity_min_fraction').style.display = 'none';
          document.getElementById('run_site_renewable_electricity_max_fraction').style.display = 'none';
          document.getElementById('run_site_CO2_emissions_reduction_min_fraction').style.display = '';
          document.getElementById('run_site_CO2_emissions_reduction_max_fraction').style.display = '';
          this.portfolio.runs.forEach(function(run, i) {
            document.getElementsByClassName('run_site_renewable_electricity_min_fraction')[i].style.display = 'none';
            document.getElementsByClassName('run_site_renewable_electricity_max_fraction')[i].style.display = 'none';
            document.getElementsByClassName('run_site_CO2_emissions_reduction_min_fraction')[i].style.display = '';
            document.getElementsByClassName('run_site_CO2_emissions_reduction_max_fraction')[i].style.display = '';
          })
        } else if(this.portfolioRun.clean_energy_target == 're') {
          document.getElementById('run_site_CO2_emissions_reduction_min_fraction').style.display = 'none';
          document.getElementById('run_site_CO2_emissions_reduction_max_fraction').style.display = 'none';
          document.getElementById('run_site_renewable_electricity_min_fraction').style.display = '';
          document.getElementById('run_site_renewable_electricity_max_fraction').style.display = '';
          this.portfolio.runs.forEach(function(run, i) {
            document.getElementsByClassName('run_site_CO2_emissions_reduction_min_fraction')[i].style.display = 'none';
            document.getElementsByClassName('run_site_CO2_emissions_reduction_max_fraction')[i].style.display = 'none';
            document.getElementsByClassName('run_site_renewable_electricity_min_fraction')[i].style.display = '';
            document.getElementsByClassName('run_site_renewable_electricity_max_fraction')[i].style.display = '';
          })
        }
      }
    },

    watch: {
      'portfolioRun.clean_energy_target': function() {
        this.updateSelectValueForRuns("clean_energy_target", this.portfolioRun.clean_energy_target);
        if (this.portfolioRun.clean_energy_target === 're' && (this.portfolioRun.site.CO2_emissions_reduction_min_fraction || this.portfolioRun.site.CO2_emissions_reduction_max_fraction)) {
          this.$store.commit('updatePortfolioValueNested1', { association_1: 'site', attribute: 'CO2_emissions_reduction_min_fraction', value: null })
          this.$store.commit('updatePortfolioValueNested1', { association_1: 'site', attribute: 'CO2_emissions_reduction_max_fraction', value: null })
        } else if (this.portfolioRun.clean_energy_target === 'emissions' && (this.portfolioRun.site.renewable_electricity_min_fraction || this.portfolioRun.site.renewable_electricity_max_fraction)) {
          this.$store.commit('updatePortfolioValueNested1', { association_1: 'site', attribute: 'renewable_electricity_min_fraction', value: null });
          this.$store.commit('updatePortfolioValueNested1', { association_1: 'site', attribute: 'renewable_electricity_max_fraction', value: null });
        }
      },
    },

    mounted: function() {
      // not sure this is needed? not sure what scenario would not have portfolioRun.clean_energy_target...
      // if(!this.portfolioRun.clean_energy_target) {
      //   this.$store.commit('updatePortfolioValue', { attribute: 'clean_energy_target', value: 're' });
      //   $("#run_clean_energy_target").val('re')
      // }

      this.toggleInputs();
    }
  }
</script>
  