<template>
  <div class="modal-mask" id="primegen-scheduler-detailed">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="close()" type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Prime Generator Maintenance Schedule - Detailed View</h4>
          </div>
          <div class="modal-body">
            <table>
              <thead>
                <tr id="maintenance-periods">
                  <th colspan="3">Maintenance Periods</th>
                </tr>
                <tr>
                  <th>Start Date & Time</th>
                  <th>End Date & Time</th>
                  <th>Length (hrs)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(period, i) in schedule.chp_unavailability_periods">
                  <td>{{ formatDateTime(schedule.formatted_datetime_periods[i].start_datetime) }}</td>
                  <td>{{ formatDateTime(schedule.formatted_datetime_periods[i].end_datetime) }}</td>
                  <td>{{ period.duration_hours }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: ['scheduleType'],

    data: function () {
      return {
        daysOfWeek: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
      }
    },

    computed: {
      ...mapGetters([
        'primegenMaintenanceDefaultSchedule',
        'primegenMaintenanceUploadedSchedule',
        'months',
      ]),
      schedule: function() {
        return this[this.scheduleType];
      }
    },

    methods: {
      formatDateTime: function(rawDate) {
        var date = new Date(rawDate);

        return `${date.toLocaleDateString()} ${this.daysOfWeek[date.getDay()]} ${date.toLocaleTimeString()}`;
      },
      close: function() {
        if(this.scheduleType == "primegenMaintenanceDefaultSchedule") {
          var object = 'showPrimeGenDefaultDetailedSchedule';
        } else if(this.scheduleType == "primegenMaintenanceUploadedSchedule") {
          var object = 'showPrimeGenUploadedDetailedSchedule';
        }

        this.$store.commit('updateObject', { object: object, value: false });
      }
    }
  }
</script>
