<template>
  <div class="form-horizontal">
    <horizontal-string-input-nested-2
        v-if="shouldShowAshpHybridConfigInputs"
        association_1="site"
        association_2="ashp_space_heater"
        attribute="min_ton"
    />

    <div v-if="shouldShowAshpHybridConfigInputs" class="row">
      <div class="col-sm-6"/>
      <div class="col-sm-4">
        <input
            type="checkbox"
            v-model="run.site.ashp_space_heater.min_non_zero_size_ton"
            name="run[site_attributes][ashp_space_heater_attributes][min_non_zero_size_ton]"
            id="ashp_space_heater_min_non_zero_size_ton"/>
        <label style="font-weight: normal" for="ashp_space_heater_min_non_zero_size_ton">
          Input minimum non-zero size in tonnage
        </label>
      </div>

      <horizontal-string-input-nested-2
          v-if="run.site.ashp_space_heater.min_non_zero_size_ton"
          association_1="site"
          association_2="ashp_space_heater"
          attribute="min_allowable_ton"
          type="number"
      />
      <horizontal-string-input-nested-2
          v-else
          association_1="site"
          association_2="ashp_space_heater"
          attribute="min_allowable_peak_capacity_fraction"
      />
    </div>


    <horizontal-string-input-nested-2
        v-if="shouldShowAshpHybridConfigInputs"
        association_1="site"
        association_2="ashp_space_heater"
        attribute="max_ton"
    />
    <horizontal-string-input-nested-2
        association_1="site"
        association_2="ashp_space_heater"
        attribute="installed_cost_per_ton"
    />
    <horizontal-string-input-nested-2
        association_1="site"
        association_2="ashp_space_heater"
        attribute="om_cost_per_ton"
    />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs()">
              <span v-if="displayAdvancedInputs">
                <font-awesome-icon icon="minus-square"/>
                Show fewer inputs
              </span>
            <span v-else>
                <font-awesome-icon icon="plus-square"/>
                Advanced inputs
              </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"/>
            Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <div v-if="run.grid">
        <h4>ASHP performance (COP and CF) table
          <tooltip :association_1="'site'" :association_2="'ashp_space_heater'" :attribute="'cop_table'"/>
        </h4>
        <ul class="nav nav-tabs">
          <li v-bind:class="isTabActive('default')">
            <a @click="updateCopType('default');" data-toggle="tab" href="#ashp_space_table_default">
              <i class="fas fa-calendar-alt" aria-hidden="true"/>
              Default
            </a>
          </li>
          <li v-bind:class="isTabActive('custom')">
            <a @click="updateCopType('custom');" data-toggle="tab" href="#ashp_space_table_custom">
              <i class="fas fa-upload" aria-hidden="true"/>
              Upload
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div
              v-if="run.site.ashp_space_heater.cop_table_type === 'default'"
              class="tab-pane active"
              id="ashp_space_heater_table_default"
          >
            <p>
              The default ASHP coefficient of performance (COP) and capacity factor (CF) versus outdoor air temperature
              is provided for heating and cooling (cooling is only relevant for certain configurations). Use the
              performance table link (lower right) to view the default values, and download the performance table (lower
              left) as a template to update and upload a custom table, if desired.
            </p>
          </div>
          <div
              v-else-if="run.site.ashp_space_heater.cop_table_type === 'custom'"
              class="tab-pane active" id="ashp_space_heater_table_custom"
          >
            <div class="form-group file required run_site_ashp_space_heater_cop_table_file">
              <div class="col-sm-6 control-label">
                <label class="file required" for="run_site_attributes_ashp_space_heater_attributes_cop_table_file">
                  <abbr title="Required" class="required">*</abbr> Custom ASHP performance table
                </label>
              </div>
              <div class="col-sm-4">
                <input
                    class="form-control hidden" type="hidden"
                    name="run[site_attributes][ashp_space_heater_attributes][cop_table_file]"
                    id="run_site_attributes_ashp_space_heater_attributes_cop_table_file"
                />
                <input
                    @change="parseCSV($event)" class="form-control-file file required load-profile-file-upload"
                    required="required" aria-required="true" type="file"
                    name="run[site_attributes][ashp_space_heater_attributes][cop_table_file]"
                />
                <p v-if="run.copied && this.run.site.ashp_space_heater.cop_table_file_name" class="help-block">
                  Uploaded file: {{ this.run.site.ashp_space_heater.cop_table_file_name }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-right:15px;">
          <div class="col-sm-6">
            <div v-if="this.run.site.ashp_space_heater.cop_table_type === 'default' ||
              (this.run.site.ashp_space_heater.cop_table_type === 'custom' && this.run.site.ashp_space_heater.cop_table_file_name)">
              <a @click="downloadCopTable()" class="load-profile-chart-link load-profile-action no-visit-color">
                <i class="fas fa-download" aria-hidden="true"/>
                Download ASHP performance table
              </a>
            </div>
          </div>

          <div class="col-sm-6 text-right">
            <a @click="handleDisplayCopTable()" class="load-profile-chart-link load-profile-action no-visit-color">
              <i class="fas fa-list-ul" aria-hidden="true"/>
              View performance table
            </a>
          </div>
        </div>

        <transition name="fade">
          <cop-table
              v-if="displayCopTable"
              @close="handleDisplayCopTable"
              :rows="copTableProps.rows"
              :headers="copTableProps.headers"
              :title="this.copTableTitle"
          />
        </transition>

        <h4>ASHP System Characteristics</h4>
        <div class="form-horizontal">
          <horizontal-string-input-nested-2
              v-if="shouldShowAshpStandardConfigInputs"
              association_1='site'
              association_2='ashp_space_heater'
              attribute='sizing_factor'
          />
          <horizontal-string-input-nested-2
              v-if="shouldShowAshpStandardConfigInputs"
              association_1='site'
              association_2='ashp_space_heater'
              attribute='avoided_capex_by_ashp_present_value'
          />
          <horizontal-string-input-nested-2
              association_1='site'
              association_2='ashp_space_heater'
              attribute='back_up_temp_threshold_degF'
          />
        </div>
      </div>

      <br/>

      <div v-if="run.grid">
        <h5>Tax Treatment</h5>
        <div class="form-horizontal">
          <horizontal-select-input-nested-2
              association_1='site'
              association_2='ashp_space_heater'
              attribute='macrs_option_years'
          />
          <horizontal-select-input-nested-2
              association_1='site'
              association_2='ashp_space_heater'
              attribute='macrs_bonus_fraction'
          />
        </div>
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"/>
        Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import CopTable from './inputs/CopTable';
import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
import Tooltip from './Tooltip';

export default {
  components: {
    CopTable,
    HorizontalSelectInputNested2,
    HorizontalStringInputNested2,
    Tooltip
  },

  data() {
    return {
      displayAdvancedInputs: false,
      displayCopTable: false,
      copTableTitle: 'ASHP Performance (COP and Capacity Factor) Table',
      heatingHeaders: [
        'Outdoor Air Temperature for Heating (F)',
        'Heating COP (kWt/kWe)',
        'Heating CF (kWt_max/kWt_rated)'
      ],
      coolingHeaders: [
        'Outdoor Air Temperature for Cooling (F)',
        'Cooling COP (kWt/kWe)',
        'Cooling CF (kWt_max/kWt_rated)'
      ],
      defaultHeatingColumns: {
        temperatures: [-5, 17, 47, 80],
        cop: [1.5, 2.3, 3.3, 4.5],
        cf: [0.38, 0.64, 1, 1.4],
      },
      defaultCoolingColumns: {
        temperatures: [70, 82, 95, 110],
        cop: [4.0, 3.5, 2.9, 2.2],
        cf: [1.03, 0.98, 0.93, 0.87]
      },
      customHeatingColumns: {},
      customCoolingColumns: {},
    };
  },

  mounted() {
    this.populateCopTableData();
  },

  computed: {
    ...mapGetters([
      'run',
    ]),

    copTableProps() {
      const ashpConfig = this.run.site.ashp_space_heater.ashp_configuration;
      const copTableType = this.run.site.ashp_space_heater.cop_table_type;

      return {
        headers: this.getCopTableHeaders(ashpConfig),
        rows: this.getCopTableRows(ashpConfig, copTableType),
      };
    },

    shouldShowAshpHybridConfigInputs() {
      return this.run.site.ashp_space_heater.ashp_configuration === 1 || this.run.site.ashp_space_heater.ashp_configuration === 3;
    },

    shouldShowAshpStandardConfigInputs() {
      return this.run.site.ashp_space_heater.ashp_configuration === 2 || this.run.site.ashp_space_heater.ashp_configuration === 4;
    }
  },

  methods: {
    downloadCopTable() {
      const ashpConfig = this.run.site.ashp_space_heater.ashp_configuration;
      const copTableType = this.run.site.ashp_space_heater.cop_table_type;

      const formatDataForCSV = () => {
        const headers = this.getCopTableHeaders(ashpConfig);
        const rows = this.getCopTableRows(ashpConfig, copTableType);

        return [headers, ...rows];
      };

      const csv = Papa.unparse(formatDataForCSV());
      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
      saveAs(blob, `ASHP Performance Table - Config ${ashpConfig}.csv`);
    },

    getCopTableColumns(ashpConfig, copTableType) {
      const isObjectEmpty = (obj) => {
        return Object.keys(obj).length === 0;
      };

      const heatingColumns = copTableType === 'custom' ? this.customHeatingColumns : this.defaultHeatingColumns;
      let coolingColumns = {};

      if (ashpConfig === 3 || ashpConfig === 4) {
        if (copTableType === 'default') {
          coolingColumns = this.defaultCoolingColumns;
        } else {
          coolingColumns = !isObjectEmpty(this.customCoolingColumns)
              ? this.customCoolingColumns
              : this.defaultCoolingColumns;
        }
      }

      return {
        heatingColumns,
        coolingColumns
      };
    },

    getCopTableHeaders(ashpConfig) {
      const heatingHeaders = this.heatingHeaders;
      const coolingHeaders = this.coolingHeaders;
      return ashpConfig === 3 || ashpConfig === 4
          ? [...heatingHeaders, ...coolingHeaders]
          : heatingHeaders;
    },

    getCopTableRows(ashpConfig, copTableType) {
      const {heatingColumns, coolingColumns} = this.getCopTableColumns(ashpConfig, copTableType);
      const rows = [];
      const numberOfRows = heatingColumns.temperatures.length;

      for (let i = 0; i < numberOfRows; i++) {
        const row = [
          heatingColumns.temperatures[i],
          heatingColumns.cop[i],
          heatingColumns.cf[i]
        ];

        // Append cooling data if applicable
        if (ashpConfig === 3 || ashpConfig === 4) {
          row.push(coolingColumns.temperatures ? coolingColumns.temperatures[i] : null);
          row.push(coolingColumns.cop ? coolingColumns.cop[i] : null);
          row.push(coolingColumns.cf ? coolingColumns.cf[i] : null);
        }

        rows.push(row);
      }

      return rows;
    },

    handleDisplayCopTable() {
      if (this.run.site.ashp_space_heater.cop_table_type === 'custom' && !this.run.site.ashp_space_heater.cop_table_file_name) {
        bootbox.alert('The ASHP performance (COP and CF) table must be uploaded or the \'Default\' tab must be selected.');
      } else {
        this.displayCopTable ^= true;
      }
    },

    isTabActive(tabType) {
      return this.run.site.ashp_space_heater.cop_table_type === tabType ? 'active' : '';
    },

    parseCSV(event) {
      const file = event.target.files[0];
      const ashpConfig = this.run.site.ashp_space_heater.ashp_configuration;

      if (!file || file.name.split('.').pop() !== 'csv') {
        bootbox.alert(`Uploaded file ${file?.name} must be a .csv`);
        return;
      }

      const handleParsedCSV = (results) => {
        let attributes;

        const data = results.data.slice(1); // Skip the headers
        if (ashpConfig === 1 || ashpConfig === 2) {
          this.customHeatingColumns = {
            temperatures: data.map(row => row[0]),
            cop: data.map(row => row[1]),
            cf: data.map(row => row[2])
          };

          attributes = {
            heating_cf_reference: this.customHeatingColumns.cf,
            heating_cop_reference: this.customHeatingColumns.cop,
            heating_reference_temps_degF: this.customHeatingColumns.temperatures,
          };
        } else if (ashpConfig === 3 || ashpConfig === 4) {
          this.customHeatingColumns = {
            temperatures: data.map(row => row[0]),
            cop: data.map(row => row[1]),
            cf: data.map(row => row[2])
          };
          this.customCoolingColumns = {
            temperatures: data.map(row => row[3]),
            cop: data.map(row => row[4]),
            cf: data.map(row => row[5])
          };

          attributes = {
            cooling_cf_reference: this.customCoolingColumns.cf,
            cooling_cop_reference: this.customCoolingColumns.cop,
            cooling_reference_temps_degF: this.customCoolingColumns.temperatures,
            heating_cf_reference: this.customHeatingColumns.cf,
            heating_cop_reference: this.customHeatingColumns.cop,
            heating_reference_temps_degF: this.customHeatingColumns.temperatures,
          };
        }

        this.updateAshpHeaterStoreAttributes({
          cop_table_file_name: file.name,
          ...attributes
        });
      };

      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (results) => handleParsedCSV(results)
      });
    },

    populateCopTableData() {
      const ashpSpaceHeater = this.run.site.ashp_space_heater;

      if (ashpSpaceHeater.cop_table_type === 'custom') {
        this.customHeatingColumns = {
          temperatures: ashpSpaceHeater.heating_reference_temps_degF,
          cop: ashpSpaceHeater.heating_cop_reference,
          cf: ashpSpaceHeater.heating_cf_reference
        };
        this.customCoolingColumns = {
          temperatures: ashpSpaceHeater.cooling_reference_temps_degF,
          cop: ashpSpaceHeater.cooling_cop_reference,
          cf: ashpSpaceHeater.cooling_cf_reference
        };
      }
    },

    resetInputs() {
      if (confirm('Are you sure you want to reset the inputs in the Air-Source Heat Pump section to their default values?')) {
        const attributes = {
          avoided_capex_by_ashp_present_value: null,
          back_up_temp_threshold_degF: null,
          cooling_cf_reference: this.defaultCoolingColumns.cf,
          cooling_cop_reference: this.defaultCoolingColumns.cop,
          cooling_reference_temps_degF: this.defaultCoolingColumns.temperatures,
          cop_table_file_name: null,
          cop_table_type: 'default',
          heating_cf_reference: this.defaultHeatingColumns.cf,
          heating_cop_reference: this.defaultHeatingColumns.cop,
          heating_reference_temps_degF: this.defaultHeatingColumns.temperatures,
          installed_cost_per_ton: null,
          macrs_bonus_fraction: 0,
          macrs_option_years: 0,
          max_ton: null,
          min_allowable_peak_capacity_fraction: null,
          min_allowable_ton: null,
          min_non_zero_size_ton: false,
          min_ton: null,
          om_cost_per_ton: null,
          site_id: null,
          sizing_factor: null,
        };

        this.updateAshpHeaterStoreAttributes(attributes);

        window.scrollTo(0, document.getElementById('ashp_space_heading').offsetTop);
      }
    },

    toggleAdvancedInputs() {
      this.displayAdvancedInputs ^= true;
    },

    updateAshpHeaterStoreAttributes(attributes) {
      Object.entries(attributes).forEach(([attribute, value]) => {
        this.$store.commit('updateValueNested2', {
          association_1: 'site',
          association_2: 'ashp_space_heater',
          attribute: attribute,
          value: value,
        });
      });
    },

    updateCopType(type) {
      this.$store.commit({
        type: 'updateValueNested2',
        association_1: 'site',
        association_2: 'ashp_space_heater',
        attribute: 'cop_table_type',
        value: type
      });
    },
  },
};
</script>
