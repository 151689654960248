<template>
  <div>
    <table class="table emissions-table-inputs tax-incentives-inputs">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" style="width:17.4%;text-align:center;">
            CO₂e
          </th>
          <th scope="col" style="width:17.4%;text-align:center;">
            NOx
          </th>
          <th scope="col" style="width:17.4%;text-align:center;">
            SO₂
          </th>
          <th scope="col" style="width:17.4%;text-align:center;">
            PM2.5
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="run.analyze_generator">
          <th scope="row" class="form-horizontal">
            <label v-if="run.focus == 'resilience'" class="control-label">
              Emergency generator (diesel) fuel emissions factor (lb / gal)
            </label>
            <label v-else class="control-label">
              Generator (diesel) fuel emissions factor (lb / gal)
            </label>
          </th>
          <td class="form-horizontal" style="vertical-align:middle;" >
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'generator'"
              :attribute="'emissions_factor_lb_CO2_per_gal'" />
          </td>
          <td style="vertical-align:middle;" >
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'generator'"
              :attribute="'emissions_factor_lb_NOx_per_gal'" />
          </td>
          <td class="form-horizontal" style="vertical-align:middle;" >
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'generator'"
              :attribute="'emissions_factor_lb_SO2_per_gal'" />
          </td>
          <td style="vertical-align:middle;" >
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'generator'"
              :attribute="'emissions_factor_lb_PM_per_gal'" />
          </td>
        </tr>
        <tr v-if="run.analyze_chp">
          <th scope="row" class="form-horizontal">
            <label id="chp-emissions-factor-label" class="control-label">
              CHP fuel emissions factor (lb /MMBtu)
            </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'chp'"
              :attribute="'emissions_factor_lb_CO2_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'chp'"
              :attribute="'emissions_factor_lb_NOx_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'chp'"
              :attribute="'emissions_factor_lb_SO2_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'chp'"
              :attribute="'emissions_factor_lb_PM_per_mmbtu'" />
          </td>
        </tr>
        <tr v-if="run.analyze_prime_generator">
          <th scope="row" class="form-horizontal">
            <label id="prime-gen-emissions-factor-label" class="control-label">
              Prime Generator fuel emissions factor (lb /MMBtu)
            </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'prime_generator'"
              :attribute="'emissions_factor_lb_CO2_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'prime_generator'"
              :attribute="'emissions_factor_lb_NOx_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'prime_generator'"
              :attribute="'emissions_factor_lb_SO2_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'prime_generator'"
              :attribute="'emissions_factor_lb_PM_per_mmbtu'" />
          </td>
        </tr>
        <tr v-if="run.analyze_chp || run.analyze_ghp || run.analyze_ashp_space || run.site.load_profile_boiler.include_site_heating_load">
          <th scope="row" class="form-horizontal">
            <label id="boiler-emissions-factor-label" class="control-label">
              Boiler fuel emissions factor (lb /MMBtu)
            </label>
          </th>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'boiler'"
              :attribute="'emissions_factor_lb_CO2_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'boiler'"
              :attribute="'emissions_factor_lb_NOx_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'boiler'"
              :attribute="'emissions_factor_lb_SO2_per_mmbtu'" />
          </td>
          <td>
            <incentives-table-string-input-nested-2
              :association_1="'site'"
              :association_2="'boiler'"
              :attribute="'emissions_factor_lb_PM_per_mmbtu'" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  import IncentivesTableStringInputNested2 from './IncentivesTableStringInputNested2';
  import IncentivesTableStringInputNested3 from './IncentivesTableStringInputNested3';

  export default {
    components: {
      Tooltip,
      IncentivesTableStringInputNested2,
      IncentivesTableStringInputNested3,
    },

    computed: {
      ...mapGetters([
        'run',
        'placeholders',
      ])
    },

    methods: {
      updateCHPEmissionsFactors: function() {
        if(this.run.site.chp.fuel_type == "natural_gas") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '117.030' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.091' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.001' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.007' });
        } else if(this.run.site.chp.fuel_type == "diesel_oil") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '163.610' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.560' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.289' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.000' });
        } else if(this.run.site.chp.fuel_type == "propane") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '139.160' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.153' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.000' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.010' });
        } else if(this.run.site.chp.fuel_type == "landfill_bio_gas") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '115.380' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.140' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.045' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'chp', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.025' });
        }
      },
      updatePrimeGeneratorEmissionsFactors: function() {
        if(this.run.site.prime_generator.fuel_type == "natural_gas") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '117.030' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.091' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.001' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.007' });
        } else if(this.run.site.prime_generator.fuel_type == "diesel_oil") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '163.610' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.560' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.289' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.000' });
        } else if(this.run.site.prime_generator.fuel_type == "propane") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '139.160' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.153' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.000' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.010' });
        } else if(this.run.site.prime_generator.fuel_type == "landfill_bio_gas") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '115.380' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.140' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.045' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'prime_generator', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.025' });
        }
      },
      updateBoilerEmissionsFactors: function() {
        if (this.run.site.boiler.fuel_type == "natural_gas") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '117.030' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.091' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.001' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.007' });
        } else if (this.run.site.boiler.fuel_type == "diesel_oil") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '163.610' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.560' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.289' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.000' });
        } else if (this.run.site.boiler.fuel_type == "landfill_bio_gas") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_CO2_per_mmbtu', value: '115.380' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_NOx_per_mmbtu', value: '0.140' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_SO2_per_mmbtu', value: '0.045' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'boiler', attribute: 'emissions_factor_lb_PM_per_mmbtu', value: '0.025' });
        }
      }
    },

    watch: {
      'run.site.chp.fuel_type': function() {
        this.updateCHPEmissionsFactors();
      },
      'run.site.prime_generator.fuel_type': function() {
        this.updatePrimeGeneratorEmissionsFactors();
      },
      'run.site.boiler.fuel_type': function() {
        this.updateBoilerEmissionsFactors();
      },
    },

    mounted: function() {
      this.updateCHPEmissionsFactors();
      this.updateBoilerEmissionsFactors();
      this.updatePrimeGeneratorEmissionsFactors();
    }
  }
</script>
