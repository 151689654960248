<template>
  <div class="form-group string optional" :class="formGroupClass">
    <div class="col-sm-6 control-label">
      <label class="string optional" :for="inputID">
        <abbr v-if="required" title="Required" class="required">*</abbr>
        {{ label }}
      </label>
      <tooltip
        :attribute="this.attribute" />
    </div>
    <div class="col-sm-4">
      <input
        v-model="run[attribute]"
        @change="$emit('input-change');"
        @blur="validateNumberOfRuns();"
        class="form-control string optional"
        :placeholder="placeholder"
        type="text"
        :name="inputName"
        :id="inputID" />
      <span v-if="validateError" class="help-block">
        {{ validateErrorMessage }}
      </span>
    </div>
    <div v-if="!run.single_run" class="col-sm-2">
      <button id="create_runs_button" class="btn btn-primary btn-sm" @click="addRuns()">Add Sites</button>
    </div>
    <div v-if="placeholder && displayDefault" class="col-sm-2 default-value-block">
      <div
        class="default-value"
        :data-default-value="placeholder"
        :data-default-value-display="placeholder">
        default = {{ placeholder }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'attribute',
      'required'
    ],

    data: function() {
      return {
        displayDefault: false,
        validateError: false
      }
    },

    computed: {
      ...mapGetters([
        'urlRoot',
        'labels',
        'placeholders',
        'run',
        'portfolioRun',
        'portfolio',
        'multiRunParams',
        'runsUtilityRatesLoading',
        'runsUtilityRates',
        'runsPlaceholders',
        'runsClientSideValidations',
      ]),
      property :function() {
        return this.run[this.attribute];
      },
      label: function() {
        return this.labels[this.attribute];
      },
      placeholder: function() {
        return this.placeholders[this.attribute];
      },
      formGroupClass: function() {
        return `run_${this.attribute}`;
      },
      inputID: function() {
        return `run_attributes_${this.attribute}`;
      },
      inputName: function() {
        return `run[${this.attribute}]`;
      },
      validateErrorMessage: function() {
        return "Must be a valid number less than 10";
      }
    },

    methods: {
      // for portfolio runs
      addRuns: function() {
        event.preventDefault()
        if (!this.portfolio.name && !this.run.number_of_runs) {
          bootbox.alert('Screening name and number of sites cannot be blank');
        } else if (!this.portfolio.name && this.run.number_of_runs && this.validateError) {
          bootbox.alert('Screening name cannot be blank and "Number of sites" must contain a number');
        } else if (this.portfolio.name && (!this.run.number_of_runs || (this.run.number_of_runs && this.validateError))) {
          bootbox.alert('Number of sites must contain a valid number');
        } else if (!this.portfolio.name && this.run.number_of_runs && !this.validateError) {
          bootbox.alert('You must enter a screening name');
        } else if (this.portfolio.name && this.run.number_of_runs > 10) {
          bootbox.alert("The number of sites cannot be greater than 10");
        } else if (this.portfolio.name && this.portfolio.runs && this.run.number_of_runs <= 10) {
          var text = "This will override any run information you have entered so far and clear the form."
          if(confirm(text)) {
            this.portfolio.runs = '';
            this.$store.commit('updateObject', { object: 'runsPlaceholders', value: [] });
            this.addRunsAjaxReq();
          }
        } else if (this.portfolio.name && this.run.number_of_runs) {
          this.addRunsAjaxReq();
        }
      },

      addRunsAjaxReq: function() {
        var self = this;
        $.ajax({
          url: self.urlRoot + '/add-runs?' + self.multiRunParams,
          context: self,
          contentType: 'application/json',
          
          success: function handleResults(runs) {
            self.$store.commit('updateRuns', runs);
            self.$store.commit('updatePortfolioValueInput', { attribute: 'single_run', value: false });
            self.portfolio.runs.forEach(function(run, i) {
              self.runsUtilityRatesLoading[i] = false;
              self.runsUtilityRates[i] = [];
              self.runsPlaceholders.push({
                site: {
                  description: 'Site ' + (i+1),
                  load_profile: {
                    annual_kwh: ''
                  },
                  financial: {
                    analysis_years: '25'
                  },
                  electric_tariff: {
                    cambium_start_year: '2024',
                    emissions_factor_hourly_source: ''
                  },
                  pv: {
                    azimuth: '180'
                  },
                  wind: {
                    installed_cost_per_kw: '$4,760',
                  }
                }
              });
              var payload = {
                attribute: 'single_run',
                value: false,
                index: i
              }
              self.$store.commit('updateRunsValues', payload);
            })

            for (const run of self.portfolio.runs) {
              self.runsClientSideValidations;
            }
          },
          error: function(data) {
            bootbox.alert(data.responseText);
            return false;
          },
        })
      },

      validateNumberOfRuns: function() {
        if(this.required && !this.run.single_run && !this.run[this.attribute]) {
          this.validateError = true;
        } else if (this.required && !this.run.single_run && !/^\d+$/.test(this.run[this.attribute])) {
          this.validateError = true;
        } else if(this.required && !this.run.single_run && /^\d+$/.test(this.run[this.attribute]) && this.run[this.attribute] > 10) {
          this.validateError = true;
        } else if(this.required && !this.run.single_run && /^\d+$/.test(this.run[this.attribute])) {
          this.validateError = false;
        }
      },
    },

    watch: {
      'property': function() {
        if(this.property) {
          this.displayDefault = true;
        } else {
          this.displayDefault = false;
        }
      },
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[0].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[0].classList.remove('has-error');
        }
      }
    },

    mounted: function() {
      // needed for when user enters value, then section disappears, then reappears
      // should display value in field & `default = XYZ` in side column
      if(this.property) {
        this.displayDefault = true;
      } else {
        this.displayDefault = false;
      }
    }
  }
</script>
