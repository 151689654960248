<template>
  <div class="form-group string optional" :class="formGroupClass">
    <div style="padding-left: 10px; padding-right: 10px;">
      <input
        v-model="run[association_1][association_2][attribute]"
        :disabled="isDisabled"
        @change="$emit('input-change');"
        @blur="validate()"
        class="form-control string optional"
        :placeholder="placeholder"
        type="text"
        :name="inputName"
        :id="inputID" />
      <span v-if="validateError" class="help-block">
        {{ validateErrorMessage }}
      </span>
    </div>
    <div v-if="placeholder && displayDefault" class="default-value-block" style="display: block;">
      <div class="default-value" :data-default-value="placeholder" :data-default-value-display="placeholder">
        {{ defaultLabel }} = {{ placeholder }}
      </div>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  
  export default {
    props: [
      'association_1',
      'association_2',
      'attribute',
      'required',
      'index',
      'run',
      'varyBySite'
    ],
  
    components: {
      Tooltip
    },
  
    data: function() {
      return {
        displayDefault: false,
        validateError: false,
      }
    },
  
    computed: {
      ...mapGetters([
        'runsClientSideValidations',
        'labels',
        'placeholders',
        'runsPlaceholders',
        'selectOptions',
      ]),
      property :function() {
        return this.run[this.association_1][this.association_2][this.attribute];
      },
      isDisabled: function() {
        if ((this.association_2 == "pv" && this.attribute == 'existing_kw') || (!this.varyBySite && this.association_2 == "pv" && (this.attribute == 'installed_cost_per_kw' || this.attribute == 'min_kw' || this.attribute == 'max_kw' || this.attribute == 'om_cost_per_kw' || this.attribute == 'azimuth' || this.attribute == 'tilt' || this.attribute == 'dc_ac_ratio' || this.attribute == 'losses' || this.attribute == 'radius') && this.index != 0)) {
          return true
        } else if (!this.varyBySite && this.association_2 == "financial" && (this.attribute == "owner_discount_rate_fraction" || this.attribute == "owner_tax_rate_fraction" || this.attribute == "analysis_years" || this.attribute == "offtaker_discount_rate_fraction" || this.attribute == "elec_cost_escalation_rate_fraction" || this.attribute == "offtaker_tax_rate_fraction" || this.attribute == "om_cost_escalation_rate_fraction" || this.attribute == "CO2_cost_per_tonne") && this.index != 0) {
          return true
        } else if (!this.varyBySite && this.association_2 == "storage" && (this.attribute == "installed_cost_per_kwh" || this.attribute == "installed_cost_per_kw" || this.attribute == "min_kwh" || this.attribute == "max_kwh" || this.attribute == "replace_cost_per_kwh" || this.attribute == "battery_replacement_year" || this.attribute == "replace_cost_per_kw" || this.attribute == "inverter_replacement_year" || this.attribute == "min_kw" || this.attribute == "max_kw" || this.attribute == "rectifier_efficiency_fraction" || this.attribute == "internal_efficiency_fraction" || this.attribute == "inverter_efficiency_fraction" || this.attribute == "soc_min_fraction" || this.attribute == "soc_init_fraction" || this.attribute == "total_itc_fraction" || this.attribute == "total_rebate_per_kw") && this.index != 0) {
          return true
        } else if (!this.varyBySite && this.association_2 == "wind" && (this.attribute == "federal_itc_fraction" || this.attribute == "min_kw" || this.attribute == "max_kw" || this.attribute == "om_cost_per_kw") && this.index != 0) {
          return true
        } else if (!this.varyBySite && this.association_2 == "electric_tariff" && (this.attribute == "cambium_start_year" || this.attribute == "emissions_factor_nox_so2_pm25_decrease_fraction" || this.attribute == "co2_annual_emissions_factor" || this.attribute == "emissions_factor_CO2_decrease_fraction" || this.attribute == "nox_annual_emissions_factor" || this.attribute == "so2_annual_emissions_factor" || this.attribute == "pm25_annual_emissions_factor") && this.index != 0) {
          return true
        } else {
          return false
        }
      },
      placeholder: function() {
        if (this.attribute == 'cambium_start_year' || this.attribute == 'co2_annual_emissions_factor' || this.attribute ==  'nox_annual_emissions_factor' || this.attribute == 'so2_annual_emissions_factor' || this.attribute == 'pm25_annual_emissions_factor' || this.attribute == 'annual_kwh' || this.attribute == 'azimuth' || (this.association_2 == 'wind' && this.attribute == 'installed_cost_per_kw')) {
          return this.runsPlaceholders[this.index][this.association_1][this.association_2][this.attribute];
        } else {
          return this.placeholders[this.association_1][this.association_2][this.attribute];
        }
      },
      formGroupClass: function() {
        return `run_${this.association_1}_${this.association_2}_${this.attribute}`;
      },
      inputID: function() {
        if (this.index >= 0) {
          return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.attribute}_${this.index + 1}`;
        } else {
          return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.attribute}`;
        }
      },
      inputName: function() {
        return `run[${this.association_1}_attributes][${this.association_2}_attributes][${this.attribute}]`;
      },
      defaultLabel: function() {
        if(this.association_2 == "load_profile" && this.attribute == "annual_kwh") {
          return "Calc. default";
        } else {
          return "default";
        }
      },
      validateErrorMessage: function() {
        return this.runsClientSideValidations.run[this.association_1][this.association_2][this.attribute];
      }
    },
  
    methods: {
      validate: function() {
        if(this.required && !this.run[this.association_1][this.association_2][this.attribute]) {
          this.validateError = true;
        } else if(this.required && this.run[this.association_1][this.association_2][this.attribute]) {
          this.validateError = false;
        }
      },
      toggleDisplayDefault: function() {
        var value = this.run[this.association_1][this.association_2][this.attribute];
  
        if(!value) {
          this.displayDefault = false;
        } else {
          this.displayDefault = true;
        }
      },
    },
  
    watch: {
      'property': function() {
        if(this.property) {
          this.displayDefault = true;
        } else {
          this.displayDefault = false;
        }
      },
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName(this.formGroupClass)[this.index].classList.add('has-error');
        } else {
          document.getElementsByClassName(this.formGroupClass)[this.index].classList.remove('has-error');
        }
      }
    },
  
    mounted: function() {
      // needed for when user enters value, then section disappears, then reappears
      // should display value in field & `default = XYZ` in side column
      if(this.property) {
        this.displayDefault = true;
      } else {
        this.displayDefault = false;
      }
    }
  }
</script>
  