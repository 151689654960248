<template>
  <div id="ghp-cop-performance" class="modal-mask">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="closeTable" type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">{{ title }}</h4>
          </div>
          <div class="modal-body">
            <div class="modal-body" style='float: left; width: 50%;'>
              <table>
                <thead>
                  <tr>
                    <th colspan="4" style='background-color: #344d80;'>Cooling Heat Pump COP (kWt/kWe)</th>
                  </tr>
                  <th rowspan="2">Entering Fluid Temperature (&deg;F)</th>
                  <th colspan="3">Cooling Loop Set Point Temperature (&deg;F)</th>
                  <tr>
                    <th v-for="(temp, i) in ghpCOPColdCustomData.chilledWaterTempHeaders">{{ temp }}</th>
                  </tr>
                </thead>
                <tbody id="coldData">
                  <tr v-for="(temp, i) in ghpCOPColdCustomData.fluidTemp">
                    <td class="fluid-temp">{{ temp }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-body" style='float: left; width: 50%;'>
              <table>
                <thead>
                  <tr>
                    <th colspan="4" style='background-color: #344d80;'>Heating Heat Pump COP (kWt/kWe)</th>
                  </tr>
                  <th rowspan="2">Entering Fluid Temperature (&deg;F)</th>
                  <th colspan="3">Heating Loop Set Point Temperature (&deg;F)</th>
                  <tr>
                    <th v-for="(temp, i) in ghpCOPHotCustomData.hotWaterTempHeaders">{{ temp }}</th>
                  </tr>
                </thead>
                <tbody id="hotData">
                  <tr v-for="(temp, i) in ghpCOPHotCustomData.fluidTemp">
                    <td class="fluid-temp">{{ temp }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters([
        'ghpCOPColdCustomData',
        'ghpCOPHotCustomData',
      ])
    },

    methods: {
      closeTable: function() {
        this.$store.commit('updateObject', { object: 'showGHPCOPWwhpCustomTable', value: false });
      }
    },
    mounted: function() {
      // populate all cop data
      var self = this;
      this.ghpCOPColdCustomData.fluidTemp.forEach(function(elem, index) {
        self.ghpCOPColdCustomData.chilledWaterTempHeaders.forEach(function(elem, i) {
          var td = document.createElement('td');
          td.innerText = self.ghpCOPColdCustomData['cop' + (i + 1)][index];
          $('#coldData tr')[index].append(td);
        });
      })

      this.ghpCOPHotCustomData.fluidTemp.forEach(function(elem, index) {
        self.ghpCOPHotCustomData.hotWaterTempHeaders.forEach(function(elem, i) {
          var td = document.createElement('td');
          td.innerText = self.ghpCOPHotCustomData['cop' + (i + 1)][index];
          $('#hotData tr')[index].append(td);
        });
      })
    }
  }
</script>
  