<template>
  <tbody>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_financial_analysis_years">
            {{ labels.site.financial.analysis_years }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="analysis_years" />
        </div>
        <!-- this might not get to have the option to apply to all sites due to cambium_levelization_years
        needing to be updated when either analysis_years or cambium_greening_of_grid is changed -->
        <!-- <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyAnalysisYearsBySite"
            id="toggle_analysis_years"
            type="checkbox"
            @change="varyAttributeBySite(varyAnalysisYearsBySite, 'analysis_years')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_analysis_years">
            Vary this input by site
          </label>
        </div> -->
      </th>
      <!-- <td v-if="!varyAnalysisYearsBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='analysis_years'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('analysis_years', portfolioRun.site.financial.analysis_years)" />
      </td> -->
      <td v-if="varyAnalysisYearsBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='analysis_years'
          :run="run"
          :index="index"
          :varyBySite="true"
          v-on:input-change="updateEmissionsYears(index)" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_financial_offtaker_discount_rate_fraction">
            {{ labels.site.financial.offtaker_discount_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="offtaker_discount_rate_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyOfftakerDiscountBySite"
            id="toggle_offtaker_discount"
            type="checkbox"
            @change="varyAttributeBySite(varyOfftakerDiscountBySite, 'offtaker_discount_rate_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_offtaker_discount">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyOfftakerDiscountBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='offtaker_discount_rate_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('offtaker_discount_rate_fraction', portfolioRun.site.financial.offtaker_discount_rate_fraction)" />
      </td>
      <td v-if="varyOfftakerDiscountBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='offtaker_discount_rate_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_financial_elec_cost_escalation_rate_fraction">
            {{ labels.site.financial.elec_cost_escalation_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="elec_cost_escalation_rate_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyElecCostEscalationRateBySite"
            id="toggle_elec_cost_escalation_rate_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyElecCostEscalationRateBySite, 'elec_cost_escalation_rate_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_elec_cost_escalation_rate_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyElecCostEscalationRateBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='elec_cost_escalation_rate_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('elec_cost_escalation_rate_fraction', portfolioRun.site.financial.elec_cost_escalation_rate_fraction)" />
      </td>
      <td v-if="varyElecCostEscalationRateBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='elec_cost_escalation_rate_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3"></th>
      <td colspan="2" class="col-sm-3" style="position: sticky; left: 440px; z-index: 1; background: #fdf4ed; text-align: left;">
        <input
          v-model="portfolioRun.site.financial.third_party_ownership"
          class="checkbox-inline"
          type="checkbox"
          value="1"
          name="run[site_attributes][financial_attributes][third_party_ownership]"
          id="run_site_attributes_financial_attributes_third_party_ownership" />
        <label class="custom-electricity-rate-label" for="run_site_attributes_financial_attributes_third_party_ownership">Use third-party ownership model</label>
        <tooltip
          :association_1="'site'"
          :association_2="'financial'"
          :attribute="'third_party_ownership'" />
      </td>
    </tr>
    <br><br>
    <tr>
      <th v-if="portfolioRun.site.financial.third_party_ownership" class="col-sm-3" style="padding-left: 5px;">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_financial_owner_discount_rate_fraction">
            {{ labels.site.financial.owner_discount_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="owner_discount_rate_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyOwnerDiscountRateFractionBySite"
            id="toggle_owner_discount_rate_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyOwnerDiscountRateFractionBySite, 'owner_discount_rate_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_owner_discount_rate_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyOwnerDiscountRateFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="portfolioRun.site.financial.third_party_ownership"
          association_1='site'
          association_2="financial"
          attribute='owner_discount_rate_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('owner_discount_rate_fraction', portfolioRun.site.financial.owner_discount_rate_fraction)" />
      </td>
      <td v-if="varyOwnerDiscountRateFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="portfolioRun.site.financial.third_party_ownership"
          association_1='site'
          association_2="financial"
          attribute='owner_discount_rate_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3" v-if="portfolioRun.site.financial.third_party_ownership">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_financial_owner_tax_rate_fraction">
            {{ labels.site.financial.owner_tax_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="owner_tax_rate_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyOwnerTaxRateFractionBySite"
            id="toggle_owner_tax_rate_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyOwnerTaxRateFractionBySite, 'owner_tax_rate_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_owner_tax_rate_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyOwnerTaxRateFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="portfolioRun.site.financial.third_party_ownership"
          association_1='site'
          association_2="financial"
          attribute='owner_tax_rate_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('owner_tax_rate_fraction', portfolioRun.site.financial.owner_tax_rate_fraction)" />
      </td>
      <td v-if="varyOwnerTaxRateFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="portfolioRun.site.financial.third_party_ownership"
          association_1='site'
          association_2="financial"
          attribute='owner_tax_rate_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_financial_offtaker_tax_rate_fraction">
            {{ labels.site.financial.offtaker_tax_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="offtaker_tax_rate_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyOfftakerTaxRateFractionBySite"
            id="toggle_offtaker_tax_rate_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyOfftakerTaxRateFractionBySite, 'offtaker_tax_rate_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_offtaker_tax_rate_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyOfftakerTaxRateFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='offtaker_tax_rate_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('offtaker_tax_rate_fraction', portfolioRun.site.financial.offtaker_tax_rate_fraction)" />
      </td>
      <td v-if="varyOfftakerTaxRateFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='offtaker_tax_rate_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_financial_om_cost_escalation_rate_fraction">
            {{ labels.site.financial.om_cost_escalation_rate_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2="financial"
            attribute="om_cost_escalation_rate_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyOmCostEscalationRateFractionBySite"
            id="toggle_om_cost_escalation_rate_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyOmCostEscalationRateFractionBySite, 'om_cost_escalation_rate_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_om_cost_escalation_rate_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyOmCostEscalationRateFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='om_cost_escalation_rate_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('om_cost_escalation_rate_fraction', portfolioRun.site.financial.om_cost_escalation_rate_fraction)" />
      </td>
      <td v-if="varyOmCostEscalationRateFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="financial"
          attribute='om_cost_escalation_rate_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested2,
    },

    data: function() {
      return {
        varyAnalysisYearsBySite: true,
        varyOfftakerDiscountBySite: false,
        varyElecCostEscalationRateBySite: false,
        varyOwnerDiscountRateFractionBySite: false,
        varyOwnerTaxRateFractionBySite: false,
        varyOfftakerTaxRateFractionBySite: false,
        varyOmCostEscalationRateFractionBySite: false,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'portfolioRun',
        'portfolio'
      ]),
    },

    methods: {
      updateEmissionsYears: function(index) {
        if (this.portfolio.runs[index].site.electric_tariff.cambium_greening_of_grid && !this.portfolio.runs[index].site.financial.analysis_years) {
          this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: '', index: index });
          this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.placeholders.site.financial.analysis_years) - 1), index: index });
          if (this.portfolio.runs[index].site.electric_tariff.cambium_start_year) {
            this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '', index: index });
          }
        } else if (this.portfolio.runs[index].site.electric_tariff.cambium_greening_of_grid && this.portfolio.runs[index].site.financial.analysis_years) {
          this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: this.portfolio.runs[index].site.financial.analysis_years, index: index });
          this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024 - ' + (2024 + parseInt(this.portfolio.runs[index].site.financial.analysis_years) - 1), index: index });
          if (this.portfolio.runs[index].site.electric_tariff.cambium_start_year) {
            this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '', index: index });
          }
        } else if (!this.portfolio.runs[index].site.electric_tariff.cambium_greening_of_grid) {
          this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_levelization_years', value: 1, index: index });
          this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'electric_tariff', attribute: 'cambium_start_year', value: '2024', index: index });
        }
      },
      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: 'financial',
            attribute: attr,
            value: value,
            index: i,
          }

          self.$store.commit('updateRunsValues', payload);
        })
      },
      varyAttributeBySite: function(action, attr) {
        if (action && this.portfolioRun.site.financial[attr]) {
          this.updateInputValueForRuns(attr, null);
          this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'financial', attribute: attr, value: null });
        } else if (!action) {
          this.updateInputValueForRuns(attr, null);
        }
      }
    },
  }
</script>
