<template>
  <tbody>
    <tr>
      <td class="col-sm-3">
        <p class="required-note" style="text-align: left;">
          <abbr class="required">*</abbr> Required field
        </p>
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_load_profile_doe_reference_name">
            <abbr title="Required" class="required">*</abbr>
              {{ labels.site.load_profile.doe_reference_name }}
          </label>
          <tooltip
            association_1="site"
            association_2='load_profile'
            attribute="doe_reference_name" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_load_profile_doe_reference_name" style="margin-top: 15px;">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2='load_profile'
            attribute='doe_reference_name'
            v-on:input-change="updateAnnualKwh(run, index);"
            :index="index"
            :run="run"
            required=true />
        </div>
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_load_profile_annual_kwh">
            {{ labels.site.load_profile.annual_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='load_profile'
            attribute="annual_kwh" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          v-if="run.site.load_profile.simulated_load_type == 'annual'"
          association_1='site'
          association_2='load_profile'
          attribute='annual_kwh'
          ref='annualKwh'
          v-on:input-change="updateAnnualKwh(run, index)"
          :index="index"
          :run="run" />
      </td>
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested1 from './inputs/PortfolioHorizontalStringInputNested1.vue';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2.vue';
  import PortfolioHorizontalSelectInputNested2 from './inputs/PortfolioHorizontalSelectInputNested2.vue';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested1,
      PortfolioHorizontalStringInputNested2,
      PortfolioHorizontalSelectInputNested2,
    },

    data: function() {
      return {}
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'urlRoot',
        'year',
        'portfolio'
      ]),
    },

    methods: {
      updateAnnualKwh: function(run, index) {
        if(run.site.load_profile.simulated_load_type != 'annual') {
          return false;
        }

        if(run.site.address) {
          var self = this;
          $.ajax({
            url: self.urlRoot + '/annual-kwh',
            contentType: 'application/json',
            data: {
              latitude: run.site.latitude,
              longitude: run.site.longitude,
              doe_reference_name: run.site.load_profile.doe_reference_name,
            },
            success: function handleResults(data) {
              if(!run.site.load_profile.annual_kwh) {
                self.$refs.annualKwh[index].toggleDisplayDefault();
              }

              self.$store.commit('updateRunsPlaceholder', {
                association_1: 'site',
                association_2: 'load_profile',
                attribute: 'annual_kwh',
                index: index,
                value: self.$store.getters.formatAsIntegerWithCommas({ value: data.toFixed(0) })
              });

              self.getElecSimBuildingAnnualLoad(run, index);
            },
            error: function() {
              return false;
            },
          })
        }
      },

      updateStore: function(attr, value, index) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          attribute: attr,
          value: value,
          index: index,
        }

        this.$store.commit('updateRunsValues', payload);
      },

      getElecSimBuildingAnnualLoad: function(run, index) {
        this.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: true });
        var self = this;

        var params = {
          latitude: run.site.latitude,
          longitude: run.site.longitude,
          doe_reference_name: run.site.load_profile.doe_reference_name,
        }
        if(run.site.load_profile.annual_kwh) {
          params['annual_kwh'] = run.site.load_profile.annual_kwh;
        }

        $.ajax({
          url: self.urlRoot + "/simulated-annual-elec-load",
          data: params,
          success: function(data) {
            var payload = {
              loadsKw: data.loads_kw,
              minKw: data.min_kw,
              meanKw: data.mean_kw,
              maxKw: data.max_kw,
            }
            self.$store.commit('updateObject', { object: 'elecSimBuildAnnualLoadProfile', value: payload });
            // self.getColdSimulatedLoadProfile();
            // self.getColdCustomAnnualPctLoad();
            // self.getColdCustomMonthlyPctLoad();
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'elecSimBuildAnnualLoadProfile', value: {} });
            // self.getColdSimulatedLoadProfile();
            // self.getColdCustomAnnualPctLoad();
            // self.getColdCustomMonthlyPctLoad();
            self.$store.commit('updateObject', { object: 'elecLoadProfileLoading', value: false });
          }
        })
      },
    },
  }
</script>