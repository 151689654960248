<template>
  <tbody>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_installed_cost_per_kw">
            {{ labels.site.pv.installed_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="installed_cost_per_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyInstalledCostPerKwBySite"
            id="toggle_installed_cost_per_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyInstalledCostPerKwBySite, 'installed_cost_per_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_installed_cost_per_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyInstalledCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='installed_cost_per_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('installed_cost_per_kw', portfolioRun.site.pv.installed_cost_per_kw)" />
      </td>
      <td v-if="varyInstalledCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='installed_cost_per_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3"></th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <input
          v-model="run.site.pv.existing_pv"
          @click="toggleDisableExistingKw(index)"
          type="checkbox"
          :id="`existing_pv_chk_${index}`" />
        <label style="font-weight: normal" :for="`existing_pv_chk_${index}`">
          Existing PV system?
        </label>
      </td>
      <td class="col-sm-3"></td>
    </tr>
    <br>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_pv_existing_kw">
            {{ labels.site.pv.existing_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="existing_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2='pv'
          attribute='existing_kw'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_min_kw">
            {{ labels.site.pv.min_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="min_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMinKwBySite"
            id="toggle_min_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyMinKwBySite, 'min_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_min_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMinKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='min_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('min_kw', portfolioRun.site.pv.min_kw)" />
      </td>
      <td v-if="varyMinKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='min_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_max_kw">
            {{ labels.site.pv.max_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="max_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMaxKwBySite"
            id="toggle_max_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyMaxKwBySite, 'max_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_max_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMaxKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='max_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('max_kw', portfolioRun.site.pv.max_kw)" />
      </td>
      <td v-if="varyMaxKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='max_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div class="row section-actions">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="toggle-more-inputs pull-left">
              <a @click="toggleAdvancedInputs();">
                <span v-if="displayAdvancedInputs">
                  <font-awesome-icon icon='minus-square' />
                  Show fewer inputs
                </span>
                <span v-else>
                  <font-awesome-icon icon='plus-square' />
                  Advanced inputs
                </span>
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">PV Costs</h4>
      </td>
    </tr>
    <br>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_om_cost_per_kw">
            {{ labels.site.pv.om_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="om_cost_per_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyOmCostPerKwBySite"
            id="toggle_om_cost_per_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyOmCostPerKwBySite, 'om_cost_per_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_om_cost_per_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyOmCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='om_cost_per_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('om_cost_per_kw', portfolioRun.site.pv.om_cost_per_kw)" />
      </td>
      <td v-if="varyOmCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='om_cost_per_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">PV System Characteristics</h4>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_module_type">
            {{ labels.site.pv.module_type }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="module_type" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyModuleTypeBySite"
            id="toggle_module_type"
            type="checkbox"
            @change="varySelectAttributeBySite(varyModuleTypeBySite, 'module_type')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_module_type">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyModuleTypeBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_module_type">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='module_type'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('module_type', portfolioRun.site.pv.module_type)" />
        </div>
      </td>
      <td v-if="varyModuleTypeBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_module_type">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='module_type'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_array_type">
            {{ labels.site.pv.array_type }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="array_type" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyArrayTypeBySite"
            id="toggle_array_type"
            type="checkbox"
            @change="varySelectAttributeBySite(varyArrayTypeBySite, 'array_type')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_array_type">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyArrayTypeBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_array_type">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='array_type'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('array_type', portfolioRun.site.pv.array_type)" />
        </div>
      </td>
      <td v-if="varyArrayTypeBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_array_type">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='array_type'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_azimuth">
            {{ labels.site.pv.azimuth }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="azimuth" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyAzimuthBySite"
            id="toggle_azimuth"
            type="checkbox"
            @change="varyAttributeBySite(varyAzimuthBySite, 'azimuth')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_azimuth">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyAzimuthBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='azimuth'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('azimuth', portfolioRun.site.pv.azimuth)" />
      </td>
      <td v-if="varyAzimuthBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='azimuth'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_tilt">
            {{ labels.site.pv.tilt }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="tilt" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyTiltBySite"
            id="toggle_tilt"
            type="checkbox"
            @change="varyAttributeBySite(varyTiltBySite, 'tilt')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_tilt">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyTiltBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='tilt'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('tilt', portfolioRun.site.pv.tilt)" />
      </td>
      <td v-if="varyTiltBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='tilt'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_dc_ac_ratio">
            {{ labels.site.pv.dc_ac_ratio }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="dc_ac_ratio" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyDcAcRatioBySite"
            id="toggle_dc_ac_ratio"
            type="checkbox"
            @change="varyAttributeBySite(varyDcAcRatioBySite, 'dc_ac_ratio')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_dc_ac_ratio">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyDcAcRatioBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='dc_ac_ratio'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('dc_ac_ratio', portfolioRun.site.pv.dc_ac_ratio)" />
      </td>
      <td v-if="varyDcAcRatioBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='dc_ac_ratio'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_losses">
            {{ labels.site.pv.losses }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="losses" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyLossesBySite"
            id="toggle_losses"
            type="checkbox"
            @change="varyAttributeBySite(varyLossesBySite, 'losses')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_losses">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyLossesBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='losses'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('losses', portfolioRun.site.pv.losses)" />
      </td>
      <td v-if="varyLossesBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='losses'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_radius">
            {{ labels.site.pv.radius }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="radius" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyRadiusBySite"
            id="toggle_radius"
            type="checkbox"
            @change="varyAttributeBySite(varyRadiusBySite, 'radius')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_radius">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyRadiusBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='radius'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('radius', portfolioRun.site.pv.radius)" />
      </td>
      <td v-if="varyRadiusBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="pv"
          attribute='radius'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">PV Incentives and Tax Treatment</h4>
        <h5 style="font-weight: lighter;">
          Capital Cost or System Size Based Incentives
          <tooltip 
            association_1="incentives" 
            attribute="capital_costs" />
        </h5>
        <a target="blank" style="font-size: 12px" href="http://www.dsireusa.org/">Database of state incentives for renewables</a>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td>
        <portfolio-incentives-table
          association_1='site'
          association_2='pv'
          :portfolioRun="portfolioRun" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h5 style="font-weight: lighter;">
          Production Based Incentives
          <a
            href="#"
            rel="tooltip"
            title="Federal, State, and Utility production incentives can be entered here. If there is more than one production based incentive offered, the combined value can be entered and should be discounted back to year one if the incentive duration differs. For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank'>help manual</a>.">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
          </a>
        </h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td>
        <portfolio-incentives-table-2
          association_1='site'
          association_2='pv'
          :portfolioRun="portfolioRun" />
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h5 style="font-weight: lighter;">Tax Treatment</h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_macrs_option_years">
            {{ labels.site.pv.macrs_option_years }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="macrs_option_years" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMacrsOptionYearsBySite"
            id="toggle_macrs_option_years"
            type="checkbox"
            @change="varySelectAttributeBySite(varyMacrsOptionYearsBySite, 'macrs_option_years')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_macrs_option_years">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMacrsOptionYearsBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_macrs_option_years">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='macrs_option_years'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false" 
            v-on:input-change="updateInputValueForRuns('macrs_option_years', portfolioRun.site.pv.macrs_option_years)" />
        </div>
      </td>
      <td v-if="varyMacrsOptionYearsBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_macrs_option_years">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='macrs_option_years'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_pv_macrs_bonus_fraction">
            {{ labels.site.pv.macrs_bonus_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='pv'
            attribute="macrs_bonus_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMacrsBonusFractionBySite"
            id="toggle_macrs_bonus_fraction"
            type="checkbox"
            @change="varySelectAttributeBySite(varyMacrsBonusFractionBySite, 'macrs_bonus_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_macrs_bonus_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMacrsBonusFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_macrs_bonus_fraction">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='macrs_bonus_fraction'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('macrs_bonus_fraction', portfolioRun.site.pv.macrs_bonus_fraction)" />
        </div>
      </td>
      <td v-if="varyMacrsBonusFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_pv_macrs_bonus_fraction">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="pv"
            attribute='macrs_bonus_fraction'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2';
  import PortfolioHorizontalSelectInputNested2 from './inputs/PortfolioHorizontalSelectInputNested2';
  import PortfolioIncentivesTable from './inputs/PortfolioIncentivesTable';
  import PortfolioIncentivesTable2 from './inputs/PortfolioIncentivesTable2';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested2,
      PortfolioHorizontalSelectInputNested2,
      PortfolioIncentivesTable,
      PortfolioIncentivesTable2,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
        varyInstalledCostPerKwBySite: false,
        varyMinKwBySite: false,
        varyMaxKwBySite: false,
        varyOmCostPerKwBySite: false,
        varyModuleTypeBySite: false,
        varyArrayTypeBySite: false,
        varyAzimuthBySite: false,
        varyTiltBySite: false,
        varyDcAcRatioBySite: false,
        varyLossesBySite: false,
        varyRadiusBySite: false,
        varyMacrsOptionYearsBySite: false,
        varyMacrsBonusFractionBySite: false,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'urlRoot',
        'year',
        'portfolioRun',
        'portfolio'
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },

      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: 'pv',
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },

      varyAttributeBySite: function(action, attr) {
        if (action && this.portfolioRun.site.pv[attr]) {
          this.updateInputValueForRuns(attr, null);
          this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'pv', attribute: attr, value: null });
        } else if (!action) {
          this.updateInputValueForRuns(attr, null);
        }
      },

      varySelectAttributeBySite: function(action, attr) {
        if (attr == 'module_type') {
          if (action && this.portfolioRun.site.pv[attr] !== 'Standard') {
            this.updateInputValueForRuns(attr, 'Standard');
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'pv', attribute: attr, value: 'Standard' });
          } else if (!action) {
            this.updateInputValueForRuns(attr, 'Standard');
          }
        } else if (attr == 'array_type') {
          if (action && this.portfolioRun.site.pv[attr] !== 'Ground Mount, Fixed') {
            this.updateInputValueForRuns(attr, 'Ground Mount, Fixed');
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'pv', attribute: attr, value: 'Ground Mount, Fixed' });
          } else if (!action) {
            this.updateInputValueForRuns(attr, 'Ground Mount, Fixed');
          }
        } else if (attr == 'macrs_option_years') {
          if (action && this.portfolioRun.site.pv[attr] !== 5) {
            this.updateInputValueForRuns(attr, 5);
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'pv', attribute: attr, value: 5 });
          } else if (!action) {
            this.updateInputValueForRuns(attr, 5);
          }
        } else if (attr == 'macrs_bonus_fraction') {
          if (action && this.portfolioRun.site.pv[attr] !== 0.6) {
            this.updateInputValueForRuns(attr, 0.6);
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'pv', attribute: attr, value: 0.6 });
          } else if (!action) {
            this.updateInputValueForRuns(attr, 0.6);
          }
        }
      },

      updateTilt: function() {
        if(this.portfolioRun.site.pv.array_type == "Ground Mount, Fixed" || this.portfolioRun.site.pv.array_type == "Rooftop, Fixed") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'tilt', value: "20" });
        } else if(this.portfolioRun.site.pv.array_type == "Ground Mount, 1-Axis Tracking") {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'tilt', value: "0" });
        }
      },

      toggleDisableExistingKw: function(index) {
        if($(`#existing_pv_chk_${index}`)[0].checked) {
          $(`#run_site_attributes_pv_attributes_existing_kw_${index + 1}`).attr('disabled', false);
        } else {
          $(`#run_site_attributes_pv_attributes_existing_kw_${index + 1}`).attr('disabled', true);
          this.$store.commit('updateRunsValues', { association_1: 'site', association_2: 'pv', attribute: 'existing_kw', value: '', index: index });
        }
      },
    },

    mounted: function() {
      this.updateTilt();
    }
  }
</script>
