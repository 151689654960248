<template>
  <tbody>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_installed_cost_per_kwh">
            {{ labels.site.storage.installed_cost_per_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="installed_cost_per_kwh" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyInstalledCostPerKwhBySite"
            id="toggle_installed_cost_per_kwh"
            type="checkbox"
            @change="varyAttributeBySite(varyInstalledCostPerKwhBySite, 'installed_cost_per_kwh')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_installed_cost_per_kwh">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyInstalledCostPerKwhBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='installed_cost_per_kwh'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('installed_cost_per_kwh', portfolioRun.site.storage.installed_cost_per_kwh)" />
      </td>
      <td v-if="varyInstalledCostPerKwhBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='installed_cost_per_kwh'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_installed_cost_per_kw">
            {{ labels.site.storage.installed_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="installed_cost_per_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyInstalledCostPerKwBySite"
            id="toggle_installed_cost_per_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyInstalledCostPerKwBySite, 'installed_cost_per_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_installed_cost_per_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyInstalledCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='installed_cost_per_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('installed_cost_per_kw', portfolioRun.site.storage.installed_cost_per_kw)" />
      </td>
      <td v-if="varyInstalledCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='installed_cost_per_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_can_grid_charge">
            {{ labels.site.storage.can_grid_charge }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="can_grid_charge" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyCanGridChargeBySite"
            id="toggle_can_grid_charge"
            type="checkbox"
            @change="varySelectAttributeBySite(varyCanGridChargeBySite, 'can_grid_charge')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_can_grid_charge">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyCanGridChargeBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_can_grid_charge">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='can_grid_charge'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false"
            v-on:input-change="updateInputValueForRuns('can_grid_charge', portfolioRun.site.storage.can_grid_charge)" />
        </div>
      </td>
      <td v-if="varyCanGridChargeBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_can_grid_charge">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='can_grid_charge'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_min_kwh">
            {{ labels.site.storage.min_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="min_kwh" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMinKwhBySite"
            id="toggle_min_kwh"
            type="checkbox"
            @change="varyAttributeBySite(varyMinKwhBySite, 'min_kwh')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_min_kwh">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMinKwhBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='min_kwh'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('min_kwh', portfolioRun.site.storage.min_kwh)" />
      </td>
      <td v-if="varyMinKwhBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='min_kwh'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_max_kwh">
            {{ labels.site.storage.max_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="max_kwh" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMaxKwhBySite"
            id="toggle_max_kwh"
            type="checkbox"
            @change="varyAttributeBySite(varyMaxKwhBySite, 'max_kwh')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_max_kwh">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMaxKwhBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='max_kwh'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('max_kwh', portfolioRun.site.storage.max_kwh)" />
      </td>
      <td v-if="varyMaxKwhBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='max_kwh'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div class="row section-actions">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="toggle-more-inputs pull-left">
              <a @click="toggleAdvancedInputs();">
                <span v-if="displayAdvancedInputs">
                  <font-awesome-icon icon='minus-square' />
                  Show fewer inputs
                </span>
                <span v-else>
                  <font-awesome-icon icon='plus-square' />
                  Advanced inputs
                </span>
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Battery Costs</h4>
      </td>
    </tr>
    <br>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_replace_cost_per_kwh">
            {{ labels.site.storage.replace_cost_per_kwh }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="replace_cost_per_kwh" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyReplaceCostPerKwhBySite"
            id="toggle_replace_cost_per_kwh"
            type="checkbox"
            @change="varyAttributeBySite(varyReplaceCostPerKwhBySite, 'replace_cost_per_kwh')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_replace_cost_per_kwh">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyReplaceCostPerKwhBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='replace_cost_per_kwh'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('replace_cost_per_kwh', portfolioRun.site.storage.replace_cost_per_kwh)" />
      </td>
      <td v-if="varyReplaceCostPerKwhBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='replace_cost_per_kwh'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_battery_replacement_year">
            {{ labels.site.storage.battery_replacement_year }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="battery_replacement_year" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyBatteryReplacementYearBySite"
            id="toggle_battery_replacement_year"
            type="checkbox"
            @change="varyAttributeBySite(varyBatteryReplacementYearBySite, 'battery_replacement_year')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_battery_replacement_year">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyBatteryReplacementYearBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='battery_replacement_year'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('battery_replacement_year', portfolioRun.site.storage.battery_replacement_year)" />
      </td>
      <td v-if="varyBatteryReplacementYearBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='battery_replacement_year'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_replace_cost_per_kw">
            {{ labels.site.storage.replace_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="replace_cost_per_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyReplaceCostPerKwBySite"
            id="toggle_replace_cost_per_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyReplaceCostPerKwBySite, 'replace_cost_per_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_replace_cost_per_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyReplaceCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='replace_cost_per_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('replace_cost_per_kw', portfolioRun.site.storage.replace_cost_per_kw)" />
      </td>
      <td v-if="varyReplaceCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='replace_cost_per_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_inverter_replacement_year">
            {{ labels.site.storage.inverter_replacement_year }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="inverter_replacement_year" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyInverterReplacementYearBySite"
            id="toggle_inverter_replacement_year"
            type="checkbox"
            @change="varyAttributeBySite(varyInverterReplacementYearBySite, 'inverter_replacement_year')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_inverter_replacement_year">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyInverterReplacementYearBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='inverter_replacement_year'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('inverter_replacement_year', portfolioRun.site.storage.inverter_replacement_year)" />
      </td>
      <td v-if="varyInverterReplacementYearBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='inverter_replacement_year'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Battery Characteristics</h4>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_min_kw">
            {{ labels.site.storage.min_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="min_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMinKwBySite"
            id="toggle_min_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyMinKwBySite, 'min_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_min_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMinKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='min_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('min_kw', portfolioRun.site.storage.min_kw)" />
      </td>
      <td v-if="varyMinKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='min_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_max_kw">
            {{ labels.site.storage.max_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="max_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMaxKwBySite"
            id="toggle_max_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyMaxKwBySite, 'max_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_max_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMaxKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='max_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('max_kw', portfolioRun.site.storage.max_kw)" />
      </td>
      <td v-if="varyMaxKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='max_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_rectifier_efficiency_fraction">
            {{ labels.site.storage.rectifier_efficiency_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="rectifier_efficiency_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='rectifier_efficiency_fraction'
          :run="portfolioRun"
          :index="index"
          v-on:input-change="updateRoundTripEfficiency(); updateInputValueForRuns('rectifier_efficiency_fraction', portfolioRun.site.storage.rectifier_efficiency_fraction);" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_internal_efficiency_fraction">
            {{ labels.site.storage.internal_efficiency_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="internal_efficiency_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='internal_efficiency_fraction'
          :run="portfolioRun"
          :index="index"
          v-on:input-change="updateRoundTripEfficiency(); updateInputValueForRuns('internal_efficiency_fraction', portfolioRun.site.storage.internal_efficiency_fraction);" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_inverter_efficiency_fraction">
            {{ labels.site.storage.inverter_efficiency_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="inverter_efficiency_fraction" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='inverter_efficiency_fraction'
          :run="portfolioRun"
          :index="index"
          v-on:input-change="updateRoundTripEfficiency(); updateInputValueForRuns('inverter_efficiency_fraction', portfolioRun.site.storage.inverter_efficiency_fraction);" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_storage_ac_ac_efficiency">
            Total AC-AC round trip efficiency
            <tooltip :association_1="'site'" :association_2="'storage'" :attribute="'ac_ac_efficiency'"></tooltip>
          </label>
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <p class="form-control-static">{{ roundTripEfficiency.toFixed(1) }}%</p>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_soc_min_fraction">
            {{ labels.site.storage.soc_min_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="soc_min_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varySocMinFractionBySite"
            id="toggle_soc_min_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varySocMinFractionBySite, 'soc_min_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_soc_min_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varySocMinFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='soc_min_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('soc_min_fraction', portfolioRun.site.storage.soc_min_fraction)" />
      </td>
      <td v-if="varySocMinFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='soc_min_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_soc_init_fraction">
            {{ labels.site.storage.soc_init_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="soc_init_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varySocInitFractionBySite"
            id="toggle_soc_init_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varySocInitFractionBySite, 'soc_init_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_soc_init_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varySocInitFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='soc_init_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('soc_init_fraction', portfolioRun.site.storage.soc_init_fraction)" />
      </td>
      <td v-if="varySocInitFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='soc_init_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Battery Incentives and Tax Treatment</h4>
        <h5>Capital Cost Based Incentives</h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_total_itc_fraction">
            {{ labels.site.storage.total_itc_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="total_itc_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyTotalItcFractionBySite"
            id="toggle_total_itc_fraction"
            type="checkbox"
            @change="varyAttributeBySite(varyTotalItcFractionBySite, 'total_itc_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_total_itc_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyTotalItcFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='total_itc_fraction'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('total_itc_fraction', portfolioRun.site.storage.total_itc_fraction)" />
      </td>
      <td v-if="varyTotalItcFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='total_itc_fraction'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_total_rebate_per_kw">
            {{ labels.site.storage.total_rebate_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="total_rebate_per_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyTotalRebatePerKwBySite"
            id="toggle_total_rebate_per_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyTotalRebatePerKwBySite, 'total_rebate_per_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_total_rebate_per_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyTotalRebatePerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='total_rebate_per_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('total_rebate_per_kw', portfolioRun.site.storage.total_rebate_per_kw)" />
      </td>
      <td v-if="varyTotalRebatePerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="storage"
          attribute='total_rebate_per_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h5>Tax Treatment</h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_macrs_option_years">
            {{ labels.site.storage.macrs_option_years }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="macrs_option_years" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMacrsOptionYearsBySite"
            id="toggle_macrs_option_years"
            type="checkbox"
            @change="varySelectAttributeBySite(varyMacrsOptionYearsBySite, 'macrs_option_years')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_macrs_option_years">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMacrsOptionYearsBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_macrs_option_years">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='macrs_option_years'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false" 
            v-on:input-change="updateInputValueForRuns('macrs_option_years', portfolioRun.site.storage.macrs_option_years)" />
        </div>
      </td>
      <td v-if="varyMacrsOptionYearsBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_macrs_option_years">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='macrs_option_years'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_storage_macrs_bonus_fraction">
            {{ labels.site.storage.macrs_bonus_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='storage'
            attribute="macrs_bonus_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMacrsBonusFractionBySite"
            id="toggle_macrs_bonus_fraction"
            type="checkbox"
            @change="varySelectAttributeBySite(varyMacrsBonusFractionBySite, 'macrs_bonus_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_macrs_bonus_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMacrsBonusFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_macrs_bonus_fraction">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='macrs_bonus_fraction'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false" 
            v-on:input-change="updateInputValueForRuns('macrs_bonus_fraction', portfolioRun.site.storage.macrs_bonus_fraction)" />
        </div>
      </td>
      <td v-if="varyMacrsBonusFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_storage_macrs_bonus_fraction">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="storage"
            attribute='macrs_bonus_fraction'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2';
  import PortfolioHorizontalSelectInputNested2 from './inputs/PortfolioHorizontalSelectInputNested2';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested2,
      PortfolioHorizontalSelectInputNested2,
    },

    data: function() {
      return {
        varyInstalledCostPerKwhBySite: false,
        varyInstalledCostPerKwBySite: false,
        varyCanGridChargeBySite: false,
        varyMinKwhBySite: false,
        varyMaxKwhBySite: false,
        varyReplaceCostPerKwhBySite: false,
        varyBatteryReplacementYearBySite: false,
        varyReplaceCostPerKwBySite: false,
        varyInverterReplacementYearBySite: false,
        varyMinKwBySite: false,
        varyMaxKwBySite: false,
        varySocMinFractionBySite: false,
        varySocInitFractionBySite: false,
        varyTotalItcFractionBySite: false,
        varyTotalRebatePerKwBySite: false,
        varyMacrsOptionYearsBySite: false,
        varyMacrsBonusFractionBySite: false,
        displayAdvancedInputs: false,
        roundTripEfficiency: 89.9,
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'urlRoot',
        'selectOptions',
        'year',
        'portfolioRun',
        'portfolio'
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },

      updateRoundTripEfficiency: function() {
        var rectifier = (this.portfolioRun.site.storage.rectifier_efficiency_fraction / 100.0) || 0.96
        var internal = (this.portfolioRun.site.storage.internal_efficiency_fraction / 100.0) || 0.975
        var inverter = (this.portfolioRun.site.storage.inverter_efficiency_fraction / 100.0) || 0.96
        this.roundTripEfficiency = rectifier * internal * inverter * 100;
      },

      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: 'storage',
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },

      varyAttributeBySite: function(action, attr) {
        if (action && this.portfolioRun.site.storage[attr]) {
          this.updateInputValueForRuns(attr, null);
          this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'storage', attribute: attr, value: null });
        } else if (!action) {
          this.updateInputValueForRuns(attr, null);
        }
      },

      varySelectAttributeBySite: function(action, attr) {
        if (attr == 'can_grid_charge') {
          if (action && !this.portfolioRun.site.storage[attr]) {
            this.updateInputValueForRuns(attr, true);
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'storage', attribute: attr, value: true });
          } else if (!action) {
            this.updateInputValueForRuns(attr, true);
          }
        } else if (attr == 'macrs_option_years') {
          if (action && this.portfolioRun.site.storage[attr] !== 7) {
            this.updateInputValueForRuns(attr, 7);
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'storage', attribute: attr, value: 7 });
          } else if (!action) {
            this.updateInputValueForRuns(attr, 7);
          }
        } else if (attr == 'macrs_bonus_fraction') {
          if (action && this.portfolioRun.site.storage[attr] !== 0.6) {
            this.updateInputValueForRuns(attr, 0.6);
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'storage', attribute: attr, value: 0.6 });
          } else if (!action) {
            this.updateInputValueForRuns(attr, 0.6);
          }
        }
      }
    },
  }
</script>