<template>
  <tbody>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_wind_size_class">
            {{ labels.site.wind.size_class }}
          </label>
          <tooltip
            association_1="site"
            association_2='wind'
            attribute="size_class" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_wind_size_class">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="wind"
            attribute='size_class'
            :index="index"
            :run="run"
            v-on:input-change="updatePlaceholders(run, index)" />
        </div>
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label">
          <label class="string optional" for="run_site_wind_installed_cost_per_kw">
            {{ labels.site.wind.installed_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='wind'
            attribute="installed_cost_per_kw" />
        </div>
      </th>
      <td class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="wind"
          attribute='installed_cost_per_kw'
          :index="index"
          :run="run" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_wind_min_kw">
            {{ labels.site.wind.min_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='wind'
            attribute="min_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMinKwBySite"
            id="toggle_min_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyMinKwBySite, 'min_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_min_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMinKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="wind"
          attribute='min_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('min_kw', portfolioRun.site.wind.min_kw)" />
      </td>
      <td v-if="varyMinKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="wind"
          attribute='min_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_wind_max_kw">
            {{ labels.site.wind.max_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='wind'
            attribute="max_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMaxKwBySite"
            id="toggle_max_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyMaxKwBySite, 'max_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_max_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMaxKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="wind"
          attribute='max_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('max_kw', portfolioRun.site.wind.max_kw)" />
      </td>
      <td v-if="varyMaxKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="wind"
          attribute='max_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr>
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <div class="row section-actions">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="toggle-more-inputs pull-left">
              <a @click="toggleAdvancedInputs();">
                <span v-if="displayAdvancedInputs">
                  <font-awesome-icon icon='minus-square' />
                  Show fewer inputs
                </span>
                <span v-else>
                  <font-awesome-icon icon='plus-square' />
                  Advanced inputs
                </span>
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Wind Costs</h4>
      </td>
    </tr>
    <br>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_wind_om_cost_per_kw">
            {{ labels.site.wind.om_cost_per_kw }}
          </label>
          <tooltip
            association_1="site"
            association_2='wind'
            attribute="om_cost_per_kw" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyOmCostPerKwBySite"
            id="toggle_om_cost_per_kw"
            type="checkbox"
            @change="varyAttributeBySite(varyOmCostPerKwBySite, 'om_cost_per_kw')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_om_cost_per_kw">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyOmCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="wind"
          attribute='om_cost_per_kw'
          :run="portfolioRun"
          :index="index"
          :varyBySite="false"
          v-on:input-change="updateInputValueForRuns('om_cost_per_kw', portfolioRun.site.wind.om_cost_per_kw)" />
      </td>
      <td v-if="varyOmCostPerKwBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <portfolio-horizontal-string-input-nested-2
          association_1='site'
          association_2="wind"
          attribute='om_cost_per_kw'
          :run="run"
          :index="index"
          :varyBySite="true" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h4 style="font-weight: lighter;">Wind Incentives and Tax Treatment</h4>
        <h5 style="font-weight: lighter;">
          Capital Cost or System Size Based Incentives
          <tooltip 
            association_1="incentives" 
            attribute="capital_costs" />
        </h5>
        <a target="blank" style="font-size: 12px" href="http://www.dsireusa.org/">Database of state incentives for renewables</a>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td>
        <portfolio-incentives-table
          association_1='site'
          association_2='wind'
          :portfolioRun="portfolioRun" />
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h5 style="font-weight: lighter;">
          Production Based Incentives
          <a
            href="#"
            rel="tooltip"
            title="Federal, State, and Utility production incentives can be entered here. If there is more than one production based incentive offered, the combined value can be entered and should be discounted back to year one if the incentive duration differs. For more information, please see the <a href='/tool/reopt-user-manual.pdf#page=5' target='_blank'>help manual</a>.">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
          </a>
        </h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td>
        <portfolio-incentives-table-2
          association_1='site'
          association_2='wind'
          :portfolioRun="portfolioRun" />
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <td colspan="2" class="col-sm-3" style="text-align: left;">
        <h5 style="font-weight: lighter;">Tax Treatment</h5>
      </td>
    </tr>
    <br v-show="displayAdvancedInputs">
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_wind_macrs_option_years">
            {{ labels.site.wind.macrs_option_years }}
          </label>
          <tooltip
            association_1="site"
            association_2='wind'
            attribute="macrs_option_years" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMacrsOptionYearsBySite"
            id="toggle_macrs_option_years"
            type="checkbox"
            @change="varySelectAttributeBySite(varyMacrsOptionYearsBySite, 'macrs_option_years')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_macrs_option_years">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMacrsOptionYearsBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_wind_macrs_option_years">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="wind"
            attribute='macrs_option_years'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false" 
            v-on:input-change="updateInputValueForRuns('macrs_option_years', portfolioRun.site.wind.macrs_option_years)" />
        </div>
      </td>
      <td v-if="varyMacrsOptionYearsBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_wind_macrs_option_years">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="wind"
            attribute='macrs_option_years'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
    <tr v-show="displayAdvancedInputs">
      <th class="col-sm-3">
        <div class="control-label" style="margin-bottom: 0;">
          <label class="string optional" for="run_site_wind_macrs_bonus_fraction">
            {{ labels.site.wind.macrs_bonus_fraction }}
          </label>
          <tooltip
            association_1="site"
            association_2='wind'
            attribute="macrs_bonus_fraction" />
        </div>
        <div class="control-label" style="padding-top: 0; margin-right: 32px;">
          <input 
            v-model="varyMacrsBonusFractionBySite"
            id="toggle_macrs_bonus_fraction"
            type="checkbox"
            @change="varySelectAttributeBySite(varyMacrsBonusFractionBySite, 'macrs_bonus_fraction')" />
          <label style="font-weight: normal; margin-left: 4px;" for="toggle_macrs_bonus_fraction">
            Vary this input by site
          </label>
        </div>
      </th>
      <td v-if="!varyMacrsBonusFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_wind_macrs_option_years">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="wind"
            attribute='macrs_bonus_fraction'
            :run="portfolioRun"
            :index="index"
            :varyBySite="false" 
            v-on:input-change="updateInputValueForRuns('macrs_bonus_fraction', portfolioRun.site.wind.macrs_bonus_fraction)" />
        </div>
      </td>
      <td v-if="varyMacrsBonusFractionBySite" class="col-sm-3" v-for="(run, index) in portfolio.runs" :key="index">
        <div class="form-group select optional run_site_wind_macrs_bonus_fraction">
          <portfolio-horizontal-select-input-nested-2
            association_1='site'
            association_2="wind"
            attribute='macrs_bonus_fraction'
            :run="run"
            :index="index"
            :varyBySite="true" />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from './Tooltip';
  import PortfolioHorizontalStringInputNested2 from './inputs/PortfolioHorizontalStringInputNested2';
  import PortfolioHorizontalSelectInputNested2 from './inputs/PortfolioHorizontalSelectInputNested2';
  import PortfolioIncentivesTable from './inputs/PortfolioIncentivesTable';
  import PortfolioIncentivesTable2 from './inputs/PortfolioIncentivesTable2';

  export default {
    components: {
      Tooltip,
      PortfolioHorizontalStringInputNested2,
      PortfolioHorizontalSelectInputNested2,
      PortfolioIncentivesTable,
      PortfolioIncentivesTable2,
    },

    data: function() {
      return {
        displayAdvancedInputs: false,
        varyMinKwBySite: false,
        varyMaxKwBySite: false,
        varyOmCostPerKwBySite: false,
        varyMacrsOptionYearsBySite: false,
        varyMacrsBonusFractionBySite:false
      }
    },

    computed: {
      ...mapGetters([
        'labels',
        'tooltips',
        'urlRoot',
        'year',
        'portfolioRun',
        'portfolio',
        'runsPlaceholders'
      ]),
    },

    methods: {
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },

      updateInputValueForRuns: function(attr, value) {
        var self = this;
        this.portfolio.runs.forEach(function(run, i) {
          var payload = {
            association_1: 'site',
            association_2: 'wind',
            attribute: attr,
            value: value,
            index: i,
          }
          self.$store.commit('updateRunsValues', payload);
        })
      },

      varyAttributeBySite: function(action, attr) {
        if (action && this.portfolioRun.site.wind[attr]) {
          this.updateInputValueForRuns(attr, null);
          this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'wind', attribute: attr, value: null });
        } else if (!action) {
          this.updateInputValueForRuns(attr, null);
        }
      },

      varySelectAttributeBySite: function(action, attr) {
        if (attr == 'macrs_option_years') {
          if (action && this.portfolioRun.site.wind[attr] !== 5) {
            this.updateInputValueForRuns(attr, 5);
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'wind', attribute: attr, value: 5 });
          } else if (!action) {
            this.updateInputValueForRuns(attr, 5);
          }
        } else if (attr == 'macrs_bonus_fraction') {
          if (action && this.portfolioRun.site.wind[attr] !== 0.6) {
            this.updateInputValueForRuns(attr, 0.6);
            this.$store.commit('updatePortfolioValueNested2', { association_1: 'site', association_2: 'wind', attribute: attr, value: 0.6 });
          } else if (!action) {
            this.updateInputValueForRuns(attr, 0.6);
          }
        }
      },

      updatePlaceholders: function(run, index) {
        if(run.site.wind.size_class == "large") {
          this.runsPlaceholders[index].site.wind.installed_cost_per_kw = "$2,386"
        } else if(run.site.wind.size_class == "medium") {
          this.runsPlaceholders[index].site.wind.installed_cost_per_kw = "$3,137"
        } else if(run.site.wind.size_class == "commercial") {
          this.runsPlaceholders[index].site.wind.installed_cost_per_kw = "$4,760"
        } else if(run.site.wind.size_class == "residential") {
          this.runsPlaceholders[index].site.wind.installed_cost_per_kw = "$6,339"
        }
      },
    },

    watch: {

    },

    mounted: function() {
      var self = this;
      this.portfolio.runs.forEach(function(run, index) {
        self.updatePlaceholders(run, index);
      })
    }
  }
</script>
