<template>
  <div id="ghp-cop-performance" class="modal-mask">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="closeTable()" type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">GHP Coefficient of Performance (COP) Table</h4>
          </div>
          <div v-if="run.site.ghp.heat_pump_configuration == 'WSHP'">
            <div class="modal-body">
              <table>
                <thead>
                  <th>Entering Fluid Temperature (F&deg;)</th>
                  <th>Cooling COP (kWt/kWe)</th>
                  <th>Heating COP (kWt/kWe)</th>
                </thead>
                <tbody>
                  <tr>
                    <td class="fluid-temp">20</td>
                    <td class="cooling-cop">11.023</td>
                    <td class="heating-cop">3.351</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">30</td>
                    <td class="cooling-cop">11.023</td>
                    <td class="heating-cop">3.639</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">40</td>
                    <td class="cooling-cop">11.023</td>
                    <td class="heating-cop">4.161</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">50</td>
                    <td class="cooling-cop">10.481</td>
                    <td class="heating-cop">4.681</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">60</td>
                    <td class="cooling-cop">9.168</td>
                    <td class="heating-cop">5.081</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">70</td>
                    <td class="cooling-cop">7.263</td>
                    <td class="heating-cop">5.678</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">80</td>
                    <td class="cooling-cop">5.826</td>
                    <td class="heating-cop">6.047</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">90</td>
                    <td class="cooling-cop">4.803</td>
                    <td class="heating-cop">6.341</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">100</td>
                    <td class="cooling-cop">3.9</td>
                    <td class="heating-cop">6.341</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">110</td>
                    <td class="cooling-cop">3.279</td>
                    <td class="heating-cop">6.341</td>
                  </tr>
                  <tr>
                    <td class="fluid-temp">120</td>
                    <td class="cooling-cop">2.707</td>
                    <td class="heating-cop">6.341</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="run.site.ghp.heat_pump_configuration == 'WWHP'">
            <div class="modal-body" style='float: left; width: 50%;'>
              <table>
                <thead>
                  <tr>
                    <th colspan="4" style='background-color: #344d80;'>Cooling Heat Pump COP (kWt/kWe)</th>
                  </tr>
                  <th rowspan="2">Entering Fluid Temperature (&deg;F)</th>
                  <th colspan="3">Cooling Loop Set Point Temperature (&deg;F)</th>
                  <tr>
                    <th>40</th>
                    <th>50</th>
                    <th>60</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>50</th>
                    <td>6.9</td>
                    <td>8.0</td>
                    <td>9.1</td>
                  </tr>
                  <tr>
                    <th>60</th>
                    <td>6.2</td>
                    <td>7.2</td>
                    <td>8.3</td>
                  </tr>
                  <tr>
                    <th>70</th>
                    <td>5.6</td>
                    <td>6.5</td>
                    <td>7.4</td>
                  </tr>
                  <tr>
                    <th>80</th>
                    <td>4.9</td>
                    <td>5.8</td>
                    <td>6.6</td>
                  </tr>
                  <tr>
                    <th>90</th>
                    <td>4.3</td>
                    <td>5.1</td>
                    <td>5.8</td>
                  </tr>
                  <tr>
                    <th>100</th>
                    <td>3.6</td>
                    <td>4.4</td>
                    <td>5.0</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-body" style='float: left; width: 50%;'>
              <table>
                <thead>
                  <tr>
                    <th colspan="4" style='background-color: #344d80;'>Heating Heat Pump COP (kWt/kWe)</th>
                  </tr>
                  <th rowspan="2">Entering Fluid Temperature (&deg;F)</th>
                  <th colspan="3">Heating Loop Set Point Temperature (&deg;F)</th>
                  <tr>
                    <th>120</th>
                    <th>130</th>
                    <th>140</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>30</th>
                    <td>2.7</td>
                    <td>2.3</td>
                    <td>2.2</td>
                  </tr>
                  <tr>
                    <th>40</th>
                    <td>3.2</td>
                    <td>2.8</td>
                    <td>2.7</td>
                  </tr>
                  <tr>
                    <th>50</th>
                    <td>3.7</td>
                    <td>3.2</td>
                    <td>3.1</td>
                  </tr>
                  <tr>
                    <th>60</th>
                    <td>4.2</td>
                    <td>3.7</td>
                    <td>3.5</td>
                  </tr>
                  <tr>
                    <th>70</th>
                    <td>4.7</td>
                    <td>4.1</td>
                    <td>4.0</td>
                  </tr>
                  <tr>
                    <th>80</th>
                    <td>5.2</td>
                    <td>4.6</td>
                    <td>4.4</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  
  export default {
    computed: {
      ...mapGetters([
        'run',
      ])
    },

    methods: {
      closeTable: function() {
        this.$store.commit('updateObject', { object: 'showGHPCOPDefaultTable', value: false });
      }
    }
  }
</script>
