<template>
  <div id="new_run" class="simple_form run" name="run">
    <div v-if="submitError" class="row">
      <div class="alert alert-danger">
        <ul>
          <li v-for="error in submitError">
            <h4>
              {{error}}
            </h4>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h2>Step 0: Login and Gather Data</h2>
      </div>
    </div>
    <div class="row row-eql-height step-0-cards">
      <div class="col-sm-4">
        <div class="step-0-card">
          <div class="step-0-card-header">
            <p>Logging in (optional) enables you to:</p>
          </div>
          <div class="step-0-card-list">
            <div class="step-0-card-list-bullet">
              <i class="fa fa-caret-right" aria-hidden="true"></i>&nbsp;&nbsp;<p>save your evaluations</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="fa fa-caret-right" aria-hidden="true"></i>&nbsp;&nbsp;<p>create a custom electricity rate</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="fa fa-caret-right" aria-hidden="true"></i>&nbsp;&nbsp;<p>build a critical load profile</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="fa fa-caret-right" aria-hidden="true"></i>&nbsp;&nbsp;<p>manage typical and critical load profiles</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="fa fa-caret-right" aria-hidden="true"></i>&nbsp;&nbsp;<p>run a portfolio analysis</p><br>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="step-0-card">
          <div class="step-0-card-header step-0-fin-run-card">
            <p>Data needed for a <strong>Financial</strong> run:</p>
          </div>
          <div class="step-0-card-list">
            <div class="step-0-card-list-bullet">
              <i class="far fa-check-square" aria-hidden="true"></i>&nbsp;&nbsp;<p>location</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="far fa-check-square" aria-hidden="true"></i>&nbsp;&nbsp;<p>electricity rate</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="far fa-check-square" aria-hidden="true"></i>&nbsp;&nbsp;<p>load (interval data or building type)</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="far fa-check-square" aria-hidden="true"></i>&nbsp;&nbsp;<p>fuel cost (if CHP is modeled)</p><br>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="step-0-card">
          <div class="step-0-card-header">
            <p>Data needed for a <strong>Resilience</strong> run:</p>
          </div>
          <div class="step-0-card-list">
            <div class="step-0-card-list-bullet">
              <i class="far fa-check-square" aria-hidden="true"></i>&nbsp;&nbsp;<p>critical load assumptions</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="far fa-check-square" aria-hidden="true"></i>&nbsp;&nbsp;<p>outage duration</p><br>
            </div>
            <div class="step-0-card-list-bullet">
              <i class="far fa-check-square" aria-hidden="true"></i>&nbsp;&nbsp;<p>outage start date and time</p><br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h2>Step 1: Select Use Case <tooltip :attribute="'multi_run'"></tooltip></h2>
      </div>
    </div>
    <div class="choice-btns technology-btns focus-btns clean-energy-goals">
      <div id="tech-btns" class="choice-btns technology-btns">
        <label id="single-run" class="btn btn-lg btn-default" for="single_run">
          <span class="technology-checkbox">
            <input
              v-model="run.single_run"
              id="single_run"
              type="radio"
              name="run[single_run]"
              value="true"
              checked />
            <span>Single Site <font-awesome-icon icon='building' /></span>
          </span>
        </label>
        <label id="portfolio" class="btn btn-lg btn-default" for="multi_run" :disabled="!currentUser" style="width: 400px;">
          <span class="technology-checkbox">
            <input
              v-model="run.single_run"
              id="multi_run"
              type="radio"
              name="run[single_run]"
              value="false"
              :disabled="!currentUser" />
            <span>Portfolio/Sensitivity Analysis <font-awesome-icon icon='building' /><font-awesome-icon icon='building' /></span>
          </span>
        </label>
      </div>
    </div>

    <div v-if="run.single_run">
      <div class="row">
        <div class="col-sm-12">
          <h2 id="step_form_start">Step 2: Choose Your Energy Goals</h2>
        </div>
      </div>

      <div class="choice-btns technology-btns focus-btns clean-energy-goals">
        <div id="tech-btns" class="choice-btns technology-btns">
          <label @click="displayCostSavingsMessage($event)" class="btn btn-lg btn-default" for="cost_savings">
            <span class="technology-checkbox">
              <input
                type="checkbox"
                value="1"
                checked=true
                id="cost_savings" />
              <span>Cost Savings <font-awesome-icon icon='dollar-sign' /></span>
            </span>
          </label>
          <label class="btn btn-lg btn-default" for="resilience">
            <span class="technology-checkbox">
              <input
                v-model="run.focus"
                type="hidden"
                name="run[focus]" />
              <input
                v-model="run.focus"
                @click="verifyNoOffgrid($event)"
                type="checkbox"
                true-value="resilience"
                false-value="financial"
                id="resilience" />
              <span>Resilience <font-awesome-icon icon='shield-alt' /></span>
            </span>
          </label>
          <label class="btn btn-lg btn-default" for="clean_energy">
            <span class="technology-checkbox">
              <input
                v-model="run.clean_energy_targets"
                type="hidden"
                name="run[clean_energy_targets]" />
              <input
                v-model="run.clean_energy_targets"
                type="checkbox"
                id="clean_energy" />
              <span>Clean Energy <font-awesome-icon icon='globe-americas' /></span>
            </span>
          </label>
        </div>
      </div>

      <br/>
      <div class="row">
        <div class="col-sm-12">
          <h2>Step 3: Select Technologies to Evaluate</h2>
        </div>
      </div>
      <div class="choice-btns technology-btns" id="tech-btns">
        <label class="btn btn-lg btn-default" for="run_analyze_pv">
          <span class="technology-checkbox">
            <input
              name="run[analyze_pv]"
              type="hidden"
              value="0" />
            <input
              v-model="run.analyze_pv"
              type="checkbox"
              value="1"
              checked="checked"
              name="run[analyze_pv]"
              id="run_analyze_pv" />
            <span>PV <font-awesome-icon icon='sun' /></span>
          </span>
        </label>

        <label class="btn btn-lg btn-default" for="run_analyze_battery">
          <span class="technology-checkbox">
            <input
              name="run[analyze_battery]"
              type="hidden"
              value="0" />
            <input
              v-model="run.analyze_battery"
              type="checkbox"
              value="1"
              checked="checked"
              name="run[analyze_battery]"
              id="run_analyze_battery" />
            <span>Battery <font-awesome-icon icon='battery-full' /></span>
          </span>
        </label>

        <label class="btn btn-lg btn-default" for="run_grid">
          <span class="technology-checkbox">
            <input
              name="run[grid]"
              type="hidden"
              value="0" />
            <input
              v-model="run.grid"
              @click="verifyFinancialRun($event)"
              type="checkbox"
              value="1"
              checked="checked"
              name="run[grid]"
              id="run_grid" />
            <span>Grid <font-awesome-icon icon='bolt' /></span>
          </span>
        </label>

        <label
          v-if="run.focus === 'resilience'"
          class="btn btn-lg btn-default"
          for="run_analyze_generator"
          id="analyze-generator">
          <span class="technology-checkbox">
            <input
              name="run[analyze_generator]"
              type="hidden"
              value="0" />
            <input
              v-model="run.analyze_generator"
              type="checkbox"
              value="1"
              name="run[analyze_generator]"
              id="run_analyze_generator" />
            <span class="cws-label">Emergency<br> Generator</span>&nbsp;<font-awesome-icon icon='bolt' />
          </span>
        </label>

        <label
          v-if="!run.grid"
          class="btn btn-lg btn-default"
          for="run_analyze_generator"
          id="analyze-generator">
          <span class="technology-checkbox">
            <input
              name="run[analyze_generator]"
              type="hidden"
              value="0" />
            <input
              v-model="run.analyze_generator"
              type="checkbox"
              value="1"
              name="run[analyze_generator]"
              id="run_analyze_generator" />
            <span>Generator <font-awesome-icon icon='bolt' /></span>
          </span>
        </label>

        <label
          class="btn btn-lg btn-default"
          for="run_analyze_wind"
          id="analyze-wind">
          <span class="technology-checkbox">
            <input
              name="run[analyze_wind]"
              type="hidden"
              value="0" />
            <input
              v-model="run.analyze_wind"
              type="checkbox"
              value="1"
              name="run[analyze_wind]"
              id="run_analyze_wind" />
            <span>Wind <slot name="wind_icon"></slot></span>
          </span>
        </label>

        <label
          v-if="run.grid"
          class="btn btn-lg btn-default"
          for="run_analyze_chp"
          id="analyze-chp">
          <span class="technology-checkbox">
            <input
              name="run[analyze_chp]"
              type="hidden"
              value="0" />
            <input
              v-model="run.analyze_chp"
              @click="toggleChpPrimeGenCheckBox()"
              type="checkbox"
              value="1"
              name="run[analyze_chp]"
              id="run_analyze_chp" />
            <span>CHP <font-awesome-icon icon='industry' /></span>
          </span>
        </label>

        <label
          v-if="run.grid"
          class="btn btn-lg btn-default"
          for="run_analyze_prime_generator"
          id="analyze-prime-gen">
          <span class="technology-checkbox">
            <input
              name="run[analyze_prime_generator]"
              type="hidden"
              value="0" />
            <input
              v-model="run.analyze_prime_generator"
              @click="toggleChpPrimeGenCheckBox()"
              type="checkbox"
              value="1"
              name="run[analyze_prime_generator]"
              id="run_analyze_prime_generator" />
            <span class="cws-label">Prime<br> Generator</span>&nbsp;<font-awesome-icon icon='bolt' />
          </span>
        </label>

        <label
          v-if="run.grid"
          class="btn btn-lg btn-default"
          for="run_analyze_cws"
          id="analyze-cws">
          <span class="technology-checkbox">
            <input
              name="run[analyze_cws]"
              type="hidden"
              value="0" />
            <input
              v-model="run.analyze_cws"
              type="checkbox"
              value="1"
              name="run[analyze_cws]"
              id="run_analyze_cws" />
            <span class="cws-label">Chilled Water<br> Storage</span>&nbsp;<font-awesome-icon icon='snowflake' />
          </span>
        </label>

        <label
          v-if="run.grid"
          class="btn btn-lg btn-default"
          for="run_analyze_ghp"
          id="analyze-ghp">
          <span class="technology-checkbox">
            <input
              name="run[analyze_ghp]"
              type="hidden"
              value="0" />
            <input
              v-model="run.site.boiler.existing_boiler_production_type"
              name="run[site_attributes][boiler_attributes][existing_boiler_production_type]"
              type="hidden"
              value="hot_water" />
            <input
              v-model="run.analyze_ghp"
              type="checkbox"
              value="1"
              name="run[analyze_ghp]"
              id="run_analyze_ghp" />
            <span class="cws-label">Geothermal<br> Heat Pump</span>&nbsp;<i class="fas fa-globe" aria-hidden="true"></i>
          </span>
        </label>

        <label
            v-if="run.grid"
            class="btn btn-lg btn-default"
            for="run_analyze_ashp_space"
            id="analyze-ashp_space"
            style="width: 220px;"
        >
          <span class="technology-checkbox">
            <input
              v-model="run.analyze_ashp_space"
              type="checkbox"
              value="1"
              name="run[analyze_ashp_space]"
              id="run_analyze_ashp_space"
            />
            <span id="inputs-ashp-icon" class="tech-opt-label">Air-Source<br>Heat Pump (Beta)&nbsp;</span><slot name="ashp_icon"></slot>
          </span>
        </label>
      </div>

      <div
        v-if="run.analyze_chp"
        class="technology-btns"
        id="chp-techs"
      >
        <div class="boiler-type-group">
          <label is="p" for="run[site_attributes][boiler_attributes][existing_boiler_production_type]">
            Existing boiler type and assumed CHP thermal production type
            <tooltip
              :association_1="'site'"
              :association_2="'boiler'"
              :attribute="'existing_boiler_production_type'" />
          </label>

          <div class="form-group select optional run_site_boiler_existing_boiler_production_type">
            <div class="col-sm-4">
              <select
                v-model="run.site.boiler.existing_boiler_production_type"
                class="form-control select optional"
                name="run[site_attributes][boiler_attributes][existing_boiler_production_type]"
              >
                <option selected="selected" value="hot_water">Hot water</option>
                <option value="steam">Steam</option>
              </select>
            </div>
          </div>
          <br>
        </div>

        <div class="tech-options" id="chp-techs">
          <div class="tech-options__header">
            <p>
              CHP technologies to evaluate
              <tooltip
                :association_1="'site'"
                :association_2="'chp'"
                :attribute="'techs_to_evaluate'"
              />
            </p>
          </div>

          <div class="tech-options__item" v-if="run.site.boiler.existing_boiler_production_type !== 'steam'">
            <label class="btn btn-lg btn-default" for="run_site_attributes_chp_attributes_analyze_hws" id="analyze-hws">
              <span class="technology-checkbox">
                <input
                    name="run[site_attributes][chp_attributes][analyze_hws]"
                    type="hidden"
                    value="0"
                />
                <input
                    v-model="run.site.chp.analyze_hws"
                    type="checkbox"
                    value="1"
                    name="run[site_attributes][chp_attributes][analyze_hws]"
                    id="run_site_attributes_chp_attributes_analyze_hws" />
                <span class="cws-label">Hot Water<br>Storage</span>
                <font-awesome-icon icon='thermometer-full' size="lg" />
              </span>
            </label>
          </div>

          <div class="tech-options__item">
            <label class="btn btn-lg btn-default" for="run_site_attributes_chp_attributes_analyze_abs_chiller" id="analyze-abs-chiller">
              <span class="technology-checkbox">
                <input
                    name="run[site_attributes][chp_attributes][analyze_abs_chiller]"
                    type="hidden"
                    value="0"
                />
                <input
                    v-model="run.site.chp.analyze_abs_chiller"
                    type="checkbox"
                    value="1"
                    name="run[site_attributes][chp_attributes][analyze_abs_chiller]"
                    id="run_site_attributes_chp_attributes_analyze_abs_chiller"
                />
                <span class="cws-label">Absorption<br>Chiller</span>
                <font-awesome-icon icon='thermometer-empty' size="lg" />
              </span>
            </label>
          </div>
        </div>
      </div>

      <div v-if="run.analyze_ashp_space" class="technology-btns" id="ashp-techs">
        <div class="tech-options">
          <div class="tech-options__header">
            <p>
              Also evaluate ASHP water heater to serve the domestic hot water load:
              <tooltip
                :association_1="'site'"
                :association_2="'ashp'"
                :attribute="'ashp_water_heater'"
              />
            </p>
          </div>

          <div class="tech-options__item">
            <label class="btn btn-lg btn-default" for="run_analyze_ashp_water" id="analyze_ashp_water">
              <span id="ashp-water-icon" class="technology-checkbox">
                <input
                  name="run[analyze_ashp_water]"
                  type="hidden"
                  value="0"
                />
                <input
                  v-model="run.analyze_ashp_water"
                  type="checkbox"
                  value="1"
                  name="run[analyze_ashp_water]"
                  id="run_analyze_ashp_water"
                />
                <span class="tech-opt-label">ASHP Water<br>Heater</span>&nbsp;<slot name="ashp_icon"></slot>
              </span>
            </label>
          </div>
          <br><br>
        </div>
        
        <div class="boiler-type-group">
          <label is="p" for="run[site_attributes][ashp_space_heater_attributes][ashp_configuration]">
            Air-Source Heat Pump configuration
            <tooltip
              :association_1="'site'"
              :association_2="'ashp'"
              :attribute="'ashp_configuration'"
            />
          </label>

          <div class="form-group select optional">
            <div class="col-sm-4">
              <select
                v-model.number="run.site.ashp_space_heater.ashp_configuration"
                class="form-control select optional"
                name="run[site_attributes][ashp_space_heater_attributes][ashp_configuration]"
                style="width: 90%;"
              >
                <option value="1" selected="selected">1: Hybrid heating</option>
                <option value="2">2: Full replacement heating</option>
                <option value="3">3: Hybrid heating and cooling</option>
                <option value="4">4: Full replacement heating and cooling</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <br/>
      <div class="row">
        <div class="col-sm-12">
          <h2>Step 4: Enter Your Site Data</h2>
        </div>
      </div>
      <div class="panel-group" id="step3-accordion" role="tablist" aria-multiselectable="true">
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="site_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#site" aria-expanded="true" aria-controls="site"><font-awesome-icon icon='map-marker-alt' />&nbsp;{{ sectionHeaders.site }} <span class="section-required">(required)</span></a>
            </h3>
          </div>
          <div id="site" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="site_heading">
            <div class="panel-body">
              <site-form
                ref="siteForm" />
            </div>
          </div>
        </div>
        <div v-if="run.grid" class="panel panel-default">
          <div class="panel-heading" role="tab" id="utility_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#utility" aria-expanded="true" aria-controls="utility"><font-awesome-icon icon='plug' />&nbsp;{{ sectionHeaders.utility }} <span class="section-required">(required)</span></a>
            </h3>
          </div>
          <div id="utility" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="utility_heading">
            <div class="panel-body">
              <utility-form
                v-on:update-emissions-defaults-ghp-conductivity="updateEmissionsDefaultsAndGHPGroundConductivity()"
                v-on:update-ghp-defaults="updateGHPDefaults()" />
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="load_profile_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#load_profile" aria-expanded="true" aria-controls="load_profile"><font-awesome-icon icon='signal' />&nbsp;{{ sectionHeaders.load_profile }} <span class="section-required">(required)</span></a>
            </h3>
          </div>
          <div id="load_profile" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="load_profile_heading">
            <div class="panel-body">
              <load-profile-form
                v-on:update-ghp-defaults="updateGHPDefaults()"
                v-on:update-ghp-heat-to-cool="updateGHPRatioHeatingToCooling()" />
            </div>
          </div>
        </div>
        <div v-if="run.focus === 'resilience'" class="panel panel-default">
          <div class="panel-heading" role="tab" id="res_section_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#res_section" aria-expanded="true" aria-controls="res_section" class="collapsed"><font-awesome-icon icon='shield-alt' />&nbsp;{{ sectionHeaders.resilience }} <span class="section-required">(required)</span></a>
            </h3>
          </div>
          <div id="res_section" class="panel-collapse collapse" role="tabpanel" aria-labelledby="res_section_heading">
            <div class="panel-body">
              <resilience-form />
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="financial_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#financial" aria-expanded="true" aria-controls="financial" class="collapsed"><font-awesome-icon icon='dollar-sign' />&nbsp;{{ sectionHeaders.financial }}</a>
            </h3>
          </div>
          <div id="financial" class="panel-collapse collapse" role="tabpanel" aria-labelledby="financial_heading">
            <div class="panel-body">
              <financial-form />
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="emissions_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#emissions" aria-expanded="true" aria-controls="emissions" class="collapsed"><font-awesome-icon icon='fire' />&nbsp;{{ sectionHeaders.emissions }}</a>
            </h3>
          </div>
          <div id="emissions" class="panel-collapse collapse" role="tabpanel" aria-labelledby="emissions_heading">
            <div class="panel-body">
              <emissions-form />
            </div>
          </div>
        </div>
        <div v-if="run.clean_energy_targets" class="panel panel-default">
          <div class="panel-heading" role="tab" id="clean_energy_targets_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#clean-energy" aria-expanded="true" aria-controls="clean-energy" class="collapsed"><i class="fas fa-globe-americas" aria-hidden="true"></i>&nbsp;{{ sectionHeaders.clean_energy }}</a>
            </h3>
          </div>
          <div id="clean-energy" class="panel-collapse collapse" role="tabpanel" aria-labelledby="clean_energy_targets_heading">
            <div class="panel-body">
              <clean-energy-form />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_pv" class="panel panel-default">
          <div class="panel-heading" role="tab" id="pv_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#pv" aria-expanded="true" aria-controls="pv" class="collapsed"><font-awesome-icon icon='sun' />&nbsp;{{ sectionHeaders.pv }}</a>
            </h3>
          </div>
          <div id="pv" class="panel-collapse collapse" role="tabpanel" aria-labelledby="pv_heading">
            <div class="panel-body">
              <pv-form ref="pvForm" />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_battery" class="panel panel-default">
          <div class="panel-heading" role="tab" id="battery_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#battery" aria-expanded="true" aria-controls="battery" class="collapsed"><font-awesome-icon icon='battery-full' />&nbsp;{{ sectionHeaders.battery }}</a>
            </h3>
          </div>
          <div id="battery" class="panel-collapse collapse" role="tabpanel" aria-labelledby="battery_heading">
            <div class="panel-body">
              <storage-form />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_wind" class="panel panel-default">
          <div class="panel-heading" role="tab" id="wind_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#wind" aria-expanded="true" aria-controls="wind" class="collapsed"><slot name="wind_icon"></slot>&nbsp;{{ sectionHeaders.wind }}</a>
            </h3>
          </div>
          <div id="wind" class="panel-collapse collapse" role="tabpanel" aria-labelledby="wind_heading">
            <div class="panel-body">
              <wind-form />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_generator && run.focus === 'resilience'" class="panel panel-default">
          <div class="panel-heading" role="tab" id="generator_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#generator" aria-expanded="true" aria-controls="generator" class="collapsed"><font-awesome-icon icon='bolt' />&nbsp;{{ sectionHeaders.emergency_generator }}</a>
            </h3>
          </div>
          <div id="generator" class="panel-collapse collapse" role="tabpanel" aria-labelledby="generator_heading">
            <div class="panel-body">
              <generator-form />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_generator && !run.grid" class="panel panel-default">
          <div class="panel-heading" role="tab" id="generator_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#generator" aria-expanded="true" aria-controls="generator" class="collapsed"><font-awesome-icon icon='bolt' />&nbsp;{{ sectionHeaders.generator }}</a>
            </h3>
          </div>
          <div id="generator" class="panel-collapse collapse" role="tabpanel" aria-labelledby="generator_heading">
            <div class="panel-body">
              <generator-form />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_chp" class="panel panel-default">
          <div class="panel-heading" role="tab" id="chp_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#chp" aria-expanded="true" aria-controls="chp" class="collapsed"><font-awesome-icon icon='industry' />&nbsp;{{ sectionHeaders.chp }}</a>
            </h3>
          </div>
          <div id="chp" class="panel-collapse collapse" role="tabpanel" aria-labelledby="chp_heading">
            <div class="panel-body">
              <chp-form
                v-on:update-chp-defaults="updateCHPDefaults('chp')" />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_prime_generator" class="panel panel-default">
          <div class="panel-heading" role="tab" id="prime_generator_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#prime_generator" aria-expanded="true" aria-controls="prime_generator" class="collapsed"><font-awesome-icon icon='bolt' />&nbsp;{{ sectionHeaders.prime_generator }}</a>
            </h3>
          </div>
          <div id="prime_generator" class="panel-collapse collapse" role="tabpanel" aria-labelledby="prime_generator_heading">
            <div class="panel-body">
              <prime-gen-form
                v-on:update-prime-generator-defaults="updateCHPDefaults('prime_generator')" />
            </div>
          </div>
        </div>

        <div v-if="run.site.chp.analyze_hws" class="panel panel-default">
          <div class="panel-heading" role="tab" id="hws_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#hws" aria-expanded="true" aria-controls="hws" class="collapsed"><font-awesome-icon icon='thermometer-full' size="lg" />&nbsp;{{ sectionHeaders.hws }}</a>
            </h3>
          </div>
          <div id="hws" class="panel-collapse collapse" role="tabpanel" aria-labelledby="hws_heading">
            <div class="panel-body">
              <hws-form />
            </div>
          </div>
        </div>

        <div v-if="run.site.chp.analyze_abs_chiller" class="panel panel-default">
          <div class="panel-heading" role="tab" id="abs_chiller_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#abs_chiller" aria-expanded="true" aria-controls="abs_chiller" class="collapsed"><font-awesome-icon icon='thermometer-empty' size="lg" />&nbsp;{{ sectionHeaders.abs_chiller }}</a>
            </h3>
          </div>
          <div id="abs_chiller" class="panel-collapse collapse" role="tabpanel" aria-labelledby="abs_chiller_heading">
            <div class="panel-body">
              <abs-chiller-form />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_cws" class="panel panel-default">
          <div class="panel-heading" role="tab" id="cws_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#cws" aria-expanded="true" aria-controls="cws" class="collapsed"><font-awesome-icon icon='thermometer-empty' size="lg" />&nbsp;{{ sectionHeaders.cws }}</a>
            </h3>
          </div>
          <div id="cws" class="panel-collapse collapse" role="tabpanel" aria-labelledby="cws_heading">
            <div class="panel-body">
              <cws-form />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_ghp" class="panel panel-default">
          <div class="panel-heading" role="tab" id="ghp_heading">
            <h3 class="panel-title">
              <a role="button" data-toggle="collapse" href="#ghp" aria-expanded="true" aria-controls="ghp" class="collapsed"><i class="fas fa-globe" aria-hidden="true"></i>&nbsp;{{ sectionHeaders.ghp }}</a>
            </h3>
          </div>
          <div id="ghp" class="panel-collapse collapse" role="tabpanel" aria-labelledby="ghp_heading">
            <div class="panel-body">
              <ghp-form
                ref='ghpForm'/>
            </div>
          </div>
        </div>

        <div v-if="run.analyze_ashp_space" class="panel panel-default">
          <div class="panel-heading" role="tab" id="ashp_space_heading">
            <h3 class="panel-title">
              <a id="ashp-accordion-icon" role="button" data-toggle="collapse" href="#ashp_space" aria-expanded="true" aria-controls="ashp_space" class="collapsed"><slot name="ashp_icon"></slot>&nbsp;{{ sectionHeaders.ashp_space_heater }}</a>
            </h3>
          </div>
          <div id="ashp_space" class="panel-collapse collapse" role="tabpanel" aria-labelledby="ashp_space_heading">
            <div class="panel-body">
              <ashp-space-heater-form />
            </div>
          </div>
        </div>

        <div v-if="run.analyze_ashp_water" class="panel panel-default">
          <div class="panel-heading" role="tab" id="ashp_water_heading">
            <h3 class="panel-title">
              <a id="ashp-accordion-icon" role="button" data-toggle="collapse" href="#ashp_water" aria-expanded="true" aria-controls="ashp_water" class="collapsed"><slot name="ashp_icon"></slot>&nbsp;{{ sectionHeaders.ashp_water_heater }}</a>
            </h3>
          </div>
          <div id="ashp_water" class="panel-collapse collapse" role="tabpanel" aria-labelledby="ashp_water_heading">
            <div class="panel-body">
              <ashp-water-heater-form />
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-bottom:15px;">
        <div class="col-sm-12 reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>

      <div class="sticky-submit">
        <div class="text-right">
          <button @click="submitForm($event)" id="site-profile-submit" type="submit" class="btn btn-primary btn-lg" data-loading-text="Loading...">
            Get Results <font-awesome-icon icon='arrow-circle-right' />
          </button>
        </div>
      </div>
    </div>

    <div v-if="!run.single_run">
      <portfolio-run-form/>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AbsChillerForm from './AbsChillerForm';
  import AshpSpaceHeaterForm from './AshpSpaceHeaterForm';
  import AshpWaterHeaterForm from './AshpWaterHeaterForm';
  import ChpForm from './ChpForm';
  import CleanEnergyForm from './CleanEnergyForm';
  import CwsForm from './CwsForm';
  import EmissionsForm from './EmissionsForm';
  import FinancialForm from './FinancialForm';
  import GeneratorForm from './GeneratorForm';
  import GhpForm from './GhpForm';
  import HwsForm from './HwsForm';
  import LoadProfileForm from './LoadProfileForm';
  import PortfolioRunForm from './PortfolioRunForm'
  import PrimeGenForm from './PrimeGenForm.vue';
  import PvForm from './PvForm';
  import ResilienceForm from './ResilienceForm';
  import SiteForm from './SiteForm';
  import StorageForm from './StorageForm';
  import Tooltip from './Tooltip';
  import UtilityForm from './UtilityForm';
  import WindForm from './WindForm';

  export default {
    components: {
      AbsChillerForm,
      AshpSpaceHeaterForm,
      AshpWaterHeaterForm,
      ChpForm,
      CleanEnergyForm,
      CwsForm,
      EmissionsForm,
      FinancialForm,
      GeneratorForm,
      GhpForm,
      HwsForm,
      LoadProfileForm,
      PortfolioRunForm,
      PrimeGenForm,
      PvForm,
      ResilienceForm,
      SiteForm,
      UtilityForm,
      StorageForm,
      Tooltip,
      WindForm,
    },

    data: function() {
      return {}
    },

    computed: {
      ...mapGetters([
        'currentUser',
        'csrfToken',
        'run',
        'sectionHeaders',
        'showCHPMaxSizeButton',
        'elecLoadProfile',
        'elecSimBuildAnnualLoadProfile',
        'elecSimBuildMonthlyLoadProfile',
        'elecSimCampusLoadProfile',
        'elecUploadedLoadProfile',
        'thermalLoadProfile',
        'thermalSimBuildAnnualLoadProfile',
        'thermalSimBuildMonthlyLoadProfile',
        'thermalSimCampusLoadProfile',
        'thermalUploadedLoadProfile',
        'tooltips',
        'urlRoot',
        'year',
        'submitError'
      ]),
      watchCHPDependencies() {
        return this.run.site.boiler.existing_boiler_production_type,
        this.thermalSimBuildAnnualLoadProfile,
        this.thermalSimBuildMonthlyLoadProfile,
        this.thermalSimCampusLoadProfile,
        this.thermalUploadedLoadProfile,
        new Date();
      },
      watchPrimeGeneratorDependencies() {
        return this.elecSimBuildAnnualLoadProfile,
        this.elecSimBuildMonthlyLoadProfile,
        this.elecSimCampusLoadProfile,
        this.elecUploadedLoadProfile,
        new Date();
      },
    },

    methods: {
      displayCostSavingsMessage: function(event) {
        bootbox.alert("Cost Savings cannot be unselected as an energy goal. If other energy goals are chosen, REopt will optimize your solution to meet those goals in the most cost effective manner over the analysis period.");
        event.preventDefault();
      },
      updateOptimalityToleranceDefault: function() {
        if(this.run.analyze_chp) {
          this.$store.commit('updatePlaceholder', { attribute: 'optimality_tolerance_techs', value: "1.0%" });
        } else if(this.run.analyze_prime_generator) {
          this.$store.commit('updatePlaceholder', { attribute: 'optimality_tolerance_techs', value: "1.0%" });
        } else if(this.run.focus === "resilience" && this.run.site.load_profile.number_of_outages === 4) {
          this.$store.commit('updatePlaceholder', { attribute: 'optimality_tolerance_techs', value: "1.0%" });
        } else {
          this.$store.commit('updatePlaceholder', { attribute: 'optimality_tolerance_techs', value: "0.1%" });
        }
      },
      getCHPSizeClass: function(size, bounds) {
        var sizeClass;
        var length = bounds.length
        bounds.forEach(function(el, i) {
          if(i === 0) {
            // nothing
          } else if(i === (length - 1) && size >= el[1]) {
            sizeClass = i;
          } else if(size >= el[0] && size <= el[1]) {
            sizeClass = i;
          }
        })

        if(size >= bounds[length - 1][1]) {
          sizeClass = length - 1;
        } else if(!sizeClass) {
          sizeClass = 0;
        }
        return sizeClass;
      },
      formattedCHPMaxSize: function(chpMaxSize) {
        return this.$store.getters.formatAsIntegerWithCommas({ value: chpMaxSize, decimals: 0 });
      },
      verifyNoErrors: function(event) {
        if(document.getElementsByClassName('has-error').length > 0) {
          event.preventDefault();
          document.querySelector('#step3-accordion').scrollIntoView({
            behavior: 'smooth'
          });
          return false
        } else {
          return true
        }
      },
      verifyNoOffgrid: function(event) {
        if(this.run.grid) {
          return true;
        } else {
          event.preventDefault();
          bootbox.alert("Resilience cannot be selected without the Grid technology also being chosen. Please select the Grid technology in Step 2.");
        }
      },
      verifyFinancialRun: function(event) {
        if(this.run.focus === "resilience") {
          event.preventDefault();
          bootbox.alert("Grid must be selected for a Resilience analysis. Please unselect Resilience in Step 1.");
        }
      },
      toggleChpPrimeGenCheckBox: function() {
        if($('#run_analyze_chp')[0].checked) {
          $('#analyze-prime-gen').attr('disabled', true) && $('#run_analyze_prime_generator').attr('disabled', true);
        } else if($('#run_analyze_prime_generator')[0].checked) {
          $('#analyze-chp').attr('disabled', true) && $('#run_analyze_chp').attr('disabled', true);
        } else {
          $('#analyze-prime-gen').attr('disabled', false) && $('#run_analyze_prime_generator').attr('disabled', false);
          $('#analyze-chp').attr('disabled', false) && $('#run_analyze_chp').attr('disabled', false);
        }
      },
      updateCHPDefaults: function(type) {
        if(type === 'chp') {
          if(!this.run.site.chp.prime_mover_type || (!this.run.site.chp.size_class && this.run.site.chp.size_class != 0)) {
            return false;
          }
          if(this.run.site.chp.custom_prime_mover) {
            var params = {
              existing_boiler_production_type: this.run.site.boiler.existing_boiler_production_type,
              prime_mover: this.run.site.chp.prime_mover_type,
              size_class: this.run.site.chp.size_class,
            }
            if(this.run.site.chp.thermal_efficiency_full_load) {
              params['thermal_efficiency_full_load'] = this.run.site.chp.thermal_efficiency_full_load / 100;
            }
          } else {
            var params = { existing_boiler_production_type: this.run.site.boiler.existing_boiler_production_type };
            if(this.run.site.chp.thermal_efficiency_full_load) {
              params['thermal_efficiency_full_load'] = this.run.site.chp.thermal_efficiency_full_load / 100;
            }
          }
          var thermalLoadProfile = this.$store.getters.thermalLoadProfile;
          if(Object.keys(this[thermalLoadProfile]).length > 0) {
            params.avg_boiler_fuel_load_mmbtu_per_hour = this[thermalLoadProfile].meanMMBtu;
          }
        }

        if(type === 'prime_generator') {
          if(!this.run.site.prime_generator.prime_mover_type || (!this.run.site.prime_generator.size_class && this.run.site.prime_generator.size_class != 0)) {
            return false;
          }
          if(this.run.site.prime_generator.custom_prime_mover) {
            var params = {
              prime_mover: this.run.site.prime_generator.prime_mover_type,
              size_class: this.run.site.prime_generator.size_class,
              is_electric_only: true,
            }
          } else {
            var params = {};
          }
          var elecLoadProfile = this.$store.getters.elecLoadProfile;
          if(Object.keys(this[elecLoadProfile]).length > 0) {
            params.avg_electric_load_kw = this[elecLoadProfile].meanKw;
            params.max_electric_load_kw = this[elecLoadProfile].maxKw;
            params.is_electric_only = true;
          }
        }

        var integerInputs = [
          "min_allowable_kw",
        ];
        var percentInputs = [
          "cooling_thermal_factor",
          "electric_efficiency_full_load",
          "min_turn_down_fraction",
          "thermal_efficiency_full_load",
          "gearbox_generator_efficiency",
          "isentropic_efficiency",
          "federal_itc_fraction"
        ];
        var costInputs = [
          "om_cost_per_kw",
          "om_cost_per_kwh",
        ];

        var self = this;
        $.ajax({
          url: (type === 'chp') ? (self.urlRoot + '/chp-defaults') : (self.urlRoot + '/prime-generator-defaults'),
          contentType: 'application/json',
          data: params,
          success: function(data) {
            const default_inputs = data.default_inputs

            if(data.chp_elec_size_heuristic_kw) {
              self.$store.commit('updateValueNested2', { association_1: 'site', association_2: type, attribute: 'prime_mover_type', value: data.prime_mover });
              var sizeClass = self.getCHPSizeClass(data.chp_elec_size_heuristic_kw, data.size_class_bounds);
              self.$store.commit('updateValueNested2', { association_1: 'site', association_2: type, attribute: 'size_class', value: data.size_class });
              self.$store.commit('updateObject', { object: 'chpHeuristicSize', value: data.chp_elec_size_heuristic_kw });
              self.$store.commit('updateObject', { object: 'chpHeuristicSizeClass', value: sizeClass });
              if(data.chp_max_size_kw) {
                var sizeClassMax = self.formattedCHPMaxSize(data.chp_max_size_kw);
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'max_kw', value: sizeClassMax });
              }
            }

            if(data.prime_mover !== 'steam_turbine') {
              self.$store.commit('updateObject', { object: 'showCHPMaxSizeButton', value: true });
              for(const key in default_inputs) {
                if(percentInputs.includes(key)) {
                  var value = (default_inputs[key] * 100.0).toFixed(2) + '%';
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: key, value: value });
                } else if(integerInputs.includes(key)) {
                  var value = Number(default_inputs[key]).toFixed(0);
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: key, value: value });
                } else if(costInputs.includes(key)) {
                  var value = "$" + Number(default_inputs[key]).toFixed(3);
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: key, value: value });
                } else if(key === "tech_sizes_for_cost_curve") {
                  var value1 = default_inputs[key][0];
                  var value2 = default_inputs[key][1];
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: `${key}_1`, value: value1 });
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: `${key}_2`, value: value2 });
                } else if(key === "installed_cost_per_kw") {
                  var value1 = "$" + default_inputs[key][0];
                  var value2 = "$" + default_inputs[key][1];
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: `${key}_1`, value: value1 });
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: `${key}_2`, value: value2 });
                } else {
                  var value = default_inputs[key];
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: key, value: value });
                }
              }
              if(!("electric_efficiency_half_load" in default_inputs) && ("electric_efficiency_full_load" in default_inputs)) {
                const key_half = "electric_efficiency_half_load"
                const key_full = "electric_efficiency_full_load"
                var value = (default_inputs[key_full] * 100.0).toFixed(2) + '%';
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: key_half, value: value });
              }
              if(!("thermal_efficiency_half_load" in default_inputs) && ("thermal_efficiency_full_load" in default_inputs) && (type =='chp')) {
                const key_half = "thermal_efficiency_half_load"
                const key_full = "thermal_efficiency_full_load"
                var value = (default_inputs[key_full] * 100.0).toFixed(2) + '%';
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: key_half, value: value });
              }
            }

            if(data.prime_mover === 'steam_turbine') {
              self.$store.commit('updateObject', { object: 'showCHPMaxSizeButton', value: false });
              for(const key in default_inputs) {
                if(percentInputs.includes(key)) {
                  var value = (default_inputs[key] * 100.0).toFixed(2) + '%';
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, association_3: 'steam_turbine', attribute: key, value: value });
                } else if(costInputs.includes(key)) {
                  var value = "$" + Number(default_inputs[key]).toFixed(3);
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, association_3: 'steam_turbine', attribute: key, value: value });
                } else if(key === "installed_cost_per_kw") {
                  var value = "$" + Number(default_inputs[key]);
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, association_3: 'steam_turbine', attribute: key, value: value });
                } else {
                  var value = default_inputs[key];
                  self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, association_3: 'steam_turbine', attribute: key, value: value });
                }
              }
            }
          },
          error: function() {
            bootbox.alert('By default, microturbine and smaller reciprocating engine (size class 1-3) CHP cannot produce steam, so there is no valid heuristic size estimate for CHP to inform appropriate cost and performance default values. Input a "thermal efficiency at full load" (under advanced inputs) equal to something greater than zero, or go back up and select "hot water" for the existing boiler production type');
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'min_allowable_kw', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'max_kw', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'tech_sizes_for_cost_curve_1', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'tech_sizes_for_cost_curve_2', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'installed_cost_per_kw_1', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'installed_cost_per_kw_2', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'om_cost_per_kwh', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'electric_efficiency_full_load', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'electric_efficiency_half_load', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'thermal_efficiency_full_load', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'thermal_efficiency_half_load', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'min_turn_down_fraction', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'cooling_thermal_factor', value: null });
            self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: type, attribute: 'federal_itc_fraction', value: '0%' });
          }
        })
      },
      toggleOffGridDefaults: function() {
        if(this.run.grid) {
          var optimalityDefault = "0.1%";
          var storageSocInitDefault = "50%";
          var generatorOMkW = "$20";
          var genMinTurndown = "0%";
          if (this.run.site.generator.generator_only_runs_during_grid_outage) {
            var installedCost = "$800";
          } else {
            var installedCost = "$650";
          }
          // these values are now the same for grid/offgrid
          // var generatorFuelAvail = "Unlimited";
          // var landAvailLabel = "Land available for PV & Wind (acres)";
        } else {
          var optimalityDefault = "5%";
          var storageSocInitDefault = "100%";
          var generatorOMkW = "$10";
          var genMinTurndown = "15%";
          var installedCost = "$880"
          // these values are now the same for grid/offgrid
          // var generatorFuelAvail = "Unlimited";
          // var landAvailLabel = "Land available for PV (acres)";
        }

        this.$store.commit('updatePlaceholder', { attribute: 'optimality_tolerance_techs', value: optimalityDefault });
        this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'storage', attribute: 'soc_init_fraction', value: storageSocInitDefault });
        this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'generator', attribute: 'om_cost_per_kw', value: generatorOMkW });
        this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'generator', attribute: 'min_turn_down_fraction', value: genMinTurndown });
        this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'generator', attribute: 'installed_cost_per_kw', value: installedCost });
        // this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'generator', attribute: 'fuel_available_gal', value: generatorFuelAvail });
        // this.$store.commit('updateLabel', { association_1: 'site', attribute: 'land_acres', value: landAvailLabel });
      },
      resetInputs: function() {
        if(confirm("Are you sure you want to reset the inputs in the entire form to their default values?")) {
          window.location = this.urlRoot;
        }
      },
      updateGHPDefaults: function() {
        if(this.run.analyze_ghp) {
          this.$refs.ghpForm.updateDefaults();
        }
      },
      updateGHPRatioHeatingToCooling: function() {
        if(this.run.analyze_ghp) {
          this.$refs.ghpForm.updateRatioHeatingToCooling();
        }
      },
      updateEmissionsDefaultsAndGHPGroundConductivity: function() {
        if (this.run.site.address) {
          this.$refs.siteForm.updateEmissionsDefaults();
          this.$refs.siteForm.updateHealthEmissionsDefaults();
          this.$refs.siteForm.updateGHPGroundConductivity();
        }
      },
      submitForm: function(event) {
        if (this.verifyNoErrors(event) === true) {
          $.ajax({
            url: this.$store.state.urlRoot + '/results',
            type: 'POST',
            data: JSON.stringify(this.$store.state.run),
            dataType: 'json',
            contentType: 'json',
            // processData: false,
            success: function(data) {
              window.location.href = (`${this.url}/${data.run_group_id}`)
            },
            error: data => {
              // arrow function required here to have $store access
              this.$store.commit('updateSubmitError', data.responseJSON.errors)
              window.scrollTo({top: 700, behavior: 'smooth'});
            }
          })
        } else {
          console.log("Validation Errors")
        }
      }
    },

    watch: {
      'run.focus': function() {
        // turn off generator for financial analysis
        if (this.run.focus === 'financial') {
          this.$store.commit('updateValue', { attribute: 'analyze_generator', value: false });
        }
        if (this.run.focus === 'resilience') {
          this.updateOptimalityToleranceDefault();
        }
      },
      'run.single_run': function() {
        if($('#multi_run').is(':checked')) {
          this.$store.commit('updateValue', { attribute: 'single_run', value: false });
          this.$store.commit('updatePortfolioValue', { attribute: 'single_run', value: false });
        } else {
          this.$store.commit('updateValue', { attribute: 'single_run', value: true });
        }
      },
      'run.grid': function() {
        this.toggleOffGridDefaults();

        if(!this.run.grid) {
          // turn off techs not supported for off grid analysis
          this.$store.commit('updateValue', { attribute: 'analyze_wind', value: false });
          this.$store.commit('updateValue', { attribute: 'analyze_chp', value: false });
          this.$store.commit('updateValue', { attribute: 'analyze_cws', value: false });
          this.$store.commit('updateValue', { attribute: 'analyze_ghp', value: false });
          this.$store.commit('updateValue', { attribute: 'analyze_ashp_space', value: false });
          this.$store.commit('updateValue', { attribute: 'analyze_ashp_water', value: false });
          this.$store.commit('updateValue', { attribute: 'analyze_prime_generator', value: false });
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'chp', attribute: 'analyze_hws', value: false });
          this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'chp', attribute: 'analyze_abs_chiller', value: false });
        } else {
          // turn off generator which is not supported for grid and financial analysis
          // reset wind to unchecked when grid is selected
          this.$store.commit('updateValue', { attribute: 'analyze_generator', value: false });
          this.$store.commit('updateValue', { attribute: 'analyze_wind', value: false });
        }
      },
      'run.analyze_generator': function() {
        if (!this.run.analyze_generator && this.run.existing_diesel_generator) {
          this.$store.commit('updateValue', { attribute: 'existing_diesel_generator', value: false });
        }
      },
      'run.analyze_chp': function(event) {
        // odd Vue.js-ness going on - but this is needed b/c this watcher fires
        // when run.analyze_generator is clicked
        if(this.run.grid) {
          this.updateOptimalityToleranceDefault();
        }
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'generator', attribute: 'generator_only_runs_during_grid_outage', value: false });
      },
      'run.analyze_prime_generator': function() {
        if(this.run.grid) {
          this.updateOptimalityToleranceDefault();
        }
        this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'generator', attribute: 'generator_only_runs_during_grid_outage', value: false });
      },
      'run.clean_energy_targets': function() {
        if (!this.run.clean_energy_targets) {
          if(this.run.clean_energy_target !== 're') this.$store.commit('updateValue', { attribute: 'clean_energy_target', value: 're' });
          if (this.run.site.CO2_emissions_reduction_min_fraction || this.run.site.CO2_emissions_reduction_max_fraction || this.run.site.renewable_electricity_min_fraction || this.run.site.renewable_electricity_max_fraction) {
            this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'CO2_emissions_reduction_min_fraction', value: null });
            this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'CO2_emissions_reduction_max_fraction', value: null });
            this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'renewable_electricity_min_fraction', value: null });
            this.$store.commit('updateValueNested1', { association_1: 'site', attribute: 'renewable_electricity_max_fraction', value: null });
          }
        }
      },
      watchPrimeGeneratorDependencies: function() {
        if(this.run.analyze_prime_generator) {
          //  if chp had previously been selected with custom prime mover, must remove the chp custom_prime_mover value or else the disable select fields doesn't work
          if (this.run.site.chp.custom_prime_mover) {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'chp', attribute: 'custom_prime_mover', value: null });
          }
          this.updateCHPDefaults('prime_generator');
        }
      },
      watchCHPDependencies: function() {
        if(this.run.analyze_chp) {
          //  if prime gen had previously been selected with custom prime mover, must remove the prime gen custom_prime_mover value or else the disable select fields doesn't work
          if (this.run.site.prime_generator.custom_prime_mover) {
            this.$store.commit('updateValueNested2', { association_1: 'site', association_2: 'prime_generator', attribute: 'custom_prime_mover', value: null });
          }
          this.updateCHPDefaults('chp');
        }
      },
      //TODO Check this watch function! What does this do?
      'run.site.boiler.existing_boiler_production_type': function() {
        if(this.run.site.chp.analyze_hws) {
          this.$store.commit('updateValueNested2', { association_1: "site", association_2: "chp", attribute: "analyze_hws", value: false });
        }
      },
    },

    mounted: function() {
      this.toggleOffGridDefaults();
      this.toggleChpPrimeGenCheckBox();
    }
  }
</script>
