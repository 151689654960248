<template>
  <div class="form-horizontal">
    <horizontal-select-input-nested-2
      association_1='site'
      association_2='ghp'
      attribute='require_ghp_purchase' />
    <horizontal-select-input-nested-2
      association_1='site'
      association_2='ghp'
      attribute='heat_pump_configuration' />
    <horizontal-select-input-nested-2
      association_1='site'
      association_2='ghp'
      attribute='hybrid_ghx_sizing_method' />
    <div v-if="run.site.ghp.heat_pump_configuration == 'WSHP'">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='ghp'
        attribute='installed_cost_heatpump_per_ton' />
    </div>
    <div v-else-if="run.site.ghp.heat_pump_configuration == 'WWHP'">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='ghp'
        attribute='installed_cost_wwhp_cooling_pump_per_ton' />
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='ghp'
        attribute='installed_cost_wwhp_heating_pump_per_ton' />
    </div>
    <div v-if="run.site.ghp.heat_pump_configuration != 'WWHP'">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='ghp'
        attribute='installed_cost_building_hydronic_loop_per_sqft' />
    </div>
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='ghp'
      attribute='installed_cost_ghx_per_ft' />
    <div v-if="run.site.ghp.hybrid_ghx_sizing_method == 'Automatic'">
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='ghp'
        attribute='aux_heater_installed_cost_us_dollars_per_mmbtu_per_hr' />
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='ghp'
        attribute='aux_heater_thermal_efficiency' />
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='ghp'
        attribute='aux_cooler_installed_cost_us_dollars_per_ton' />
      <horizontal-string-input-nested-2
        association_1='site'
        association_2='ghp'
        attribute='aux_cooler_energy_use_intensity_kwe_per_kwt' />
    </div>
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='ghp'
      attribute='om_cost_per_sqft_year' />
    <horizontal-string-input-nested-2
      v-if="run.site.ghp.heat_pump_configuration == 'WSHP'"
      association_1='site'
      association_2='ghp'
      attribute='space_heating_efficiency_thermal_factor'
      v-on:input-change="updateRatioHeatingToCooling()" />
    <horizontal-string-input-nested-2
      v-if="run.site.ghp.heat_pump_configuration == 'WSHP'"
      association_1='site'
      association_2='ghp'
      attribute='cooling_efficiency_thermal_factor'
      v-on:input-change="updateRatioHeatingToCooling()" />

    <div class="row section-actions">
      <div class="col-sm-6 col-sm-offset-6">
        <div class="toggle-more-inputs pull-left">
          <a @click="toggleAdvancedInputs();">
            <span v-if="displayAdvancedInputs">
              <font-awesome-icon icon='minus-square' />
              Show fewer inputs
            </span>
            <span v-else>
              <font-awesome-icon icon='plus-square' />
              Advanced inputs
            </span>
          </a>
        </div>
        <div v-if="!displayAdvancedInputs" class="reset-inputs pull-right">
          <a @click="resetInputs()">
            <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
          </a>
        </div>
      </div>
    </div>

    <div v-show="displayAdvancedInputs">
      <h4>GHP performance (COP) table <tooltip :association_1="'site'" :association_2="'ghp'" :attribute="'cop_table'"></tooltip></h4>
      <ul class="nav nav-tabs">
        <li class="active"><a @click="updateCOPType('default');" data-toggle="tab" href="#ghp_table_default"><i class="fas fa-calendar-alt" aria-hidden="true"></i> Default</a></li>
        <li><a @click="updateCOPType('custom');" data-toggle="tab" href="#ghp_table_custom"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
      </ul>

      <div class="tab-content">
        <div v-if="run.site.ghp.cop_table_type == 'default'" class="tab-pane in active" id="ghp_table_default">
          <p>Default GHP coefficient of performance (COP) versus entering fluid temperature for the heat pumps for both heating and cooling is provided.</p>

          <p>Use the <strong>performance table</strong> link below to view the details of the default performance.</p>
        </div>

        <div v-else-if="run.site.ghp.cop_table_type == 'custom' && run.site.ghp.heat_pump_configuration == 'WSHP'" class="tab-pane active" id="ghp_table_custom">
          <div class="form-group file required run_site_ghp_cop_table_file">
            <div class="col-sm-6 control-label">
              <label class="file required" for="run_site_attributes_ghp_attributes_cop_table_file">
                <abbr title="Required" class="required">*</abbr> Custom GHP performance table
              </label>
            </div>
            <div class="col-sm-4">
              <input class="form-control hidden" type="hidden" name="run[site_attributes][ghp_attributes][cop_table_file]" id="run_site_attributes_ghp_attributes_cop_table_file" />
              <input @change="parseCSV($event)" class="form-control-file file required load-profile-file-upload" required="required" aria-required="true" type="file" name="run[site_attributes][ghp_attributes][cop_table_file]" />
              <!-- <p v-if="run.copied && this.run.site.ghp.cop_table_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.ghp.cop_table_file_data).metadata.filename }}</p> -->
              <p v-if="run.copied && this.run.site.ghp.cop_table_file_name" class="help-block">Uploaded file: {{ this.run.site.ghp.cop_table_file_name }}</p>
            </div>
          </div>
        </div>
        <div v-else-if="run.site.ghp.cop_table_type == 'custom' && run.site.ghp.heat_pump_configuration == 'WWHP'" class="tab-pane active" id="ghp_table_custom">
          <div class="form-group file required run_site_ghp_cold_cop_table_file">
            <div class="col-sm-6 control-label">
              <label class="file required" for="run_site_attributes_ghp_attributes_cold_cop_table_file">
                <abbr title="Required" class="required">*</abbr> Custom cooling WWHP performance table
              </label>
            </div>
            <div class="col-sm-4">
              <input class="form-control hidden" type="hidden" name="run[site_attributes][ghp_attributes][cold_cop_table_file]" id="run_site_attributes_ghp_attributes_cold_cop_table_file" />
              <input @change="parseColdCSV($event)" class="form-control-file file required load-profile-file-upload" required="required" aria-required="true" type="file" name="run[site_attributes][ghp_attributes][cold_cop_table_file]" />
              <!-- <p v-if="run.copied && this.run.site.ghp.cold_cop_table_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.ghp.cold_cop_table_file_data).metadata.filename }}</p> -->
              <p v-if="run.copied && this.run.site.ghp.cold_cop_table_file_name" class="help-block">Uploaded file: {{ this.run.site.ghp.cold_cop_table_file_name }}</p>
            </div>
          </div>
          <div class="form-group file required run_site_ghp_hot_cop_table_file">
            <div class="col-sm-6 control-label">
              <label class="file required" for="run_site_attributes_ghp_attributes_hot_cop_table_file">
                <abbr title="Required" class="required">*</abbr> Custom heating WWHP performance table
              </label>
            </div>
            <div class="col-sm-4">
              <input class="form-control hidden" type="hidden" name="run[site_attributes][ghp_attributes][hot_cop_table_file]" id="run_site_attributes_ghp_attributes_hot_cop_table_file" />
              <input @change="parseHotCSV($event)" class="form-control-file file required load-profile-file-upload" required="required" aria-required="true" type="file" name="run[site_attributes][ghp_attributes][hot_cop_table_file]" />
              <!-- <p v-if="run.copied && this.run.site.ghp.hot_cop_table_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.ghp.hot_cop_table_file_data).metadata.filename }}</p> -->
              <p v-if="run.copied && this.run.site.ghp.hot_cop_table_file_name" class="help-block">Uploaded file: {{ this.run.site.ghp.hot_cop_table_file_name }}</p>
            </div>
          </div>
        </div>         
      </div>

      <div v-if="run.site.ghp.cop_table_type == 'default'" class="row" style="padding-right:15px;">
        <div v-if="run.site.ghp.heat_pump_configuration == 'WSHP'" class="col-sm-6">
          <div>
            <a @click="downloadCOPDefaultTable()" class="load-profile-chart-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download GHP performance table
            </a>
          </div>
        </div>
        <div v-else-if="run.site.ghp.heat_pump_configuration == 'WWHP'" class="col-sm-6">
          <div>
            <a @click="downloadColdCOPDefaultTable()" class="load-profile-chart-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download GHP chilled water performance table
            </a>
          </div>
          <div>
            <a @click="downloadHotCOPDefaultTable()" class="load-profile-chart-link load-profile-action no-visit-color">
              <i class="fas fa-download" aria-hidden="true"></i>Download GHP hot water performance table
            </a>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <a @click="displayCOPDefaultTable()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-list-ul" aria-hidden="true"></i>View performance table
          </a>
        </div>
      </div>
      <div v-else-if="run.site.ghp.cop_table_type == 'custom' && run.site.ghp.heat_pump_configuration == 'WSHP'" class="row" style="padding-right:15px;">
        <div class="col-sm-offset-6 text-right">
          <a @click="displayCOPCustomTable()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-list-ul" aria-hidden="true"></i>View performance table
          </a>
        </div>
      </div>
      <div v-else-if="run.site.ghp.cop_table_type == 'custom' && run.site.ghp.heat_pump_configuration == 'WWHP'" class="row" style="padding-right:15px;">
        <div class="col-sm-offset-6 text-right">
          <a @click="displayCOPCustomTable()" class="load-profile-chart-link load-profile-action no-visit-color">
            <i class="fas fa-list-ul" aria-hidden="true"></i>View performance table
          </a>
        </div>
      </div>

      <transition name="fade">
        <ghp-cop-default-table v-if="showGHPCOPDefaultTable" />
      </transition>
      <transition name="fade">
        <ghp-cop-custom-table v-if="showGHPCOPCustomTable" />
      </transition>
      <transition name="fade">
        <ghp-cop-wwhp-custom-table v-if="showGHPCOPWwhpCustomTable" />
      </transition>

      <h4>GHP System Characteristics</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghp_capacity_sizing_factor_on_peak_load' />
        <horizontal-string-input-nested-2
          v-if="run.site.ghp.hybrid_ghx_sizing_method == 'Automatic'"
          association_1='site'
          association_2='ghp'
          attribute='aux_unit_capacity_sizing_factor_on_peak_load' />
        <horizontal-string-input-nested-2
          v-if="run.site.ghp.heat_pump_configuration == 'WWHP'"
          association_1='site'
          association_2='ghp'
          attribute='wwhp_cooling_setpoint_f' />
        <horizontal-string-input-nested-2
          v-if="run.site.ghp.heat_pump_configuration == 'WWHP'"
          association_1='site'
          association_2='ghp'
          attribute='wwhp_heating_setpoint_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='avoided_capex_by_ghp_present_value' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_useful_life_years' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='borehole_depth_ft' />
        <div class="row">
          <div class="col-sm-6">&nbsp;</div>
          <div class="col-sm-6">
            <input
              v-model="run.site.ghp.can_serve_dhw"
              type="checkbox"
              value="1"
              name="run[site_attributes][ghp_attributes][can_serve_dhw]"
              id="run_site_attributes_ghp_attributes_can_serve_dhw" />
            <label style="font-weight: normal" for="run_site_attributes_ghp_attributes_can_serve_dhw">
              Heat pump can serve the domestic hot water load <tooltip :association_1="'site'" :association_2="'ghp'" :attribute="'can_serve_dhw'"></tooltip>
            </label>
          </div>
        </div>
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ground_thermal_conductivity_btu_per_hr_ft_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='max_eft_allowable_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='min_eft_allowable_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='simulation_years' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='borehole_spacing_ft' />
        <horizontal-select-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='borehole_spacing_type' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='borehole_diameter_inch' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='grout_thermal_conductivity_btu_per_hr_ft_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ground_mass_density_lb_per_ft3' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ground_specific_heat_btu_per_lb_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghp_fluid_flow_rate_gpm_per_ton' />
      </div>

      <br />
      <h4>Ground Heat Exchanger (GHX) System Characteristics</h4>
      <div class="form-horizontal">
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_pump_power_watt_per_gpm' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_pump_min_speed_fraction' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_pump_power_exponent' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_pipe_outer_diameter_inch' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_pipe_wall_thickness_inch' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_pipe_thermal_conductivity_btu_per_hr_ft_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_shank_space_inch' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_fluid_mass_density_lb_per_ft3' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_fluid_specific_heat_btu_per_lb_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_fluid_thermal_conductivity_btu_per_hr_ft_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_fluid_dynamic_viscosity_lbm_per_ft_hr' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='ghx_header_depth_ft' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='solver_eft_tolerance_f' />
        <horizontal-string-input-nested-2
          association_1='site'
          association_2='ghp'
          attribute='init_sizing_factor_ft_per_peak_ton' />
      </div>

      <h4>GHP Incentives and Tax Treatment</h4>
      <incentives-table
        association_1='site'
        association_2='ghp' />

      <div v-if="run.grid">
        <h5>Tax Treatment</h5>
        <div class="form-horizontal">
          <horizontal-select-input-nested-2
            association_1='site'
            association_2='ghp'
            attribute='macrs_option_years' />
          <horizontal-select-input-nested-2
            association_1='site'
            association_2='ghp'
            attribute='macrs_bonus_fraction' />
        </div>
      </div>
    </div>

    <div v-if="displayAdvancedInputs" class="reset-inputs pull-right">
      <a @click="resetInputs()">
        <i class="fas fa-sync-alt" aria-hidden="true"></i>Reset to default values
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './inputs/HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './inputs/HorizontalSelectInputNested2';
  import IncentivesTable from './inputs/IncentivesTable';
  import Tooltip from './Tooltip'
  import GhpCopCustomTable from './inputs/GhpCopCustomTable'
  import GhpCopDefaultTable from './inputs/GhpCopDefaultTable'
  import GhpCopWwhpCustomTable from './inputs/GhpCopWwhpCustomTable'

  export default {
    components: {
      // nested 3 b/c run.site.chp.hot_water_storage - measured from run
      GhpCopCustomTable,
      GhpCopDefaultTable,
      GhpCopWwhpCustomTable,
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      Tooltip,
      IncentivesTable,
    },

    data: function() {
      return {
        displayAdvancedInputs: false
      }
    },

    computed: {
      ...mapGetters([
        'cwsAttrs',
        'ghpCOPCustomData',
        'ghpCOPColdCustomData',
        'ghpCOPHotCustomData',
        'run',
        'selectOptions',
        'showGHPCOPCustomTable',
        'showGHPCOPWwhpCustomTable',
        'showGHPCOPDefaultTable',
        'urlRoot',
        'ratioHeatingToCooling',
        'placeholders',
        'annualMmbtuForRatio',
        'annualTonHourForRatio',
        'dhwAnnualMMbtuForRatio',
      ])
    },

    methods: {
      updateStore: function(attr, value) {
        var payload = {
          association_1: 'site',
          association_2: 'ghp',
          attribute: attr,
          value: value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateCOPType: function(type) {
        this.updateStore('cop_table_type', type);
      },
      toggleAdvancedInputs: function() {
        this.displayAdvancedInputs = !this.displayAdvancedInputs;
      },
      displayCOPDefaultTable: function() {
        this.$store.commit('updateObject', {object: 'showGHPCOPDefaultTable', value: true });
      },
      displayCOPCustomTable: function() {
        if(this.run.site.ghp.heat_pump_configuration == 'WSHP' && Object.keys(this.ghpCOPCustomData).length == 0) {
          bootbox.alert("The GHP performance (COP) table must be uploaded or the 'Default' tab must be selected.");
        } else if (this.run.site.ghp.heat_pump_configuration == 'WWHP' && (Object.keys(this.ghpCOPColdCustomData).length == 0 || Object.keys(this.ghpCOPHotCustomData).length == 0)) {
          bootbox.alert("The GHP cooling and/or heating performance (COP) table must be uploaded or the 'Default' tab must be selected.");
        } else if (this.run.site.ghp.heat_pump_configuration == 'WSHP' && Object.keys(this.ghpCOPCustomData).length > 0) {
          this.$store.commit('updateObject', {object: 'showGHPCOPCustomTable', value: true });
        } else {
          this.$store.commit('updateObject', {object: 'showGHPCOPWwhpCustomTable', value: true });
        }
      },
      downloadCOPDefaultTable: function() {
        var values = [];
        if(this.run.site.ghp.heat_pump_configuration == 'WSHP') {
          var rows = [
            ['Entering Fluid Temp. (F)', 'Cooling COP (kWt/kWe)', 'Heating COP (kWt/kWe)'],
            [20, 11.023, 3.351],
            [30, 11.023, 3.639],
            [40, 11.023, 4.161],
            [50, 10.481, 4.681],
            [60, 9.168, 5.081],
            [70, 7.263, 5.678],
            [80, 5.826, 6.047],
            [90, 4.803, 6.341],
            [100, 3.9, 6.341],
            [110, 3.279, 6.341],
            [120, 2.707, 6.341],
          ];

          var csv = Papa.unparse(rows);
          var blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'GHP WSHP COP Performance Table.csv');
        } else if(this.run.site.ghp.heat_pump_configuration == 'WWHP') {
          var rows = [
            ['Cooling COP (kWt/kWe)', , , ],
            ['Entering Fluid Temp. (F)', 'Chilled Water Set Point Temperature (F)', , ],
            [, 40, 50, 60],
            [50, 6.9, 8.0, 9.1],
            [60, 6.2, 7.2, 8.3],
            [70, 5.6, 6.5, 7.4],
            [80, 4.9, 5.8, 6.6],
            [90, 4.3, 5.1, 5.8],
            [100, 3.6, 4.4, 5.0],
            [, , , ,],
            ['Heating COP (kWt/kWe)', , , ],
            ['Entering Fluid Temp. (F)', 'Hot Water Set Point Temperature (F)', , ],
            [, 120, 130, 140],
            [30, 2.7, 2.3, 2.2],
            [40, 3.2, 2.8, 2.7],
            [50, 3.7, 3.2, 3.1],
            [60, 4.2, 3.7, 3.5],
            [70, 4.7, 4.1, 4.0],
            [80, 5.2, 4.6, 4.4],
          ];

          var csv = Papa.unparse(rows);
          var blob = new Blob([csv], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, 'GHP WWHP COP Performance Table.csv');
        }
      },
      downloadColdCOPDefaultTable: function() {
        var values = [];
        var rows = [
          ['Entering Fluid Temp. (F)', 'Chilled Water Set Point Temperature (F)', , ],
          [, 40, 50, 60],
          [50, 6.9, 8.0, 9.1],
          [60, 6.2, 7.2, 8.3],
          [70, 5.6, 6.5, 7.4],
          [80, 4.9, 5.8, 6.6],
          [90, 4.3, 5.1, 5.8],
          [100, 3.6, 4.4, 5.0],
        ];

        var csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'GHP Cooling WWHP COP Performance Table.csv');
      },
      downloadHotCOPDefaultTable: function() {
        var values = [];
        var rows = [
          ['Entering Fluid Temp. (F)', 'Hot Water Set Point Temperature (F)', , ],
          [, 120, 130, 140],
          [30, 2.7, 2.3, 2.2],
          [40, 3.2, 2.8, 2.7],
          [50, 3.7, 3.2, 3.1],
          [60, 4.2, 3.7, 3.5],
          [70, 4.7, 4.1, 4.0],
          [80, 5.2, 4.6, 4.4],
        ];

        var csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'GHP Heating WWHP COP Performance Table.csv');
      },
      parseCSV: function(event) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  self.run.site.ghp.cop_table = [];
                  event.target.value = "";
                  bootbox.alert('The GHP performance table could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = {
                  fluidTemp: [],
                  coolingCOP: [],
                  heatingCOP: [],
                }

                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i == 0) {
                    var value = row[0].toLowerCase();

                    // Skip header columns.
                    if(value.indexOf('temp.') >= 0 || value.indexOf('cooling') >= 0 || value.indexOf('heating') >= 0) {
                      continue;
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  if(isNaN(parseFloat(row[0])) || isNaN(parseFloat(row[1])) || isNaN(parseFloat(row[2]))) {
                    self.run.site.ghp.cop_table = [];
                    event.target.value = "";
                    bootbox.alert('The GHP performance table must contain only numeric values. Could not parse value at row ' + (i + 1));

                    return false;
                  }

                  values.fluidTemp.push(row[0]);
                  values.coolingCOP.push(row[1]);
                  values.heatingCOP.push(row[2]);
                }

                self.$store.commit('updateObject', { object: 'ghpCOPCustomData', value: values });
                var payload = {
                  association_1: 'site',
                  association_2: 'ghp',
                  attribute: 'cop_table',
                  value: JSON.stringify(values),
                };
                self.$store.commit('updateValueNested2', payload);
                self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'ghp', attribute: 'cop_table_file_name', value: file.name});
              } finally {
              }
            }
          })
        }
      },
      parseColdCSV: function(event) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if (file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  self.run.site.ghp.cold_cop_table = [];
                  event.target.value = "";
                  bootbox.alert('The GHP cold performance table could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);
                  return false;
                } else if (results.data[0][1].toLowerCase().includes('hot') || results.data[0][1].toLowerCase().includes('cooling cop')) {
                  self.run.site.ghp.cold_cop_table = [];
                  event.target.value = "";
                  bootbox.alert('You must upload the GHP cooling performance table');
                  return false;
                }

                var rows = results.data;
                var coolingValues = {
                  chilledWaterTempHeaders: [],
                  fluidTemp: [],
                }
                for (var i = 1; i < rows[1].length; i++) {
                  coolingValues['cop' + i] = [];
                }

                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  if (i == 1 && row[0] == '') {
                    row.forEach(function(elem, i) {
                      if (elem != '') {
                        coolingValues.chilledWaterTempHeaders.push(elem);
                      }
                    })
                  } else {
                    // Detect header columns.
                    if(i == 0) {
                      var value = row[0].toLowerCase();
                      // Skip header columns.
                      if(value.indexOf('temp.') >= 0 || value.indexOf('chilled') >= 0) {
                        continue;
                      }
                    }

                    // Skip empty rows.
                    if(row.join('') === '') {
                      continue;
                    }

                    // Ensure the values are only numbers.
                    if(isNaN(parseFloat(row[0])) || isNaN(parseFloat(row[1])) || isNaN(parseFloat(row[2]))) {
                      self.run.site.ghp.cold_cop_table = [];
                      event.target.value = "";
                      bootbox.alert('The GHP performance table must contain only numeric values. Could not parse value at row ' + (i + 1));

                      return false;
                    }

                    coolingValues.fluidTemp.push(row[0]);
                    // push each cooling temp value into the correct coolingValues cop array
                    row.forEach(function(elem, i) {
                      if (i == 0) {
                        return;
                      } else {
                        coolingValues['cop' + i].push(elem);
                      }
                    })
                  }
                }

                self.$store.commit('updateObject', { object: 'ghpCOPColdCustomData', value: coolingValues });
                var payload = {
                  association_1: 'site',
                  association_2: 'ghp',
                  attribute: 'cold_cop_table',
                  value: JSON.stringify(coolingValues),
                };
                self.$store.commit('updateValueNested2', payload);
                self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'ghp', attribute: 'cold_cop_table_file_name', value: file.name});
              } finally {
              }
            }
          })
        }
      },
      parseHotCSV: function(event) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if (file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.ghp.hot_cop_table = [];
                  event.target.value = "";
                  bootbox.alert('The GHP hot performance table could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);
                  return false;
                } else if (results.data[0][1].toLowerCase().includes('chilled') || results.data[0][1].toLowerCase().includes('cooling cop')) {
                  self.run.site.ghp.cold_cop_table = [];
                  event.target.value = "";
                  bootbox.alert('You must upload the GHP heating performance table');
                  return false;
                }

                var rows = results.data;
                var heatingValues = {
                  hotWaterTempHeaders: [],
                  fluidTemp: [],
                }
                for (var i = 1; i < rows[1].length; i++) {
                  heatingValues['cop' + i] = [];
                }

                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  if (i == 1 && row[0] == '') {
                    row.forEach(function(elem, i) {
                      if (elem != '') {
                        heatingValues.hotWaterTempHeaders.push(elem);
                      }
                    })
                  } else {
                    // Detect header columns.
                    if(i == 0) {
                      var value = row[0].toLowerCase();
                      // Skip header columns.
                      if(value.indexOf('temp.') >= 0 || value.indexOf('chilled') >= 0) {
                        continue;
                      }
                    }

                    // Skip empty rows.
                    if(row.join('') === '') {
                      continue;
                    }

                    // Ensure the values are only numbers.
                    if(isNaN(parseFloat(row[0])) || isNaN(parseFloat(row[1])) || isNaN(parseFloat(row[2]))) {
                      self.run.site.ghp.hot_cop_table = [];
                      event.target.value = "";
                      bootbox.alert('The GHP performance table must contain only numeric values. Could not parse value at row ' + (i + 1));

                      return false;
                    }

                    heatingValues.fluidTemp.push(row[0]);
                    // push each cooling temp value into the correct heatingValues cop array
                    row.forEach(function(elem, i) {
                      if (i == 0) {
                        return;
                      } else {
                        heatingValues['cop' + i].push(elem);
                      }
                    })
                  }
                }

                self.$store.commit('updateObject', { object: 'ghpCOPHotCustomData', value: heatingValues });
                var payload = {
                  association_1: 'site',
                  association_2: 'ghp',
                  attribute: 'hot_cop_table',
                  value: JSON.stringify(heatingValues),
                };
                self.$store.commit('updateValueNested2', payload);
                self.$store.commit('updateValueNested2', {association_1: 'site', association_2: 'ghp', attribute: 'hot_cop_table_file_name', value: file.name});
              } finally {
              }
            }
          })
        }
      },
      updateRatioHeatingToCooling: function() {
        if (this.annualMmbtuForRatio && this.annualTonHourForRatio) {
          var annualMmbtu = this.annualMmbtuForRatio;
          var annualTonHour = this.annualTonHourForRatio;

          if (this.run.site.boiler.boiler_efficiency) {
            var boilerEfficiency = this.run.site.boiler.boiler_efficiency / 100;
          } else {
            var boilerEfficiency = this.placeholders.site.boiler.boiler_efficiency.split('%')[0] / 100;
          }

          if (this.run.site.ghp.space_heating_efficiency_thermal_factor) {
            var heatingThermalFactor = this.run.site.ghp.space_heating_efficiency_thermal_factor / 100;
          } else {
            var heatingThermalFactor = this.placeholders.site.ghp.space_heating_efficiency_thermal_factor.split('%')[0] / 100;
          }

          if (this.run.site.ghp.cooling_efficiency_thermal_factor) {
            var coolingThermalFactor = this.run.site.ghp.cooling_efficiency_thermal_factor / 100;
          } else {
            var coolingThermalFactor = this.placeholders.site.ghp.cooling_efficiency_thermal_factor.split('%')[0] / 100;
          }
          if (!this.run.site.ghp.can_serve_dhw) {
            var calculateRatio = ((annualMmbtu - this.dhwAnnualMMbtuForRatio) * boilerEfficiency * heatingThermalFactor) / (annualTonHour * 0.012 * coolingThermalFactor);
          } else {
            var calculateRatio = (annualMmbtu * boilerEfficiency * heatingThermalFactor) / (annualTonHour * 0.012 * coolingThermalFactor);
          }
          this.$store.commit('updateObject', { object: 'ratioHeatingToCooling', value: calculateRatio });
        }
      },
      resetInputs: function(event) {
        var selectAttrs = [
          "macrs_bonus_fraction",
          "macrs_option_years",
        ]
        if(confirm("Are you sure you want to reset the inputs in the Geothermal Heat Pump section to their default values?")) {
          if(this.run.site.ghp.require_ghp_purchase) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'require_ghp_purchase', value: false });
          if(this.run.site.ghp.installed_cost_heatpump_per_ton) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'installed_cost_heatpump_per_ton', value: '' });
          if(this.run.site.ghp.installed_cost_wwhp_cooling_pump_per_ton) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'installed_cost_wwhp_cooling_pump_per_ton', value: '' });
          if(this.run.site.ghp.installed_cost_wwhp_heating_pump_per_ton) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'installed_cost_wwhp_heating_pump_per_ton', value: '' });
          if(this.run.site.ghp.installed_cost_building_hydronic_loop_per_sqft) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'installed_cost_building_hydronic_loop_per_sqft', value: '' });
          if(this.run.site.ghp.installed_cost_ghx_per_ft) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'installed_cost_ghx_per_ft', value: '' });
          if(this.run.site.ghp.om_cost_per_sqft_year) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'om_cost_per_sqft_year', value: '' });
          if(this.run.site.ghp.heat_pump_configuration) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'heat_pump_configuration', value: 'WSHP' });
          if(this.run.site.ghp.hybrid_ghx_sizing_method) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'hybrid_ghx_sizing_method', value: 'None' });
          if(this.run.site.ghp.space_heating_efficiency_thermal_factor) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'space_heating_efficiency_thermal_factor', value: '' });
          if(this.run.site.ghp.cooling_efficiency_thermal_factor) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'cooling_efficiency_thermal_factor', value: '' });
          if(this.run.site.ghp.cop_table_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'cop_table_file_name', value: '' });
          if(this.run.site.ghp.cold_cop_table_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'cold_cop_table_file_name', value: '' });
          if(this.run.site.ghp.hot_cop_table_file_name) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'hot_cop_table_file_name', value: '' });
          
          if(this.run.site.ghp.ghp_capacity_sizing_factor_on_peak_load) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghp_capacity_sizing_factor_on_peak_load', value: '' });
          if(this.run.site.ghp.wwhp_cooling_setpoint_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'wwhp_cooling_setpoint_f', value: '' });
          if(this.run.site.ghp.wwhp_heating_setpoint_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'wwhp_heating_setpoint_f', value: '' });
          if(this.run.site.ghp.avoided_capex_by_ghp_present_value) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'avoided_capex_by_ghp_present_value', value: '' });
          if(this.run.site.ghp.ghx_useful_life_years) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_useful_life_years', value: '' });
          if(this.run.site.ghp.borehole_depth_ft) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'borehole_depth_ft', value: '' });
          if(this.run.site.ghp.can_serve_dhw) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'can_serve_dhw', value: false });
          if(this.run.site.ghp.ground_thermal_conductivity_btu_per_hr_ft_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ground_thermal_conductivity_btu_per_hr_ft_f', value: '' });
          if(this.run.site.ghp.max_eft_allowable_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'max_eft_allowable_f', value: '' });
          if(this.run.site.ghp.min_eft_allowable_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'min_eft_allowable_f', value: '' });
          if(this.run.site.ghp.simulation_years) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'simulation_years', value: '' });
          if(this.run.site.ghp.borehole_spacing_ft) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'borehole_spacing_ft', value: '' });
          if(this.run.site.ghp.borehole_spacing_type != 'rectangular') this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'borehole_spacing_type', value: 'rectangular' });
          if(this.run.site.ghp.borehole_diameter_inch) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'borehole_diameter_inch', value: '' });
          if(this.run.site.ghp.grout_thermal_conductivity_btu_per_hr_ft_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'grout_thermal_conductivity_btu_per_hr_ft_f', value: '' });
          if(this.run.site.ghp.ground_mass_density_lb_per_ft3) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ground_mass_density_lb_per_ft3', value: '' });
          if(this.run.site.ghp.ground_specific_heat_btu_per_lb_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ground_specific_heat_btu_per_lb_f', value: '' });
          if(this.run.site.ghp.ghp_fluid_flow_rate_gpm_per_ton) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghp_fluid_flow_rate_gpm_per_ton', value: '' });
          
          if(this.run.site.ghp.ghx_pump_power_watt_per_gpm) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_pump_power_watt_per_gpm', value: '' });
          if(this.run.site.ghp.ghx_pump_min_speed_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_pump_min_speed_fraction', value: '' });
          if(this.run.site.ghp.ghx_pump_power_exponent) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_pump_power_exponent', value: '' });
          if(this.run.site.ghp.ghx_pipe_outer_diameter_inch) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_pipe_outer_diameter_inch', value: '' });
          if(this.run.site.ghp.ghx_pipe_wall_thickness_inch) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_pipe_wall_thickness_inch', value: '' });
          if(this.run.site.ghp.ghx_pipe_thermal_conductivity_btu_per_hr_ft_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_pipe_thermal_conductivity_btu_per_hr_ft_f', value: '' });
          if(this.run.site.ghp.ghx_shank_space_inch) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_shank_space_inch', value: '' });
          if(this.run.site.ghp.ghx_fluid_mass_density_lb_per_ft3) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_fluid_mass_density_lb_per_ft3', value: '' });
          if(this.run.site.ghp.ghx_fluid_specific_heat_btu_per_lb_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_fluid_specific_heat_btu_per_lb_f', value: '' });
          if(this.run.site.ghp.ghx_fluid_thermal_conductivity_btu_per_hr_ft_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_fluid_thermal_conductivity_btu_per_hr_ft_f', value: '' });
          if(this.run.site.ghp.ghx_fluid_dynamic_viscosity_lbm_per_ft_hr) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_fluid_dynamic_viscosity_lbm_per_ft_hr', value: '' });
          if(this.run.site.ghp.ghx_header_depth_ft) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'ghx_header_depth_ft', value: '' });
          if(this.run.site.ghp.solver_eft_tolerance_f) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'solver_eft_tolerance_f', value: '' });
          if(this.run.site.ghp.init_sizing_factor_ft_per_peak_ton) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'init_sizing_factor_ft_per_peak_ton', value: '' });
          
          if(this.run.site.ghp.macrs_option_years != 5) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'macrs_option_years', value: 5 });
          if(this.run.site.ghp.macrs_bonus_fraction != 0.8) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'macrs_bonus_fraction', value: 0.6 });
          if(this.run.site.ghp.federal_itc_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'federal_itc_fraction', value: '' });
          if(this.run.site.ghp.state_ibi_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'state_ibi_fraction', value: '' });
          if(this.run.site.ghp.utility_ibi_fraction) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'utility_ibi_fraction', value: '' });
          if(this.run.site.ghp.state_ibi_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'state_ibi_max', value: '' });
          if(this.run.site.ghp.utility_ibi_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'utility_ibi_max', value: '' });
          if(this.run.site.ghp.federal_rebate_per_ton) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'federal_rebate_per_ton', value: '' });
          if(this.run.site.ghp.state_rebate_per_ton) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'state_rebate_per_ton', value: '' });
          if(this.run.site.ghp.utility_rebate_per_ton) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'utility_rebate_per_ton', value: '' });
          if(this.run.site.ghp.state_rebate_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'state_rebate_max', value: '' });
          if(this.run.site.ghp.utility_rebate_max) this.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'ghp', attribute: 'utility_rebate_max', value: '' });

          window.scrollTo(0, document.getElementById('ghp_heading').offsetTop);
        }
      },
      updateDefaults: function() {
        if(this.run.site.load_profile_boiler.thermal_load_type == 'simulated') {
          if(this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile_boiler.doe_reference_name) {
            var self = this;

            $.ajax({
              url: `${this.urlRoot}/ghp-efficiency-thermal-factors`,
              contentType: 'application/json',
              data: {
                latitude: this.run.site.latitude,
                longitude: this.run.site.longitude,
                doe_reference_name: this.run.site.load_profile_boiler.doe_reference_name,
              },
              success: function handleResults(data) {
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp', attribute: 'space_heating_efficiency_thermal_factor', value: `${(data.space_heating_efficiency_thermal_factor * 100.0).toFixed(2)}%` });
                self.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp', attribute: 'cooling_efficiency_thermal_factor', value: `${(data.cooling_efficiency_thermal_factor * 100.0).toFixed(2)}%` });
              }
            })
          } else {
            this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp', attribute: 'space_heating_efficiency_thermal_factor', value: '' });
            this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp', attribute: 'cooling_efficiency_thermal_factor', value: '' });
          }
        } else {
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp', attribute: 'space_heating_efficiency_thermal_factor', value: '1.0' });
          this.$store.commit('updatePlaceholder', { association_1: 'site', association_2: 'ghp', attribute: 'cooling_efficiency_thermal_factor', value: '1.0' });
        }
      }
    },

    watch: {
      'ratioHeatingToCooling': function() {
        if(this.ratioHeatingToCooling >= 0.1 && this.ratioHeatingToCooling <= 10) {
          document.getElementById("run_site_attributes_ghp_attributes_hybrid_ghx_sizing_method").disabled = false;
          document.getElementById("run_site_attributes_ghp_attributes_hybrid_ghx_sizing_method").className = "form-control select optional"
        } else {
          document.getElementById("run_site_attributes_ghp_attributes_hybrid_ghx_sizing_method").disabled = true;
          document.getElementById("run_site_attributes_ghp_attributes_hybrid_ghx_sizing_method").className = "form-control select optional disabled"
          this.updateStore('hybrid_ghx_sizing_method', "None");
        }
      },
      'run.site.ghp.can_serve_dhw': function() {
        this.updateRatioHeatingToCooling();
      },
      'run.site.ghp.heat_pump_configuration': function() {
        if (this.run.site.ghp.heat_pump_configuration == 'WWHP' && this.run.site.ghp.cop_table) {
          this.run.site.ghp.cop_table_type = 'default';
          document.querySelectorAll("[href='#ghp_table_default']")[0].click();
          this.run.site.ghp.cop_table = null;
          this.run.site.ghp.cop_table_file_name = null;
        } else if (this.run.site.ghp.heat_pump_configuration == 'WSHP' && (this.run.site.ghp.cold_cop_table || this.run.site.ghp.hot_cop_table)) {
          this.run.site.ghp.cop_table_type = 'default';
          document.querySelectorAll("[href='#ghp_table_default']")[0].click();
          this.run.site.ghp.cold_cop_table = null;
          this.run.site.ghp.hot_cop_table = null;
          this.run.site.ghp.cold_cop_table_file_name = null;
          this.run.site.ghp.hot_cop_table_file_name = null;
        }
      },
    },

    mounted: function() {
      // for copied runs
      if(this.run.site.ghp.cop_table_file_data) {
        document.getElementById("run_site_attributes_ghp_attributes_cop_table_file").value = this.run.site.electric_tariff.cop_table_file_data;
      }
      if(this.run.site.ghp.cold_cop_table_file_data) {
        document.getElementById("run_site_attributes_ghp_attributes_cold_cop_table_file").value = this.run.site.electric_tariff.cold_cop_table_file_data;
      }
      if(this.run.site.ghp.hot_cop_table_file_data) {
        document.getElementById("run_site_attributes_ghp_attributes_hot_cop_table_file").value = this.run.site.electric_tariff.hot_cop_table_file_data;
      }

      if(this.run.site.ghp.cop_table_type == "default") {
        document.querySelectorAll("[href='#ghp_table_default']")[0].click();
      } else if(this.run.site.ghp.cop_table_type == "custom") {
        document.querySelectorAll("[href='#ghp_table_custom']")[0].click();
      }
    }
  }
</script>

<style lang="scss" scope>
  #ghp_table_default {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
