<template>
  <div class="search-dropdown" v-if="options" id="wrapper" style="margin-bottom: 0rem">
    <!-- Dropdown Input -->
    <div class="dropdown-wrapper" id="search-dropdown-component">
      <input class="dropdown-input"
        id="dropdown-input"
        :name="name"
        @focus="showOptions()"
        @blur="exit()"
        @keyup="keyMonitor"
        v-model="searchFilter"
        :disabled="!options.length"
        :placeholder="placeholder" />
      
      <font-awesome-icon icon="angle-down" class="dropdown-icon"/>
    </div>

    <div style="display: none; padding-top: 5px; color: #a94442" id="error">
      Electricity Rate cannot be blank
    </div>

    <!-- Dropdown Menu -->
    <div class="dropdown-content"
      id="dropdown-content"
      v-if="categories"
      v-show="optionsShown">
      <div
        v-for="(option, index) in filteredOptions"
        @mousedown="selectOption(option)"
        :key="index"
        :class="{
          'category': option.type === 'category',
          'dropdown-item': option.type !== 'category'
        }"
        >
          {{ option.name || option.id || '-' }}
      </div>
    </div>
    <div class="dropdown-content"
      v-else
      v-show="optionsShown">
      <div
        v-for="(option, index) in filteredOptions"
        @mousedown="selectOption(option)"
        :key="index"
        class='dropdown-item'
        >
          {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SearchDropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
    },
    options: {
      type: Array,
      required: true,
      default: [],
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxItem: {
      type: Number,
      required: false,
      default: 1000,
    },
    categories: {
      type: Boolean,
      required: false,
      default: false,
    },
    run_attribute: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: ''
    }
  },
  created() {
    window.addEventListener('scroll', this.checkDivPosition);
    window.addEventListener('resize', this.checkDivPosition);
    if (this.$store.getters.run.site.electric_tariff.urdb_label) {
      var selectedOption = this.options.filter((option) => option.id === this.$store.getters.run.site.electric_tariff.urdb_label)[0];
      this.selected = selectedOption;
      this.searchFilter = this.selected.name;
      this.run.site.electric_tariff.urdb_label = selectedOption.id;
    }
    this.$emit('selected', this.selected);
  },
  computed: {
    ...mapGetters([
        'run'
      ]),
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)){
          if (filtered.length < this.maxItem) filtered.push(option);
        }
        if (option.type === "category" && filtered.indexOf(option) === -1) {
          filtered.push(option)
        }
      }
      return filtered;
  }
  },
  methods: {
    selectOption(option) {
      if (option.type !== "category") {
        if (this.run_attribute === "urdb_label") {
          this.run.site.electric_tariff.urdb_label = option.id
        }
        this.selected = option;
        this.optionsShown = false;
        this.searchFilter = this.selected.name;
        this.$emit('selected', this.selected);
      }
    },
    checkDivPosition() {
      const dropdownComponent = document.getElementById('search-dropdown-component');
      const rect = dropdownComponent.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const screenLeft = window.screenX;
      const screenWidth = window.screen.width;
      const dropdownContent = document.getElementById('dropdown-content');

      if (dropdownContent.style.display === 'none') {
        dropdownContent.style.display === 'block'
      }

      const divScreenX = screenLeft + rect.left;
      if (divScreenX < screenWidth / 2) {
        dropdownContent.style.left = '0%';
      } else {
        dropdownContent.style.left = '-167%';
      }

      if (rect.top < windowHeight / 2) {
        dropdownContent.style.top = '4rem';
      } else {
        dropdownContent.style.top = '-35rem';
      }
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
        const dropdownInput = document.getElementById("dropdown-input")
        const error = document.getElementById("error")
        dropdownInput.classList.remove('has-error')
        error.style.display = "none"
        wrapper.style = "margin-bottom: 0rem"
        label.style.color = "black"
      }
    },
    exit() {
      const dropdownInput = document.getElementById("dropdown-input")
      const error = document.getElementById("error")
      if (this.run_attribute === "urdb_label") {
        const wrapper = document.getElementById("wrapper")
        const label = document.getElementById("label")
        if (!this.selected.id) {
          this.selected = {};
          this.searchFilter = '';
          dropdownInput.classList.add('has-error')
          error.style.display = "block"
          wrapper.style = "margin-bottom: 5rem"
          label.style.color = "#a94442"
        } else {
          this.searchFilter = this.selected.name;
          dropdownInput.classList.remove('has-error')
          error.style.display = "none"
          wrapper.style = "margin-bottom: 0rem"
          label.style.color = "black"
        }
      }
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    keyMonitor(event) {
      if (event.key === "Enter" && this.filteredOptions[0] && this.filteredOptions[0].type !== 'category') {
        this.selectOption(this.filteredOptions[0]);
      }
    }
  },
};
</script>