<template>
  <div class="form-group grouped_select required run_site_electric_tariff_urdb_label">
    <div style="padding-left: 10px; padding-right: 10px;">
      <!-- <div v-if="runsUtilityRatesLoading[index]"> -->
      <div v-if="utilityRatesLoading">
        <span class="fas fa-sync-alt fa-spin" aria-hidden="true"></span>
        Loading utility rate options...
      </div>
      <select
        v-else-if="runsUtilityRates[index].length == 0 && !checkedForRates"
        @blur="validate(run)"
        class="form-control grouped_select required"
        required="required"
        aria-required="true"
        name="run[site_attributes][electric_tariff_attributes][urdb_label]"
        style="margin-bottom: 15px;">
        <option value=""></option>
        <optgroup label="Enter a Site location above first"></optgroup>
      </select>
      <select
        v-else-if="runsUtilityRates[index].length == 0 && checkedForRates"
        @blur="validate(run)"
        disabled
        class="form-control grouped_select required"
        required="required"
        aria-required="true"
        name="run[site_attributes][electric_tariff_attributes][urdb_label]"
        style="margin-bottom: 15px;">
        <option value=""></option>
      </select>
      <select
        v-else
        v-model="run.site.electric_tariff.urdb_label"
        @blur="validate(run)"
        class="form-control grouped_select required"
        required="required"
        aria-required="true"
        name="run[site_attributes][electric_tariff_attributes][urdb_label]"
        style="margin-bottom: 15px;">
        <option value=""></option>
        <optgroup v-for="array in runsUtilityRates[index]" :label="array[0]">
          <option v-for="rate in array[1]" :value="rate[1]">
            {{ rate[0] }}
          </option>
        </optgroup>
      </select>
      <a
        v-if="run.site.electric_tariff.urdb_label"
        target="_blank"
        style="font-size:12px;"
        :href="rateUrl">
        Rate Details
      </a>
      <div v-if="runsUtilityRates[index].length == 0 && checkedForRates" class="has-error">
        <p class="help-block">
          No electricity rates were found for the location you entered. Please try another location nearby.
        </p>
      </div>
    </div>
    <div v-if="validateError" class="col-sm-12 help-block">
      {{ validateErrorMessage }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: [
      'index',
      'run'
    ],

    data: function() {
      return {
        checkedForRates: false,
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'runsClientSideValidations',
        'utilityRatesLoading',
        // 'runsUtilityRatesLoading',
        'runsUtilityRates',
        'urlRoot',
      ]),
      validateErrorMessage: function() {
        return this.runsClientSideValidations.run.site.electric_tariff.urdb_label;
      },
      rateUrl: function() {
        return `https://openei.org/apps/IURDB/rate/view/${this.run.site.electric_tariff.urdb_label}`;
      }
    },

    methods: {
      validate: function(run = null) {
        if (!run.site.electric_tariff.urdb_label) {
          this.validateError = true;
        } else if (run.site.electric_tariff.urdb_label) {
          this.validateError = false;
        }
      },
      fetchUtilityRates: function(run = null, index = null) {
        // how on earth do I relate this update
        // this.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRatesLoading', value: true, index: index });
        this.$store.commit('updateObject', { object: 'utilityRatesLoading', value: true });
        var self = this;
        $.ajax({
          url: self.urlRoot + '/utility-rates',
          data: {
            latitude: run.site.latitude,
            longitude: run.site.longitude,
            location: run.site.address
          },
          success: function handleResults(data) {
            self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRates', value: data, index: index });
            // self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRatesLoading', value: false, index: self.index });
            self.$store.commit('updateObject', { object: 'utilityRatesLoading', value: false });
            self.checkedForRates = true;
            // self.updateAzimuth(index);
          },
          error: function() {
            self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRates', value: [], index: index });
            self.$store.commit('updateRunsUtilityRates', { object: 'runsUtilityRatesLoading', value: false, index: index });

            alert('An unexpected error occurred while fetching the utility rates. Please try again or contact us for assistance.');
          },
        })
      },

      // updateAzimuth: function(index) {
      //   if(this.run.site.latitude < 0) {
      //     this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'azimuth', value: 0, index: index });
      //   } else {
      //     this.$store.commit('updateRunsPlaceholder', { association_1: 'site', association_2: 'pv', attribute: 'azimuth', value: 180, index: index });
      //   }
      // },
    },

    watch: {
      // maybe need to add utility rates loading to watch for a specific element somehow like validateError????
      'validateError': function() {
        if(this.validateError) {
          document.getElementsByClassName('run_site_electric_tariff_urdb_label')[this.index].classList.add('has-error');
        } else {
          document.getElementsByClassName('run_site_electric_tariff_urdb_label')[this.index].classList.remove('has-error');
        }
      },
    },
  }
</script>
  