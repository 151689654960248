<template>
  <div v-if="!run.site.load_profile_boiler.split_heating_loads">
    <horizontal-string-input-nested-2
      association_1='site'
      association_2='load_profile_boiler'
      attribute='campus_annual_mmbtu'
      v-on:input-change="updateCampusMMBtu('total')"
      required='true' />
    <input
      v-model="run.site.load_profile_boiler.campus_space_annual_mmbtu"
      type="hidden"
      name="run[site_attributes][load_profile_boiler_attributes][campus_space_annual_mmbtu]"
      id="run_site_attributes_load_profile_boiler_attributes_campus_space_annual_mmbtu" />
    <input
      v-model="run.site.load_profile_boiler.campus_water_annual_mmbtu"
      type="hidden"
      name="run[site_attributes][load_profile_boiler_attributes][campus_water_annual_mmbtu]"
      id="run_site_attributes_load_profile_boiler_attributes_campus_water_annual_mmbtu" />
    <div class="row">
      <div class="col-sm-offset-3 col-sm-7">
        <table id="thermal-campus" class="simulated-campus">
          <thead>
            <tr>
              <th>Select Building Type <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'campus_select_building'"></tooltip></th>
              <th>% of Total Heating System Fuel Consumption (MMBtu) <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'percent_of_energy_consump'"></tooltip></th>
            </tr>
          </thead>
          <tbody class="fields">
            <tr v-for="(building, i) in run.site.load_profile_boiler.campus_buildings" >
              <td class="campus-building-type">
                <select v-model="building.doe_reference_name" @change="updateCampusBuildings(); updateCampusMMBtu('total')" label="false" class="select optional" :name="`run[site_attributes][load_profile_boiler_attributes][campus_buildings_attributes][${i}][doe_reference_name]`" >
                  <option v-for="option in options" :value="option['value']">
                    {{ option['label'] }}
                  </option>
                </select>
              </td>
              <td>
                <input v-model="building.percent_of_total" @change="updateCampusMMBtu('total')" label="false" class="string optional building-percent" type="text" :name="`run[site_attributes][load_profile_boiler_attributes][campus_buildings_attributes][${i}][percent_of_total]`" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-offset-3">
        <div class="campus-table-actions">
          <button type="button" @click="addBuilding()" class="btn btn-primary">Add Building</button>
          <button type="button" @click="removeBuilding()" class="remove-campus-building btn btn-primary">Remove</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="form-group select optional" style="margin-bottom: 10px">
      <div class="col-sm-offset-6 col-sm-6 heating-load-types">
        <div>Space Heating</div>
        <div>Water Heating</div>
      </div>
    </div>
    <div class="form-group select optional" style="margin-bottom: 25px;">
      <div class="col-sm-6 control-label">
        <label class="grouped_select required" for="run_site_attributes_load_profile_boiler_attributes_annual_mmbtu">
          Annual heating system fuel consumption (MMBtu)
        </label>
        <tooltip
          association_1='site'
          association_2='load_profile_boiler'
          attribute='annual_mmbtu' />
      </div>
      <div class="col-sm-6">
        <div class="heating-load-types">
          <input
            v-model="run.site.load_profile_boiler.space_heating_load.campus_annual_mmbtu"
            v-on:input-change="updateCampusMMBtu('space')"
            class="form-control string optional"
            type="text"/>
          <input
            v-model="run.site.load_profile_boiler.water_heating_load.campus_annual_mmbtu"
            v-on:input-change="updateCampusMMBtu('water')"
            class="form-control string optional"
            type="text"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-offset-3 col-sm-7">
        <table id="thermal-campus" class="simulated-campus">
          <thead>
            <tr>
              <th style="width: 40%;">Select Building Type <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'campus_select_building'"></tooltip></th>
              <th style="width: 30%;">% of Space Heating Fuel Consumption (MMBtu) <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'percent_of_energy_consump'"></tooltip></th>
              <th style="width: 30%;">% of Water Heating Fuel Consumption (MMBtu) <tooltip :association_1="'site'" :association_2="'load_profile'" :attribute="'percent_of_energy_consump'"></tooltip></th>
            </tr>
          </thead>
          <tbody class="fields">
            <tr v-for="(building, i) in run.site.load_profile_boiler.campus_buildings" >
              <td class="campus-building-type">
                <select v-model="building.doe_reference_name" @change="updateCampusBuildings(); updateCampusMMBtu('space'); updateCampusMMBtu('water')" label="false" class="select optional" :name="`run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][campus_buildings_attributes][${i}][doe_reference_name]`" >
                  <option v-for="option in options" :value="option['value']">
                    {{ option['label'] }}
                  </option>
                </select>
              </td>
              <td>
                <input 
                  v-model="run.site.load_profile_boiler.space_heating_load.campus_buildings[i].percent_of_total" 
                  @change="updateCampusMMBtu('space')" label="false" class="string optional building-percent" type="text" 
                  :name="`run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][campus_buildings_attributes][${i}][percent_of_total]`" 
                  :id="`space_heating_campus_building_${i}_percent_of_total`"/>
              </td>
              <td>
                <input 
                  v-model="run.site.load_profile_boiler.water_heating_load.campus_buildings[i].percent_of_total" 
                  @change="updateCampusMMBtu('water')" label="false" class="string optional building-percent" type="text" 
                  :name="`run[site_attributes][load_profile_boiler_attributes][water_heating_load_attributes][campus_buildings_attributes][${i}][percent_of_total]`" 
                  :id="`water_heating_campus_building_${i}_percent_of_total`"/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-for="(building, i) in run.site.load_profile_boiler.campus_buildings">
        <input 
          v-model="run.site.load_profile_boiler.space_heating_load.campus_buildings[i].doe_reference_name" 
          type="hidden"
          :name="`run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][campus_buildings_attributes][${i}][doe_reference_name]`" 
          :id="`space_heating_campus_building_${i}_doe_reference_name`"/>
        <input 
          v-model="run.site.load_profile_boiler.water_heating_load.campus_buildings[i].doe_reference_name" 
          type="hidden"
          :name="`run[site_attributes][load_profile_boiler_attributes][water_heating_load_attributes][campus_buildings_attributes][${i}][doe_reference_name]`" 
          :id="`water_heating_campus_building_${i}_doe_reference_name`"/>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-offset-3">
        <div class="campus-table-actions">
          <button type="button" @click="addBuilding()" class="btn btn-primary">Add Building</button>
          <button type="button" @click="removeBuilding()" class="remove-campus-building btn btn-primary">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  import HorizontalStringInputNested2 from './HorizontalStringInputNested2';

  export default {
    components: {
      HorizontalStringInputNested2,
      Tooltip
    },

    computed: {
      ...mapGetters([
        'thermalSimCampusLoadProfile',
        'run',
        'selectOptions',
        'urlRoot',
        'annualMmbtuForRatio',
        'annualTonHourForRatio',
        'placeholders',
        'dhwAnnualMMbtuForRatio',
        'simCampusTotalLoad',
        'simCampusSpaceLoad',
        'simCampusWaterLoad'
      ]),
      options: function() {
        return this.selectOptions.site.load_profile.doe_reference_name.options;
      },
      watchDependencies:  function() {
        return this.run.site.latitude, 
        this.run.site.longitude,
        this.run.site.load_profile_boiler.campus_annual_mmbtu,
        this.run.site.load_profile_boiler.space_heating_load.campus_annual_mmbtu,
        this.run.site.load_profile_boiler.water_heating_load.campus_annual_mmbtu,
        new Date();
      },
    },

    methods: {
      addBuilding: function() {
        if(
          this.run.site.load_profile_boiler.campus_buildings.length == 5 ||
          this.run.site.load_profile_boiler.space_heating_load.campus_buildings.length == 5 ||
          this.run.site.load_profile_boiler.water_heating_load.campus_buildings.length == 5
        ) {
          bootbox.alert("Can only have a maximum of 5 building types.");
          return false;
        }
        var campusBuildings = [...this.run.site.load_profile_boiler.campus_buildings, {doe_reference_name: '', percent_of_total: '',}]
        var campusSpaceBuildings = [...this.run.site.load_profile_boiler.space_heating_load.campus_buildings, {doe_reference_name: '', percent_of_total: '',}]
        var campusWaterBuildings = [...this.run.site.load_profile_boiler.water_heating_load.campus_buildings, {doe_reference_name: '', percent_of_total: '',}]

        var payload = {
          association_1: 'site',
          association_2: 'load_profile_boiler',
          attribute: 'campus_buildings',
        }

        payload['value'] = campusBuildings
        this.$store.commit('updateValueNested2', payload);
        this.updateCampusMMBtu('total');

        payload['association_3'] = 'space_heating_load'
        payload['value'] = campusSpaceBuildings
        this.$store.commit('updateValueNested3', payload);
        this.updateCampusMMBtu('space');

        payload['association_3'] = 'water_heating_load'
        payload['value'] = campusWaterBuildings
        this.$store.commit('updateValueNested3', payload);
        this.updateCampusMMBtu('water');

      },
      removeBuilding: function() {
        if(this.run.site.load_profile_boiler.campus_buildings.length == 1 ||
          this.run.site.load_profile_boiler.space_heating_load.campus_buildings.length == 1 ||
          this.run.site.load_profile_boiler.water_heating_load.campus_buildings.length == 1
        ) {
          bootbox.alert("Must have at least 1 building type.");
          return false;
        }

        this.$store.commit('updateObject', { object: 'removeThermalCampusBuilding' });
        this.updateCampusMMBtu('total');
        this.updateCampusMMBtu('space');
        this.updateCampusMMBtu('water');
      },
      verifyAllBuildingsSelected: function(type) {
        if(type == 'total') {
          var selected = this.run.site.load_profile_boiler.campus_buildings.map(function(el, i) {
            if(el.doe_reference_name) {
              return true;
            } else {
              return false;
            }
          })
          if(selected.includes(false)) {
            return false;
          } else {
            return true;
          }
        } else if(type == 'space' || type == 'water') {
          var selected_space = this.run.site.load_profile_boiler.space_heating_load.campus_buildings.map(function(el, i) {
            if(el.doe_reference_name) {
              return true;
            } else {
              return false;
            }
          })
          var selected_water = this.run.site.load_profile_boiler.water_heating_load.campus_buildings.map(function(el, i) {
            if(el.doe_reference_name) {
              return true;
            } else {
              return false;
            }
          })
          if(selected_space.includes(false) || selected_water.includes(false)) {
            return false;
          } else {
            return true;
          }
        }
      },
      verifyPercentsSumTo100: function(type) {
        if(type == 'total') {
          var sum = this.run.site.load_profile_boiler.campus_buildings.map(function(el, i) {
            return Number(el.percent_of_total);
          }).reduce(function(total, num) {
            return total + num;
          })

          if(sum == 100) {
            return true;
          } else {
            return false;
          }
        } else if(type == 'space' || type == 'water') {
          var sum_space = this.run.site.load_profile_boiler.space_heating_load.campus_buildings.map(function(el, i) {
            return Number(el.percent_of_total);
          }).reduce(function(total, num) {
            return total + num;
          })
          var sum_water = this.run.site.load_profile_boiler.water_heating_load.campus_buildings.map(function(el, i) {
            return Number(el.percent_of_total);
          }).reduce(function(total, num) {
            return total + num;
          })
          if(sum_space == 100 && sum_water == 100) {
            return true;
          } else {
            return false;
          }
        }
      },
      updateStoreNested2: function(payload) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile_boiler',
          attribute: payload.attribute,
          value: payload.value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateStoreNested3: function(payload) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile_boiler',
          association_3: payload.association_3,
          attribute: payload.attribute,
          value: payload.value,
        }

        this.$store.commit('updateValueNested3', payload);
      },
      updateCampusBuildings: function() {
        var values = [];
        if(this.run.site.load_profile_boiler.campus_buildings) {
          this.run.site.load_profile_boiler.campus_buildings.forEach(function(building, i) {
            values.push(building.doe_reference_name); 
          })
        }
        this.$store.commit('updateCampusBuildings', values);
      },
      updateCampusMMBtu: function(type) {
        if(
          this.run.site.load_profile_boiler.thermal_load_type != 'simulated_campus' || 
          (type == 'total' && !this.run.site.load_profile_boiler.campus_annual_mmbtu) || 
          (type == 'space' && !this.run.site.load_profile_boiler.space_heating_load.campus_annual_mmbtu) ||
          (type == 'water' && !this.run.site.load_profile_boiler.water_heating_load.campus_annual_mmbtu)
        ) {
          return false;
        }

        if(this.verifyAllBuildingsSelected(type) && this.verifyPercentsSumTo100(type) && this.run.site.latitude && this.run.site.longitude) {
          if (type == "total") {
            this.getSimCampusTotalLoad();
          } else if (type == "space" || type == 'water') {
            this.getSimCampusSpaceLoad();
            this.getSimCampusWaterLoad();
          }
        } else {
          this.$store.commit('updateObject', { object: 'thermalSimCampusLoadProfile', value: {} });
        }
      },
      getSimCampusTotalLoad: function() {
        var doeReferenceNames = this.run.site.load_profile_boiler.campus_buildings.map(function(el, i) {
          return el.doe_reference_name
        })
        var percentShares = this.run.site.load_profile_boiler.campus_buildings.map(function(el, i) {
          return el.percent_of_total/100.0
        })

        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
          campus_annual_mmbtu: this.run.site.load_profile_boiler.campus_annual_mmbtu,
          doe_reference_names: doeReferenceNames,
          percent_shares: percentShares,
        }
        params['load_type'] = "heating"

        var self = this;
        $.ajax({
          url: self.urlRoot + '/simulated-campus-thermal-load',
          contentType: 'application/json',
          data: params,
          success: function handleResults(data) {
            var campusDhwAnnualMmbtu = data.dhw_annual_mmbtu;
            var campusSpaceAnnualMmbtu = data.space_annual_mmbtu;

            self.updateStoreNested2({
              association_2: 'load_profile_boiler',
              attribute: 'campus_water_annual_mmbtu',
              value: Math.round(campusDhwAnnualMmbtu)
            });

            self.updateStoreNested2({
              association_2: 'load_profile_boiler',
              attribute: 'campus_space_annual_mmbtu',
              value: Math.round(campusSpaceAnnualMmbtu)
            });
            var payload = {
              loadsMMBtu: data.loads_mmbtu_per_hour,
              minMMBtu: data.min_mmbtu_per_hour,
              meanMMBtu: data.mean_mmbtu_per_hour,
              maxMMBtu: data.max_mmbtu_per_hour,
              dhwLoadsMMBtu: data.dhw_loads_mmbtu_per_hour,
              dhwMinMMBtu: data.dhw_min_mmbtu_per_hour,
              dhwMaxMMBtu: data.dhw_max_mmbtu_per_hour,
              dhwMeanMMBtu: data.dhw_mean_mmbtu_per_hour,
              spaceLoadsMMBtu: data.space_loads_mmbtu_per_hour,
              spaceMinMMBtu: data.space_min_mmbtu_per_hour,
              spaceMaxMMBtu: data.space_max_mmbtu_per_hour,
              spaceMeanMMBtu: data.space_mean_mmbtu_per_hour,
            }
            self.$store.commit('updateObject', { object: 'simCampusTotalLoad', value: payload });
            self.getThermalSimCampusLoad('total');
          },
          error: function() {
            bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
            self.$store.commit('updateObject', { object: 'simCampusTotalLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });
          },
        })
      },
      getSimCampusSpaceLoad: function() {
        var doeReferenceNames = this.run.site.load_profile_boiler.space_heating_load.campus_buildings.map(function(el, i) {
          return el.doe_reference_name
        })
        var percentShares = this.run.site.load_profile_boiler.space_heating_load.campus_buildings.map(function(el, i) {
          return el.percent_of_total/100.0
        })

        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
          campus_annual_mmbtu: this.run.site.load_profile_boiler.space_heating_load.campus_annual_mmbtu,
          doe_reference_names: doeReferenceNames,
          percent_shares: percentShares,
        }
        params['load_type'] = "space_heating"

        var self = this;
        $.ajax({
          url: self.urlRoot + '/simulated-campus-thermal-load',
          contentType: 'application/json',
          data: params,
          success: function handleResults(data) {
            var payload = {
              spaceLoadsMMBtu: data.loads_mmbtu_per_hour,
              spaceMinMMBtu: data.min_mmbtu_per_hour,
              spaceMeanMMBtu: data.mean_mmbtu_per_hour,
              spaceMaxMMBtu: data.max_mmbtu_per_hour,
              spaceAnnualMMBtu: data.annual_mmbtu,
            }
            self.$store.commit('updateObject', { object: 'simCampusSpaceLoad', value: payload });
            self.getThermalSimCampusLoad('space');
          },
          error: function() {
            bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
            self.$store.commit('updateObject', { object: 'simCampusSpaceLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });
          },
        })
      },
      getSimCampusWaterLoad: function() {
        var doeReferenceNames = this.run.site.load_profile_boiler.water_heating_load.campus_buildings.map(function(el, i) {
          return el.doe_reference_name
        })
        var percentShares = this.run.site.load_profile_boiler.water_heating_load.campus_buildings.map(function(el, i) {
          return el.percent_of_total/100.0
        })

        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
          campus_annual_mmbtu: this.run.site.load_profile_boiler.water_heating_load.campus_annual_mmbtu,
          doe_reference_names: doeReferenceNames,
          percent_shares: percentShares,
        }
        params['load_type'] = "dhw"

        var self = this;
        $.ajax({
          url: self.urlRoot + '/simulated-campus-thermal-load',
          contentType: 'application/json',
          data: params,
          success: function handleResults(data) {
            var payload = {
              dhwLoadsMMBtu: data.loads_mmbtu_per_hour,
              dhwMinMMBtu: data.min_mmbtu_per_hour,
              dhwMeanMMBtu: data.mean_mmbtu_per_hour,
              dhwMaxMMBtu: data.max_mmbtu_per_hour,
              dhwAnnualMMBtu: data.annual_mmbtu,
            }
            self.$store.commit('updateObject', { object: 'simCampusWaterLoad', value: payload });
            self.getThermalSimCampusLoad('water');
          },
          error: function() {
            bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
            self.$store.commit('updateObject', { object: 'simCampusWaterLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });
          },
        })
      },
      getThermalSimCampusLoad: function(type) {
        this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });

        if (type == "total") {
          var payload = this.simCampusTotalLoad;
        } else if (type == "space" || type == 'water') {
          if(this.simCampusSpaceLoad.spaceLoadsMMBtu && this.simCampusWaterLoad.dhwLoadsMMBtu) {
            var space_data = this.simCampusSpaceLoad;
            var space_payload = {
              spaceLoadsMMBtu: space_data.spaceLoadsMMBtu,
              spaceMinMMBtu: space_data.spaceMinMMBtu,
              spaceMeanMMBtu: space_data.spaceMeanMMBtu,
              spaceMaxMMBtu: space_data.spaceMaxMMBtu,
              spaceAnnualMMBtu: space_data.spaceAnnualMMBtu,
            }
            var water_data = this.simCampusWaterLoad;
            var water_payload = {
              dhwLoadsMMBtu: water_data.dhwLoadsMMBtu,
              dhwMinMMBtu: water_data.dhwMinMMBtu,
              dhwMeanMMBtu: water_data.dhwMeanMMBtu,
              dhwMaxMMBtu: water_data.dhwMaxMMBtu,
              dhwAnnualMMBtu: water_data.dhwAnnualMMBtu,
            }
            var loads_mmbtu_per_hour = space_payload.spaceLoadsMMBtu.map(function (num, idx) {return num + water_payload.dhwLoadsMMBtu[idx];});
            var min_mmbtu_per_hour = Math.min.apply(Math, loads_mmbtu_per_hour);
            var mean_mmbtu_per_hour = (loads_mmbtu_per_hour.reduce((a, b) => a + b)) / loads_mmbtu_per_hour.length;
            var max_mmbtu_per_hour = Math.max.apply(Math, loads_mmbtu_per_hour);
            var annual_mmbtu = space_payload.spaceAnnualMMBtu + water_payload.dhwAnnualMMBtu;

            var total_payload = {
              loadsMMBtu: loads_mmbtu_per_hour,
              minMMBtu: min_mmbtu_per_hour,
              meanMMBtu: mean_mmbtu_per_hour,
              maxMMBtu: max_mmbtu_per_hour,
              annualMMBtu: annual_mmbtu,
            }
            var payload = { ...space_payload, ...water_payload, ...total_payload };
          }
        }

        this.$store.commit('updateObject', { object: 'thermalSimCampusLoadProfile', value: payload });
        this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });

        if (this.run.analyze_ghp) {
          var campusDhwAnnualMmbtu = payload.dhwAnnualMMBtu;
          var annualMmbtuForRatio = payload.annualMMBtu
          this.$store.commit('updateObject', { object: 'dhwAnnualMMbtuForRatio', value: campusDhwAnnualMmbtu });
          this.$store.commit('updateObject', { object: 'annualMmbtuForRatio', value: annualMmbtuForRatio });
        }

        if (this.run.analyze_ghp && self.annualTonHourForRatio) {
          this.updateRatioHeatingToCooling();
        }
      },
      updateRatioHeatingToCooling: function() {
        this.$emit('update-ghp-heat-to-cool');
      },
    },

    watch: {
      watchDependencies: function() {
        var heating_type = this.run.site.load_profile_boiler.thermal_load_type;
        if(heating_type == "simulated_campus") {
          if(!this.run.site.load_profile_boiler.split_heating_loads) {
            this.updateCampusMMBtu('total');
          } else {
            this.updateCampusMMBtu('space');
            this.updateCampusMMBtu('water');
          }
        }
      }
    },

    mounted: function() {
      if(this.run.site.latitude && this.run.site.longitude && this.run.site.load_profile_boiler.campus_annual_mmbtu && this.verifyAllBuildingsSelected() && this.verifyPercentsSumTo100()) {
        if(!this.run.site.load_profile_boiler.split_heating_loads) {
          this.updateCampusMMBtu('total');
        } else {
          this.updateCampusMMBtu('space');
          this.updateCampusMMBtu('water');
        }
      }
    }
  }
</script>
