<template>
  <div class="modal-mask" id="primegen-scheduler-summary">
    <div class="modal-wrapper" style="display:flex;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="close()" type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Prime Generator Maintenance Schedule - Summary View</h4>
          </div>
          <div class="modal-body">
            <table>
              <thead>
                <th></th>
                <th>Weekend (hrs)</th>
                <th>Weekday (hrs)</th>
                <th>Total (hrs)</th>
              </thead>
              <tbody>
                <tr>
                  <th>Total</th>
                  <td>{{ totalWeekend }}</td>
                  <td>{{ totalWeekday }}</td>
                  <td>{{ totalWeekend + totalWeekday  }}</td>
                </tr>
                <tr v-for="(month, i) in months">
                  <th>{{ month }}</th>
                  <td>{{ schedule.weekday_weekend_total_hours_by_month[i + 1].weekends }}</td>
                  <td>{{ schedule.weekday_weekend_total_hours_by_month[i + 1].weekdays }}</td>
                  <td>{{ schedule.weekday_weekend_total_hours_by_month[i + 1].total }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: ['scheduleType'],

    computed: {
      ...mapGetters([
        'primegenMaintenanceDefaultSchedule',
        'primegenMaintenanceUploadedSchedule',
        'months',
      ]),
      schedule: function() {
        return this[this.scheduleType];
      },
      totalWeekend: function() {
        var total = 0;
        for(var i = 1; i <= 12; i++) {
          total += this.schedule.weekday_weekend_total_hours_by_month[i].weekends;
        }

        return total;
      },
      totalWeekday: function() {
        var total = 0;
        for(var i = 1; i <= 12; i++) {
          total += this.schedule.weekday_weekend_total_hours_by_month[i].weekdays;
        }

        return total;
      }
    },

    methods: {
      close: function() {
        if(this.scheduleType == "primegenMaintenanceDefaultSchedule") {
          var object = 'showPrimeGenDefaultSummarySchedule';
        } else if(this.scheduleType == "primegenMaintenanceUploadedSchedule") {
          var object = 'showPrimeGenUploadedSummarySchedule';
        }

        this.$store.commit('updateObject', { object: object, value: false });
      }
    }
  }
</script>
