<template>
  <div class="form-group grouped_select required run_site_electric_tariff_urdb_label">
    <div class="col-sm-6 control-label">
      <label class="grouped_select required" id="label" for="run_site_attributes_electric_tariff_attributes_urdb_label">
        <abbr title="Required" class="required">*</abbr>
        Electricity rate
      </label>
      <tooltip
        association_1="site"
        association_2="electric_tariff"
        attribute="urdb_label" />
    </div>
    <div class="col-sm-4">

      <div v-if="utilityRatesLoading">
        <span class="fas fa-sync-alt fa-spin" aria-hidden="true"></span>
        Loading utility rate options...
      </div>

      <SearchDropdown
        v-else-if="utilityRates.length == 0 && !checkedForRates && !run.site.electric_tariff.custom_electricity_rate"
        :options="computedOptions"
        :disabled="false"
        :categories="true"
        class="required"
        run_attribute="urdb_label"
        name="run[site_attributes][electric_tariff_attributes][urdb_label]"
        placeholder="Enter a Site location above first">
      </SearchDropdown>

      <SearchDropdown
        v-else-if="!run.site.electric_tariff.custom_electricity_rate"
        :options="computedOptions"
        :disabled="false"
        :categories="true"
        run_attribute="urdb_label"
        name="run[site_attributes][electric_tariff_attributes][urdb_label]"
        class="required"
        placeholder="Select an Electricity Rate">
      </SearchDropdown>

      <a
        v-if="run.site.electric_tariff.urdb_label && !run.site.electric_tariff.custom_electricity_rate"
        target="_blank" rel="noopener noreferrer"
        style="font-size:12px;"
        :href="rateUrl">
        Rate Details
      </a>
      <div v-if="utilityRates.length == 0 && checkedForRates && !run.site.electric_tariff.custom_electricity_rate" class="has-error">
        <p class="help-block">
          No electricity rates were found for the location you entered. Please try another location nearby.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';
  import SearchDropdown from '../SearchDropdown';

  export default {
    components: {
      Tooltip,
      SearchDropdown
    },

    data: function() {
      return {
        checkedForRates: false,
        validateError: false,
      }
    },

    computed: {
      ...mapGetters([
        'clientSideValidations',
        'utilityRates',
        'utilityRatesLoading',
        'run',
        'urlRoot'
      ]),
      rateUrl: function() {
        return `https://openei.org/apps/IURDB/rate/view/${this.run.site.electric_tariff.urdb_label}`;
      },
      computedOptions() {
        // Initialize an empty array to store all options
        let options = [];

        // Iterate through utilityRates
        this.utilityRates.forEach(([category, items]) => {
          // Iterate through items of each category
          options.push({
            id: category,
            name: category,
            type: "category"
            // category: category  // Optionally include category name in each option
          });
          items.forEach(item => {
            // Push each item as an option object into the options array
            options.push({
              id: item[1],
              name: item[0],
              type: "rate"
              // category: category  // Optionally include category name in each option
            });
          });
        });

        return options;
      }
    },

    methods: {
      fetchUtilityRates: function() {
        this.$store.commit('updateObject', { object: 'utilityRatesLoading', value: true });

        var self = this;
        $.ajax({
          url: self.urlRoot + '/utility-rates',
          data: {
            latitude: self.run.site.latitude,
            longitude: self.run.site.longitude,
            location: self.run.site.address
          },
          success: function handleResults(data) {
            self.$store.commit('updateObject', { object: 'utilityRates', value: data });
            self.$store.commit('updateObject', { object: 'utilityRatesLoading', value: false });
            self.checkedForRates = true;
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'utilityRates', value: [] });
            self.$store.commit('updateObject', { object: 'utilityRatesLoading', value: false });

            alert('An unexpected error occurred while fetching the utility rates. Please try again or contact us for assistance.');
          },
        })
      }
    },

    mounted: function() {
      if(this.run.site.electric_tariff.urdb_label) {
        this.fetchUtilityRates();
      }
    }
  }
</script>
