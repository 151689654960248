<template>
  <div>
    <h4><abbr class="required">*</abbr> Typical heating system fuel load <tooltip :association_1="'site'" :association_2="'load_profile_boiler'" :attribute="'section_header'"></tooltip></h4>
    <p>How would you like to enter the typical thermal load profile?</p>

    <input v-model="run.site.load_profile_boiler.thermal_load_type" type="hidden" name="run[site_attributes][load_profile_boiler_attributes][thermal_load_type]" />

    <ul class="nav nav-tabs">
      <li class="active"><a @click="updateThermalLoadType('simulated');" data-toggle="tab" href="#thermal_load_profile_simulated_inputs"><i class="fas fa-home" aria-hidden="true"></i> Simulate Building</a></li>
      <li><a @click="updateThermalLoadType('simulated_campus');" data-toggle="tab" href="#thermal_simulated_campus"><i class="fas fa-university" aria-hidden="true"></i> Simulate Campus</a></li>
      <li><a @click="updateThermalLoadType('uploaded');" data-toggle="tab" href="#thermal_load_profile_custom_inputs"><i class="fas fa-upload" aria-hidden="true"></i> Upload</a></li>
    </ul>
    <div class="tab-content">
      <div v-if="run.site.load_profile_boiler.thermal_load_type == 'simulated'" class="tab-pane fade in active">
        <div class="col-sm-offset-6 col-sm-6" style="margin-top: -10px; margin-bottom: 10px;padding-right: 30px;">
          <input
            type="checkbox"
            v-model="run.site.load_profile_boiler.split_heating_loads"
            name="run[site_attributes][load_profile_boiler_attributes][split_heating_loads]"
            id="split_heating_loads" />
          <label style="font-weight: normal; display: inline;" for="split_heating_loads">
            &nbsp;&nbsp;Separately input space heating and domestic hot water loads
          </label>
          <tooltip
            association_1="site"
            association_2="load_profile_boiler"
            attribute="split_heating_loads" />
        </div>
        <div v-if="!run.site.load_profile_boiler.split_heating_loads">
          <horizontal-select-input-nested-2
            association_1='site'
            association_2='load_profile_boiler'
            attribute='doe_reference_name'
            style="margin-bottom:-25px"
            required=true
            v-on:input-change="updateAnnualMMBtu('total');updateMonthlyMMBtu('total');updateGHPDefaults();" />
          <div class="col-sm-offset-6 col-sm-6 simulated-load-options" style="margin-bottom:10px">
            <input
              v-model="run.site.load_profile_boiler.simulated_load_type"
              style="width:15px;"
              type="radio"
              value="annual"
              checked="checked"
              name="run[site_attributes][load_profile_boiler_attributes][simulated_load_type]"
              id="run_site_attributes_load_profile_boiler_attributes_simulated_load_type_annual" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_boiler_attributes_simulated_load_type_annual">Annual</label>
            <input
              v-model="run.site.load_profile_boiler.simulated_load_type"
              class="simulated-monthly"
              style="width:15px;"
              type="radio"
              value="monthly"
              name="run[site_attributes][load_profile_boiler_attributes][simulated_load_type]"
              id="run_site_attributes_load_profile_boiler_attributes_simulated_load_type_monthly" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_boiler_attributes_simulated_load_type_monthly">Monthly</label>
          </div>
          <horizontal-string-input-nested-2
            v-if="run.site.load_profile_boiler.simulated_load_type == 'annual'"
            association_1='site'
            association_2='load_profile_boiler'
            ref='annualMMBtu'
            attribute='annual_mmbtu'
            v-on:input-change="updateAnnualMMBtu('total');" />
          <input
            v-model="run.site.load_profile_boiler.space_annual_mmbtu"
            type="hidden"
            name="run[site_attributes][load_profile_boiler_attributes][space_annual_mmbtu]"
            id="run_site_attributes_load_profile_boiler_attributes_space_annual_mmbtu" />
          <input
            v-model="run.site.load_profile_boiler.water_annual_mmbtu"
            type="hidden"
            name="run[site_attributes][load_profile_boiler_attributes][water_annual_mmbtu]"
            id="run_site_attributes_load_profile_boiler_attributes_water_annual_mmbtu" />
          <horizontal-string-input-nested-2
            v-if="run.site.load_profile_boiler.simulated_load_type == 'annual'"
            association_1='site'
            association_2='load_profile_boiler'
            attribute='addressable_load_fraction'
            v-on:input-change="getSimAnnualTotalLoad()" />
          <thermal-monthly-inputs
            v-else="run.site.load_profile_boiler.simulated_load_type == 'monthly'"
            type='thermal'
            v-on:update-total-monthly-mmbtu="updateMonthlyMMBtu('total');" 
            v-on:paste-total-inputs="pasteInputs('total')"
            v-on:paste-percent-inputs="pasteInputs('percent')" />
        </div>
        <div v-else>
          <div class="form-group select optional" style="margin-bottom: 10px">
            <div class="col-sm-offset-6 col-sm-6 heating-load-types">
              <div>Space Heating</div>
              <div>Water Heating</div>
            </div>
          </div>
          <div class="form-group select optional">
            <div class="col-sm-6 control-label">
              <label class="grouped_select required" for="run_site_attributes_load_profile_boiler_attributes_urdb_label">
                <abbr title="Required" class="required">*</abbr>
                Type of building
              </label>
              <tooltip
                association_1='site'
                association_2='load_profile_boiler'
                attribute='doe_reference_name' />
            </div>
            <div class="col-sm-6" style="margin-bottom:-15px">
              <div class="heating-load-types">
                <select
                  v-model="run.site.load_profile_boiler.space_heating_load.doe_reference_name"
                  class="form-control select required"
                  required="required"
                  @change="updateAnnualMMBtu('space');updateMonthlyMMBtu('space');updateGHPDefaults();"
                  name="run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][doe_reference_name]">
                  <option v-for="option in options" :value="option['value']">
                    {{ option['label'] }}
                  </option>
                </select>
                <select
                  v-model="run.site.load_profile_boiler.water_heating_load.doe_reference_name"
                  class="form-control select required"
                  required="required"
                  @change="updateAnnualMMBtu('water');updateMonthlyMMBtu('water');updateGHPDefaults();"
                  name="run[site_attributes][load_profile_boiler_attributes][water_heating_load_attributes][doe_reference_name]">
                  <option v-for="option in options" :value="option['value']">
                    {{ option['label'] }}
                  </option>
                </select>
              </div>
              <p class="building-details">
                <a href="#" rel="tooltip" :title="tooltips.site.load_profile.building_details" target="_blank">
                  Building Details
                </a>
              </p>
            </div>
          </div>
          <div class="col-sm-offset-6 col-sm-6 simulated-load-options" style="margin-bottom:10px">
            <input
              v-model="run.site.load_profile_boiler.simulated_load_type"
              style="width:15px;"
              type="radio"
              value="annual"
              checked="checked"
              name="run[site_attributes][load_profile_boiler_attributes][simulated_load_type]"
              id="run_site_attributes_load_profile_boiler_attributes_simulated_load_type_annual" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_boiler_attributes_simulated_load_type_annual">Annual</label>
            <input
              v-model="run.site.load_profile_boiler.simulated_load_type"
              class="simulated-monthly"
              style="width:15px;"
              type="radio"
              value="monthly"
              name="run[site_attributes][load_profile_boiler_attributes][simulated_load_type]"
              id="run_site_attributes_load_profile_boiler_attributes_simulated_load_type_monthly" />
            <label class="radio-inline" style="padding-top:0px;padding-left:0px;" for="run_site_attributes_load_profile_boiler_attributes_simulated_load_type_monthly">Monthly</label>
          </div>
          <div v-if="run.site.load_profile_boiler.simulated_load_type == 'annual'">
            <div class="form-group select optional">
              <div class="col-sm-6 control-label">
                <label class="grouped_select required" for="run_site_attributes_load_profile_boiler_attributes_annual_mmbtu">
                  Annual heating system fuel consumption (MMBtu)
                </label>
                <tooltip
                  association_1='site'
                  association_2='load_profile_boiler'
                  attribute='annual_mmbtu' />
              </div>
              <div class="col-sm-6">
                <div class="heating-load-types">
                  <input
                    v-model="run.site.load_profile_boiler.space_heating_load.annual_mmbtu"
                    @change="updateAnnualMMBtu('space');"
                    class="form-control string optional"
                    :placeholder="placeholders['site']['load_profile_boiler']['space_heating_load']['annual_mmbtu']"
                    name="run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][annual_mmbtu]"
                    id="run_site_attributes_load_profile_boiler_attributes_space_heating_load_attributes_annual_mmbtu"
                    type="text"/>
                  <input
                    v-model="run.site.load_profile_boiler.water_heating_load.annual_mmbtu"
                    @change="updateAnnualMMBtu('water');"
                    class="form-control string optional"
                    :placeholder="placeholders['site']['load_profile_boiler']['water_heating_load']['annual_mmbtu']"
                    name="run[site_attributes][load_profile_boiler_attributes][water_heating_load_attributes][water_annual_mmbtu]"
                    id="run_site_attributes_load_profile_boiler_attributes_water_heating_load_attributes_annual_mmbtu"
                    type="text"/>
                </div>
              </div>
            </div>
            <div class="form-group select optional">
              <div class="col-sm-6 control-label">
                <label class="grouped_select required" for="run_site_attributes_load_profile_boiler_attributes_annual_mmbtu">
                  Addressable load percent (%)
                </label>
                <tooltip
                  association_1='site'
                  association_2='load_profile_boiler'
                  attribute='addressable_load_fraction' />
              </div>
              <div class="col-sm-6">
                <div class="heating-load-types">
                  <input
                    v-model="run.site.load_profile_boiler.space_heating_load.addressable_load_fraction"
                    @change="getSimAnnualSpaceLoad()"
                    :placeholder="placeholders['site']['load_profile_boiler']['space_heating_load']['addressable_load_fraction']"
                    class="form-control string optional"
                    type="text"/>
                  <input
                    v-model="run.site.load_profile_boiler.water_heating_load.addressable_load_fraction"
                    @change="getSimAnnualWaterLoad()"
                    :placeholder="placeholders['site']['load_profile_boiler']['water_heating_load']['addressable_load_fraction']"
                    class="form-control string optional"
                    type="text"/>
                </div>
              </div>
            </div>
          </div>
          <thermal-monthly-inputs
            v-if="run.site.load_profile_boiler.simulated_load_type == 'monthly'"
            type='thermal'
            v-on:update-space-monthly-mmbtu="updateMonthlyMMBtu('space');" 
            v-on:update-water-monthly-mmbtu="updateMonthlyMMBtu('water');" 
            v-on:paste-space-inputs="pasteInputs('space');"
            v-on:paste-water-inputs="pasteInputs('water');" />
        </div>
      </div>
      <div v-else-if="run.site.load_profile_boiler.thermal_load_type == 'simulated_campus'" class="tab-pane" id="thermal_simulated_campus">
        <div class="col-sm-offset-6 col-sm-4" style="margin-top: -10px; margin-bottom: 10px;padding-right: 30px;">
          <input
            type="checkbox"
            v-model="run.site.load_profile_boiler.split_heating_loads"
            name="run[site_attributes][load_profile_boiler_attributes][split_heating_loads]"
            id="split_heating_loads" />
          <label style="font-weight: normal; display: inline;" for="split_heating_loads">
            &nbsp;&nbsp;Separately input space heating and domestic hot water loads
          </label>
          <tooltip
            association_1="site"
            association_2="load_profile_boiler"
            attribute="split_heating_loads" />
        </div>
        <thermal-simulated-campus-table />
      </div>
      <div v-else-if="run.site.load_profile_boiler.thermal_load_type == 'uploaded'" class="tab-pane" id="thermal_load_profile_custom_inputs">
        <div class="col-sm-offset-6 col-sm-4" style="margin-top: -10px; margin-bottom: 10px;padding-right: 30px;">
          <input
            type="checkbox"
            v-model="run.site.load_profile_boiler.split_heating_loads"
            name="run[site_attributes][load_profile_boiler_attributes][split_heating_loads]"
            id="split_heating_loads" />
          <label style="font-weight: normal; display: inline;" for="split_heating_loads">
            &nbsp;&nbsp;Separately input space heating and domestic hot water loads
          </label>
          <tooltip
            association_1="site"
            association_2="load_profile_boiler"
            attribute="split_heating_loads" />
        </div>
        <div v-if="!run.site.load_profile_boiler.split_heating_loads">
          <input
            v-model="run.site.load_profile_boiler.loads_mmbtu_per_hour"
            type="hidden"
            name="run[site_attributes][load_profile_boiler_attributes][loads_mmbtu_per_hour]" />

          <div class="form-group file required">
            <div class="col-sm-6 control-label">
              <label class="file required" for="run_site_attributes_load_profile_boiler_attributes_thermal_load_file"><abbr title="Required" class="required">*</abbr> Custom heating system fuel load profile</label><tooltip :association_1="'site'" :association_2="'load_profile_boiler'" :attribute="'thermal_load_file'"></tooltip>
            </div>
            <div class="col-sm-4">
              <input
                type="hidden"
                name="run[site_attributes][load_profile_boiler_attributes][thermal_load_file]"
                id="run_site_attributes_load_profile_boiler_attributes_thermal_load_file"/>
              <input
                @change="parseCSV($event, 'total')"
                class="form-control-file file required"
                required="required"
                aria-required="true"
                type="file"
                name="run[site_attributes][load_profile_boiler_attributes][thermal_load_file]" />
              <!-- <p v-if="run.copied && this.run.site.load_profile_boiler.thermal_load_file_data" class="help-block">Uploaded file: {{ JSON.parse(this.run.site.load_profile_boiler.thermal_load_file_data).metadata.filename }}</p> -->
              <p v-if="run.copied && this.run.site.load_profile_boiler.thermal_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile_boiler.thermal_load_file_name }}</p>
            </div>
          </div>
          <horizontal-string-input-nested-2
            association_1='site'
            association_2='load_profile_boiler'
            attribute='space_heating_load_fraction'
            v-on:input-change="applyHeatingLoadFraction();" />
        </div>
        <div v-else>
          <div class="col-sm-offset-6 col-sm-6 heating-load-types">
            <div>Space Heating</div>
            <div style="margin-left: 45px;">Water Heating</div>
          </div>
          <input
            v-model="run.site.load_profile_boiler.space_heating_load.loads_mmbtu_per_hour"
            type="hidden"
            name="run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][loads_mmbtu_per_hour]" />
          <input
            v-model="run.site.load_profile_boiler.water_heating_load.loads_mmbtu_per_hour"
            type="hidden"
            name="run[site_attributes][load_profile_boiler_attributes][water_heating_load_attributes][loads_mmbtu_per_hour]" />
          <div class="form-group file required">
            <div class="col-sm-6 control-label">
              <label class="grouped_select required" for="run_site_attributes_load_profile_boiler_attributes_annual_mmbtu">
                Custom heating system fuel load profile
              </label>
              <tooltip
                association_1='site'
                association_2='load_profile_boiler'
                attribute='annual_mmbtu' />
            </div>
            <div class="col-sm-6">
              <div class="heating-load-types">
                <input
                  type="hidden"
                  name="run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][thermal_load_file]"
                  id="run_site_attributes_load_profile_boiler_attributes_space_heating_load_attributes_thermal_load_file"/>
                <input
                  type="hidden"
                  name="run[site_attributes][load_profile_boiler_attributes][water_heating_load_attributes][thermal_load_file]"
                  id="run_site_attributes_load_profile_boiler_attributes_water_heating_load_attributes_thermal_load_file"/>
                <div>
                  <input
                    @change="parseCSV($event, 'space')"
                    class="form-control-file file required"
                    required="required"
                    aria-required="true"
                    type="file"
                    name="run[site_attributes][load_profile_boiler_attributes][space_heating_load_attributes][thermal_load_file]" />
                  <p v-if="run.copied && this.run.site.load_profile_boiler.space_heating_load.thermal_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile_boiler.space_heating_load.thermal_load_file_name }}</p>
                </div>
                <div>
                  <input
                    @change="parseCSV($event, 'water')"
                    class="form-control-file file required"
                    required="required"
                    aria-required="true"
                    type="file"
                    name="run[site_attributes][load_profile_boiler_attributes][water_heating_load_attributes][thermal_load_file]" />
                  <p v-if="run.copied && this.run.site.load_profile_boiler.water_heating_load.thermal_load_file_name" class="help-block">Uploaded file: {{ this.run.site.load_profile_boiler.water_heating_load.thermal_load_file_name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="run.site.load_profile_boiler.thermal_load_type == 'simulated' && run.site.load_profile_boiler.simulated_load_type == 'annual'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!thermalLoadProfileLoading" @click="downloadLoadProfile('thermalSimBuildAnnualLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download heating system fuel load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!thermalLoadProfileLoading" @click="displayThermalSimBuildingAnnualLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart heating system fuel load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_boiler.thermal_load_type == 'simulated' && run.site.load_profile_boiler.simulated_load_type == 'monthly'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!thermalLoadProfileLoading" @click="downloadLoadProfile('thermalSimBuildMonthlyLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download heating system fuel load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!thermalLoadProfileLoading" @click="displayThermalSimBuildingMonthlyLP();" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart heating system fuel load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_boiler.thermal_load_type == 'simulated_campus'" class="row">
      <div class="col-sm-6">
        <div>
          <a v-if="!thermalLoadProfileLoading" @click="downloadLoadProfile('thermalSimCampusLoadProfile')" class="load-profile-download-link load-profile-action no-visit-color">
            <i class="fas fa-download" aria-hidden="true"></i>Download heating system fuel load profile
          </a>
          <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <a v-if="!thermalLoadProfileLoading" @click="displayThermalSimCampusLP();" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart heating system fuel load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <div v-else-if="run.site.load_profile_boiler.thermal_load_type == 'uploaded'" class="row">
      <div class="col-sm-6 col-sm-offset-6 text-right">
        <a v-if="!thermalLoadProfileLoading" @click="displayThermalUploadedLP()" class="load-profile-chart-link load-profile-action no-visit-color">
          <i class="fas fa-chart-area" aria-hidden="true"></i>Chart heating system fuel load data
        </a>
        <div v-else class="load-profile-spinner" data-load-type="typical"><i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i></div>
      </div>
    </div>
    <br><br>
    <!-- add v-on:input-change="updateRatioHeatingToCooling()" to chp input just in case ghp is also selected -->
    <horizontal-string-input-nested-2
      v-if="run.analyze_chp || run.analyze_ghp || run.analyze_ashp_space || run.site.load_profile_boiler.include_site_heating_load"
      association_1='site'
      association_2='boiler'
      attribute='boiler_efficiency'
      v-on:input-change="updateRatioHeatingToCooling()" />
    <horizontal-string-input-nested-2
      v-if="run.analyze_chp"
      association_1='site'
      association_2='boiler'
      attribute='max_thermal_factor_on_peak_load' />

    <transition name="fade">
      <thermal-load-profile-modal
        v-if="showThermalSimBuildAnnualLoadProfile"
        :loadType="'thermalSimBuildAnnualLoadProfile'">
      </thermal-load-profile-modal>
    </transition>
    <transition name="fade">
      <thermal-load-profile-modal
        v-if="showThermalSimBuildMonthlyLoadProfile"
        :loadType="'thermalSimBuildMonthlyLoadProfile'">
      </thermal-load-profile-modal>
    </transition>
    <transition name="fade">
      <thermal-load-profile-modal
        v-if="showThermalSimCampusLoadProfile"
        :loadType="'thermalSimCampusLoadProfile'">
      </thermal-load-profile-modal>
    </transition>
    <transition name="fade">
      <thermal-load-profile-modal
        v-if="showThermalUploadedLoadProfile"
        :loadType="'thermalUploadedLoadProfile'">
      </thermal-load-profile-modal>
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import HorizontalStringInputNested2 from './HorizontalStringInputNested2';
  import HorizontalSelectInputNested2 from './HorizontalSelectInputNested2';
  import ThermalLoadProfileModal from './../modals/ThermalLoadProfileModal';
  import ThermalSimulatedCampusTable from './ThermalSimulatedCampusTable';
  import Tooltip from './../Tooltip';
  import ThermalAnnualInputs from './ThermalAnnualInputs';
  import ThermalMonthlyInputs from './ThermalMonthlyInputs';

  export default {
    components: {
      HorizontalStringInputNested2,
      HorizontalSelectInputNested2,
      ThermalLoadProfileModal,
      ThermalSimulatedCampusTable,
      Tooltip,
      ThermalAnnualInputs,
      ThermalMonthlyInputs
    },

    computed: {
      ...mapGetters([
        'currentUser',
        'placeholders',
        'run',
        'selectOptions',
        'tooltips',
        'urlRoot',
        'year',

        'thermalLoadProfileLoading',
        'thermalSimBuildAnnualLoadProfile',
        'thermalSimBuildMonthlyLoadProfile',
        'thermalSimCampusLoadProfile',
        'thermalUploadedLoadProfile',
        'simAnnualTotalLoad',
        'simAnnualSpaceLoad',
        'simAnnualWaterLoad',
        'simMonthlyTotalLoad',
        'simMonthlySpaceLoad',
        'simMonthlyWaterLoad',
        'uploadedTotalLoad',
        'uploadedSpaceLoad',
        'uploadedWaterLoad',

        'showThermalSimBuildAnnualLoadProfile',
        'showThermalSimBuildMonthlyLoadProfile',
        'showThermalSimCampusLoadProfile',
        'showThermalUploadedLoadProfile',

        'ratioHeatingToCooling',
        'annualMmbtuForRatio',
        'annualTonHourForRatio',
        'dhwAnnualMMbtuForRatio',
      ]),
      options: function() {
        return this.selectOptions.site.load_profile_boiler.doe_reference_name.options;
      },
      watchDependencies:  function() {
        return this.run.analyze_chp,
        this.run.analyze_ghp,
        this.run.analyze_ashp_space,
        this.run.analyze_ashp_water,
        this.run.site.latitude, 
        this.run.site.longitude,
        this.run.site.load_profile_boiler.simulated_load_type,
        this.run.site.load_profile_boiler.thermal_load_type,
        this.run.site.load_profile_boiler.doe_reference_name,
        this.run.site.load_profile_boiler.space_heating_load.doe_reference_name,
        this.run.site.load_profile_boiler.water_heating_load.doe_reference_name,
        this.run.site.load_profile_boiler.split_heating_loads,
        new Date();
      },
    },

    methods: {
      updateAnnualMMBtu: function(type) {
        if(
          this.run.site.load_profile_boiler.simulated_load_type != 'annual' || 
          (type == 'total' && !this.run.site.load_profile_boiler.doe_reference_name) || 
          (type == 'space' && !this.run.site.load_profile_boiler.space_heating_load.doe_reference_name) ||
          (type == 'water' && !this.run.site.load_profile_boiler.water_heating_load.doe_reference_name)
        ) {
          return false;
        }

        if(this.run.site.address) {
          var self = this;
          if (type == "total") {
            var doeReferenceName = this.run.site.load_profile_boiler.doe_reference_name;
            var loadType = "heating"
          } else if (type == "space") {
            var doeReferenceName = this.run.site.load_profile_boiler.space_heating_load.doe_reference_name;
            var loadType = "space_heating"
          } else if (type == 'water') {
            var doeReferenceName = this.run.site.load_profile_boiler.water_heating_load.doe_reference_name;
            var loadType = "dhw"
          }
          $.ajax({
            url: self.urlRoot + '/annual-mmbtu',
            contentType: 'application/json',
            data: {
              latitude: this.run.site.latitude,
              longitude: this.run.site.longitude,
              doe_reference_name: doeReferenceName,
              load_type: loadType,
            },
            success: function handleResults(data) {
              if (type == "total") {
                if(!self.run.site.load_profile_boiler.annual_mmbtu) {
                  self.$refs.annualMMBtu.toggleDisplayDefault();
                }
                self.$store.commit('updatePlaceholder', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  attribute: 'annual_mmbtu',
                  value: Math.round(data.annual_mmbtu)
                });
                self.$store.commit('updatePlaceholder', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  attribute: 'space_annual_mmbtu',
                  value: Math.round(data.space_annual_mmbtu)
                });
                self.$store.commit('updatePlaceholder', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  attribute: 'water_annual_mmbtu',
                  value: Math.round(data.dhw_annual_mmbtu)
                });
                self.getSimAnnualTotalLoad();
              } else if (type == "space") {
                self.$store.commit('updatePlaceholder', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  association_3: 'space_heating_load',
                  attribute: 'annual_mmbtu',
                  value: Math.round(data.annual_mmbtu)
                });
                self.getSimAnnualSpaceLoad();
              } else if (type == "water") {
                self.$store.commit('updatePlaceholder', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  association_3: 'water_heating_load',
                  attribute: 'annual_mmbtu',
                  value: Math.round(data.annual_mmbtu)
                });
                self.getSimAnnualWaterLoad();
              }
            },
            error: function() {
              return false;
            },
          })
        }
      },
      updateStore: function(attr, value) {
        var payload = {
          association_1: 'site',
          association_2: 'load_profile',
          attribute: attr,
          value: value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateStoreNested2: function(payload) {
        var payload = {
          association_1: 'site',
          association_2: payload.association_2,
          attribute: payload.attribute,
          value: payload.value,
        }

        this.$store.commit('updateValueNested2', payload);
      },
      updateStoreNested3: function(payload) {
        var payload = {
          association_1: 'site',
          association_2: payload.association_2,
          association_3: payload.association_3,
          attribute: payload.attribute,
          value: payload.value,
        }

        this.$store.commit('updateValueNested3', payload);
      },
      displayThermalSimBuildingAnnualLP: function() {
        // display thermal simulated building annual load profile
        if(!this.run.site.load_profile_boiler.split_heating_loads) {
          if(!this.run.site.load_profile_boiler.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
            bootbox.alert('A heating system fuel load profile must be selected or uploaded first.');
            return false;
          }
        } else {
          if(!this.run.site.load_profile_boiler.space_heating_load.doe_reference_name || !this.run.site.load_profile_boiler.water_heating_load.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
            bootbox.alert('A heating system fuel load profile must be selected or uploaded first.');
            return false;
          }
        }

        this.$store.commit('updateObject', { object: 'showThermalSimBuildAnnualLoadProfile', value: true });
      },
      displayThermalSimCampusLP: function() {
        // display thermal simulated campus load profile
        if(Object.keys(this.thermalSimCampusLoadProfile).length == 0) {
          bootbox.alert('All building information along with the total energy consumption must be entered first. Please verify all percents sum to 100.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showThermalSimCampusLoadProfile', value: true });
      },
      allMonthlyInputsPresent: function(type) {
        var values = [];
        for(var i = 1; i <= 12; i++) {
          if(type == 'total') {
            values.push(this.run.site.load_profile_boiler[`monthly_mmbtu_${i}`]);
          } else if(type == 'space' || type == 'water') {
            values.push(this.run.site.load_profile_boiler.space_heating_load[`monthly_mmbtu_${i}`]);
            values.push(this.run.site.load_profile_boiler.water_heating_load[`monthly_mmbtu_${i}`]);
          }
        }

        if(values.includes("") || values.includes(null) || values.includes(undefined)) {
          return false;
        } else {
          return true;
        }
      },
      displayThermalSimBuildingMonthlyLP: function() {
        // display thermal simulated building monthly load profile
        if(!this.run.site.load_profile_boiler.split_heating_loads) {
          if(!this.allMonthlyInputsPresent('total') || !this.run.site.load_profile_boiler.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
            bootbox.alert('Please verify that all monthly values for thermal energy consumption have been entered, a Type of Building has been selected, and a Site Location entered.');
            return false;
          }
        } else {
          if(!this.allMonthlyInputsPresent('space') || !this.allMonthlyInputsPresent('water') || !this.run.site.load_profile_boiler.space_heating_load.doe_reference_name || !this.run.site.load_profile_boiler.water_heating_load.doe_reference_name || !this.run.site.latitude || !this.run.site.longitude) {
            bootbox.alert('Please verify that all monthly values for thermal energy consumption have been entered, a Type of Building has been selected, and a Site Location entered.');
            return false;
          }
        }

        this.$store.commit('updateObject', { object: 'showThermalSimBuildMonthlyLoadProfile', value: true });
      },
      displayThermalUploadedLP: function() {
        // display electric uploaded load profile
        if(!this.thermalUploadedLoadProfile.loadsMMBtu) {
          bootbox.alert('A thermal load profile must be selected or uploaded first.');
          return false;
        }

        this.$store.commit('updateObject', { object: 'showThermalUploadedLoadProfile', value: true });
      },
      getSimAnnualTotalLoad: function() {
        var self = this;
        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
        }

        params['doe_reference_name'] = this.run.site.load_profile_boiler.doe_reference_name;
        params['load_type'] = "heating"
        if(this.run.site.load_profile_boiler.annual_mmbtu) {
          params['annual_mmbtu'] = this.run.site.load_profile_boiler.annual_mmbtu;
        }
        if(this.run.site.load_profile_boiler.addressable_load_fraction) {
          params['addressable_load_fraction'] = this.run.site.load_profile_boiler.addressable_load_fraction / 100.0;
        }

        $.ajax({
          url: self.urlRoot + "/simulated-annual-thermal-load",
          data: params,
          success: function(data) {
            if (self.run.site.load_profile_boiler.annual_mmbtu && self.run.site.load_profile_boiler.addressable_load_fraction) {
              var spaceMMBtu = (data.space_annual_mmbtu / (self.run.site.load_profile_boiler.addressable_load_fraction / 100)).toFixed(3);
              var waterMMBtu = (data.dhw_annual_mmbtu / (self.run.site.load_profile_boiler.addressable_load_fraction / 100)).toFixed(3);
            } else {
              var spaceMMBtu = data.space_annual_mmbtu;
              var waterMMBtu = data.dhw_annual_mmbtu;
            }
            self.updateStoreNested2({
              association_2: 'load_profile_boiler',
              attribute: 'space_annual_mmbtu',
              value: spaceMMBtu
            });
            self.updateStoreNested2({
              association_2: 'load_profile_boiler',
              attribute: 'water_annual_mmbtu',
              value: waterMMBtu
            });
            var payload = {
              loadsMMBtu: data.loads_mmbtu_per_hour,
              minMMBtu: data.min_mmbtu_per_hour,
              meanMMBtu: data.mean_mmbtu_per_hour,
              maxMMBtu: data.max_mmbtu_per_hour,
              dhwLoadsMMBtu: data.dhw_loads_mmbtu_per_hour,
              dhwMinMMBtu: data.dhw_min_mmbtu_per_hour,
              dhwMaxMMBtu: data.dhw_max_mmbtu_per_hour,
              dhwMeanMMBtu: data.dhw_mean_mmbtu_per_hour,
              spaceLoadsMMBtu: data.space_loads_mmbtu_per_hour,
              spaceMinMMBtu: data.space_min_mmbtu_per_hour,
              spaceMaxMMBtu: data.space_max_mmbtu_per_hour,
              spaceMeanMMBtu: data.space_mean_mmbtu_per_hour,
              annualMMBtu: data.annual_mmbtu,
              spaceAnnualMMBtu: data.space_annual_mmbtu,
              dhwAnnualMMBtu: data.dhw_annual_mmbtu,
            }
            self.$store.commit('updateObject', { object: 'simAnnualTotalLoad', value: payload });
            self.getThermalSimBuildingAnnualLoad('total');
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'simAnnualTotalLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }
        })
      },
      getSimAnnualSpaceLoad: function() {
        var self = this;
        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
        }

        params['doe_reference_name'] = this.run.site.load_profile_boiler.space_heating_load.doe_reference_name;
        params['load_type'] = "space_heating"
        if(this.run.site.load_profile_boiler.space_heating_load.annual_mmbtu) {
          params['annual_mmbtu'] = this.run.site.load_profile_boiler.space_heating_load.annual_mmbtu;
        }
        if(this.run.site.load_profile_boiler.space_heating_load.addressable_load_fraction) {
          params['addressable_load_fraction'] = this.run.site.load_profile_boiler.space_heating_load.addressable_load_fraction / 100.0;
        }
        
        $.ajax({
          url: self.urlRoot + "/simulated-annual-thermal-load",
          data: params,
          success: function(data) {
            var payload = {
              spaceLoadsMMBtu: data.loads_mmbtu_per_hour,
              spaceMinMMBtu: data.min_mmbtu_per_hour,
              spaceMeanMMBtu: data.mean_mmbtu_per_hour,
              spaceMaxMMBtu: data.max_mmbtu_per_hour,
              spaceAnnualMMBtu: data.annual_mmbtu,
            }
            self.$store.commit('updateObject', { object: 'simAnnualSpaceLoad', value: payload });
            self.getThermalSimBuildingAnnualLoad('space');
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'simAnnualSpaceLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }
        })
      },
      getSimAnnualWaterLoad: function() {
        var self = this;
        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
        }

        params['doe_reference_name'] = this.run.site.load_profile_boiler.water_heating_load.doe_reference_name;
        params['load_type'] = "dhw"
        if(this.run.site.load_profile_boiler.water_heating_load.annual_mmbtu) {
          params['annual_mmbtu'] = this.run.site.load_profile_boiler.water_heating_load.annual_mmbtu;
        }
        if(this.run.site.load_profile_boiler.water_heating_load.addressable_load_fraction) {
          params['addressable_load_fraction'] = this.run.site.load_profile_boiler.water_heating_load.addressable_load_fraction / 100.0;
        }

        $.ajax({
          url: self.urlRoot + "/simulated-annual-thermal-load",
          data: params,
          success: function(data) {
            var payload = {
              dhwLoadsMMBtu: data.loads_mmbtu_per_hour,
              dhwMinMMBtu: data.min_mmbtu_per_hour,
              dhwMeanMMBtu: data.mean_mmbtu_per_hour,
              dhwMaxMMBtu: data.max_mmbtu_per_hour,
              dhwAnnualMMBtu: data.annual_mmbtu,
            }
            self.$store.commit('updateObject', { object: 'simAnnualWaterLoad', value: payload });
            self.getThermalSimBuildingAnnualLoad('water');
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'simAnnualWaterLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }
        })
      },
      getThermalSimBuildingAnnualLoad: function(type) {
        this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });
        var payload = [];
        
        if (type == "total") {
          payload = this.simAnnualTotalLoad;
        } else if (type == "space" || type == 'water') {
          var space_data = this.simAnnualSpaceLoad;
          var space_payload = {
            spaceLoadsMMBtu: space_data.spaceLoadsMMBtu,
            spaceMinMMBtu: space_data.spaceMinMMBtu,
            spaceMeanMMBtu: space_data.spaceMeanMMBtu,
            spaceMaxMMBtu: space_data.spaceMaxMMBtu,
            spaceAnnualMMBtu: space_data.spaceAnnualMMBtu,
          }
          var water_data = this.simAnnualWaterLoad;
          var water_payload = {
            dhwLoadsMMBtu: water_data.dhwLoadsMMBtu,
            dhwMinMMBtu: water_data.dhwMinMMBtu,
            dhwMeanMMBtu: water_data.dhwMeanMMBtu,
            dhwMaxMMBtu: water_data.dhwMaxMMBtu,
            dhwAnnualMMBtu: water_data.dhwAnnualMMBtu,
          }
          if (space_payload.spaceLoadsMMBtu && water_payload.dhwLoadsMMBtu) {
            var loads_mmbtu_per_hour = space_payload.spaceLoadsMMBtu.map(function (num, idx) {return num + water_payload.dhwLoadsMMBtu[idx];});
            var min_mmbtu_per_hour = Math.min.apply(Math, loads_mmbtu_per_hour);
            var mean_mmbtu_per_hour = (loads_mmbtu_per_hour.reduce((a, b) => a + b)) / loads_mmbtu_per_hour.length;
            var max_mmbtu_per_hour = Math.max.apply(Math, loads_mmbtu_per_hour);
            var annual_mmbtu = space_payload.spaceAnnualMMBtu + water_payload.dhwAnnualMMBtu;

            var total_payload = {
              loadsMMBtu: loads_mmbtu_per_hour,
              minMMBtu: min_mmbtu_per_hour,
              meanMMBtu: mean_mmbtu_per_hour,
              maxMMBtu: max_mmbtu_per_hour,
              annualMMBtu: annual_mmbtu,
            }
            payload = { ...space_payload, ...water_payload, ...total_payload };
          }
        }
        
        this.$store.commit('updateObject', { object: 'thermalSimBuildAnnualLoadProfile', value: payload });
        this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });

        if (this.run.analyze_ghp) {
          var dhwAnnualMMbtuForRatio = payload.dhwAnnualMMBtu;
          var annualMmbtuForRatio = payload.annualMMBtu;

          this.$store.commit('updateObject', { object: 'dhwAnnualMMbtuForRatio', value: dhwAnnualMMbtuForRatio });
          this.$store.commit('updateObject', { object: 'annualMmbtuForRatio', value: annualMmbtuForRatio });
        }
        
        if (this.run.analyze_ghp && self.annualTonHourForRatio) {
          this.updateRatioHeatingToCooling();
        }
      },
      updateMonthlyMMBtu: function(type) {
        if(
          this.run.site.load_profile_boiler.simulated_load_type != 'monthly' || 
          (type == 'total' && !this.run.site.load_profile_boiler.doe_reference_name) || 
          (type == 'space' && !this.run.site.load_profile_boiler.space_heating_load.doe_reference_name) ||
          (type == 'water' && !this.run.site.load_profile_boiler.water_heating_load.doe_reference_name)
        ) {
          return false;
        }

        if(this.allMonthlyInputsPresent(type) && this.run.site.latitude && this.run.site.longitude) {
          if (type == "total") {
            this.getSimMonthlyTotalLoad();
          } else if (type == "space" || type == 'water') {
            this.getSimMonthlySpaceLoad();
            this.getSimMonthlyWaterLoad();
          }
        }
      },
      getSimMonthlyTotalLoad: function() {
        if(!this.allMonthlyInputsPresent("total") || !this.run.site.latitude || !this.run.site.longitude) {
          return false;
        }
        
        var monthly_mmbtu = [];
        var addressable_load_fraction = [];
        for(var i = 1; i <= 12; i++) {
          monthly_mmbtu.push(this.run.site.load_profile_boiler[`monthly_mmbtu_${i}`]);

          var monthlyAddressableLoad = this.run.site.load_profile_boiler[`addressable_load_fraction_monthly_${i}`];
          if(monthlyAddressableLoad) {
            addressable_load_fraction.push(monthlyAddressableLoad / 100.0);
          } else {
            addressable_load_fraction.push(1.0);
          }
        }

        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
          doe_reference_name: this.run.site.load_profile_boiler.doe_reference_name,
          monthly_mmbtu: monthly_mmbtu,
          addressable_load_fraction: addressable_load_fraction,
        }
        params['load_type'] = "heating"

        var self = this;
        $.ajax({
          url: self.urlRoot + "/simulated-monthly-thermal-load",
          data: params,
          success: function(data) {
            var payload = {
              loadsMMBtu: data.loads_mmbtu_per_hour,
              minMMBtu: data.min_mmbtu_per_hour,
              meanMMBtu: data.mean_mmbtu_per_hour,
              maxMMBtu: data.max_mmbtu_per_hour,
              dhwLoadsMMBtu: data.dhw_loads_mmbtu_per_hour,
              dhwMinMMBtu: data.dhw_min_mmbtu_per_hour,
              dhwMaxMMBtu: data.dhw_max_mmbtu_per_hour,
              dhwMeanMMBtu: data.dhw_mean_mmbtu_per_hour,
              spaceLoadsMMBtu: data.space_loads_mmbtu_per_hour,
              spaceMinMMBtu: data.space_min_mmbtu_per_hour,
              spaceMaxMMBtu: data.space_max_mmbtu_per_hour,
              spaceMeanMMBtu: data.space_mean_mmbtu_per_hour,
            }
            data.dhw_monthly_mmbtu.forEach(function(mmbtu, index) {
              if (self.run.site.load_profile_boiler[`addressable_load_fraction_monthly_${index + 1}`]) {
                var addressableLoadFrac = self.run.site.load_profile_boiler[`addressable_load_fraction_monthly_${index + 1}`] / 100;
                var waterMMBtu = parseFloat((mmbtu / addressableLoadFrac).toFixed(2));
              } else {
                var waterMMBtu = mmbtu
              }
              self.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'load_profile_boiler', attribute: `water_monthly_mmbtu_${index + 1}`, value: waterMMBtu });
            });

            data.space_monthly_mmbtu.forEach(function(mmbtu, index) {
              if (self.run.site.load_profile_boiler[`addressable_load_fraction_monthly_${index + 1}`]) {
                var addressableLoadFrac = self.run.site.load_profile_boiler[`addressable_load_fraction_monthly_${index + 1}`] / 100;
                var spaceMMBtu = parseFloat((mmbtu / addressableLoadFrac).toFixed(2));
              } else {
                var spaceMMBtu = mmbtu
              } 
              self.$store.commit({type: 'updateValueNested2', association_1: 'site', association_2: 'load_profile_boiler', attribute: `space_monthly_mmbtu_${index + 1}`, value: spaceMMBtu });
            });

            self.$store.commit('updateObject', { object: 'simMonthlyTotalLoad', value: payload });
            self.getThermalSimBuildingMonthlyLoad('total');
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'simMonthlyTotalLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }
        })
      },
      getSimMonthlySpaceLoad: function() {
        var monthly_mmbtu = [];
        var addressable_load_fraction = [];
        for(var i = 1; i <= 12; i++) {
          monthly_mmbtu.push(this.run.site.load_profile_boiler.space_heating_load[`monthly_mmbtu_${i}`]);

          var monthlyAddressableLoad = this.run.site.load_profile_boiler.space_heating_load.addressable_load_fraction;
          if(monthlyAddressableLoad) {
            addressable_load_fraction.push(monthlyAddressableLoad / 100.0);
          } else {
            addressable_load_fraction.push(1.0);
          }
        }

        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
          doe_reference_name: this.run.site.load_profile_boiler.space_heating_load.doe_reference_name,
          monthly_mmbtu: monthly_mmbtu,
          addressable_load_fraction: addressable_load_fraction,
        }
        params['load_type'] = "space_heating"

        var self = this;
        $.ajax({
          url: self.urlRoot + "/simulated-monthly-thermal-load",
          data: params,
          success: function(data) {
            var payload = {
              spaceLoadsMMBtu: data.loads_mmbtu_per_hour,
              spaceMinMMBtu: data.min_mmbtu_per_hour,
              spaceMeanMMBtu: data.mean_mmbtu_per_hour,
              spaceMaxMMBtu: data.max_mmbtu_per_hour,
              spaceAnnualMMBtu: data.annual_mmbtu,
            }
            self.$store.commit('updateObject', { object: 'simMonthlySpaceLoad', value: payload });
            self.getThermalSimBuildingMonthlyLoad('space');
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'simMonthlySpaceLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }
        })
      },
      getSimMonthlyWaterLoad: function() {
        var monthly_mmbtu = [];
        var addressable_load_fraction = [];
        for(var i = 1; i <= 12; i++) {
          monthly_mmbtu.push(this.run.site.load_profile_boiler.water_heating_load[`monthly_mmbtu_${i}`]);

          var monthlyAddressableLoad = this.run.site.load_profile_boiler.water_heating_load.addressable_load_fraction;
          if(monthlyAddressableLoad) {
            addressable_load_fraction.push(monthlyAddressableLoad / 100.0);
          } else {
            addressable_load_fraction.push(1.0);
          }
        }

        var params = {
          latitude: this.run.site.latitude,
          longitude: this.run.site.longitude,
          doe_reference_name: this.run.site.load_profile_boiler.water_heating_load.doe_reference_name,
          monthly_mmbtu: monthly_mmbtu,
          addressable_load_fraction: addressable_load_fraction,
        }
        params['load_type'] = "dhw"

        var self = this;
        $.ajax({
          url: self.urlRoot + "/simulated-monthly-thermal-load",
          data: params,
          success: function(data) {
            var payload = {
              dhwLoadsMMBtu: data.loads_mmbtu_per_hour,
              dhwMinMMBtu: data.min_mmbtu_per_hour,
              dhwMeanMMBtu: data.mean_mmbtu_per_hour,
              dhwMaxMMBtu: data.max_mmbtu_per_hour,
              dhwAnnualMMBtu: data.annual_mmbtu,
            }
            self.$store.commit('updateObject', { object: 'simMonthlyWaterLoad', value: payload });
            self.getThermalSimBuildingMonthlyLoad('water');
          },
          error: function() {
            self.$store.commit('updateObject', { object: 'simMonthlyWaterLoad', value: {} });
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }
        })
      },
      getThermalSimBuildingMonthlyLoad: function(type) {
        if(
          this.run.site.load_profile_boiler.simulated_load_type != 'monthly' || 
          (type == 'total' && !this.run.site.load_profile_boiler.doe_reference_name) || 
          (type == 'space' && !this.run.site.load_profile_boiler.space_heating_load.doe_reference_name) ||
          (type == 'water' && !this.run.site.load_profile_boiler.water_heating_load.doe_reference_name)
        ) {
          return false;
        }

        if(this.allMonthlyInputsPresent(type) && this.run.site.latitude && this.run.site.longitude) {
          this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });

          if (type == "total") {
            var payload = this.simMonthlyTotalLoad;
          } else if (type == "space" || type == 'water') {
            if(this.simMonthlySpaceLoad.spaceLoadsMMBtu && this.simMonthlyWaterLoad.dhwLoadsMMBtu) {
              var space_data = this.simMonthlySpaceLoad;
              var space_payload = {
                spaceLoadsMMBtu: space_data.spaceLoadsMMBtu,
                spaceMinMMBtu: space_data.spaceMinMMBtu,
                spaceMeanMMBtu: space_data.spaceMeanMMBtu,
                spaceMaxMMBtu: space_data.spaceMaxMMBtu,
                spaceAnnualMMBtu: space_data.spaceAnnualMMBtu,
              }
            
              var water_data = this.simMonthlyWaterLoad;
              var water_payload = {
                dhwLoadsMMBtu: water_data.dhwLoadsMMBtu,
                dhwMinMMBtu: water_data.dhwMinMMBtu,
                dhwMeanMMBtu: water_data.dhwMeanMMBtu,
                dhwMaxMMBtu: water_data.dhwMaxMMBtu,
                dhwAnnualMMBtu: water_data.dhwAnnualMMBtu,
              }
              var loads_mmbtu_per_hour = space_payload.spaceLoadsMMBtu.map(function (num, idx) {return num + water_payload.dhwLoadsMMBtu[idx];});
              var min_mmbtu_per_hour = Math.min.apply(Math, loads_mmbtu_per_hour);
              var mean_mmbtu_per_hour = (loads_mmbtu_per_hour.reduce((a, b) => a + b)) / loads_mmbtu_per_hour.length;
              var max_mmbtu_per_hour = Math.max.apply(Math, loads_mmbtu_per_hour);
              var annual_mmbtu = space_payload.spaceAnnualMMBtu + water_payload.dhwAnnualMMBtu;

              var total_payload = {
                loadsMMBtu: loads_mmbtu_per_hour,
                minMMBtu: min_mmbtu_per_hour,
                meanMMBtu: mean_mmbtu_per_hour,
                maxMMBtu: max_mmbtu_per_hour,
                annualMMBtu: annual_mmbtu,
              }
              var payload = { ...space_payload, ...water_payload, ...total_payload };
            }
          }
          this.$store.commit('updateObject', { object: 'thermalSimBuildMonthlyLoadProfile', value: payload });
          this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });

          if (this.run.analyze_ghp) {
            var dhwAnnualMMbtuForRatio = payload.dhwAnnualMMBtu;
            var annualMMbtuForRatio = payload.annualMMBtu

            this.$store.commit('updateObject', { object: 'dhwAnnualMMbtuForRatio', value: dhwAnnualMMbtuForRatio });
            this.$store.commit('updateObject', { object: 'annualMmbtuForRatio', value: annualMMbtuForRatio });
          }

          if (this.run.analyze_ghp && this.annualTonHourForRatio) {
            this.updateRatioHeatingToCooling();
          }
        }
      },
      updateRatioHeatingToCooling: function() {
        this.$emit('update-ghp-heat-to-cool');
      },
      updateThermalLoadType: function(type) {
        this.updateStoreNested2({
          association_2: 'load_profile_boiler',
          attribute: 'thermal_load_type',
          value: type
        });
        this.$emit('update-ghp-defaults');
      },
      parseCSV: function(event, type) {
        var file = event.target.files[0];

        if(file && file.name.split(".")[1] != "csv") {
          bootbox.alert("Uploaded file " + file.name + " must be a .csv");
          this.run.site.load_profile.loads_kw = [];
          event.target.value = "";

          return false;
        }

        if(file) {
          var self = this;
          Papa.parse(file, {
            delimiter: ",",
            complete: function(results) {
              try {
                if(results.errors && results.errors.length > 0) {
                  this.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile could not be parsed. File must be a CSV.<br><br>Error (row ' + results.errors[0].row + '): ' + results.errors[0].message);

                  return false;
                }

                var rows = results.data;
                var values = [];
                var hasColumns = false;
                for(var i = 0, len = rows.length; i < len; i++) {
                  var row = rows[i];

                  // Detect header columns.
                  if(i < 3) {
                    if(row[0] && typeof row[0] === 'string') {
                      var value = row[0].toLowerCase();

                      // Detect when the CSV has multiple columns. Otherwise assume
                      // the CSV is just straight list of values.
                      if(value.indexOf('hour') !== -1) {
                        hasColumns = true;
                      }

                      // Skip header columns.
                      if(value.indexOf('hour') !== -1 || value.indexOf('directions') !== -1) {
                        continue;
                      }
                    }
                  }

                  // Skip empty rows.
                  if(row.join('') === '') {
                    continue;
                  }

                  // Ensure the values are only numbers.
                  var valueIndex = (hasColumns) ? 1 : 0;
                  var value = parseFloat(row[valueIndex]);
                  if(isNaN(value)) {
                    self.run.site.load_profile.loads_kw = [];
                    event.target.value = "";
                    bootbox.alert('The load profile must contain only numeric values. Could not parse value at row ' + (i + 1) + ': "' + row[valueIndex] + '"');

                    return false;
                  }
                  values.push(value);
                }

                // Validate the file contains the expected number of value rows.
                var valuesCount = values.length;
                if(valuesCount !== 8760 && valuesCount !== 17520 && valuesCount !== 35040) {
                  self.run.site.load_profile.loads_kw = [];
                  event.target.value = "";
                  bootbox.alert('The load profile must contain either 8,760 values (hourly data), 17,520 values (30-minute data), or 35,040 values (15-minute data) of numeric values.')

                  return false;
                }

                if(type == 'total') {
                  self.updateUploadedLoadProfile(values, 'total');
                  self.updateStoreNested2({
                    association_2: 'load_profile_boiler',
                    attribute: 'loads_mmbtu_per_hour',
                    value: JSON.stringify(values)
                  });
                  self.updateStoreNested2({association_2: 'load_profile_boiler', attribute: 'thermal_load_file_name', value: file.name});
                  self.displayThermalLPLoading();
                } else if(type == 'space') {
                  self.updateUploadedLoadProfile(values, 'space');
                  self.updateStoreNested3({
                    association_2: 'load_profile_boiler',
                    association_3: 'space_heating_load',
                    attribute: 'loads_mmbtu_per_hour',
                    value: JSON.stringify(values)
                  });
                  self.updateStoreNested3({association_2: 'load_profile_boiler', association_3: 'space_heating_load', attribute: 'thermal_load_file_name', value: file.name});
                  self.displayThermalLPLoading();
                } else if(type == 'water') {
                  self.updateUploadedLoadProfile(values, 'water');
                  self.updateStoreNested3({
                    association_2: 'load_profile_boiler',
                    association_3: 'water_heating_load',
                    attribute: 'loads_mmbtu_per_hour',
                    value: JSON.stringify(values)
                  });
                  self.updateStoreNested3({association_2: 'load_profile_boiler', association_3: 'water_heating_load', attribute: 'thermal_load_file_name', value: file.name});
                  self.displayThermalLPLoading();
                }
              } finally {
              }
            }
          })
        }
      },
      applyHeatingLoadFraction: function() {
        this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile_boiler.loads_mmbtu_per_hour), 'total');
      },
      updateUploadedLoadProfile: function(values, type) {
        if(!values) {
          this.$store.commit('updateObject', { object: 'thermalUploadedLoadProfile', value: {} });
          return false;
        }
        var avgValue = 0;
        var minValue;
        var maxValue;
        for(var i = 0; i < values.length; i++) {
          var value = values[i];
          avgValue += value;

          if(minValue === undefined || value < minValue) {
            minValue = value;
          }

          if(maxValue === undefined || value > maxValue) {
            maxValue = value;
          }
        }
        avgValue = avgValue / values.length;

        if(type == 'total') {
          var total_payload = {
            loadsMMBtu: values,
            minMMBtu: minValue,
            meanMMBtu: avgValue,
            maxMMBtu: maxValue,
          } 
          this.$store.commit('updateObject', { object: 'uploadedTotalLoad', value: total_payload });
          if (this.run.site.load_profile_boiler.space_heating_load_fraction) {
            var factor = this.run.site.load_profile_boiler.space_heating_load_fraction / 100;
          } else {
            var factor = this.placeholders.site.load_profile_boiler.space_heating_load_fraction.split('%')[0] / 100;
          }
          var space_values = Object.values(values).map(function(el, i) {return el * factor;})
          var space_payload = {
            spaceLoadsMMBtu: space_values,
            spaceMinMMBtu: minValue * factor,
            spaceMeanMMBtu: avgValue * factor,
            spaceMaxMMBtu: maxValue * factor,
          } 
          this.$store.commit('updateObject', { object: 'uploadedSpaceLoad', value: space_payload });
          var water_values = Object.values(values).map(function(el, i) {return el * (1 - factor);})
          var water_payload = {
            dhwLoadsMMBtu: water_values,
            dhwMinMMBtu: minValue * (1 - factor),
            dhwMeanMMBtu: avgValue * (1 - factor),
            dhwMaxMMBtu: maxValue * (1 - factor),
          }
          this.$store.commit('updateObject', { object: 'uploadedWaterLoad', value: water_payload }); 
        } else if(type == 'space') {
          var space_payload = {
            spaceLoadsMMBtu: values,
            spaceMinMMBtu: minValue,
            spaceMeanMMBtu: avgValue,
            spaceMaxMMBtu: maxValue,
          } 
          this.$store.commit('updateObject', { object: 'uploadedSpaceLoad', value: space_payload });
          if(this.uploadedWaterLoad.dhwLoadsMMBtu) {
            var total_payload = {
              minMMBtu: minValue + this.uploadedWaterLoad.dhwMinMMBtu,
              meanMMBtu: avgValue + this.uploadedWaterLoad.dhwMeanMMBtu,
              maxMMBtu: maxValue + this.uploadedWaterLoad.dhwMaxMMBtu,
            } 
            var dhwLoadsMMBtu = this.uploadedWaterLoad.dhwLoadsMMBtu;
            total_payload["loadsMMBtu"] = values.map(function (num, idx) {return num + dhwLoadsMMBtu[idx];});
            this.$store.commit('updateObject', { object: 'uploadedTotalLoad', value: total_payload });
          }
        } else if(type == 'water') {
          var water_payload = {
            dhwLoadsMMBtu: values,
            dhwMinMMBtu: minValue,
            dhwMeanMMBtu: avgValue,
            dhwMaxMMBtu: maxValue,
          }
          this.$store.commit('updateObject', { object: 'uploadedWaterLoad', value: water_payload }); 
          if(this.uploadedSpaceLoad.spaceLoadsMMBtu) {
            var total_payload = {
              minMMBtu: minValue + this.uploadedSpaceLoad.spaceMinMMBtu,
              meanMMBtu: avgValue + this.uploadedSpaceLoad.spaceMeanMMBtu,
              maxMMBtu: maxValue + this.uploadedSpaceLoad.spaceMaxMMBtu,
            } 
            var spaceLoadsMMBtu = this.uploadedSpaceLoad.spaceLoadsMMBtu;
            total_payload["loadsMMBtu"] = values.map(function (num, idx) {return num + spaceLoadsMMBtu[idx];});
            this.$store.commit('updateObject', { object: 'uploadedTotalLoad', value: total_payload });
          }
        }
        var payload = { ...this.uploadedTotalLoad, ...this.uploadedSpaceLoad, ...this.uploadedWaterLoad };
        if(
          (this.run.site.load_profile_boiler.split_heating_loads && this.uploadedWaterLoad.dhwLoadsMMBtu && this.uploadedSpaceLoad.spaceLoadsMMBtu && this.uploadedTotalLoad.loadsMMBtu) ||
          (!this.run.site.load_profile_boiler.split_heating_loads && this.uploadedTotalLoad.loadsMMBtu)
        ) {
          this.$store.commit('updateObject', { object: 'thermalUploadedLoadProfile', value: payload });
        } else {
          this.$store.commit('updateObject', { object: 'thermalUploadedLoadProfile', value: {} });
        }
      },
      displayThermalLPLoading: function() {
        var type = this.run.site.load_profile_boiler.thermal_load_type;
        var simBuildType = this.run.site.load_profile_boiler.simulated_load_type;
        var thermalSimBuildingAnnualLP = type == "simulated" && simBuildType == "annual" && this.thermalSimBuildAnnualLoadProfile.loadsMMBtu;
        var thermalSimBuildingMonthlyLP = type == "simulated" && simBuildType == "monthly" && this.thermalSimBuildMonthlyLoadProfile.loadsMMBtu;
        var thermalUploadedLP = type == "uploaded" && this.thermalUploadedLoadProfile.loadsMMBtu;

        if(thermalSimBuildingAnnualLP || thermalSimBuildingMonthlyLP || thermalUploadedLP) {
          this.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: true });
          var self = this;
          setTimeout(function() {
            self.$store.commit('updateObject', { object: 'thermalLoadProfileLoading', value: false });
          }, 800);
        }
      },
      downloadLoadProfile: function(loadProfile) {
        if(!this.run.site.load_profile_boiler.split_heating_loads) {
          var values = this[loadProfile].loadsMMBtu;
          var rows = [ ['Hour', 'Heating Load (MMBtu/hr)'] ];
          if(!values || values.length == 0) {
            bootbox.alert("A load profile must be selected or uploaded first.");
            return false;
          }
          for(var i = 0, len = values.length; i < len; i++) {
            rows.push([i + 1, values[i]]);
          }
        } else {
          if(this[loadProfile].spaceLoadsMMBtu) {
            var space_values = this[loadProfile].spaceLoadsMMBtu;
          }
          if(this[loadProfile].dhwLoadsMMBtu) {
            var water_values = this[loadProfile].dhwLoadsMMBtu;
          }
          if(!space_values || !water_values || space_values.length == 0 || water_values.length == 0) {
            bootbox.alert("A load profile must be selected or uploaded first.");
            return false;
          }
          var rows = [ ['Hour', 'Space Heating Load (MMBtu/hr)', 'Water Heating Load (MMBtu/hr)'] ];
          for(var i = 0, len = space_values.length; i < len; i++) {
            rows.push([i + 1, space_values[i], water_values[i]]);
          }
        }

        var csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, 'REopt Load Profile.csv');
      },
      updateGHPDefaults: function() {
        this.$emit('update-ghp-defaults');
      },
      pasteInputs: function(type) {
        event.preventDefault();
        var self = this;
        var clip = event.clipboardData.getData('text');

        if (clip.includes('\r\n')) {
          var clipArray = clip.split('\r\n');
          if (clipArray.length > 12) {
            // remove any values at the end of the array if they exceed the 12 month count so the first 12 paste to the correct months
            clipArray.splice(12, clipArray.length - 12);
          }
          // remove any commas within the numbers if they exist
          clipArray.forEach(function(item, i) {
            clipArray[i] = item.split(',').join('');
          })

          // set the month number to be assigned dynamically below
          if (type === 'total') {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[3].split('_')[2]);
          } else if (type === 'percent') {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[3].split('_')[4]);
          } else {
            var month = parseInt(event.target.name.replaceAll(']', '').split('[')[4].split('_')[2]);
          }
        } else {
          // remove any commas if they exist for single value paste
          var clipValue = clip.split(',').join('');
          if (type === 'total' || type === 'percent') {
            var attributeName = event.target.name.replaceAll(']', '').split('[')[3];
          } else {
            var attributeName = event.target.name.replaceAll(']', '').split('[')[4];
          }
        }

        if (clipArray) {
          clipArray.forEach(function(item, i) {
            // Ensure the values are only numbers.
            if (isNaN(parseFloat(item))) {
              bootbox.alert('The monthly input must contain only numeric values. Could not parse value "' + item + '"');
              return false;
            } else {
              if (type === 'total') {
                self.$store.commit('updateValueNested2', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  attribute: `monthly_mmbtu_${i + month}`,
                  value: item
                });
              } else if (type === 'percent') {
                self.$store.commit('updateValueNested2', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  attribute: `addressable_load_fraction_monthly_${i + month}`,
                  value: item
                });
              } else if (type === 'space') {
                self.$store.commit('updateValueNested3', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  association_3: 'space_heating_load',
                  attribute: `monthly_mmbtu_${i + month}`,
                  value: item
                });
              } else if (type === 'water') {
                self.$store.commit('updateValueNested3', {
                  association_1: 'site',
                  association_2: 'load_profile_boiler',
                  association_3: 'water_heating_load',
                  attribute: `monthly_mmbtu_${i + month}`,
                  value: item
                });
              }
            }
          });
        } else {
          // for single input paste
          if (type === 'total') {
            self.$store.commit('updateValueNested2', {
              association_1: 'site',
              association_2: 'load_profile_boiler',
              attribute: attributeName,
              value: clipValue
            });
          } else if (type === 'percent') {
            self.$store.commit('updateValueNested2', {
              association_1: 'site',
              association_2: 'load_profile_boiler',
              attribute: attributeName,
              value: clipValue
            });
          } else if (type === 'space') {
            self.$store.commit('updateValueNested3', {
              association_1: 'site',
              association_2: 'load_profile',
              association_3: 'space_heating_load',
              attribute: attributeName,
              value: clipValue
            });
          } else if (type === 'water') {
            self.$store.commit('updateValueNested3', {
              association_1: 'site',
              association_2: 'load_profile',
              association_3: 'water_heating_load',
              attribute: attributeName,
              value: clipValue
            });
          }
        }

        if (type === 'total') {
          this.getSimMonthlyTotalLoad();
        } else if (type === 'space') {
          this.getSimMonthlySpaceLoad();
        } else if (type === 'water') {
          this.getSimMonthlyWaterLoad();
        }
      }
    },

    watch: {
      watchDependencies: function() {
        if (this.run.analyze_chp || this.run.analyze_ghp || this.run.analyze_ashp_space) {
          var heating_type = this.run.site.load_profile_boiler.thermal_load_type;
          if(heating_type == "simulated" && this.run.site.load_profile_boiler.simulated_load_type == "annual") {
            if(!this.run.site.load_profile_boiler.split_heating_loads) {
              this.updateAnnualMMBtu('total');
            } else {
              this.updateAnnualMMBtu('space');
              this.updateAnnualMMBtu('water');
            }
          } else if(heating_type == "simulated" && this.run.site.load_profile_boiler.simulated_load_type == "monthly") {
            if(!this.run.site.load_profile_boiler.split_heating_loads) {
              this.updateMonthlyMMBtu('total');
            } else {
              this.updateMonthlyMMBtu('space');
              this.updateMonthlyMMBtu('water');
            }
          } else if(heating_type == "uploaded") {
            if(!this.run.site.load_profile_boiler.split_heating_loads) {
              this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile_boiler.loads_mmbtu_per_hour), 'total');
            } else {
              this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile_boiler.space_heating_load.loads_mmbtu_per_hour), 'space');
              this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile_boiler.water_heating_load.loads_mmbtu_per_hour), 'water');
            }
          }
        }
      },
    },

    mounted: function() {
      var self = this;

      //TODO: the .click() should not ne necessary once bootstrap-vue integrated
      if(this.run.analyze_chp || this.run.analyze_ghp || this.run.analyze_ashp_space) {
        if(this.run.site.load_profile_boiler.thermal_load_type == "simulated" && this.run.site.load_profile_boiler.simulated_load_type == "annual") {
          document.querySelectorAll("[href='#thermal_load_profile_simulated_inputs']")[0].click();
          if(!this.run.site.load_profile_boiler.split_heating_loads) {
            this.updateAnnualMMBtu('total');
          } else {
            this.updateAnnualMMBtu('space');
            this.updateAnnualMMBtu('water');
          }
        } else if(this.run.site.load_profile_boiler.thermal_load_type == "simulated" && this.run.site.load_profile_boiler.simulated_load_type == "monthly") {
          document.querySelectorAll("[href='#thermal_load_profile_simulated_inputs']")[0].click();
          if(!this.run.site.load_profile_boiler.split_heating_loads) {
            this.updateMonthlyMMBtu('total');
          } else {
            this.updateMonthlyMMBtu('space');
            this.updateMonthlyMMBtu('water');
          }
        } else if(this.run.site.load_profile_boiler.thermal_load_type == "simulated_campus") {
          document.querySelectorAll("[href='#thermal_simulated_campus']")[0].click();
        } else if(this.run.site.load_profile_boiler.thermal_load_type == "uploaded") {
          document.querySelectorAll("[href='#thermal_load_profile_custom_inputs']")[0].click();
          if(!this.run.site.load_profile_boiler.split_heating_loads) {
            this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile_boiler.loads_mmbtu_per_hour), 'total');
          } else {
            this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile_boiler.space_heating_load.loads_mmbtu_per_hour), 'space');
            this.updateUploadedLoadProfile(JSON.parse(this.run.site.load_profile_boiler.water_heating_load.loads_mmbtu_per_hour), 'water');
          }
        }
      }
      
      if(this.run.site.load_profile_boiler.thermal_load_type == "simulated" && this.run.site.load_profile_boiler.simulated_load_type == "annual") {
        if(!this.run.site.load_profile_boiler.split_heating_loads) {
          this.updateAnnualMMBtu('total');
        } else {
          this.updateAnnualMMBtu('space');
          this.updateAnnualMMBtu('water');
        }
      }
    }
  }
</script>

<style>
  .noUi-handle-lower {
    background-color: rgb(61,111,189);
    box-shadow: none;
  }
</style>
