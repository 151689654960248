<template>
  <div class="form-group string optional" :class="formGroupClass">
    <div class="col-sm-6 control-label">
      <label class="string optional" :for="inputID">
        <abbr v-if="required" title="Required" class="required">*</abbr>
        {{ label }}
      </label>
      <tooltip
          :association_1="this.association_1"
          :association_2="this.association_2"
          :attribute="this.attribute"/>
    </div>
    <div class="col-sm-4">
      <input
          v-model="run[association_1][association_2][attribute]"
          @change="$emit('input-change');"
          @blur="validate()"
          class="form-control string optional"
          :placeholder="placeholder"
          type="text"
          :name="inputName"
          :id="inputID"
          :disabled="isDisabled"/>
      <span v-if="validateError" class="help-block">
        {{ validateErrorMessage }}
      </span>
    </div>
    <div v-if="placeholder && displayDefault" class="col-sm-2 default-value-block">
      <div class="default-value" :data-default-value="placeholder" :data-default-value-display="placeholder">
        {{ defaultLabel }} = {{ placeholder }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Tooltip from '../Tooltip';

export default {
  props: [
    "association_1",
    "association_2",
    "attribute",
    "required",
    "type"
  ],

  components: {
    Tooltip
  },

  data() {
    return {
      displayDefault: false,
      validateError: false,
    };
  },

  computed: {
    ...mapGetters([
      "clientSideValidations",
      "labels",
      "placeholders",
      "run",
      "selectOptions",
      "userEnteredAnnualKwhValue"
    ]),

    property() {
      return this.run[this.association_1][this.association_2][this.attribute];
    },

    label() {
      if (this.association_2 === "chp" && this.attribute === "fuel_blended_annual_rates_per_mmbtu" && (this.run.analyze_chp && this.run.site.chp.fuel_type === "diesel_oil" || this.run.site.chp.fuel_type === "propane")) {
        return "Annual CHP fuel cost ($/gal)";
      } else if (this.association_2 === "prime_generator" && this.attribute === "fuel_blended_annual_rates_per_mmbtu" && (this.run.analyze_prime_generator && this.run.site.prime_generator.fuel_type === "diesel_oil" || this.run.site.prime_generator.fuel_type === "propane")) {
        return "Annual Prime Generator fuel cost ($/gal)";
      } else if (this.association_2 === "boiler" && this.attribute === "fuel_blended_annual_rates_per_mmbtu" && (this.run.site.boiler.fuel_type === "diesel_oil" || this.run.site.boiler.fuel_type === "propane")) {
        return "Annual existing heating system fuel cost ($/gal)";
      } else if (this.association_2 === "chp" && this.attribute === "fuel_percent_RE") {
        return `CHP ${this.findSelectOptionGivenValue('chp')} percent renewable energy (%)`;
      } else if (this.association_2 === "boiler" && this.attribute === "fuel_percent_RE") {
        return `Existing heating system ${this.findSelectOptionGivenValue('boiler')} percent renewable energy (%)`;
      } else if (this.association_2 === "prime_generator" && this.attribute === "fuel_percent_RE") {
        return `Prime Generator ${this.findSelectOptionGivenValue('prime_generator')} percent renewable energy (%)`;
      } else if (this.run.grid && this.attribute === "fuel_cost_per_gallon") {
        return "Fuel cost ($/gal)";
      } else if (this.run.grid && this.run.analyze_generator && this.attribute === "existing_kw") {
        return "Existing generator size (kW)";
      } else if (this.run.focus === "resilience" && this.attribute === "generator_fuel_percent_RE") {
        return "Emergency generator fuel percent renewable energy (%)";
      } else if (this.attribute === "ghp_fluid_flow_rate_gpm_per_ton" && this.run.site.ghp.heat_pump_configuration === "WWHP") {
        return "GHP nominal flow rate of heating and cooling loop fluid (GPM/ton)";
      } else {
        return this.labels[this.association_1][this.association_2][this.attribute];
      }
    },

    placeholder() {
      const value = this.placeholders[this.association_1][this.association_2][this.attribute];

      if (value) {
        if (["emissions_factor_lb_CO2_per_mmbtu", "cop_electric"].indexOf(this.attribute) >= 0) {
          return this.$store.getters.formatAsIntegerWithCommas({value: value, decimals: 1});
        } else if (["max_thermal_factor_on_peak_load", "cop_thermal"].indexOf(this.attribute) >= 0) {
          return this.$store.getters.formatAsIntegerWithCommas({value: value, decimals: 2});
        } else if (["cop"].indexOf(this.attribute) >= 0) {
          return this.$store.getters.formatAsIntegerWithCommas({value: value, decimals: 2});
        } else if (this.attribute === "ghp_fluid_flow_rate_gpm_per_ton" && this.run.site.ghp.heat_pump_configuration === "WWHP") {
          return "3.0";
        } else if (this.attribute === "om_cost_per_ton" && ["ashp_space_heater", "ashp_water_heater"].includes(this.association_2) && ![1, 3].includes(this.run.site.ashp_space_heater.ashp_configuration)) {
          return "$0";
        } else {
          return value;
        }
      }
    },

    formGroupClass() {
      return `run_${this.association_1}_${this.association_2}_${this.attribute}`;
    },

    inputID() {
      return `run_${this.association_1}_attributes_${this.association_2}_attributes_${this.attribute}`;
    },

    inputName() {
      return `run[${this.association_1}_attributes][${this.association_2}_attributes][${this.attribute}]`;
    },

    isDisabled() {
      if (this.association_2 === "electric_tariff" && this.attribute === "wholesale_rate" && this.run.site.electric_tariff.custom_wholesale_rate) {
        return true;
      } else {
        return !!(this.attribute === "cambium_start_year" && this.run.site.electric_tariff.cambium_greening_of_grid);
      }
    },

    defaultLabel() {
      if (this.association_2 === "load_profile" && this.attribute === "annual_kwh") {
        return "Calc. default";
      } else {
        return "default";
      }
    },

    validateErrorMessage() {
      return this.clientSideValidations.run[this.association_1][this.association_2][this.attribute];
    }
  },

  methods: {
    validate() {
      if (this.required && !this.run[this.association_1][this.association_2][this.attribute]) {
        this.validateError = true;
      } else if (this.required && this.run[this.association_1][this.association_2][this.attribute]) {
        this.validateError = false;
      }
    },

    toggleDisplayDefault() {
      var value = this.run[this.association_1][this.association_2][this.attribute];

      if (!value) {
        this.displayDefault = false;
      } else {
        this.displayDefault = true;
      }
    },

    findSelectOptionGivenValue(technology) {
      var selected;
      var self = this;
      var options = this.selectOptions.site[technology].fuel_type.options;

      options.forEach((el,) => {
        if (el.value === self.run.site[technology].fuel_type) {
          selected = el.label;
        }
      });

      return selected;
    }
  },

  watch: {
    property() {
      if (this.property) {
        this.displayDefault = true;
      } else {
        this.displayDefault = false;
      }
    },
    validateError() {
      if (this.validateError) {
        document.getElementsByClassName(this.formGroupClass)[0].classList.add("has-error");
      } else {
        document.getElementsByClassName(this.formGroupClass)[0].classList.remove("has-error");
      }
    }
  },

  mounted() {
    // needed for when user enters value, then section disappears, then reappears
    // should display value in field & `default = XYZ` in side column
    if (this.property) {
      this.displayDefault = true;
    } else {
      this.displayDefault = false;
    }
  }
};
</script>
