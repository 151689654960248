<template>
  <div class="custom-monthly-rate" style="margin-top: 5px;">
    <div class="container">
      <div class="row">
        <div class="col-xs-8 col-sm-8" style="text-align: right;">
        </div>
        <div class="col-xs-4 col-sm-4">
          <p v-if="type == 'electric'">Energy consumption (kWh)</p>
          <p v-else-if="type == 'thermal'">Monthly heating boiler fuel consumption (MMBtu)</p>
          <p v-else-if="type == 'cold'">Monthly thermal energy consumption (tonhour)</p>
          <p v-else-if="type == 'cold-percent'">Monthly fixed percentage of total electric load (%) <tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'monthly_fraction'"></tooltip></p>
          <p v-else-if="type == 'boiler-cost' && (run.site.boiler.fuel_type == 'natural_gas' || run.site.boiler.fuel_type == 'landfill_bio_gas')">Monthly heating system fuel cost ($/MMBtu) <tooltip :association_1="'site'" :association_2="'boiler'" :attribute="'fuel_type'"></tooltip></p>
          <p v-else-if="type == 'boiler-cost' && (run.site.boiler.fuel_type == 'diesel_oil' || run.site.boiler.fuel_type == 'propane')">Monthly heating system fuel cost ($/gallon) <tooltip :association_1="'site'" :association_2="'boiler'" :attribute="'fuel_type'"></tooltip></p>
          <p v-else-if="type == 'chp-fuel-cost' && (run.site.chp.fuel_type == 'natural_gas' || run.site.chp.fuel_type == 'landfill_bio_gas')">Monthly CHP fuel cost ($/MMBtu) <tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'monthly_fraction'"></tooltip></p>
          <p v-else-if="type == 'chp-fuel-cost' && (run.site.chp.fuel_type == 'diesel_oil' || run.site.chp.fuel_type == 'propane')">Monthly CHP fuel cost ($/gallon) <tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'monthly_fraction'"></tooltip></p>
          <p v-else-if="type == 'prime-generator-fuel-cost' && (run.site.prime_generator.fuel_type == 'natural_gas' || run.site.prime_generator.fuel_type == 'landfill_bio_gas')">Monthly Prime Generator fuel cost ($/MMBtu) <tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'monthly_fraction'"></tooltip></p>
          <p v-else-if="type == 'prime-generator-fuel-cost' && (run.site.prime_generator.fuel_type == 'diesel_oil' || run.site.prime_generator.fuel_type == 'propane')">Monthly Prime Generator fuel cost ($/gallon) <tooltip :association_1="'site'" :association_2="'load_profile_chiller'" :attribute="'monthly_fraction'"></tooltip></p>
        </div>
      </div>
    </div>
    <div v-for="(month, i) in months" class="container monthly-entry-rows">
      <div class="row">
        <div class="col-xs-8 col-sm-8" style="text-align: right;">
          {{ month }}
        </div>
        <div class="col-xs-2 col-sm-2 monthly-entry-row">
          <input v-if="type == 'electric'" v-model="run.site.load_profile[`energy_use_monthly_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} Energy Cost($/kWh)`" type="number" step="any" :name="name(i)" />
          <input v-else-if="type == 'cold'" v-model="run.site.load_profile_chiller[`monthly_tonhour_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} Energy Cost($/kWh)`" type="number" step="any" :name="name(i)" />
          <input v-else-if="type == 'cold-percent'" v-model="run.site.load_profile_chiller[`lp_fraction_monthly_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly fixed fraction of total electric load`" type="number" step="any" :name="name(i)" />
          <input v-else-if="type == 'boiler-cost'" v-model="run.site.boiler[`fuel_monthly_rate_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly fixed fraction of total electric load`" type="number" step="any" :name="name(i)" />
          <input v-else-if="type == 'chp-fuel-cost'" v-model="run.site.chp[`fuel_monthly_rate_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly fixed fraction of total electric load`" type="number" step="any" :name="name(i)" />
          <input v-else-if="type == 'prime-generator-fuel-cost'" v-model="run.site.prime_generator[`fuel_monthly_rate_${i + 1}`]" @change="$emit('input-change')" @paste="$emit('paste-inputs')" class="numeric decimal optional energy-cost-monthly form-control" label="false" :aria-label="`${month} monthly fixed fraction of total electric load`" type="number" step="any" :name="name(i)" />
        </div>
        <div v-if="type == 'electric'" class="col-sm-2 default-value-block">
          <div v-if="run.site.load_profile.load_reduction !== '100.00' && userEnteredMonthlyKwhValues[i]" class="default-value" data-default-value="test" data-default-value-display="test">
            User Value = {{ Number(userEnteredMonthlyKwhValues[i]).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Tooltip from '../Tooltip';

  export default {
    components: {
      Tooltip
    },

    props: ['type'],

    data: function() {
      return {
        months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
      }
    },

    computed: {
      ...mapGetters([
        'run',
        'userEnteredMonthlyKwhValues'
      ]),
    },

    methods: {
      name: function(i) {
        if(this.type == "electric") {
          return `run[site_attributes][load_profile_attributes][energy_use_monthly_${i + 1}]`;
        } else if(this.type == "cold") {
          return `run[site_attributes][load_profile_chiller_attributes][monthly_tonhour_${i + 1}]`;
        } else if(this.type == "cold-percent") {
          return `run[site_attributes][load_profile_chiller_attributes][lp_fraction_monthly_${i + 1}]`;
        } else if(this.type == ("boiler-cost")) {
          return `run[site_attributes][boiler_attributes][fuel_monthly_rate_${i + 1}]`;
        } else if(this.type == "chp-fuel-cost") {
          return `run[site_attributes][chp_attributes][fuel_monthly_rate_${i + 1}]`;
        } else if(this.type == "prime-generator-fuel-cost") {
          return `run[site_attributes][prime_generator_attributes][fuel_monthly_rate_${i + 1}]`;
        }
      }
    }
  }
</script>
